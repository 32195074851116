import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/dashboard/container';
import { isAuthor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import StoriesForm from './storiesForm';
import * as ROUTES from '../../constants/routes';

const NewClient = () => (
  <Container backPath={ROUTES.STORIES} title="Nueva Historia">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <StoriesForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isAuthor)(NewClient);
