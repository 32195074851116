import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import PilotoForm from './pilotForm';
import * as ROUTES from '../../../constants/routes';

const NewPiloto = () => (
  <Container backPath={ROUTES.PILOTOS} title="Nuevo Piloto">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <PilotoForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isAeroAdmin)(NewPiloto);
