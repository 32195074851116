import React from 'react';
import {
  Route,
} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import { withAuthorization } from '../../model/Session';
import Navigation from './navigation';
import HomePage from '../../routes/Home';
import AccountPage from '../../routes/Account';
import AdminPage from '../../routes/Admin';
import ClientPage from '../../routes/Clients';
import BusinessPage from '../../routes/Business';
import DatesPage from '../../routes/Dates';
import StoriesPage from '../../routes/Stories';
import CategoriesPage from '../../routes/Categories';
import CitiesPage from '../../routes/Fedeaereos/Ciudades';
import ClubesPage from '../../routes/Fedeaereos/Clubes';
import CompetitionPage from '../../routes/Fedeaereos/Competitions';
import PilotosPage from '../../routes/Fedeaereos/Pilotos';
import LolaMora from '../../routes/LolaMora';
import * as ROUTES from '../../constants/routes';
import { DEFAULT_TITLE } from '../title';
import Maclic from '../maclic';
import MadeWithLove from '../made';
import AccountMenu from '../accountMenu';
import { NotificationReceiver } from '../notification';
import maclicBackground from '../../media/maclic-background.png';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: theme.grayMaclic,
    backgroundImage: `url(${maclicBackground})`,
    backgroundSize: 200,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: '48px !important',
  },
  appBar: {
    background: theme.background,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.shadows[1],
  },
  // appBar: {
  //
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  // },
  // appBarShift: {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  menuButton: {
    marginRight: 10,
  },
  // menuButtonHidden: {
  //   display: 'none',
  // },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    width: 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawer = () => {
    setOpen(!open);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Maclic />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {DEFAULT_TITLE}
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open
      >
        <div className={classes.toolbarIcon} />
        <Navigation setOpenNavigation={setOpen} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Route exact path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.CLIENTES} component={ClientPage} />
              <Route path={ROUTES.PLACES} component={BusinessPage} />
              <Route path={ROUTES.DATES} component={DatesPage} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.STORIES} component={StoriesPage} />
              <Route path={ROUTES.CATEGORIES} component={CategoriesPage} />
              <Route path={ROUTES.CIUDADES} component={CitiesPage} />
              <Route path={ROUTES.CLUBES} component={ClubesPage} />
              <Route path={ROUTES.COMPETENCIAS} component={CompetitionPage} />
              <Route path={ROUTES.PILOTOS} component={PilotosPage} />
              <Route path={ROUTES.LOLA} component={LolaMora} />
            </Grid>
          </Grid>
        </Container>
        <MadeWithLove />
        <NotificationReceiver />
      </main>
    </div>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Dashboard);
