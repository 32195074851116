import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';

const RenderDate = ({ date, format }) => {
  const newDate = moment(date);
  const formatted = newDate.isValid() ? newDate.format(format) : 'Fecha no válida';
  return (
    <>
      {formatted}
    </>
  );
};

RenderDate.defaultProps = {
  format: 'ddd, DD \\d\\e MMMM, hh:mm a',
  date: '',
};

RenderDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  format: PropTypes.string,
};

export default RenderDate;
