import PropTypes from 'prop-types';
import React from 'react';
import Background from 'react-social-icons/dist/background';
import Icon from 'react-social-icons/dist/icon';
import Mask from 'react-social-icons/dist/mask';
import { keyFor } from 'react-social-icons/dist/networks';
import { socialContainer, socialIcon, socialSvg } from 'react-social-icons/dist/styles';

function getNetworkKey(props) {
  return props.network || keyFor(props.url);
}

function SocialIcon(props) {
  const {
    url, network, bgColor, fgColor, className, label, style, ...rest
  } = props;
  const networkKey = getNetworkKey({ url, network });

  return (
    <div
      {...rest}
      className={`${className || ''}`}
      style={{ ...socialIcon, ...style }}
      aria-label={label || networkKey}
    >
      <div className="social-container" style={socialContainer}>
        <svg className="social-svg" style={socialSvg} viewBox="0 0 64 64">
          <Background />
          <Icon networkKey={networkKey} fgColor={fgColor} />
          <Mask networkKey={networkKey} bgColor={bgColor} />
        </svg>
      </div>
    </div>
  );
}

SocialIcon.defaultProps = {
  bgColor: null,
  fgColor: null,
  network: null,
  url: null,
  className: null,
  label: null,
  style: {},
};

SocialIcon.propTypes = {
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  network: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default SocialIcon;
