import React from 'react';
import * as PropTypes from 'prop-types';
import { Marker } from 'google-maps-react';
import CurrentLocation from './currentLocation';
import MMap from './map';

const MapInput = ({ onChange, value }) => {
  const setLocation = (location) => {
    if (onChange) {
      onChange(location);
    }
  };
  const onClick = (mapProps, map, clickEvent) => {
    setLocation({
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng(),
    });
  };
  return (
    <div>
      <CurrentLocation onGetCurrentLocation={setLocation} />
      <MMap
        center={value}
        onClick={onClick}
        initialCenter={value}
        height={200}
      >
        {!!value && (
          <Marker
            title="Ubicación actual del lugar"
            name="Lugar/Negocio"
            position={value}
          />
        )}
      </MMap>
    </div>
  );
};

MapInput.defaultProps = {
  onChange: null,
  value: null,
};

MapInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default MapInput;
