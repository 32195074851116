/* eslint-disable no-undef */
import React from 'react';
import AuthUserContext from './context';
import { FirebasePropType, withFirebase } from '../Firebase';
import WarningTab from '../../components/warningTab';

const withAuthentication = (Component) => {
  const WithAuthentication = ({ firebase, ...props }) => {
    const [authUser, setAuthUser] = React.useState(JSON.parse(localStorage.getItem('authUser')));
    const [multipleTabs, setMultipleTabs] = React.useState(null);
    const listener = React.useRef(null);
    React.useEffect(() => {
      firebase.enablePersistence()
        .then(() => {
          setMultipleTabs(false);
        })
        .catch(() => {
          setMultipleTabs(true);
        })
        .finally(() => {
          listener.current = firebase.onAuthUserListener(
            (user) => {
              localStorage.setItem('authUser', JSON.stringify(user));
              setAuthUser(user);
            },
            () => {
              localStorage.removeItem('authUser');
              setAuthUser(null);
            },
          );
        });
      return () => {
        listener.current = () => {};
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (multipleTabs === null) {
      return null;
    }
    return (
      <AuthUserContext.Provider value={authUser}>
        {!!multipleTabs && <WarningTab />}
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };
  WithAuthentication.propTypes = {
    firebase: FirebasePropType.isRequired,
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
