import React from 'react';
import sections from './sections';
import { MTab } from '../../../components/tabs';

const sectionTabs = (authUser, membership) => Object.keys(sections).map((item) => {
  const section = sections[item];
  if (section.isRestricted(membership, authUser)) {
    return null;
  }
  return (
    <MTab
      key={item}
      icon={section.icon}
      label={section.label}
      disabled={section.disabled}
      value={item}
    />
  );
});

export default sectionTabs;
