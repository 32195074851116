import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  makeStyles, Card, CardActions,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Background } from 'react-imgix';
import MembershipChip from '../../../components/renders/renderMembershipChip';
import Fields from '../fields';
import { domain } from '../../../model/Images';
import {
  isAuthor, isAuthorOrTranslator, isTranslator,
} from '../../../components/access';
import * as ROUTES from '../../../constants/routes';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import confirm from '../../../components/confirm';
import {
  DeleteButton, EditButton, TranslateButton,
} from '../../../components/buttons';

const defaultImage = encodeURI(`${domain}/defaults/maclic-transparent.png`);

const useStyles = makeStyles((theme) => {
  const media = {
    width: '100%',
    paddingTop: '60%',
    position: 'relative',
  };
  return {
    // card: {
    //   maxWidth: 345,
    // },
    titleContainer: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    title: {
      ...theme.typography.subtitle1,
      fontWeight: 500,
      padding: 0,
      margin: 0,
      color: 'white',
      textShadow: '1px 1px 1px #707070',
    },
    alpha: {
      ...media,
      backgroundColor: grey[800],
    },
  };
});

const getEditButton = (id, authUser, history) => {
  if (isAuthor(authUser)) {
    return (
      <EditButton
        aria-label="Modificar"
        onClick={() => {
          history.push(`${ROUTES.STORIES}/detail/${id}/edit`);
        }}
      />
    );
  }
  return null;
};

const getTranslateButton = (id, authUser, history) => {
  if (isTranslator(authUser)) {
    return (
      <TranslateButton
        aria-label="Traducir"
        onClick={() => {
          history.push(`${ROUTES.STORIES}/detail/${id}/translate`);
        }}
      />
    );
  }
  return null;
};

const deleteAction = (id, firebase, notification, photo) => {
  confirm(
    '¿Está seguro de borrar la historia?',
    'Confirmar Borrar',
  )
    .then(
      () => {
        firebase.story(id)
          .delete()
          .then(() => {
            notification.setMessage({ message: 'Historia borrada satisfactoriamente 🙂', type: 'success' });
          })
          .catch(() => {
            notification.setMessage({ message: 'Algo no salió bien borrando la historia 😥', type: 'warning' });
          })
          .finally(() => {
            if (photo) {
              firebase.deleteFile(photo)
                .then(() => console.info(`Photo Removed: ${photo}`))
                .catch(e => console.warn(e.message));
            }
          });
      },
      () => {},
    );
};

const getDeleteButton = (id, authUser, firebase, notification, photo) => {
  if (isAuthor(authUser)) {
    return (
      <DeleteButton
        aria-label="Borrar"
        onClick={() => deleteAction(id, firebase, notification, photo)}
      />
    );
  }
  return null;
};

const PlaceItem = ({
  story, authUser, history, firebase, notification,
}) => {
  const classes = useStyles();
  const [display, setDisplay] = React.useState({
    id: '',
    name: 'Maclic Story',
    membership: 'alpha',
    membershipLabel: 'Story category',
    image: defaultImage,
    imageReference: null,
  });

  React.useEffect(() => {
    let image = defaultImage;
    let imageReference = null;
    if (story.photo && story.photo.length > 0) {
      // image = buildURL(`${domain}${place.photos[0].reference.replace('/images', '')}`,
      // { w: 400 });
      image = encodeURI(`${domain}${story.photo.replace('/images', '')}`);
      imageReference = story.photo;
    }

    setDisplay({
      name: story.title,
      id: story.id,
      category: story.category,
      image,
      imageReference,
    });
  }, [story]);

  const editButton = getEditButton(display.id, authUser, history);
  const deleteButton = getDeleteButton(display.id, authUser, firebase, notification, display.imageReference);
  const translateButton = getTranslateButton(display.id, authUser, history);

  return (
    <Card>
      <Background src={display.image} className={classes.alpha}>
        <div className={classes.titleContainer}>
          <h5 className={classes.title}>
            {display.name}
          </h5>
          <MembershipChip
            style={{ fontSize: 10, height: 20 }}
            value="alpha"
            label={display.category}
          />
        </div>
      </Background>
      <CardActions disableSpacing>
        {editButton}
        {deleteButton}
        {translateButton}
      </CardActions>
    </Card>
  );
};

PlaceItem.propTypes = {
  story: Fields.getPropTypes().isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withCurrentUser,
  withNotification,
  withFirebase,
  withRouter,
)(PlaceItem);
