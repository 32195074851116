import React from 'react';
import * as PropTypes from 'prop-types';
import {
  makeStyles, Grid, Typography, Chip,
} from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { RenderDate } from '../../../../components/renders';
import { DOC_TYPES, COUNTRIES } from '../fields';
import FieldsComps from '../../Competitions/fields';
import LicenseLabelItem from './licenseLabelItem';
import LicenseTitleSection from './licenseTitleSection';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
  competition: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    marginTop: theme.spacing(0.2),
    marginRight: theme.spacing(0.2),
  },
}));

const RenderCompetition = ({ classes, label }) => (
  <Chip
    component="span"
    label={label}
    className={classes.competition}
  />
);

RenderCompetition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

const BasicSection = ({ pilot, firebase }) => {
  const classes = useStyles();
  const countries = COUNTRIES();
  const [competitions, setCompetitions] = React.useState([]);
  React.useEffect(() => {
    let unsubscribe;
    if (pilot) {
      unsubscribe = firebase.competitions()
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            setCompetitions([]);
          } else {
            const comps = [];
            snapshot.forEach((doc) => {
              comps.push(FieldsComps.getInstance(doc));
            });
            if (pilot.events && Object.keys(pilot.events).length > 0) {
              const events = comps.filter((comp) => pilot.events[comp.id]);
              setCompetitions(events);
            }
          }
        }, (e) => {
          console.warn(e.message);
          setCompetitions([]);
        });
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, pilot]);
  if (pilot && competitions) {
    // console.warn(pilot.docType);
    return (
      <div>
        <LicenseTitleSection title="Información Básica" />
        <Grid container spacing={3}>
          <LicenseLabelItem label="Nombres" value={pilot.firstName} />
          <LicenseLabelItem label="Apellidos" value={pilot.lastName} />
          {!!pilot.docType && (
            <LicenseLabelItem label="Tipo de documento" value={DOC_TYPES[pilot.docType].label} />
          )}
          <LicenseLabelItem label="Número de documento" value={pilot.docNumber} />
          {!!pilot.docDueDate && (
            <LicenseLabelItem
              label="Fecha de vencimiento documento"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.docDueDate} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {!!pilot.countryBirth && (
            <LicenseLabelItem label="País de nacimiento" value={countries[pilot.countryBirth].label} />
          )}
          {!!pilot.dateBirth && (
            <LicenseLabelItem
              label="Fecha de nacimiento"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.dateBirth} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {!!pilot.nationality && (
            <LicenseLabelItem label="Nacionalidad" value={countries[pilot.nationality].label} />
          )}
          {!!pilot.countryResidence && (
            <LicenseLabelItem label="País de residencia" value={countries[pilot.countryResidence].label} />
          )}
          {!!pilot.arriveDate && (
            <LicenseLabelItem
              label="Fecha de llegada"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.arriveDate} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {!!pilot.leaveDate && (
            <LicenseLabelItem
              label="Fecha de salida"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.leaveDate} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {competitions.length > 0 && (
            <LicenseLabelItem
              label="Competencias"
              value={competitions.map((competition) => (
                <RenderCompetition classes={classes} label={competition.name} />
              ))}
            />
          )}
        </Grid>
      </div>
    );
  }
  return null;
};

BasicSection.defaultProps = {
  pilot: null,
};

BasicSection.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
};

export default withFirebase(BasicSection);
