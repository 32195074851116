import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { PanelCalendarPlace } from '../../Dates';
import MPanel from '../../../components/panel';

const Calendar = ({ id }) => (
  <Grid item xs={12}>
    <MPanel withPaper title="Citas del Lugar/Negocio">
      {!!id && (
        <PanelCalendarPlace placeId={id} />
      )}
    </MPanel>
  </Grid>
);

Calendar.defaultProps = {
  id: null,
};

Calendar.propTypes = {
  id: PropTypes.string,
};

export default Calendar;
