import React from 'react';
import { compose } from 'recompose';
import {
  Grid, makeStyles, Typography, Button,
} from '@material-ui/core';
import Container from '../../../components/dashboard/container';
import {
  isCoordinator, isAuthorOrTranslator,
} from '../../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import { MButton } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from '../fields';
import Loading from '../../../components/loading';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import PlaceItem from '../placeItem';
import Filter from './filter';
import { TRANSLATE_STATUS } from '../Sections/translate';

const BLOCK = 16;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const getFilteredItems = (data, filters) => new Promise((resolve) => {
  if (filters.name === '' && filters.membership === '' && filters.translated === '') {
    resolve([...data]);
  }
  let tempData = [];
  if (filters.membership !== '') {
    tempData = data.filter((item) => item.membership === filters.membership);
  } else {
    tempData.push(...data);
  }
  if (filters.translated && filters.translated !== '') {
    tempData = tempData.filter((item) => {
      if (!item.translated && filters.translated === TRANSLATE_STATUS.pending.value) {
        return true;
      }
      return item.translated === filters.translated;
    });
  }
  if (filters.name !== '') {
    const reg = new RegExp(`${filters.name}`, 'i');
    tempData = tempData.filter((item) => reg.test(item.name));
  }
  resolve(tempData);
});

const ListBusiness = ({ firebase, authUser, notification }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [values, setValues] = React.useState({
    items: [],
    more: true,
    quantity: 0,
  });
  const [filter, setFilter] = React.useState({ name: '', membership: '', translated: '' });

  const loadData = React.useCallback((theData, theValues, cb) => {
    if (theData.length === 0) {
      setValues({
        items: [],
        more: false,
        quantity: BLOCK,
      });
      cb();
      return;
    }
    let { quantity } = theValues;
    quantity += BLOCK;
    let more = true;
    if (quantity >= theData.length) {
      more = false;
    }
    const items = theData.slice(0, quantity);
    setValues({
      items,
      more,
      quantity,
    });
    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase.places().onSnapshot((snapshot) => {
      const dataTmp = [];
      snapshot.forEach((doc) => {
        const place = Fields.getInstance(doc);
        // if (!isContentEditor(authUser)) {
        //   dataTmp.push(place);
        // } else if (CONTENT_MEMBERSHIPS.includes(place.membership)
        //   && !isRestrictedPlace(authUser, place.id)) {
        //   dataTmp.push(place);
        // }
        dataTmp.push(place);
      });
      setData(dataTmp);
      setFilteredData(dataTmp);
      loadData(dataTmp, values, () => {
        setLoading(false);
      });
    }, () => {
      // eslint-disable-next-line no-console
      setLoading(false);
      notification.setMessage({ type: 'error', message: 'Ups! Algo no salió bien cargando los datos.' });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    setLoadingMore(true);
    loadData(filteredData, values, () => {
      setLoadingMore(false);
    });
  };

  const filterAction = (result) => {
    setLoading(true);
    setFilter(result);
    getFilteredItems(data, result)
      .then((tempData) => {
        setFilteredData(tempData);
        const theValues = { ...values };
        theValues.quantity = BLOCK;
        loadData(tempData, theValues, () => {
          setLoading(false);
        });
      });
  };

  let component = (
    <Grid item xs={12}>
      <Loading />
    </Grid>
  );
  if (!loading) {
    component = values.items.length > 0 ? (
      <>
        {values.items.map((item) => (
          <Grid item lg={3} md={4} xs={12} key={item.id}>
            <PlaceItem place={item} />
          </Grid>
        ))}
        {!!values.more && (
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
            <Button
              disabled={loadingMore}
              onClick={loadMore}
              color="primary"
              size="large"
              variant="outlined"
            >
              {loadingMore ? 'Cargando' : 'Cargar más'}
            </Button>
          </Grid>
        )}
      </>

    ) : (
      <Grid item xs={12}>
        <Typography variant="button" style={{ textAlign: 'center' }} component="h2">
          No se encontraron lugares/negocios.
        </Typography>
      </Grid>
    );
  }

  return (
    <Container
      title="Listado de Lugares/Negocios"
      header={(
        !!isCoordinator(authUser)
        && <MButton fullWidth={false} label="Nuevo Lugar/Negocio" to={`${ROUTES.PLACES}/new`} />
      )}
    >
      <Grid className={classes.root} spacing={3} container>
        <Filter disabled={loading} authUser={authUser} filter={filterAction} values={filter} />
        {component}
      </Grid>
    </Container>
  );
};

ListBusiness.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withCurrentUser,
  withFirebase,
  withNotification,
)(ListBusiness);
