import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isLolaAdmin } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import NewArtista from './Artistas/new';
import ArtistasList from './Artistas/list';
import EditArtista from './Artistas/edit';
import ExpresionNew from './Expresiones/new';
import ExpresionList from './Expresiones/list';
import ExpresionEdit from './Expresiones/edit';

const LolaMora = () => (
  <Switch>
    <Route path={`${ROUTES.ARTISTAS}/new`} component={NewArtista} />
    <Route path={`${ROUTES.ARTISTAS}/edit/:id`} component={EditArtista} />
    <Route path={ROUTES.ARTISTAS} component={ArtistasList} />
    <Route path={`${ROUTES.EXPRESIONES}/new`} component={ExpresionNew} />
    <Route path={`${ROUTES.EXPRESIONES}/edit/:id`} component={ExpresionEdit} />
    <Route path={ROUTES.EXPRESIONES} component={ExpresionList} />
    {/* <Route path={`${ROUTES.PILOTOS}/edit/:id`} component={EditPiloto} /> */}
    {/* <Route path={`${ROUTES.PILOTOS}/license/:id`} component={GenerateLicense} /> */}
    {/* <Route path={ROUTES.PILOTOS} component={TablePiloto} /> */}
  </Switch>
);

export default withAuthorization(isLolaAdmin)(LolaMora);
