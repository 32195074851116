import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from 'react-router-dom';
import MaclicTheme from '../../components/theme';
import Dashboard from '../../components/dashboard';
import Access from '../../components/access';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../model/Session';
import Title from '../../components/title';

const App = () => {
  React.useEffect(() => {
    const element = document.getElementById('preloading'); // eslint-disable-line
    element.parentNode.removeChild(element);
  }, []);
  return (
    <MaclicTheme>
      <Title>
        <Router>
          <Switch>
            <Route path={ROUTES.HOME} component={Dashboard} />
            <Route path={ROUTES.SIGN_IN} component={Access} />
            <Redirect to={ROUTES.HOME} />
          </Switch>
        </Router>
      </Title>
    </MaclicTheme>
  );
};

export default withAuthentication(App);
