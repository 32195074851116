import React from 'react';
import * as PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import {
  Button, LinearProgress, makeStyles, Typography,
} from '@material-ui/core';

import './dnd.css';

const useStyles = makeStyles((theme) => ({
  dndContainer: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 2,
  },
}));

const DragNDrop = ({
  onClick, handleDrop, buttonText, areaText, disabled, loaded,
}) => {
  const classes = useStyles();

  const onDrop = (source, event) => {
    event.preventDefault();
    event.stopPropagation();
    handleDrop(Object.values(source));
  };

  const component = loaded === -1 ? (
    <FileDrop onDrop={onDrop}>
      <Typography variant="overline" component="span">
        {areaText}
      </Typography>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        size="small"
        fullWidth={false}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </FileDrop>
  ) : (
    <>
      <Typography variant="overline" display="block" style={{ textAlign: 'center' }}>
        {`Cargando archivos ${Math.round(loaded)}%`}
      </Typography>
      <LinearProgress variant="determinate" value={loaded} />
    </>
  );

  return (
    <div id="dnd-files" className={classes.dndContainer}>
      {component}
    </div>
  );
};

DragNDrop.defaultProps = {
  areaText: ' Arrastra archivos aquí o ',
  buttonText: 'Carga Documento',
  disabled: false,
  loaded: -1,
};

DragNDrop.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  areaText: PropTypes.string,
  disabled: PropTypes.bool,
  loaded: PropTypes.number,
};

export default DragNDrop;
