import React from 'react';
import * as PropTypes from 'prop-types';
import { Detector } from 'react-detect-offline';
import { Chip, makeStyles, Typography } from '@material-ui/core';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import { NotificationPropTypes, withNotification } from '../notification';

const useStyles = makeStyles((theme) => {
  const chip = {
    fontSize: theme.typography.pxToRem(11),
    color: 'white',
    height: theme.spacing(3),
    border: 0,
    borderRadius: 0,
    width: '100%',
  };
  return {
    chipOnline: {
      ...chip,
      backgroundColor: green[400],
    },
    chipOffline: {
      ...chip,
      backgroundColor: grey[500],
    },
    icon: {
      color: 'white',
      width: '1rem',
      height: '1rem',
    },
    message: {
      backgroundColor: grey[500],
      color: 'white',
      width: '100%',
      margin: 0,
      padding: theme.spacing(1, 2),
      whiteSpace: 'normal',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(13),
      display: 'block',
    },
  };
});

const TheChip = ({ online }) => {
  const classes = useStyles();
  const Icon = online ? WifiIcon : WifiOffIcon;
  const label = online ? 'Conectado' : 'Desconectado';
  return (
    <div>
      <Chip
        component="span"
        className={online ? classes.chipOnline : classes.chipOffline}
        variant="outlined"
        icon={<Icon className={classes.icon} />}
        label={label}
      />
      {!online && (
        <Typography className={classes.message} variant="caption" component="span">
          Mientras el dispositivo no se encuentre conectado a Internet,
          algunas funciones podrían no estar disponibles.
          <br />
          Los datos que que observas están almacenados localmente y
          podrían no estar totalmente actualizados.
          Los nuevos registros que crees se sincronizarán automáticamente
          la próxima vez que te conectes a Internet.
        </Typography>
      )}
    </div>
  );
};

TheChip.propTypes = {
  online: PropTypes.bool.isRequired,
};


const StatusChip = ({ notification }) => {
  // const updated = React.useState(null);
  const onChange = (res) => {
    if (res) {
      notification.setMessage({
        type: 'success',
        message: 'Se restableció la conexión a Internet',
      });
    } else {
      notification.setMessage({
        type: 'warning',
        message: 'Se ha perdido la conexión a Internet',
      });
    }
  };
  return (
    <Detector
      onChange={onChange}
      render={({ online }) => <TheChip online={online} />}
    />
  );
};

StatusChip.propTypes = {
  notification: NotificationPropTypes.isRequired,
};

export default withNotification(StatusChip);
