import FieldsTemplate from '../../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  section: {
    id: 'section',
    label: 'Sección',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de sección producto no válido',
    required: false,
  },
  name: {
    id: 'name',
    label: 'Nombre Producto',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de producto no válido',
    required: true,
  },
  details: {
    id: 'details',
    value: '',
    type: 'editor',
    validationType: TYPES.EDITOR,
    label: 'Detalle',
    required: false,
  },
  photo: {
    id: 'photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  category: {
    id: 'category',
    label: 'Categoría',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'categories',
      label: ['name'],
      addNew: 'Agregar Nueva Categoría?',
      validation: 'name',
    },
    validationMessage: '',
    required: false,
  },
  priceFixed: {
    id: 'priceFixed',
    label: 'Precio Fijo',
    value: true,
    type: 'boolean',
    validationType: TYPES.BOOLEAN,
    validationMessage: 'Precio fijo de producto no válido',
    required: true,
  },
  price: {
    id: 'price',
    label: 'Precio',
    value: 0,
    type: 'number',
    validationType: TYPES.NUMBER,
    validationMessage: 'Precio de producto no válido',
    required: true,
  },
  priceTo: {
    id: 'priceTo',
    label: 'Precio Hasta',
    value: 0,
    type: 'number',
    validationType: TYPES.NUMBER,
    validationMessage: '"Precio hasta" de producto no válido',
    required: false,
  },
  published: {
    id: 'published',
    label: 'Publicada',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  photoURL: {
    id: 'photoURL',
    value: '',
  },
  index: {
    id: 'index',
    value: 0,
    type: TYPES.NUMBER,
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
  name_en: {
    id: 'name_en',
    value: '',
  },
  details_en: {
    id: 'details_en',
    value: '',
  },
  section_en: {
    id: 'section_en',
    value: '',
  },
};

class FieldsProducts extends FieldsTemplate {}

export default new FieldsProducts(FIELDS);
