import React from 'react';
import {
  makeStyles, Grid, Typography,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import WebAppButton from './button';
import Badge from './badge';
import { withAuthorization } from '../../model/Session';
import { isEditor } from '../access';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  warning: {
    fontSize: '0.8rem',
    color: grey[600],
    fontWeight: 300,
    marginBottom: theme.spacing(1),
  },
  warningBold: {
    color: grey[700],
    fontWeight: 500,
  },
}));

const WebPanel = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={0} justify="center">
      <Grid item xs={12} md={4}>
        <Typography component="p" variant="h4" className={classes.warning}>
          Usar este botón para actualizar el contenido del sitio Web de Maclic.
          <br />
          <strong className={classes.warningBold}>
            Usar únicamente al terminar de actualizar todos los contenidos pendientes.
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <WebAppButton />
        <Badge />
      </Grid>
    </Grid>
  );
};

export default withAuthorization(isEditor)(WebPanel);
