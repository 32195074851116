import FieldsTemplate from '../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  password: {
    id: 'password',
    label: 'Contraseña',
    value: '',
    type: 'password',
    validationType: TYPES.NEW_PASSWORD,
    validationMessage: 'Indique una contraseña válida',
    required: true,
  },
  passwordTwo: {
    id: 'passwordTwo',
    label: 'Repetir Contraseña',
    value: '',
    type: 'password',
    validationType: TYPES.PASSWORD,
    validationMessage: 'Las contraseñas no coinciden',
    required: true,
  },
  id: {
    id: 'id',
    value: '',
  },
};

export default new FieldsTemplate(FIELDS);
