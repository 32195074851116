import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Button, withStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { withNotification, NotificationPropTypes } from '../../../../components/notification';

const GenerateButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const ReGenerateButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
}))(Button);

const ButtonGenerateLicense = ({
  firebase, pilot, notification, setLicenseURL, setLoading, loading,
}) => {
  const doGenerate = () => {
    setLoading(true);
    firebase.generateLicense(pilot.id)
      .then((result) => {
        firebase.pilot(pilot.id)
          .set({
            licenseURL: result.data,
          }, { merge: true })
          .then(() => {
            notification.setMessage({
              type: 'success',
              message: 'Se ha generado la licencia.',
            });
            setLicenseURL(result.data);
          })
          .catch(() => {
            notification.setMessage({
              type: 'warning',
              message: 'Ups! Algo ocurrió mientras se generaba la licencia temporal.',
            });
          });
      })
      .catch((err) => {
        notification.setMessage({
          type: 'warning',
          message: err.message,
        });
      })
      .finally(() => setLoading(false));
  };
  return pilot.licenseURL && pilot.licenseURL.length !== '' ? (
    <ReGenerateButton
      disabled={loading}
      onClick={doGenerate}
      size="small"
    >
      Re-generar
    </ReGenerateButton>
  ) : (
    <GenerateButton
      disabled={loading}
      onClick={doGenerate}
      size="small"
    >
      Generar
    </GenerateButton>
  );
};

ButtonGenerateLicense.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object.isRequired,
  notification: NotificationPropTypes.isRequired,
  setLicenseURL: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(ButtonGenerateLicense);
