import React from 'react';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import ButtonAddProducts from './buttonAddProducts';
import Fields from '../fields';
import * as ROUTES from '../../../constants/routes';

const ProductDialog = ({ place, productsCount, history }) => {
  const createNew = () => {
    history.push(`${ROUTES.PLACES}/${place.id}/product/new`);
  };

  return (
    <React.Fragment>
      <ButtonAddProducts
        action={createNew}
        membership={place.membership}
        productsCount={productsCount}
      />
    </React.Fragment>
  );
};

ProductDialog.defaultProps = {
  productsCount: null,
};

ProductDialog.propTypes = {
  place: Fields.getPropTypes().isRequired,
  productsCount: PropTypes.number,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(ProductDialog);
