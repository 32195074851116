import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MSelect from './mSelect';
import { TYPES, validate } from '../validator';
import LabelItem from './labelItem';

const SelectFormInput = ({
  reference, value, onValueChange, label, error,
  validationType, validationMessage, id, required,
}) => {
  const values = required ? reference.values : ({ all: { label: '', value: '' }, ...reference.values });
  function onChangeEvent(newValue) {
    const result = {
      id,
      value: newValue.value,
    };
    if (!validationType) {
      onValueChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      required,
    });
    onValueChange({
      ...result,
      ...validation,
    });
  }

  const currentValue = value === '' ? { value, label } : values[value];
  const theValues = Object.values(values);

  return (
    <>
      <LabelItem label={label} value={value} required={required} />
      <MSelect
        setValue={onChangeEvent}
        value={currentValue}
        options={theValues}
      />
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </>
  );
};

SelectFormInput.defaultProps = {
  error: false,
  validationType: TYPES.SELECT,
  validationMessage: null,
  required: false,
};

SelectFormInput.propTypes = {
  reference: PropTypes.shape({
    // eslint-disable-next-line
    values: PropTypes.object.isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]),
  validationMessage: PropTypes.string,
};

export default SelectFormInput;
