import React from 'react';
import * as PropTypes from 'prop-types';
import { Tabs, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 40,
    marginBottom: theme.spacing(-1),
  },
  scroller: {
    scrollbarWidth: 'none',
  },
}));

const MTabs = ({ children, value, handleChange }) => {
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
      indicatorColor="primary"
      textColor="primary"
      classes={classes}
    >
      {children}
    </Tabs>
  );
};

MTabs.defaultProps = {
  children: null,
  value: false,
  handleChange: null,
};

MTabs.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  handleChange: PropTypes.func,
};

export default MTabs;
