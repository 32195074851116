import moment from 'moment';
import { containsExtension } from '../../../components/validator';
import { getRandomString, updateLoaded, uploadFile } from '../../../lib/misc';
import Fields from './fields';

export const getFilePath = (file, place) => `/uploads/places/${place.id}/documents/${getRandomString()}${file.name}`;

const getValidFiles = (files) => files
  .filter((item) => containsExtension(item.name, Fields.fields.file.fileTypes));

export const uploadMultipleDocuments = async (files, place, firebase, setLoaded) => {
  setLoaded(0);
  const validFiles = getValidFiles(files);
  if (validFiles.length === 0) {
    setLoaded(-1);
    throw new Error('Ups! Parece que ninguno de los archivos indicados tiene un formato válido 😅');
  }
  const progress = new Array(validFiles.length);
  const results = new Array(validFiles.length);
  const promises = validFiles.map(async (item, index) => {
    results[index] = await uploadFile(item, place, firebase, (updateProgress) => {
      progress[index] = updateProgress;
      updateLoaded(progress, setLoaded);
    });
    results[index].name = item.name;
  });
  await Promise.all(promises);
  const now = moment();
  const batch = firebase.batch();
  results.forEach((item) => {
    batch.set(firebase.placeFiles(place.id).doc(), {
      name: item.name,
      file: item.reference,
      fileURL: item.url,
      date: now.toISOString(),
    });
  });
  await batch.commit();
  setLoaded(-1);
  return `Fueron cargados ${validFiles.length} documentos satisfactoriamente 😇!`;
};
