import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Fields, { MEMBERSHIPS } from './fields';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Loading from '../../components/loading';
import { RenderEmail, RenderPhone, RenderMembership } from '../../components/renders';
import { AsyncColumnRender } from '../../components/table';
import { SectionTitle } from '../../components/title';
import LabelItem from '../../components/labelItem';

const useStyles = makeStyles(theme => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
}));

const PanelBusiness = ({ placeId, firebase, showTitle }) => {
  const classes = useStyles();
  const [placeState, setPlaceState] = React.useState(null);
  React.useEffect(() => {
    if (typeof placeId === 'string') {
      firebase.place(placeId).onSnapshot((doc) => {
        setPlaceState(Fields.getInstance(doc));
      });
    } else if (placeId) {
      setPlaceState(placeId);
    }
    return function cleanup() {
      if (typeof placeId === 'string') {
        firebase.place(placeId).onSnapshot(() => {});
      }
    };
  }, [firebase, placeId]);

  if (placeState) {
    return (
      <div>
        {!!showTitle && (
          <SectionTitle marginBottom title="Información del lugar/negocio" />
        )}
        {!!placeState.address && (
          <LabelItem label="Dirección" value={placeState.address} />
        )}
        <LabelItem label="Persona de contacto" value={placeState.contactName} />
        {!!placeState.contactPhone && (
          <LabelItem
            label="Teléfono de contacto"
            value={(
              <RenderPhone value={placeState.contactPhone} />
            )}
          />
        )}
        {!!placeState.contactEmail && (
          <LabelItem
            label="Correo electrónico de contacto"
            value={(
              <RenderEmail value={placeState.contactEmail} />
            )}
          />
        )}
        <LabelItem
          label="Membresía"
          value={(
            <RenderMembership
              value={placeState.membership}
              label={MEMBERSHIPS[placeState.membership].label}
            />
          )}
        />
        <LabelItem
          label="Redactor"
          value={(
            <AsyncColumnRender
              loadFunction={() => new Promise((resolve, reject) => {
                firebase.user(placeState.editor).get()
                  .then((doc) => {
                    const data = doc.data();
                    resolve((
                      <Typography className={classes.itemValue} component="p" variant="subtitle2">
                        {data.username}
                      </Typography>
                    ));
                  })
                  .catch(() => reject());
              })}
            />
          )}
        />
        <LabelItem
          label="Asesor de venta"
          value={(
            <AsyncColumnRender
              loadFunction={() => new Promise((resolve, reject) => {
                firebase.user(placeState.advisor).get()
                  .then((doc) => {
                    const data = doc.data();
                    resolve((
                      <Typography className={classes.itemValue} component="p" variant="subtitle2">
                        {data.username}
                      </Typography>
                    ));
                  })
                  .catch(() => reject());
              })}
            />
          )}
        />
      </div>
    );
  }
  return (
    <Loading variant="h6" size={20} />
  );
};

PanelBusiness.defaultProps = {
  placeId: null,
  showTitle: true,
};

PanelBusiness.propTypes = {
  showTitle: PropTypes.bool,
  firebase: FirebasePropType.isRequired,
  placeId: PropTypes.oneOfType([
    PropTypes.string,
    Fields.getPropTypes(),
  ]),
};

export default withFirebase(PanelBusiness);
