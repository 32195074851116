import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { VERSION, VERSION_CODE } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0, 1),
    textAlign: 'center',
  },
  version: {
    margin: theme.spacing(0, 0, 4),
    textAlign: 'center',
    opacity: 0.5,
    display: 'block',
    fontSize: '0.6rem',
  },
  dino: {
    textDecoration: 'none',
    cursor: 'default',
  },
}));

const MadeWithLove = () => {
  const classes = useStyles();
  const [clicks, setClicks] = React.useState(0);
  const onClick = (event) => {
    event.preventDefault();
    if (clicks === 5) {
      setClicks(0);
      // eslint-disable-next-line no-undef
      const newWindow = window.open();
      newWindow.document.write('<pre>           Mil salúdame... soy Anacleto?    boing boing!\n'
        + ' e-e           . - .         . - .         . - .\n'
        + '(\\_/)\\       \'       `.   ,\'       `.   ,\'       .\n'
        + ' `-\'\\ `--.___,         . .           . .          .\n'
        + '    \'\\( ,_.-\'\n'
        + '       \\\\               "             "            m:m\n'
        + '       ^\'</pre>');
      return;
    }
    setClicks(clicks + 1);
  };
  return (
    <React.Fragment>
      <Typography className={classes.root} variant="body2" color="textSecondary">
        {'Hecho con '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={classes.dino} href="#" onClick={onClick}>
          {'❤️'}
        </a>
        {'️ por el equipo de '}
        <Link color="inherit" href="https://roldanilloeasy.com/">
          Roldanillo Easy
        </Link>
      </Typography>
      <Typography className={classes.version} variant="overline" color="textSecondary">
        {`Versión actual: ${VERSION_CODE} - ${VERSION}`}
      </Typography>
    </React.Fragment>
  );
};

export default MadeWithLove;
