import React from 'react';
import * as PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}));

const MChip = ({ color, id, label }) => {
  const classes = useStyles();
  return (
    <Chip
      component="span"
      className={classes.root}
      color={color}
      key={id}
      label={label}
    />
  );
};

MChip.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
};

MChip.defaultProps = {
  color: 'primary',
  id: 'mchip',
};

export default MChip;
