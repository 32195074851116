import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Button, withStyles } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { withNotification, NotificationPropTypes } from '../../../../components/notification';

const ColorButton = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const ButtonDownloadLicense = ({
  firebase, licenseURL, notification, setLoading, loading,
}) => {
  const doDownload = () => {
    setLoading(true);
    firebase.downloadLicense(licenseURL)
      .then((result) => {
        // eslint-disable-next-line no-undef
        // window.open(result);
        // eslint-disable-next-line no-undef
        const a = document.createElement('a');
        a.download = 'pilot-temporary-license.pdf';
        a.href = result;
        // eslint-disable-next-line no-undef
        const dv = document.getElementById('downloadLicense');
        dv.append(a);
        a.click();
        notification.setMessage({
          type: 'success',
          message: 'Se inició la descarga de la licencia.',
        });
      })
      .catch((e) => {
        console.warning(e.message);
        notification.setMessage({
          type: 'warning',
          message: 'Ups ocurrió algo obteniendo el enlace de descarga.',
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <ColorButton
        disabled={loading}
        onClick={doDownload}
        size="small"
      >
        Descargar
      </ColorButton>
      <div id="downloadLicense" style={{ display: 'none' }} />
    </>
  );
};

ButtonDownloadLicense.propTypes = {
  firebase: FirebasePropType.isRequired,
  licenseURL: PropTypes.string.isRequired,
  notification: NotificationPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(ButtonDownloadLicense);
