import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import DepartmentForm from './departmentForm';
import * as ROUTES from '../../../constants/routes';

const NewDepartment = () => (
  <Container backPath={ROUTES.DEPARTAMENTOS} title="Nuevo Departamento">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <DepartmentForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isAeroAdmin)(NewDepartment);
