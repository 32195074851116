import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Title from '../../components/title';
import SignInLink from '../SignIn/signInLink';
import PasswordForgetForm from './passwordForgetForm';

const title = 'Olvidé mi contraseña';

const PasswordForgetPage = () => (
  <Title title={title}>
    <Fragment>
      <Typography component="h1" variant="h5">
        {title}
      </Typography>
      <PasswordForgetForm />
      <Grid container>
        <SignInLink />
      </Grid>
    </Fragment>
  </Title>
);

export default PasswordForgetPage;
