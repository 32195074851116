import React from 'react';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { MForm, generateInputs } from '../../../components/form';
import { withFirebase, FirebasePropType } from '../../../model/Firebase';
import Fields from './fieldsDepartamento';
import { validateForm } from '../../../components/validator';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';

const getInitialFields = () => JSON.parse(JSON.stringify(Fields.fields));

const assignDefaultValues = (fields, defaultValues) => {
  if (defaultValues) {
    const theFields = { ...fields };
    Object.keys(defaultValues).forEach((item) => {
      theFields[item].value = defaultValues[item];
    });
    return theFields;
  }
  return fields;
};

const DepartmentForm = ({
  firebase, notification, history, defaultValues, isNew,
}) => {
  const [fields, setFields] = React.useState(getInitialFields());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setFields(assignDefaultValues(fields, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const saveData = (reference, dataSet, successMessage, redirect) => {
    reference.set(dataSet, { merge: true })
      .then(() => {
        setFields(getInitialFields());
        notification.setMessage({ message: successMessage, type: 'success' });
        if (redirect) {
          history.push(redirect);
        }
      })
      .catch((e) => notification.setMessage({ message: e.message, type: 'error' }));
  };

  const onSubmit = () => {
    setLoading(true);
    const dataSet = {
      name: fields.name.value,
    };
    firebase.departments().where('name', '==', dataSet.name)
      .get()
      .then((snapShot) => {
        if ((isNew && !snapShot.empty)
          || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
          notification.setMessage({
            message: 'Ya existe un departamento con ese nombre.',
            type: 'warning',
          });
        } else {
          const reference = isNew ? firebase.departments().doc()
            : firebase.department(fields.id.value);
          if (isNew) {
            saveData(reference, dataSet, 'Se creó el nuevo departamento 😀');
          } else {
            saveData(reference, dataSet, 'Se modificó el departamento 😀', ROUTES.DEPARTAMENTOS);
          }
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    setFields(theFields);
  };

  const isInvalid = !validateForm(fields);

  return (
    <MForm
      onSubmit={onSubmit}
      loading={loading}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Departamento`,
        disabled: isInvalid,
      }}
    >
      {generateInputs(fields, onChange)}
    </MForm>
  );
};

DepartmentForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

DepartmentForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  // withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(DepartmentForm);
