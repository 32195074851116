import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { generateInputs, MForm } from '../../../../components/form';
import MDialog from '../../../../components/mdialog';
import Fields from './fields';
import { validateForm } from '../../../../components/validator';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';
import { changeAction, getRandomString, uploadMultipleFiles } from '../../../../lib/misc';

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
  },
}));

const ButtonAddObra = ({
  disabled, notification, firebase, artistaId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(-1);
  const [fields, setFields] = React.useState({ ...Fields.fields });

  const [storageKey] = React.useState(getRandomString());

  const endSubmit = React.useCallback((load, success) => {
    setLoading(load);
    setProgress(-1);
    if (success) {
      setFields({ ...Fields.fields });
      setOpen(false);
    }
  }, []);

  const persists = React.useCallback((dataSet) => {
    firebase.obras(artistaId).doc().set(dataSet)
      .then(() => {
        notification.setMessage({ message: 'Se agregó la nueva Obra 😀', type: 'success' });
        endSubmit(false, true);
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
        endSubmit(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (result) => changeAction(result, fields, setFields, 'photos', 'files');

  const isInvalid = !validateForm(fields);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      endSubmit(false);
    } else {
      const dataSet = {
        title: fields.title.value,
        category: fields.category.value,
        details: fields.details.value,
      };
      uploadMultipleFiles(
        fields.photos, fields.photos.fileTypes, `/images/artistas/${artistaId}`, null, firebase, setProgress, 3,
      ).then((result) => {
        dataSet.photos = result.map((item) => item.reference);
        persists(dataSet);
      }).catch((err) => {
        endSubmit(false, false);
        notification.setMessage({ message: err.message, type: 'error' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, isInvalid]);

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        Agregar Obra
      </Button>
      <MDialog
        maxWidth="sm"
        fullWidth
        keepMounted={false}
        open={open}
        handleClose={() => {
          if (!disabled) {
            setOpen(false);
          }
        }}
        title="Nueva Obra"
      >
        <MForm
          loading={loading}
          uploaded={progress}
          onSubmit={submitAction}
          button={{
            disabled: isInvalid,
            label: 'Agregar Obra',
          }}
        >
          {generateInputs(fields, onChange, storageKey)}
        </MForm>
      </MDialog>
    </>
  );
};

ButtonAddObra.defaultProps = {
  disabled: false,
};

ButtonAddObra.propTypes = {
  disabled: PropTypes.bool,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  artistaId: PropTypes.string.isRequired,
};

export default compose(
  withNotification,
  withFirebase,
)(ButtonAddObra);
