/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  labelItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(1),
    color: grey[600],
  },
  // required: {
  //   color: red[600],
  // },
}));

const LabelItem = ({ value, label, required }) => {
  const classes = useStyles();
  const result = value !== '' ? label : null;
  return !!result && (
    <Typography variant="caption" display="block" className={classes.labelItem} component="span">
      {result}
      {required && (
        <span>
          {' *'}
        </span>
      )}
    </Typography>
  );
};

LabelItem.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

LabelItem.defaultProps = {
  value: '',
  label: '',
  required: false,
};

export default LabelItem;
