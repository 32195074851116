import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { RenderPhone } from '../../../../components/renders';
import LicenseLabelItem from './licenseLabelItem';
import LicenseTitleSection from './licenseTitleSection';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
    wordWrap: 'break-word',
  },
}));

const AccommodationSection = ({ pilot }) => {
  const classes = useStyles();
  if (pilot) {
    return (
      <div>
        <LicenseTitleSection marginTop title="Alojamiento en Colombia o durante competencias" />
        <Grid container spacing={3}>
          <LicenseLabelItem label="Ciudad en Colombia" value={pilot.localCity} />
          <LicenseLabelItem label="Dirección en Colombia" value={pilot.localAddress} />
          {!!pilot.localPhone && (
            <LicenseLabelItem
              label="Número telefónico en Colombia"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderPhone value={pilot.localPhone} />
                </Typography>
              )}
            />
          )}
        </Grid>
      </div>
    );
  }
  return null;
};

AccommodationSection.defaultProps = {
  pilot: null,
};

AccommodationSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
};

export default AccommodationSection;
