import isLength from 'validator/lib/isLength';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

export const getHours = () => {
  const values = [];
  for (let i = 0; i <= 24; i += 1) {
    const label = `${i < 10 ? '0' : ''}${i}`;
    values.push({ label, value: i });
  }
  return values;
};

export const getMinutes = () => {
  const values = [];
  for (let i = 0; i <= 55; i += 5) {
    const label = `${i < 10 ? '0' : ''}${i}`;
    values.push({ label, value: i });
  }
  return values;
};

export const validateForm = (fields) => {
  const invalid = Object.values(fields).some(field => !isLength(`${field.value}`, { min: 1 }));
  if (invalid) {
    return { invalid };
  }
  const {
    startHour, startMinute, endHour, endMinute,
  } = fields;
  if ((startHour.value === 24 && startMinute.value > 0)
    || (endHour.value === 24 && endMinute.value > 0)) {
    return { invalid: true, message: 'El valor máximo permitido es 24:00.' };
  }
  if (endHour.value < startHour.value
    || (endHour.value === startHour.value && endMinute.value <= startMinute.value)) {
    return { invalid: true, message: 'El periodo de tiempo no es válido.' };
  }
  return { isInvalid: false };
};

export const getDays = () => [
  { label: 'Lunes', value: 'monday' },
  { label: 'Martes', value: 'tuesday' },
  { label: 'Miércoles', value: 'wednesday' },
  { label: 'Jueves', value: 'thursday' },
  { label: 'Viernes', value: 'friday' },
  { label: 'Sábado', value: 'saturday' },
  { label: 'Domingo', value: 'sunday' },
  { label: 'Festivo', value: 'holiday' },
];

const getRange = (moment, starts, ends) => {
  const date = moment().format('YYYY-MM-DD');
  const start = moment(`${date} ${starts}`);
  const end = moment(`${date} ${ends}`);
  return moment.range(start, end);
};

export const isValidPeriod = (periods, newPeriod) => {
  const day = periods[newPeriod.day];
  if (day && day.length > 0) {
    const moment = extendMoment(Moment);
    const range = getRange(moment, newPeriod.start, newPeriod.end);
    return !day.some((period) => {
      const periodRange = getRange(moment, period.start, period.end);
      return range.overlaps(periodRange);
    });
  }
  return true;
};
