import React from 'react';
import FieldsTemplate from '../../../lib/FieldsTemplate';
import { RenderDate } from '../../../components/renders';
import { AsyncColumnRender } from '../../../components/table';
import Disciplines from '../Disciplines';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  discipline: {
    id: 'discipline',
    label: 'Disciplina',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: Disciplines,
    },
    validationMessage: 'Débe indicar la disciplina de la competencia.',
    required: true,
  },
  faiEvent: {
    id: 'faiEvent',
    label: 'Evento FAI',
    value: false,
    type: 'boolean',
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  club: {
    id: 'club',
    label: 'Club',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'clubes',
      label: ['name'],
    },
    validationMessage: 'Débe indicar el club.',
    required: true,
  },
  city: {
    id: 'city',
    label: 'Ciudad',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: {},
    },
    validationMessage: 'Débe indicar la ciudad.',
    required: true,
  },
  initialDate: {
    id: 'initialDate',
    label: 'Inicio',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida para el inicio de la competición.',
    required: true,
  },
  endDate: {
    id: 'endDate',
    label: 'Fin',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar la fecha de finalización de la competición.',
    required: true,
  },
  id: {
    id: 'id',
    value: '',
  },
};

class CompetitionFields extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults(firebase) {
    const columnsObject = this.columnsTable();
    columnsObject.id.hidden = true;
    columnsObject.discipline.render = (rowData) => (
      <>
        {Disciplines[rowData.discipline].label || ''}
      </>
    );
    columnsObject.faiEvent.render = (rowData) => (
      <>
        {rowData.faiEvent ? 'SI' : 'NO'}
      </>
    );
    columnsObject.initialDate.render = (rowData) => (
      <RenderDate date={rowData.initialDate} format="ddd, DD \d\e MMMM \d\e YYYY" />
    );
    columnsObject.endDate.render = (rowData) => (
      <RenderDate date={rowData.endDate} format="ddd, DD \d\e MMMM \d\e YYYY" />
    );
    columnsObject.city.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve, reject) => {
          firebase.city(rowData.city).get().then((doc) => {
            if (doc.exists) {
              const city = doc.data();
              if (city.department) {
                firebase.department(city.department).get().then((docDep) => {
                  if (docDep.exists) {
                    resolve(`${city.name} - ${docDep.data().name}`);
                  } else {
                    resolve(city.name);
                  }
                });
              } else {
                resolve(city.name);
              }
            } else {
              reject();
            }
          });
        })}
      />
    );
    columnsObject.club.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          firebase.club(rowData.club).get().then((doc) => {
            resolve(doc.data().name);
          });
        })}
      />
    );
    return {
      title: 'Listado de Competencias',
      columns: Object.values(columnsObject),
    };
  }
}

export default new CompetitionFields(FIELDS);
