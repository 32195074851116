import FieldsTemplate from '../../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre Documento',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de documento no válido',
    required: false,
  },
  date: {
    id: 'date',
    label: 'Fecha',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'DD/MM/YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  file: {
    id: 'file',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg', '.pdf'],
    validationType: TYPES.FILE,
    label: 'Archivo',
    required: true,
    validationMessage: 'Débe indicar un documento con un formato válido.',
  },
  fileURL: {
    id: 'fileURL',
    value: '',
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
};

class DocumentFields extends FieldsTemplate {}

export default new DocumentFields(FIELDS);
