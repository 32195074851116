import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import Fields from './fields';
import { validateForm } from '../../../components/validator';
import { generateInputs, MForm } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { isCoordinator } from '../../../components/access';
import PlaceFields from '../fields';
import { getFilePath } from './functions';

const DocsForm = ({
  authUser, firebase, notification, place, onSuccessAction,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);
  const [fields, setFields] = React.useState({});

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(Fields.fields));
    setFields(newFields);
  }, []);

  const endSubmit = (error, message) => {
    const type = error ? 'error' : 'success';
    notification.setMessage({ type, message });
    if (!error) {
      setFields(JSON.parse(JSON.stringify(Fields.fields)));
      if (onSuccessAction) {
        onSuccessAction();
      }
    }
    setLoading(false);
  };

  const uploadFile = (file, thePlace) => new Promise((resolve, reject) => {
    const filePath = getFilePath(file, thePlace);
    firebase.uploadFile(filePath, file, (progress) => {
      setUploaded(progress.value);
    }, (error, url) => {
      if (error) {
        reject(new Error(error));
        return;
      }
      resolve({ reference: filePath, url });
    });
  });

  const onSubmit = () => {
    setLoading(true);
    const dataSet = {
      name: fields.name.value,
      date: fields.date.value,
      createdBy: authUser.uid,
    };
    firebase.placeFiles(place.id)
      .where('name', '==', dataSet.name)
      .where('date', '==', dataSet.date)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          endSubmit(true, 'Ya existe un documento con el mismo nombre en esa misma fecha 🧐!');
        } else {
          if (fields.file.file) {
            uploadFile(fields.file.file, place)
              .then((result) => {
                dataSet.file = result.reference;
                dataSet.fileURL = result.url;
                firebase.placeFiles(place.id).doc()
                  .set(dataSet)
                  .then(() => endSubmit(false, 'Se ha cargado el archivo satisfactoriamente 😀!'))
                  .catch(() => endSubmit(true, 'Ups! Algo no salió bien cargando el archivo. Intentalo de nuevo 🙁!'));
              })
              .catch(e => endSubmit(true, e.message));
            return;
          }
          endSubmit(true, 'Ups! Algo no salió bien procesando el archivo. Revisalo por favor 🙁!');
        }
      });
  };

  const onChange = (result) => {
    const newFields = JSON.parse(JSON.stringify(fields));
    newFields[result.id].value = result.value;
    newFields[result.id].error = !result.isValid;
    if (result.id === Fields.fields.file.id) {
      newFields[result.id].file = result.file;
    }
    setFields(newFields);
  };

  const isInvalid = !validateForm(fields);

  return (
    <Grid item xs={12}>
      <MForm
        loading={loading}
        button={{ label: 'Guardar Archivo', disabled: isInvalid }}
        uploaded={uploaded}
        onSubmit={onSubmit}
      >
        {generateInputs(fields, onChange)}
      </MForm>
    </Grid>
  );
};

DocsForm.defaultProps = {
  onSuccessAction: null,
};

DocsForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  place: PlaceFields.getPropTypes().isRequired,
  onSuccessAction: PropTypes.func,
};

export default compose(
  withAuthorization(isCoordinator),
  withFirebase,
  withNotification,
  withCurrentUser,
)(DocsForm);
