import React from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { buildURL } from 'react-imgix';
import Fields from '../fields';
import PhotoItem from './photoItem';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { domain } from '../../../model/Images';

const updatePlace = (place, index, firebase, notification, thePhotos) => {
  const photos = thePhotos || place.photos;
  if (index !== null && index !== undefined) {
    photos.splice(index, 1);
  }
  firebase.place(place.id)
    .set({ photos }, { merge: true })
    .then(() => {
      notification.setMessage({ type: 'success', message: 'Se actualizaron las imagenes del lugar/negocio 😀' });
    })
    .catch(() => {
      notification.setMessage({ type: 'error', message: 'Ups! Algo no salió bien actualizando las imagenes del lugar/negocio 🥺' });
    });
};

const deletePhoto = (place, photo, firebase, notification) => {
  const index = place.photos.findIndex((item) => item.reference === photo.reference);
  firebase.deleteFile(photo.reference)
    .then(() => {
      updatePlace(place, index, firebase, notification);
    })
    .catch((error) => {
      if (error.code === 'storage/object-not-found') {
        updatePlace(place, index, firebase, notification);
      } else {
        notification.setMessage({ type: 'error', message: 'Ups! Algo no salió bien borrando la imagen 🥺' });
      }
    });
};

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const PhotosList = ({ place, firebase, notification }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [index, setIndex] = React.useState(0);
  const toggleModal = (toOpen, toIndex) => {
    setOpen(toOpen);
    setIndex(toIndex);
  };

  React.useEffect(() => {
    if (place.photos) {
      let photosTemp = [];
      place.photos.forEach((item, theIndex) => {
        if (item.index) {
          photosTemp.push(item);
        } else {
          photosTemp.push({
            ...item,
            index: theIndex,
          });
        }
      });
      photosTemp = photosTemp.sort((a, b) => (a.index < b.index ? -1 : 1));
      const imagesTemp = photosTemp.map((item) => ({
        src: buildURL(`${domain}${item.reference.replace('/images', '')}`, { w: 1600 }),
      }));
      setImages(imagesTemp);
      setPhotos(photosTemp);
    }
  }, [place.photos]);

  if (!place.photos || place.photos.length === 0) {
    return null;
  }

  const onDeletedImage = (photo) => {
    deletePhoto(place, photo, firebase, notification);
  };

  const onUpdateImage = (photo) => {
    const i = photos.findIndex((item) => item.reference === photo.reference);
    photos.splice(i, 1);
    photos.splice(photo.index, 0, photo);
    const thePhotos = photos.map((item, j) => ({ ...item, index: j }));
    updatePlace(place, null, firebase, notification, thePhotos);
  };

  return (
    <>
      <Grid spacing={1} className={classes.grid} container>
        {photos.map((photo) => (
          <Grid key={photo.reference} xs={12} sm={6} item>
            <PhotoItem
              toggleModal={toggleModal}
              photo={photo}
              totalPhotos={photos.length}
              onDeletedImage={onDeletedImage}
              onUpdateImage={onUpdateImage}
            />
          </Grid>
        ))}
      </Grid>
      <ModalGateway>
        {open ? (
          <Modal
            onClose={() => toggleModal(false, 0)}
            styles={{
              positioner: (base) => ({
                ...base,
                zIndex: 2100,
              }),
              dialog: (base) => ({
                ...base,
                maxWidth: 800,
              }),
              blanket: (base) => ({
                ...base,
                zIndex: 2000,
              }),
            }}
          >
            <Carousel currentIndex={index} views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

PhotosList.propTypes = {
  place: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(PhotosList);
