import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(10, 2),
    verticalAlign: 'middle',
  },
  progressContainer: {
    textAlign: 'center',
    width: '100%',
  },
  progressText: {
    verticalAlign: 'middle',
  },
}));

function CircularIndeterminate({ message, size, variant }) {
  const classes = useStyles();

  return (
    <div className={classes.progressContainer}>
      <CircularProgress size={size} className={classes.progress} />
      <Typography component="span" variant={variant} className={classes.progressText}>
        {message}
      </Typography>
    </div>
  );
}

CircularIndeterminate.defaultProps = {
  message: 'Cargando... 🙄',
  size: 30,
  variant: 'h4',
};

CircularIndeterminate.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.number,
};

export default CircularIndeterminate;
