import FieldsTemplate from '../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  name_en: {
    id: 'name_en',
    label: 'Name',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Name not valid',
    required: true,
  },
  parents: {
    id: 'parents',
    label: 'Categorías padre',
    value: [],
    type: 'multireference',
    validationType: TYPES.MULTIREFERENCE,
    reference: {
      entity: 'categories',
      label: ['name'],
    },
    validationMessage: '',
    required: false,
  },
  id: {
    id: 'id',
    value: '',
  },
};

export default new FieldsTemplate(FIELDS);
