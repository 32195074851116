import { containsExtension } from '../components/validator';

const getRandomString = () => Math.random()
  .toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const getValidFiles = (result, fileTypes) => {
  const files = result.files ? [...result.files] : [result.file];
  return files.filter((item) => containsExtension(item.name, fileTypes));
};

const getFilePath = (file, firebaseRef) => `${firebaseRef}/${getRandomString()}${file.name}`;

const uploadFile = (file, firebaseRef, firebase, updateLoaded) => new Promise((resolve, reject) => {
  const filePath = getFilePath(file, firebaseRef);
  firebase.uploadFile(filePath, file, (progress) => {
    updateLoaded(progress.value);
  }, (err, url) => {
    if (err) {
      reject(err);
      return;
    }
    resolve({ reference: filePath, url });
  });
});

const updateLoaded = (progress, setLoaded) => {
  let result = 0;
  progress.forEach((item) => {
    if (item) {
      result += item;
    }
  });
  setLoaded(result / progress.length);
};

const uploadMultipleFiles = async (
  result, fileTypes, firebaseRef, setValue, firebase, setProgress, maxFiles,
) => {
  const validFiles = getValidFiles(result, fileTypes);
  if (typeof setValue === 'function') {
    setValue(`${validFiles.length} archivos`);
  }
  setProgress(0);
  if (validFiles.length === 0) {
    throw new Error('Ups! Parece que ninguno de los archivos indicados tiene un formato válido 😅');
  }
  if (validFiles.length > maxFiles) {
    throw new Error(`Lo sentimos!! No puedes agregar más de ${maxFiles} archivos 😅`);
  }
  const progress = new Array(validFiles.length);
  const results = new Array(validFiles.length);
  const promises = validFiles.map(async (item, index) => {
    results[index] = await uploadFile(item, firebaseRef, firebase, (updateProgress) => {
      progress[index] = updateProgress;
      updateLoaded(progress, setProgress);
    });
  });
  await Promise.all(promises);
  return results;
};

const changeAction = (result, fields, setFields, key = 'photo', file = 'file') => {
  const newFields = { ...fields };
  newFields[result.id].value = result.value;
  newFields[result.id].error = !result.isValid;
  if (result.id === key) {
    newFields[result.id][file] = result[file];
  }
  setFields(newFields);
};

export {
  getRandomString, getValidFiles, getFilePath, uploadMultipleFiles, uploadFile, updateLoaded, changeAction,
};
