import React from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

const MIconButton = ({
  className, onClick, children, ...others
}) => (
  <IconButton {...others} size="small" className={className} onClick={onClick}>
    {children}
  </IconButton>
);

MIconButton.defaultProps = {
  className: null,
  onClick: null,
  children: null,
};

MIconButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default MIconButton;
