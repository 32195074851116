import React from 'react';
import * as PropTypes from 'prop-types';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { Typography } from '@material-ui/core';

const LocatedComponent = ({
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
  locationCallBack,
  locating,
}) => {
  const [firstMount, setFirstMount] = React.useState(true);
  let text = '';
  let data;
  if (isGeolocationAvailable) {
    if (isGeolocationEnabled) {
      if (coords) {
        text = 'Localización actual obtenida';
        data = { status: 'located', coords };
      } else if (locating) {
        text = 'Obteniendo localización...';
      }
    } else {
      text = 'Geolocalización desactivada';
      data = { status: 'deactivated' };
    }
  } else {
    text = 'El navegador no soporta Geolocalización';
    data = { status: 'unsupported' };
  }

  React.useEffect(() => {
    if (!firstMount && locationCallBack && data) {
      locationCallBack(data);
    }
    setFirstMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, isGeolocationEnabled, isGeolocationAvailable]);

  return (
    <Typography display="inline" variant="overline" color="textSecondary" component="span">
      {text}
    </Typography>
  );
};

LocatedComponent.defaultProps = {
  locationCallBack: null,
  locating: false,
};

LocatedComponent.propTypes = {
  locationCallBack: PropTypes.func,
  locating: PropTypes.bool,
  ...geoPropTypes,
};

export default geolocated(
  {
    userDecisionTimeout: 5000,
    suppressLocationOnMount: true,
    positionOptions: {
      enableHighAccuracy: true,
    },
  },
)(LocatedComponent);
