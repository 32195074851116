import React from 'react';
import * as PropTypes from 'prop-types';
import { confirmable, createConfirmation } from 'react-confirm';
import {
  DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Button, Zoom,
} from '@material-ui/core';
import MaclicTheme from '../theme';

const Transition = React
  .forwardRef((props, ref) => <Zoom direction="up" ref={ref} {...props} />);

const ConfirmDialog = ({
  proceed, proceedLabel, cancel, cancelLabel, title, show, dismiss, confirmation,
}) => {
  const actions = [
    <Button key="okbutton" color="primary" variant="outlined" size="medium" onClick={proceed}>
      {proceedLabel}
    </Button>,
    <Button key="cancelbutton" color="default" variant="outlined" size="medium" onClick={cancel}>
      {cancelLabel}
    </Button>,
  ];
  return (
    <MaclicTheme>
      <Dialog
        open={show}
        onClose={dismiss}
        TransitionComponent={Transition}
        aria-labelledby="alert-confirm-title"
        aria-describedby="alert-confirm-description"
      >
        <DialogTitle id="alert-confirm-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-confirm-description">
            {confirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </MaclicTheme>
  );
};

ConfirmDialog.defaultProps = {
  proceedLabel: 'Si',
  cancelLabel: 'No',
  title: 'Confirmar Acción',
};

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func.isRequired,
  proceedLabel: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  confirmation: PropTypes.string.isRequired,
};

const confirmComponent = createConfirmation(confirmable(ConfirmDialog));

const confirm = (confirmation, title, options) => confirmComponent(
  { confirmation, title, ...options },
);

export default confirm;

// export default confirmable(ConfirmDialog);
