import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import NewCompetition from './newCompetition';
import EditCompetition from './editCompetition';
import TableCompetition from './table';

const CompetitionPage = () => (
  <Switch>
    <Route path={`${ROUTES.COMPETENCIAS}/new`} component={NewCompetition} />
    <Route path={`${ROUTES.COMPETENCIAS}/edit/:id`} component={EditCompetition} />
    <Route path={ROUTES.COMPETENCIAS} component={TableCompetition} />
  </Switch>
);

export default withAuthorization(isAeroAdmin)(CompetitionPage);
