import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import * as ROUTES from '../../constants/routes';

const SignInLink = () => (
  <Grid item xs>
    <Link to={ROUTES.SIGN_IN} component={RouterLink} variant="body2">
      Volver a iniciar sesión
    </Link>
  </Grid>
);

export default SignInLink;
