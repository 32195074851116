import React from 'react';
import { compose } from 'recompose';
import {
  Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import SummaryField from './summaryField';
import { AuthUserPropTypes, withCurrentUser } from '../../../../model/Session';
import { isAuthorOrTranslator } from '../../../../components/access';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    boxShadow: theme.shadows[1],
  },
  title: {
    marginTop: theme.spacing(2),
    color: 'black',
    textAlign: 'center',
    fontWeight: 600,
  },
}));

const PlacesSummary = ({ firebase, authUser }) => {
  const classes = useStyles();
  const [summaryPlaces, setSummaryPlaces] = React.useState(null);
  React.useEffect(() => {
    const unsubscribe = firebase.summaryPlaces().onSnapshot((doc) => {
      if (doc.exists) {
        setSummaryPlaces(doc.data());
      }
    }, (e) => console.error(e));
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isAuthorOrTranslator(authUser)) {
    return summaryPlaces && (
      <Paper>
        <Grid className={classes.root} container spacing={0} justify="center">
          <Grid item xs={12} sm={4}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="subtitle1" component="h3">
                  Resumen de Membresías Activas
                </Typography>
              </Grid>
              <SummaryField value={summaryPlaces.total} label="Total" xs={12} total />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0}>
              <SummaryField value={summaryPlaces.blue} label="Blue" color="blue" xs={12} sm={6} />
              <SummaryField value={summaryPlaces.green} label="Green" color="green" xs={12} sm={6} />
              <SummaryField value={summaryPlaces.red} label="Red" color="red" xs={12} sm={6} />
              <SummaryField value={summaryPlaces.alpha} label="Alpha" color="alpha" xs={12} sm={6} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return null;
};

PlacesSummary.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(PlacesSummary);
