import React from 'react';
import { compose } from 'recompose';
import {
  withRouter,
} from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import Fields, { MEMBERSHIPS } from '../fields';
import ProductFields from '../Products/fields';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import * as ROUTES from '../../../constants/routes';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { isAuthorOrTranslator } from '../../../components/access';
import { isRestrictedPlace } from '../../../constants/restrictions';
import { MTabs } from '../../../components/tabs';
import {
  sectionTabs, sections, General, Calendar, Description,
  Products, Notes, Contact, Edit, Story, Translate,
} from '../Sections';
import MPanel from '../../../components/panel';
import FullWidthContainer from '../../../components/dashboard/fullWidthContainer';

const DetailBusiness = ({
  match, history, firebase, authUser,
}) => {
  const [id, setId] = React.useState(null);
  React.useEffect(() => {
    const paramId = match.params.id;
    if (paramId && !isRestrictedPlace(authUser, paramId)) {
      setId(paramId);
    } else {
      history.push(ROUTES.PLACES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, match.params.id]);

  const [place, setPlace] = React.useState(null);
  const [membership, setMembership] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.place(id).onSnapshot((doc) => {
        const placeData = Fields.getInstance(doc);
        placeData.categories = placeData.categories
          ? [...Object.keys(placeData.categories)] : [];
        setPlace(placeData);
        setMembership(placeData.membership);
      });
    }
    return function cleanup() {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [products, setProducts] = React.useState(null);
  const [loadingProducts, setLoadingProducts] = React.useState(false);
  React.useEffect(() => {
    let unsubscribe;
    if (id && membership && membership !== MEMBERSHIPS.alpha.value) {
      setLoadingProducts(true);
      unsubscribe = firebase.products(place.id).onSnapshot((querySnapshot) => {
        const newProducts = [];
        querySnapshot.forEach((doc) => {
          newProducts.push(ProductFields.getInstance(doc));
        });
        setProducts(newProducts);
        setLoadingProducts(false);
      });
    }
    return function cleanup() {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, membership]);

  const title = place ? place.name : 'Cargando...';

  const [currentSection, setCurrentSection] = React.useState(sections.general.value);
  React.useEffect(() => {
    const section = match.params.section || sections.general.value;
    setCurrentSection(section);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.section]);
  const handleChange = (event, section) => {
    history.push(`${ROUTES.PLACES}/detail/${id}/${section}`);
  };

  const getCurrentSection = () => {
    if (sections[currentSection] && sections[currentSection].isRestricted(membership, authUser)) {
      return (
        <Grid item xs={12}>
          <MPanel withPaper>
            <Typography component="p" display="inline" variant="h5">
              Ups! Al parecer no tienes acceso a esta sección
              <span role="img" aria-label="Carita confundida">🙄</span>
              .
            </Typography>
          </MPanel>
        </Grid>
      );
    }
    switch (currentSection) {
      case sections.description.value:
        return <Description place={place} />;
      case sections.calendar.value:
        return <Calendar id={id} />;
      case sections.products.value:
        return <Products products={products} place={place} loadingProducts={loadingProducts} />;
      case sections.notes.value:
        return <Notes place={place} />;
      case sections.contact.value:
        return <Contact place={place} />;
      case sections.edit.value:
        return <Edit place={place} />;
      case sections.story.value:
        return <Story place={place} />;
      case sections.translation.value:
        return <Translate place={place} products={products} loadingProducts={loadingProducts} />;
      default:
        return <General place={place} />;
    }
  };

  return (
    <FullWidthContainer
      title={title}
      backPath={ROUTES.PLACES}
      tabs={(
        <MTabs
          value={currentSection}
          handleChange={handleChange}
        >
          {sectionTabs(authUser, membership)}
        </MTabs>
      )}
    >
      {getCurrentSection()}
    </FullWidthContainer>
  );
};

DetailBusiness.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withFirebase,
  withRouter,
  withCurrentUser,
)(DetailBusiness);
