import React from 'react';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import { MButton } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Table from '../../../components/table';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import Fields, { requiresTempLicence } from './fields';
import FieldsEvents from '../Competitions/fields';
import Filters, { LICENSE_STATUS } from './licenseComponents/filters';
import Loading from '../../../components/loading';
import DownloadButton from "./downloadButton";

const TablePiloto = ({ firebase, history, notification }) => {
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState([]);
  const [events, setEvents] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = React.useState({ status: '', event: '' });
  React.useEffect(() => {
    firebase.competitions().get()
      .then((snapshot) => {
        const newEvents = {};
        snapshot.forEach((doc) => {
          newEvents[doc.id] = FieldsEvents.getInstance(doc);
        });
        setEvents(newEvents);
      });
  }, [firebase]);
  React.useEffect(() => {
    let unsubscribe;
    if (events) {
      unsubscribe = firebase.pilots().onSnapshot((querySnapshot) => {
        setLoading(true);
        const theValues = [];
        querySnapshot.forEach((doc) => {
          const pilot = Fields.getInstance(doc);
          if (pilot.events) {
            const newEvents = [];
            const newEventsID = {};
            Object.keys(pilot.events).forEach((key) => {
              if (pilot.events[key] && events[key]) {
                newEvents.push(events[key].name);
                newEventsID[key] = true;
              }
            });
            pilot.eventsId = newEventsID;
            pilot.events = newEvents;
            let status = requiresTempLicence(pilot, events);
            if (status) {
              if (pilot.licenseURL) {
                status = LICENSE_STATUS.generated.value;
              } else {
                status = LICENSE_STATUS.pending.value;
              }
            } else {
              status = LICENSE_STATUS.unrequired.value;
            }
            pilot.status = status;
          }
          theValues.push(pilot);
        });
        setData(theValues);
        setValues(theValues);
        setLoading(false);
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [firebase, events]);

  const timeout = () => new Promise((resolve) => {
    setTimeout(() => resolve(), 500);
  });

  const filterAction = async (filterValues) => {
    setValues([]);
    setLoading(true);
    const asyncAction = async () => data.filter((item) => {
      if (filterValues.status !== '') {
        let valid;
        if (filterValues.status === LICENSE_STATUS.required.value) {
          valid = item.status === LICENSE_STATUS.pending.value
            || item.status === LICENSE_STATUS.generated.value;
        } else {
          valid = item.status === LICENSE_STATUS[filterValues.status].value;
        }
        if (!valid) {
          return false;
        }
      }
      if (filterValues.event !== '') {
        return item.eventsId && item.eventsId[filterValues.event];
      }
      return true;
    });
    const theValues = await asyncAction();
    await timeout();
    setValues(theValues);
    setFilters(filterValues);
    setLoading(false);
  };
  const actions = () => [
    () => ({
      icon: 'edit',
      tooltip: 'Editar Piloto',
      onClick: (event, rowDataLocal) => history.push(`${ROUTES.PILOTOS}/edit/${rowDataLocal.id}`),
    }),
    () => ({
      icon: 'list',
      tooltip: 'Ver detalles',
      onClick: (event, rowDataLocal) => history.push(`${ROUTES.PILOTOS}/license/${rowDataLocal.id}`),
    }),
  ];

  const editable = () => {
    const onRowDelete = (oldData) => new Promise((resolve, reject) => {
      firebase.pilot(oldData.id)
        .delete()
        .then(() => {
          notification.setMessage({ message: 'Piloto borrado satisfactoriamente 🙂', type: 'success' });
          resolve();
        })
        .catch((e) => {
          notification.setMessage({ message: 'Algo no salió bien borrando el piloto 😥', type: 'warning' });
          reject(e);
        });
    });
    return {
      isDeletable: () => true,
      onRowDelete,
    };
  };
  const defaults = Fields.tableDefaults(events);
  return (
    <Container
      title="Listado de Pilotos"
      header={(
        <MButton fullWidth={false} label="Nuevo Piloto" to={`${ROUTES.PILOTOS}/new`} />
      )}
    >
      <Filters disabled={loading} filter={filterAction} values={filters} />
      <DownloadButton data={values} />
      {loading ? (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      ) : (
        <Table
          data={values}
          columns={defaults.columns}
          title={defaults.title}
          isLoading={loading}
          editable={editable()}
          actions={actions()}
        />
      )}
    </Container>
  );
};


TablePiloto.propTypes = {
  firebase: FirebasePropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(TablePiloto);
