import React from 'react';

const styles = {
  logo: {
    margin: 10,
    width: 30,
    height: 'auto',
  },
};

const MaclicLogo = () => (
  <img alt="Maclic Logo" src="/images/maclic-logo.png" style={styles.logo} />
);

export default MaclicLogo;
