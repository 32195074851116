import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { generateInputs, MForm } from '../../components/form';
import { isEditor } from '../../components/access';
import {
  withCurrentUser, withAuthorization, AuthUserPropTypes,
} from '../../model/Session';
import { withFirebase, FirebasePropType } from '../../model/Firebase';
import Fields from './fields';
import { validateForm } from '../../components/validator';
import { withNotification, NotificationPropTypes } from '../../components/notification';
import * as ROUTES from '../../constants/routes';

const getCategoriesValues = (categories, defaultValues, firebase) => {
  const result = {};
  categories.forEach((item) => {
    result[item] = true;
  });
  if (defaultValues && defaultValues.categories) {
    defaultValues.categories.forEach((categoryName) => {
      if (!result[categoryName]) {
        result[categoryName] = firebase.firestore.FieldValue.delete();
      }
    });
  }
  return result;
};

const BusinessForm = ({
  defaultValues, firebase, authUser, isNew, notification, history,
}) => {
  const [fields, setFields] = React.useState(JSON.parse(JSON.stringify(Fields.fields)));
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (defaultValues) {
      const theFields = { ...fields };
      Object.keys(defaultValues).forEach((item) => {
        theFields[item].value = defaultValues[item];
      });
      setFields(theFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const saveData = (clientReference, dataSet, successMessage, placeId) => {
    let operation;
    if (isNew) {
      operation = clientReference.add(dataSet);
    } else {
      operation = clientReference.set(dataSet, { merge: true });
    }
    operation.then((doc) => {
      setFields(JSON.parse(JSON.stringify(Fields.fields)));
      setLoading(false);
      notification.setMessage({ message: successMessage, type: 'success' });
      history.push(`${ROUTES.PLACES}/detail/${placeId || doc.id}`);
    })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
        setLoading(false);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const dataSet = {
      name: fields.name.value,
      client: fields.client.value,
      contactName: fields.contactName.value,
      contactPhone: fields.contactPhone.value,
      contactEmail: fields.contactEmail.value,
      advisor: fields.advisor.value,
      membership: fields.membership.value,
      editor: fields.editor.value,
      address: fields.address.value,
      published: fields.published.value,
      delivery: fields.delivery.value,
      categories: getCategoriesValues(fields.categories.value, defaultValues, firebase),
    };
    firebase.places().where('name', '==', dataSet.name)
      .get()
      .then((snapShot) => {
        if ((isNew && !snapShot.empty)
          || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
          notification.setMessage({
            message: 'Ya existe otro negocio/lugar con ese nombre.',
            type: 'warning',
          });
          setLoading(false);
        } else {
          const placeReference = isNew ? firebase.places()
            : firebase.place(fields.id.value);
          if (isNew) {
            dataSet.createdBy = authUser.uid;
            saveData(placeReference, dataSet, 'Se creó el nuevo lugar/negocio 😀');
          } else {
            saveData(placeReference, dataSet, 'Se modificó el lugar/negocio 😀', fields.id.value);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
        notification.setMessage({ message: e.message, type: 'error' });
      });
  };

  const setContactDetails = (result) => {
    firebase.client(result.value).get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const asyncFields = { ...fields };
          if (asyncFields[Fields.fields.contactName.id].value === ''
            && asyncFields[Fields.fields.contactPhone.id].value === '') {
            asyncFields[Fields.fields.contactName.id].value = `${data.firstName} ${data.lastName}`;
            asyncFields[Fields.fields.contactPhone.id].value = data.phone || '';
            asyncFields[Fields.fields.contactEmail.id].value = data.email || '';
            setFields(asyncFields);
          }
        }
      })
      .catch(() => {});
  };

  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    if (result.id === Fields.fields.client.id && result.value) {
      setContactDetails(result);
    }
    setFields(theFields);
  };

  const isInvalid = !validateForm(fields);

  return (
    <MForm
      loading={loading}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Negocio/Lugar`,
        disabled: isInvalid,
      }}
      onSubmit={onSubmit}
    >
      {generateInputs(fields, onChange)}
    </MForm>
  );
};

BusinessForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

BusinessForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isEditor),
  withCurrentUser,
  withFirebase,
  withRouter,
  withNotification,
)(BusinessForm);
