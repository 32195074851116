import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardMedia, CardContent, Typography, CardActions,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Fields from './fields';
import Confirm from '../../../../components/confirm';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { withAuthorization } from '../../../../model/Session';
import { isLolaAdmin } from '../../../../components/access';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';
import { domain } from '../../../../model/Images';
import { DeleteButton } from '../../../../components/buttons';
import ObraDetails from './details';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  media: {
    height: 200,
  },
}));

const deleteObra = (artistaId, obra, firebase, notification) => {
  let message;
  firebase.obra(artistaId, obra.id)
    .delete()
    .then(() => {
      const promises = obra.photos.map((item) => firebase.deleteFile(item));
      return Promise.all(promises);
    })
    .then(() => {
      message = { type: 'success', message: 'Obra borrada satisfactoriamente 😀' };
    })
    .catch(() => {
      message = { type: 'success', message: 'Ups! Algo no salió bien borrando la obra 🥺' };
    })
    .finally(() => notification.setMessage(message));
};

const ObraItem = ({
  obra, artistaId, firebase, notification,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [image] = React.useState(
    buildURL(`${domain}${obra.photos[0].replace('/images', '')}`, { w: 400 }),
  );

  React.useEffect(() => {
    const theImages = [];
    obra.photos.forEach((item) => {
      theImages.push({
        src: buildURL(`${domain}${item.replace('/images', '')}`, { w: 1000 }),
      });
    });
    setImages(theImages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAction = () => {
    Confirm('¿Está seguro de borrar esta obra?')
      .then(
        () => deleteObra(artistaId, obra, firebase, notification),
        () => {},
      );
  };

  return (
    <>
      <Card>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia
            className={classes.media}
            image={image}
            title={obra.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {obra.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <DeleteButton onClick={deleteAction} />
          <ObraDetails obra={obra} />
        </CardActions>
      </Card>
      <ModalGateway>
        {open ? (
          <Modal
            onClose={() => setOpen(false)}
            styles={{
              positioner: (base) => ({
                ...base,
                zIndex: 2100,
              }),
              dialog: (base) => ({
                ...base,
                maxWidth: 800,
              }),
              blanket: (base) => ({
                ...base,
                zIndex: 2000,
              }),
            }}
          >
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

ObraItem.propTypes = {
  obra: Fields.getPropTypes().isRequired,
  artistaId: PropTypes.string.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withFirebase,
  withNotification,
)(ObraItem);
