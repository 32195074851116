import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { RenderEmail, RenderPhone } from '../../../../components/renders';
import LicenseLabelItem from './licenseLabelItem';
import LicenseTitleSection from './licenseTitleSection';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
    wordWrap: 'break-word',
  },
}));

const PersonalSection = ({ pilot }) => {
  const classes = useStyles();
  if (pilot) {
    return (
      <div>
        <LicenseTitleSection marginTop title="Información Personal" />
        <Grid container spacing={3}>
          {!!pilot.email && (
            <LicenseLabelItem
              label="Correo electrónico"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderEmail value={pilot.email} />
                </Typography>
              )}
            />
          )}
          {!!pilot.phone && (
            <LicenseLabelItem
              label="Número telefónico"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderPhone value={pilot.phone} />
                </Typography>
              )}
            />
          )}
          <LicenseLabelItem label="Ciudad de residencia" value={pilot.cityResidence} />
          <LicenseLabelItem label="Dirección de residencia" value={pilot.addressResidence} />
          <LicenseLabelItem label="Código postal residencia" value={pilot.postalResidence} />
        </Grid>
      </div>
    );
  }
  return null;
};

PersonalSection.defaultProps = {
  pilot: null,
};

PersonalSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
};

export default PersonalSection;
