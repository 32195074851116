import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { LinearProgress, Typography } from '@material-ui/core';
import MUpload from '../mUpload';
import { TYPES } from '../../validator';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../notification';
import { uploadMultipleFiles } from '../../../lib/misc';

const MAutoUpload = ({
  id, label, validationType, onUpload, validationMessage, fileTypes, firebaseRef,
  firebase, notification, maxFiles, dndText,
}) => {
  const [value, setValue] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);

  const onChange = (result) => {
    if (result.isValid) {
      setError(false);
    } else {
      setError(true);
      return;
    }
    if (firebaseRef) {
      uploadMultipleFiles(result, fileTypes, firebaseRef, setValue, firebase, setProgress, maxFiles)
        .then((res) => {
          if (maxFiles > 0) {
            onUpload(res);
          } else {
            onUpload(res[0]);
          }
        })
        .catch((err) => notification.setMessage({ type: 'error', message: err.message }))
        .finally(() => {
          setValue('');
          setProgress(0);
        });
    } else {
      onUpload(result);
      setValue(result.value);
      setProgress(0);
    }
  };

  if (value !== '' && firebaseRef) {
    return (
      <>
        <Typography variant="overline" display="block" style={{ textAlign: 'center' }}>
          {`Cargando archivo ${Math.round(progress)}%`}
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
      </>
    );
  }

  return (
    <MUpload
      id={id}
      value={value}
      label={label}
      validationType={validationType}
      validationMessage={validationMessage}
      required
      error={error}
      fileTypes={fileTypes}
      onChange={onChange}
      multiple={maxFiles > 1}
      dndText={dndText}
    />
  );
};

MAutoUpload.defaultProps = {
  id: 'mautoupload',
  fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
  firebaseRef: null,
  maxFiles: 1,
  dndText: null,
};

MAutoUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]).isRequired,
  validationMessage: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  firebase: FirebasePropType.isRequired,
  firebaseRef: PropTypes.string,
  notification: NotificationPropTypes.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  dndText: PropTypes.string,
};

export default compose(
  withFirebase,
  withNotification,
)(MAutoUpload);
