import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import EventAgenda from './EventAgenda';
import Loading from '../../components/loading';
import EventDetails from './eventDetails';
import { eventStyle, dateRangeEvent } from './util';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import { isAuthor, isAuthorExtrict, isEditorExtrict } from '../../components/access';
import Fields, { DATE_STATUS } from './fields';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import FilterDateStatus from './filterDateStatus';

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    margin: theme.spacing(2),
    height: '500px',
    display: 'block',
  },
  filterDivider: {
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
}));

const localizer = momentLocalizer(moment);

const PanelCalendarPlace = ({
  placeId, firebase, authUser, notification,
}) => {
  const classes = useStyles();

  const [date, setDate] = React.useState(new Date());
  const [view, setView] = React.useState(Views.AGENDA);

  const [status, setStatus] = React.useState(DATE_STATUS.scheduled.value);
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [dateRange, setDateRange] = React.useState({
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  });

  React.useEffect(() => {
    setLoading(true);
    let query = firebase.dates().where('initialDate', '<=', dateRange.end)
      .where('initialDate', '>=', dateRange.start)
      .where('place', '==', placeId);
    if (isAuthorExtrict(authUser) || isEditorExtrict(authUser)) {
      query = query.where('content', '==', true);
    }
    if (status && status !== 'all') {
      query = query.where('status', '==', status);
    }
    const unsubscribe = query.orderBy('initialDate', 'desc')
      .onSnapshot((querySnapshot) => {
        const newEvents = [];
        querySnapshot.forEach(doc => newEvents.push(Fields.getEvent(doc)));
        setEvents(newEvents);
        setLoading(false);
      }, (e) => {
        setLoading(false);
        console.log(e);
        notification.setMessage({ message: 'Algo no salió bien cargando las citas 😰', type: 'error' });
      });
    return () => unsubscribe();
  }, [authUser, dateRange.end, dateRange.start, firebase, notification, placeId, status]);

  const [selectedEvent, setSelectedEvent] = React.useState(null);

  return (
    <div className={classes.calendarContainer}>
      <FilterDateStatus
        changeStatus={setStatus}
        status={status}
        showLabel={false}
        showButton
        place={placeId}
      />
      <Divider component="hr" className={classes.filterDivider} />
      {!loading && (
        <BigCalendar
          localizer={localizer}
          components={{
            agenda: {
              event: EventAgenda(setSelectedEvent),
            },
          }}
          events={events}
          date={date}
          view={view}
          views={{ agenda: true }}
          onNavigate={nDate => setDate(nDate)}
          onView={nView => setView(nView)}
          onRangeChange={dateRangeEvent(setDateRange)}
          onSelectEvent={event => setSelectedEvent(event)}
          eventPropGetter={eventStyle}
        />
      )}
      {!!loading && (
        <Loading />
      )}
      {!!selectedEvent && (
        <EventDetails
          event={selectedEvent}
          closeEvent={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

PanelCalendarPlace.propTypes = {
  placeId: PropTypes.string.isRequired,
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withCurrentUser,
  withNotification,
)(PanelCalendarPlace);
