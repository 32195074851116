import React from 'react';
import * as PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MIconButton from './iconButton';

const styles = () => ({
  buttonEdit: {
    // backgroundColor: grey[50],
    // color: grey[500],
    '&:hover': {
      backgroundColor: grey[100],
      color: orange[900],
    },
  },
});

const AddButton = ({ classes, onClick, ...others }) => (
  <MIconButton {...others} onClick={onClick} className={classes.buttonEdit}>
    <AddIcon fontSize="small" />
  </MIconButton>
);

AddButton.defaultProps = {
  onClick: null,
};

AddButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(AddButton);
