/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import LabelItem from '../../../../components/labelItem';

const LicenseLabelItem = ({ label, value, ...props }) => !!value && (
  <Grid
    item
    xs={12}
    sm={6}
    md={3}
    {...props}
  >
    <LabelItem label={label} value={value} />
  </Grid>
);

LicenseLabelItem.defaultProps = {
  value: null,
};

LicenseLabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.func,
  ]),
  ...Grid.PropTypes,
};

export default LicenseLabelItem;
