import React from 'react';
import { compose } from 'recompose';
import {
  CardMedia, Grid, makeStyles, Paper,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import { withFirebase, FirebasePropType } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { PanelStory } from '../Editors';
import { TYPES, validateForm } from '../../../components/validator';
import Fields from '../Products/fields';
import { generateInputs, MForm } from '../../../components/form';
import { domain } from '../../../model/Images';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const FIELDS = {
  story_title: {
    id: 'story_title',
    label: 'Título de la historia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Título no válido',
    required: true,
  },
  story_color: {
    id: 'story_color',
    label: 'Color Fondo Historia',
    value: '',
    type: TYPES.COLOR,
    validationType: TYPES.COLOR,
    required: false,
  },
  story_photo: {
    id: 'story_photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Imagen',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una imagen con un formato válido.',
  },
  story_published: {
    id: 'story_published',
    label: 'Historia Publicada',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  story_photo_URL: {
    id: 'story_photo_URL',
    value: '',
  },
};

const persistProduct = (
  firebase, fields, place, dataSet, notification, setLoading,
) => {
  const successMessage = 'Se modificó el producto/servicio/historia 😀';
  firebase.place(place.id).set(dataSet, { merge: true })
    .then(() => {
      notification.setMessage({ message: successMessage, type: 'success' });
      setLoading(false);
    })
    .catch((e) => {
      notification.setMessage({ message: e.message, type: 'error' });
      setLoading(false);
    });
};

const uploadFile = (place, file, firebase, setUploaded) => new Promise((resolve, reject) => {
  const timeStamp = new Date().getTime();
  const filePath = `/images/places/${place.id}/stories/${timeStamp}-${file.name}`;
  firebase.uploadFile(filePath, file, (progress) => {
    setUploaded(progress.value);
  }, (err, url) => {
    if (err) {
      setUploaded(-1);
      reject(err);
    }
    setUploaded(100);
    resolve({ url, reference: filePath });
  });
});

const cleanFields = (newFields, setFields) => {
  // eslint-disable-next-line no-param-reassign
  newFields[FIELDS.story_photo.id].value = '';
  // eslint-disable-next-line no-param-reassign
  newFields[FIELDS.story_photo.id].error = false;
  // eslint-disable-next-line no-param-reassign
  newFields[FIELDS.story_photo.id].file = null;
  // eslint-disable-next-line no-param-reassign
  newFields[FIELDS.story_photo_URL.id].value = '';
  setFields(newFields);
};

const Story = ({ place, firebase, notification }) => {
  const classes = useStyles();
  const [fields, setFields] = React.useState({});
  const [key, setKey] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);
  const [image, setImage] = React.useState(null);

  const removeImage = React.useCallback((newFields) => {
    setLoading(true);
    if (place && newFields[FIELDS.story_photo_URL.id].value !== '') {
      firebase.deleteFile(newFields[FIELDS.story_photo.id].value)
        .then(() => {
          firebase.place(place.id)
            .set({ story_photo: '', story_photo_URL: '' }, { merge: true })
            .then(() => {
              notification.setMessage({ type: 'success', message: 'Se quito correctamente la imagen.' });
              cleanFields(newFields, setFields);
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
              setLoading(false);
            });
        })
        .catch((e) => {
          notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
          setLoading(false);
          console.error(e);
        });
    } else {
      cleanFields(newFields, setFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (place !== null && place !== undefined) {
      const newFields = JSON.parse(JSON.stringify(FIELDS));
      setKey(place.id);
      Object.keys(newFields).forEach((item) => {
        newFields[item].value = place[item];
      });
      if (typeof newFields[FIELDS.story_photo.id].value !== 'string') {
        newFields[FIELDS.story_photo.id].value = '';
        newFields[FIELDS.story_photo_URL.id].value = '';
      }
      newFields[FIELDS.story_photo.id].onRemove = removeImage;
      setFields(newFields);
      if (place.story_photo && place.story_photo !== '') {
        const url = buildURL(`https:${domain}${place.story_photo.replace('/images', '')}`, { w: 400, h: 400 });
        setImage(url);
      } else {
        setImage(null);
      }
    }
  }, [place]); // eslint-disable-line

  const isInvalid = !validateForm(fields);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      setLoading(false);
    } else {
      const dataSet = {
        story_title: fields.story_title.value,
        story_color: fields.story_color.value,
        story_published: fields.story_published.value,
      };
      if (fields.story_photo.value === '' || fields.story_photo_URL.value !== '') {
        persistProduct(firebase, fields, place, dataSet, notification, setLoading);
        return;
      }
      uploadFile(place, fields.story_photo.file, firebase, setUploaded)
        .then((picture) => {
          dataSet[FIELDS.story_photo.id] = picture.reference;
          dataSet[FIELDS.story_photo_URL.id] = picture.url;
          persistProduct(firebase, fields, place, dataSet, notification, setLoading);
        })
        .catch((err) => {
          setLoading(false);
          notification.setMessage({ message: err.message, type: 'error' });
        });
    }
  }, [fields, firebase, isInvalid, notification, place]);

  const onChange = (result) => {
    const newFields = { ...fields };
    newFields[result.id].value = result.value;
    newFields[result.id].error = !result.isValid;
    if (result.id === FIELDS.story_photo.id) {
      newFields[result.id].file = result.file;
    }
    setFields(newFields);
  };

  return (
    <>
      <Grid item xs={12} lg={4}>
        <Paper className={classes.paper}>
          {!!place && (
            <MForm
              loading={loading}
              button={{ label: 'Guardar Historía', disabled: isInvalid }}
              uploaded={uploaded}
              onSubmit={submitAction}
            >
              {generateInputs(fields, onChange, key)}
            </MForm>
          )}
          <CardMedia
            className={classes.media}
            image={image}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={8}>
        <PanelStory place={place} />
      </Grid>
    </>
  );
};

Story.defaultProps = {
  place: null,
};

Story.propTypes = {
  place: Fields.getPropTypes(),
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(Story);
