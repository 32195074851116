import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, Button, Typography } from '@material-ui/core';
import { buildURL } from 'react-imgix';
import { TYPES, validate } from '../validator';
import DndContainer from '../dragNdrop/DndContainer';
import { domain } from '../../model/Images';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  label: {
    ...theme.typography.button,
    borderColor: theme.borderColor,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '5px',
    padding: theme.spacing(1),
  },
  legend: {
    ...theme.typography.caption,
    textTransform: 'initial',
  },
  spanLabel: {
    float: 'left',
    maxWidth: 450,
    width: '100%',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  removeButton: {
    display: 'block',
    marginLeft: 'auto',
  },
}));

const MUpload = ({
  id, label, value: initialValue, error: initialError, fileTypes, multiple,
  validationMessage, validationType, onChange, required, onRemove, fields, dndText,
}) => {
  const classes = useStyles();
  const idField = `contained-button-file${id}`;
  const fileInput = React.useRef();

  const [value, setValue] = React.useState([]);
  React.useEffect(() => {
    let val = [initialValue];
    if (Array.isArray(initialValue)) {
      val = [...initialValue];
    }
    setValue(val);
  }, [initialValue]);

  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    setError(initialError);
  }, [initialError]);

  const changeEvent = (remove) => {
    const result = {
      id,
    };
    if (remove) {
      result.value = multiple ? [''] : '';
      result.files = [];
      if (onRemove) {
        onRemove(fields);
        return;
      }
    } else if (multiple) {
      result.value = [];
      result.files = [];
      const files = Object.values(fileInput.current.files);
      files.forEach((item) => {
        result.value.push(item.name);
        result.files.push(item);
      });
    } else {
      const currentFile = fileInput.current.files[0];
      result.value = currentFile.name;
      result.file = currentFile;
    }
    if (!validationType) {
      onChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      fileTypes,
      required,
    });
    onChange({
      ...result,
      ...validation,
    });
  };

  const handleDrop = (result) => {
    fileInput.current.files = result;
    changeEvent(false);
  };

  const component = error || value[0] === '' ? (
    <DndContainer handleDrop={handleDrop} areaText={dndText}>
      <input
        accept={fileTypes.join(',')}
        className={classes.input}
        id={idField}
        multiple={multiple}
        type="file"
        ref={fileInput}
        onChange={() => changeEvent(false)}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={idField}>
        <Button variant="contained" color="primary" component="span" className={classes.button}>
          {label}
        </Button>
      </label>
    </DndContainer>
  ) : (
    <fieldset className={classes.label}>
      <legend className={classes.legend}>
        {label}
      </legend>
      {value.map((item) => {
        let href;
        if (item.startsWith('/images/')) {
          href = buildURL(
            `${domain}${item.replace('/images', '')}`,
            {
              w: 1600,
            },
          );
        }
        return (
          <React.Fragment key={`item${item}`}>
            <a href={href} rel="noopener noreferrer" target="_blank" className={classes.spanLabel}>
              {item}
            </a>
            <br />
          </React.Fragment>
        );
      })}
      <Button
        className={classes.removeButton}
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => changeEvent(true)}
      >
        Quitar
      </Button>
    </fieldset>
  );

  return (
    <div className={classes.root}>
      {component}
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </div>
  );
};

MUpload.defaultProps = {
  value: '',
  onChange: null,
  label: 'Subir Archivo',
  error: false,
  validationType: null,
  validationMessage: null,
  required: false,
  fileTypes: ['.png', '.jpeg', '.jpg', '.pdf'],
  onRemove: null,
  fields: null,
  multiple: false,
  dndText: null,
};

MUpload.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]),
  validationMessage: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func,
  multiple: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object,
  dndText: PropTypes.string,
};

export default MUpload;
