import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { getDays } from './util';
import PeriodItem from './periodItem';

const useStyles = makeStyles(theme => ({
  emptyMessage: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  root: {
    margin: theme.spacing(2, 0),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
}));

// eslint-disable-next-line no-unused-vars
const PeriodList = ({ periods, removePeriod }) => {
  const classes = useStyles();
  if (periods) {
    const periodsComponents = [];
    getDays().forEach((item) => {
      if (periods[item.value]) {
        periodsComponents.push((
          <div key={item.value} className={classes.item}>
            <Typography component="p" variant="subtitle1">
              {item.label}
            </Typography>
            <div>
              {periods[item.value].map((period, periodIndex) => (
                <PeriodItem
                  key={`${item.value}${period.start}`}
                  period={period}
                  deleteAction={removePeriod ? () => removePeriod(item.value, periodIndex) : null}
                />
              ))}
            </div>
          </div>
        ));
      }
    });

    return (
      <div className={classes.root}>
        {periodsComponents}
      </div>
    );
  }
  return (
    <Typography variant="subtitle2" component="p" className={classes.emptyMessage}>
      No se ha registrado ningún horario
    </Typography>
  );
};

PeriodList.defaultProps = {
  periods: null,
  removePeriod: null,
};

PeriodList.propTypes = {
  periods: PropTypes.objectOf(PropTypes.any),
  removePeriod: PropTypes.func,
};

export default PeriodList;
