import React from 'react';
import * as PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const PeriodItem = ({ period, deleteAction }) => {
  const classes = useStyles();
  return (
    <Chip
      label={`${period.start} - ${period.end}`}
      variant="outlined"
      className={classes.root}
      color="primary"
      icon={<TimerIcon fontSize="small" />}
      onDelete={deleteAction}
    />
  );
};

PeriodItem.defaultProps = {
  deleteAction: null,
};

PeriodItem.propTypes = {
  period: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  deleteAction: PropTypes.func,
};

export default PeriodItem;
