import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import {
  Button, Card, CardActionArea, CardActions, CardContent, makeStyles, Typography,
} from '@material-ui/core';
import { defaultStyles, FileIcon } from 'react-file-icon';
import moment from 'moment';
import Fields from './fields';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import confirm from '../../../components/confirm';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { isCoordinator } from '../../../components/access';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  title: {
    wordBreak: 'break-all',
  },
}));

const DocumentItem = ({
  document, placeId, firebase, notification,
}) => {
  const classes = useStyles();

  const deleteAction = () => {
    confirm(
      '¿Está seguro de que desea borrar este archivo?',
      'Confirma Borrado',
    ).then(() => {
      firebase.deleteFile(document.file)
        .then(() => {
          let message;
          firebase.placeFile(placeId, document.id).delete()
            .then(() => { message = { type: 'success', message: 'El archivo fue borrado satisfactoriamente 😁!' }; })
            .catch(() => { message = { type: 'error', message: 'Ups! Algo no salió bien borrando el archivo 😞' }; })
            .finally(() => notification.setMessage(message));
        })
        .catch((err) => notification.setMessage('error', err.message));
    }, () => {});
  };

  const extension = document.file.match(/.[0-9a-z]+$/i)[0];

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea href={document.fileURL} target="_blank" rel="noreferrer">
          <FileIcon size={120} extension={extension} {...defaultStyles[extension]} />
        </CardActionArea>
        <CardContent>
          <Typography variant="subtitle1" component="p" className={classes.title}>
            {document.name}
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            {moment(document.date).format('DD/MM/YYYY')}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="default" onClick={deleteAction}>
            Borrar
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

DocumentItem.propTypes = {
  document: Fields.getPropTypes().isRequired,
  placeId: PropTypes.string.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isCoordinator),
  withFirebase,
  withNotification,
)(DocumentItem);
