import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isLolaAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import ArtistaForm from './form';
import * as ROUTES from '../../../constants/routes';

const NewArtista = () => (
  <Container backPath={ROUTES.ARTISTAS} title="Nuevo Artista">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <ArtistaForm toEdit />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isLolaAdmin)(NewArtista);
