/* eslint-disable react/no-multi-comp */
import React, { forwardRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import List from '@material-ui/icons/List';
import TableRenderDate from './renderDate';
import RenderPhone from './renderPhone';
import TableRenderEmail from './renderEmail';
import AsyncColumnRender from './asyncColumnRender';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  List: forwardRef((props, ref) => <List {...props} ref={ref} />),
};

const spanish = {
  grouping: {
    groupedBy: 'Agrupar por:',
    placeholder: 'Arrastra las cabeceras aquí para agrupar',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsPerPage: 'Filas por página:',
    labelRowsSelect: 'filas',
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Siguiente página',
    nextTooltip: 'Siguiente página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
    exportName: 'Exportar como CSV',
    showColumnsAriaLabel: 'Mostrar columnas',
    showColumnsTitle: 'Mostrar columnas',
    nRowsSelected: '{0} fila(s) seleccionadas',
    addRemoveColumns: 'Agregar o quitar columnas',
  },
  header: {
    actions: 'Acciones',
  },
  body: {
    filterRow: {
      filterTooltip: 'Filtro',
    },
    editRow: {
      saveTooltip: 'Guardar',
      cancelTooltip: 'Cancelar',
      deleteText: 'Está seguro de borrar esta fila?',
    },
    addTooltip: 'Agregar',
    deleteTooltip: 'Borrar',
    editTooltip: 'Editar',
    emptyDataSourceMessage: 'No hay datos para mostrar',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 1),
  },
}));

// const getEditable = (editAction, removeAction) => {
//   let editable = null;
//   if (editAction || removeAction) {
//     editable = {};
//     if (editAction) {
//       editable.isEditable = () => true;
//       editable.onRowUpdate = (newData, oldData) => new Promise((resolve, reject) => {
//         editAction(newData, oldData)
//           .then(() => resolve())
//           .catch(e => reject(e));
//       });
//     }
//     if (removeAction) {
//       editable.isDeletable = () => true;
//       editable.onRowDelete = oldData => new Promise((resolve, reject) => {
//         removeAction(oldData)
//           .then(() => resolve())
//           .catch(e => reject(e));
//       });
//     }
//   }
//   return editable;
// };

const Table = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <MaterialTable
        {...props}
        icons={tableIcons}
        localization={spanish}
        options={{
          headerStyle: {
            backgroundColor: theme.grayMaclic,
          },
        }}
      />
    </div>
  );
};

Table.defaultProps = {
  ...MaterialTable.defaultProps,
};

Table.propTypes = {
  ...MaterialTable.propTypes,
};

export {
  AsyncColumnRender, TableRenderEmail, RenderPhone, TableRenderDate,
};

export default Table;
