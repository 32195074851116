import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import Container from '../../components/dashboard/container';
import { isAuthor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import StoriesForm from './storiesForm';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import * as ROUTES from '../../constants/routes';
import Fields from './fields';
import Loading from '../../components/loading';

const redirect = (history) => {
  history.push(ROUTES.STORIES);
};

const EditStory = ({ history, match, firebase }) => {
  const [story, setStory] = React.useState(null);
  React.useEffect(() => {
    const { id } = match.params;
    if (id) {
      firebase.story(id)
        .get()
        .then((doc) => {
          const storyData = Fields.getInstance(doc);
          setStory(storyData);
        })
        .catch(() => redirect(history));
    } else {
      redirect(history);
    }
  }, [firebase, history, match.params]);

  let title = 'Cargando...';
  let component = <Loading />;
  if (story) {
    title = `Editando ${story.title}`;
    component = <StoriesForm storyValues={story} />;
  }

  return (
    <Container backAction={() => redirect(history)} title={title}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {component}
        </Grid>
      </Grid>
    </Container>
  );
};

EditStory.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withRouter,
)(EditStory);
