import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAuthorOrTranslator } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import New from './new';
// import EditBusiness from './editBusiness';
// import DetailBusiness from './detailBusiness';
import DetailBusiness from './Details';
// import Table from './table';
import ListBusiness from './List';
import NewProduct from './Products/new';
import EditProduct from './Products/edit';

const BusinessPage = () => (
  <Switch>
    <Route path={`${ROUTES.PLACES}/new`} component={New} />
    {/* <Route path={`${ROUTES.PLACES}/edit/:id`} component={EditBusiness} /> */}
    <Route path={`${ROUTES.PLACES}/detail/:id/:section?`} component={DetailBusiness} />
    <Route path={`${ROUTES.PLACES}/:placeId/product/new`} component={NewProduct} />
    <Route path={`${ROUTES.PLACES}/:placeId/product/edit/:productId`} component={EditProduct} />
    <Route path={`${ROUTES.PLACES}`} component={ListBusiness} />
    {/* <Route path={ROUTES.PLACES} component={Table} /> */}
  </Switch>
);

export default withAuthorization(isAuthorOrTranslator)(BusinessPage);
