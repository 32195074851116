import PropTypes from 'prop-types';
import AuthUserContext from './context';
import withAuthentication from './withAuthentication';
import withAuthorization from './withAuthorization';
import withCurrentUser from './withCurrentUser';

const AuthUserPropTypes = PropTypes.shape({
  uid: PropTypes.string.isRequired,
});

export {
  withAuthentication, AuthUserContext, withAuthorization, withCurrentUser, AuthUserPropTypes,
};
