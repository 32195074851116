import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Divider, makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import PhoneIcon from '@material-ui/icons/PhoneInTalkRounded';
import { RenderPhoneWhatsapp } from '../../../../components/renders';
import MTextField from '../../../../components/form/mTextField';
import { isLocalPhone } from '../../../../components/validator';
import SocialIcon from '../../../../components/social/icon';

const DEFAULT_PHONE = {
  number: '',
  whatsapp: false,
  phone: true,
};

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
  whatsapp: {
    width: '35px !important',
    height: '35px !important',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  whatsappOn: {
    padding: 0,
    backgroundColor: 'rgb(37, 211, 102)',
    '&:hover': {
      backgroundColor: 'rgb(32, 179, 87)',
    },
  },
  whatsappOff: {
    padding: 0,
    backgroundColor: grey[400],
    '&:hover': {
      backgroundColor: grey[500],
    },
  },
  phoneOn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  phoneOff: {
    color: 'white',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: grey[400],
    '&:hover': {
      backgroundColor: grey[500],
    },
  },
}));

const PhonesField = ({ phones, editPhones }) => {
  const classes = useStyles();
  const [thePhones, setPhones] = React.useState([]);
  const [phone, setPhone] = React.useState(DEFAULT_PHONE);
  const [error, setError] = React.useState({
    error: false,
    message: '',
  });
  const [valid, setValid] = React.useState(false);
  React.useEffect(() => {
    setPhones(phones.map(item => ({
      number: item.number || item,
      whatsapp: item.whatsapp || false,
      phone: item.phone === undefined || item.phone === null ? true : item.phone,
    })));
  }, [phones]);

  const deleteAction = (index) => {
    const temp = [...thePhones];
    temp.splice(index, 1);
    setPhones(temp);
    if (editPhones) {
      editPhones(temp);
    }
  };

  const onChangePhone = (result) => {
    const res = { ...phone };
    res[result.id] = result.value;
    if (res.number === '') {
      setError({ error: false, message: '' });
      setValid(false);
    } else if (!isLocalPhone(res.number)) {
      setError({ error: true, message: 'Número telefónico no válido.' });
      setValid(false);
    } else if (!res.whatsapp && !res.phone) {
      setError({ error: true, message: 'Débe indicar si es teléfono para llamadas o para WhatsApp.' });
      setValid(false);
    } else if (res.whatsapp && res.number.length !== 10) {
      setError({ error: true, message: 'El número no es válido para WhatsApp.' });
      setValid(false);
    } else {
      setError({ error: false, message: '' });
      setValid(true);
    }
    setPhone(res);
  };

  const addAction = () => {
    const temp = [...thePhones];
    temp.push(Object.assign({}, phone));
    setPhones(temp);
    // setPhone(DEFAULT_PHONE);
    if (editPhones) {
      editPhones(temp);
    }
  };

  return (
    <React.Fragment>
      <div>
        <MTextField
          label="Agregar Teléfono"
          value={phone.number}
          id="number"
          onChange={onChangePhone}
          error={error.error}
          validationMessage={error.message}
        />
      </div>
      <Button
        variant="outlined"
        className={phone.whatsapp ? classes.whatsappOn : classes.whatsappOff}
        onClick={() => onChangePhone({ id: 'whatsapp', value: !phone.whatsapp })}
      >
        <SocialIcon bgColor="transparent" fgColor="white" className={classes.whatsapp} network="whatsapp" />
      </Button>
      <Button
        variant="outlined"
        className={phone.phone ? classes.phoneOn : classes.phoneOff}
        onClick={() => onChangePhone({ id: 'phone', value: !phone.phone })}
      >
        <PhoneIcon />
      </Button>
      <Button
        variant="outlined"
        onClick={addAction}
        disabled={!valid}
        color="primary"
      >
        Agregar
      </Button>
      <Divider light className={classes.divider} />
      <div>
        {thePhones.map((item, index) => (
          <RenderPhoneWhatsapp
            key={item.number}
            phone={item}
            deleteAction={() => deleteAction(index)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

PhonesField.defaultProps = {
  phones: [],
  editPhones: null,
};

PhonesField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  phones: PropTypes.arrayOf(PropTypes.any),
  editPhones: PropTypes.func,
};

export default PhonesField;
