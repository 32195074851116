import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAuthor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import New from './new';
import EditDate from './editDate';
// import Table from './table';
// import TableMyDates from './tableMyDates';
// import TableContentDates from './tableContentDates';
import CalendarView from './calendarView';

const BusinessPage = () => (
  <Switch>
    <Route path={`${ROUTES.DATES}/new`} component={New} />
    {/* <Route path={`${ROUTES.DATES}/my`} component={TableMyDates} /> */}
    {/* <Route path={`${ROUTES.DATES}/content`} component={TableContentDates} /> */}
    <Route path={`${ROUTES.DATES}/edit/:id`} component={EditDate} />
    {/* <Route path={ROUTES.DATES} component={Table} /> */}
    <Route path={ROUTES.DATES} component={CalendarView} />
  </Switch>
);

export default withAuthorization(isAuthor)(BusinessPage);
