import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { MForm, generateInputs } from '../../components/form';
import * as ROUTES from '../../constants/routes';
import { validateForm } from '../../components/validator';
import FIELDS from './fields';

const INITIAL_STATE = {
  fields: FIELDS,
  error: null,
  loading: false,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const { fields } = this.state;
    const { firebase, history } = this.props;
    firebase
      .doSignInWithEmailAndPassword(fields.email.value, fields.password.value)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      })
      .finally(() => this.setState({ loading: false }));
  }

  onChange(result) {
    const { fields } = this.state;
    fields[result.id].value = result.value;
    fields[result.id].error = !result.isValid;
    this.setState({
      fields,
    });
  }

  render() {
    const { fields, error, loading } = this.state;

    const isInvalid = !validateForm(fields);

    return (
      <MForm
        loading={loading}
        loadingMessage="Iniciando sesión..."
        button={{
          label: 'Ingresar',
          disabled: isInvalid,
        }}
        onSubmit={this.onSubmit}
      >
        {generateInputs(fields, this.onChange)}
        {error && (
          <Typography
            variant="caption"
            style={{ color: 'red' }}
          >
            {error.message}
          </Typography>
        )}
      </MForm>
    );
  }
}

SignInFormBase.propTypes = {
  firebase: FirebasePropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
