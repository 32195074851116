import React from 'react';
import * as PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    float: 'right',
    marginRight: theme.spacing(-3),
    marginTop: theme.spacing(-2),
  },
}));

function MDialog({
  open, handleClose, title, children, keepMounted, ...others
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={keepMounted}
        scroll="body"
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        {...others}
      >
        <DialogTitle>
          {title}
          <IconButton onClick={handleClose} aria-label="delete" className={classes.closeButton}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
          {children}
          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

MDialog.defaultProps = {
  children: null,
  keepMounted: true,
};

MDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  keepMounted: PropTypes.bool,
  ...Dialog.propTypes,
};

export default MDialog;
