import AccessTemplate from './accessTemplate';
import * as ROLES from '../../constants/roles';

export const isAdmin = (authUser) => !!authUser.roles[ROLES.ADMIN];
export const isCoordinator = (authUser) => isAdmin(authUser) || !!authUser.roles[ROLES.COORDINATOR];
export const isEditor = (authUser) => isCoordinator(authUser) || !!authUser.roles[ROLES.EDITOR];
export const isEditorExtrict = (authUser) => !!authUser.roles[ROLES.EDITOR];
export const isAuthor = (authUser) => isEditor(authUser) || !!authUser.roles[ROLES.AUTHOR];
export const isAuthorExtrict = (authUser) => !!authUser.roles[ROLES.AUTHOR];
export const isContentEditor = (authUser) => !!authUser.roles[ROLES.AUTHOR]
  || !!authUser.roles[ROLES.EDITOR];
export const isTranslator = (authUser) => isCoordinator(authUser)
  || !!authUser.roles[ROLES.TRANSLATOR];
export const isAuthorOrTranslator = (authUser) => isAuthor(authUser) || isTranslator(authUser);
export const isAeroAdmin = (authUser) => !!authUser.roles[ROLES.AEROADMIN] || isAdmin(authUser);
export const isLolaAdmin = (authUser) => !!authUser.roles[ROLES.LOLAADMIN] || isAdmin(authUser);
export default AccessTemplate;
