import FieldsTemplate from '../../../../lib/FieldsTemplate';

export const DISCIPLINAS = {
  fotografia: {
    label: 'Fotografía',
    value: 'fotografia',
  },
  pintura: {
    label: 'Pintura',
    value: 'pintura',
  },
};

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  title: {
    id: 'title',
    label: 'Título de la obra',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Título de la obra no válido',
    required: false,
  },
  category: {
    id: 'category',
    value: '',
    type: 'select',
    label: 'Categoría',
    validationType: TYPES.SELECT,
    reference: {
      values: DISCIPLINAS,
    },
    validationMessage: 'Débe indicar una categoría.',
    required: true,
  },
  details: {
    id: 'details',
    value: '',
    type: 'editor',
    validationType: TYPES.EDITOR,
    label: 'Concepto',
    required: false,
  },
  photos: {
    id: 'photos',
    value: '',
    type: TYPES.MULTIFILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.MULTIFILE,
    label: 'Fotografías',
    required: true,
    multiple: true,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  id: {
    id: 'id',
    value: '',
  },
};

class ObraFields extends FieldsTemplate {}

export default new ObraFields(FIELDS);
