import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const EventAgenda = setSelectedEvent => ({ event }) => ( // eslint-disable-line
  <Typography component="span" variant="body1">
    {`${event.title} `}
    <Button component="a" onClick={() => setSelectedEvent(event)}>
      Ver más...
    </Button>
  </Typography>
);

export default EventAgenda;
