import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = (marginBottom) => (makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(marginBottom),
  },
})));

const SectionTitle = ({ title, marginBottom, block }) => {
  const classes = useStyles(marginBottom ? 2 : 0)();
  return (
    <Typography
      component="h3"
      variant="h6"
      className={classes.heading}
      display={block ? 'block' : 'inline'}
    >
      {title}
    </Typography>
  );
};

SectionTitle.defaultProps = {
  marginBottom: false,
  block: true,
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  marginBottom: PropTypes.bool,
  block: PropTypes.bool,
};

export default SectionTitle;
