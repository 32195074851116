const DATE_STATUS = {
  scheduled: {
    value: 'scheduled',
    label: 'Programada',
    color: 'primary',
  },
  done: {
    value: 'done',
    label: 'Realizada',
    color: 'secondary',
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelada',
    color: 'default',
  },
};

module.exports = DATE_STATUS;
