import React from 'react';
import { compose } from 'recompose';
import {
  Grid, makeStyles, Typography,
} from '@material-ui/core';
import Container from '../../../components/dashboard/container';
import {
  isAuthorOrTranslator, isAuthor,
} from '../../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import { MButton } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from '../fields';
import Loading from '../../../components/loading';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import CategoryItem from './categoryItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const setChildrenToParents = (category, theData) => {
  let data = { ...theData };
  const parents = (category.parents && Object.keys(category.parents)) || [];
  parents.forEach((parentId) => {
    if (category.parents[parentId]) {
      const parent = data[parentId] || {};
      if (!parent.children) {
        parent.children = {};
      }
      parent.children = {
        ...parent.children,
        ...category.children,
      };
      data[parentId] = parent;
      data = setChildrenToParents(parent, data);
    }
  });
  return data;
};

const ListCategories = ({ firebase, authUser, notification }) => {
  const classes = useStyles();
  const [data, setData] = React.useState({
    loading: true,
    list: {},
    ordered: [],
  });

  React.useEffect(() => {
    const unsubscribe = firebase.categories().onSnapshot((snapshot) => {
      const { list, ordered } = data;
      setData({ loading: true, list, ordered });
      const theList = {};
      if (!snapshot.empty) {
        let index = 1;
        snapshot.forEach((doc) => {
          const cat = Fields.getInstance(doc);
          if (theList[doc.id]) {
            theList[doc.id] = {
              ...theList[doc.id],
              ...cat,
              index,
            };
          } else {
            theList[doc.id] = {
              ...cat,
              index,
            };
          }
          index += 1;
          const parents = (cat.parents && Object.keys(cat.parents)) || [];
          parents.forEach((parentId) => {
            if (cat.parents[parentId]) {
              const parent = theList[parentId] || {};
              if (!parent.children) {
                parent.children = {};
              }
              parent.children[doc.id] = true;
              theList[parentId] = parent;
            }
          });
        });
      }
      let finalList = { ...theList };
      Object.values(theList).forEach((category) => {
        finalList = setChildrenToParents(category, finalList);
      });
      const parents = Object.values(finalList)
        .filter((item) => (item.parents && Object.keys(item.parents).length === 0));
      const newOrdered = Object.values(parents).sort((a, b) => {
        const ac = (!!a.children && Object.keys(a.children).length) || 0;
        const bc = (!!b.children && Object.keys(b.children).length) || 0;
        if (ac === bc) {
          return a.name.localeCompare(b.name);
        }
        return bc - ac;
      });
      setData({ loading: false, list: finalList, ordered: newOrdered });
    }, (err) => {
      console.warn(err.message);
      notification.setMessage({ type: 'warning', message: 'Ups! Algo no salió bien cargando las categorías.' });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let component = (
    <Grid item xs={12}>
      <Loading />
    </Grid>
  );
  if (!data.loading) {
    component = data.ordered.length > 0 ? (
      <>
        {data.ordered.map((item) => (
          <CategoryItem key={item.id} categories={data.list} category={item} />
        ))}
      </>

    ) : (
      <Grid item xs={12}>
        <Typography variant="button" style={{ textAlign: 'center' }} component="h2">
          No se encontraron categorías.
        </Typography>
      </Grid>
    );
  }

  return (
    <Container
      title="Listado de Categorías"
      header={(
        !!isAuthor(authUser)
        && <MButton fullWidth={false} label="Nueva Categoría" to={`${ROUTES.CATEGORIES}/new`} />
      )}
    >
      <Grid className={classes.root} spacing={3} container>
        {component}
      </Grid>
    </Container>
  );
};

ListCategories.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withCurrentUser,
  withFirebase,
  withNotification,
)(ListCategories);
