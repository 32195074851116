import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, Paper } from '@material-ui/core';
import { SectionTitle } from '../title';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MPanel = ({
  withPaper, children, title, titleBlock, ...props
}) => {
  const Component = withPaper ? Paper : 'div';
  const classes = useStyles();
  return (
    <Component {...props} className={classes.paper}>
      <div>
        {!!title && <SectionTitle block={!titleBlock} marginBottom title={title} />}
        {titleBlock}
      </div>
      {children}
    </Component>
  );
};

MPanel.defaultProps = {
  withPaper: false,
  children: null,
  title: null,
  titleBlock: null,
};

MPanel.propTypes = {
  withPaper: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  titleBlock: PropTypes.node,
};

export default MPanel;
