import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core';
import { isEditor } from '../../components/access';
import * as ROUTES from '../../constants/routes';
import { withCurrentUser, AuthUserPropTypes } from '../../model/Session';
import Fields from './fields';

const useStyles = makeStyles(() => ({
  root: {
    color: blue[400],
  },
}));

const EditButton = ({
  date, authUser, history, processing,
}) => {
  const classes = useStyles();
  if (date && (isEditor(authUser) || authUser.uid === date.createdBy)) {
    return (
      <Button
        component="button"
        disabled={processing}
        onClick={() => history.push(`${ROUTES.DATES}/edit/${date.id}`)}
        className={classes.root}
      >
        <EditIcon />
        Editar
      </Button>
    );
  }
  return null;
};

EditButton.defaultProps = {
  date: null,
};

EditButton.propTypes = {
  date: Fields.getPropTypes(),
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  withCurrentUser,
)(EditButton);
