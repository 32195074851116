import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import ClubForm from './clubForm';
import * as ROUTES from '../../../constants/routes';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from './fields';
import FieldsCity from '../Ciudades/fields';
import Loading from '../../../components/loading';

const EditClub = ({ firebase, match, history }) => {
  const [club, setClub] = React.useState(Fields.defaultValues());
  const [city, setCity] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.club(id)
        .onSnapshot((doc) => {
          const codClub = Fields.getInstance(doc);
          firebase.city(codClub.city).get()
            .then((docCity) => {
              if (docCity.exists) {
                const codCity = FieldsCity.getInstance(docCity);
                firebase.department(codCity.department).get()
                  .then((docDep) => {
                    if (docDep.exists) {
                      setCity(`${codCity.name}, ${docDep.data().name}`);
                    } else {
                      setCity(codCity.name);
                    }
                  });
              }
              setClub(codClub);
              setLoading(false);
            });
        }, () => history.push(ROUTES.CLUBES));
    } else {
      history.push(ROUTES.CLUBES);
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, history, match.params]);

  return (
    <Container
      backPath={ROUTES.CLUBES}
      title={`Club: ${club.name}${city ? ` - ${city}` : ''}`}
    >
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loading />
          ) : (
            <ClubForm isNew={false} defaultValues={club} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

EditClub.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
)(EditClub);
