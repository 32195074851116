import React from 'react';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import { withFirebase } from '../../model/Firebase';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Container from '../../components/dashboard/container';
import Users from './Users';
import NewUser from './Users/new';

const AdminPage = () => (
  <Switch>
    <Route exact path={`${ROUTES.USERS}/new`} component={NewUser} />
    <Route path={ROUTES.USERS} component={Users} />
    <Route>
      <Container title="Admin">
        <p>
          Páginas de administración del panel de administración de Maclic.
        </p>
      </Container>
    </Route>
  </Switch>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(AdminPage);
