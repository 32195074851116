import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../../../components/loading';
import ObraItem from './item';
import Fields from './fields';

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const ObrasList = ({ obras, artistaId }) => {
  const classes = useStyles();
  if (obras) {
    if (obras.length === 0) {
      return (
        <Typography variant="button" display="block" className={classes.emptyMessage}>
          No se han creado obras.
        </Typography>
      );
    }
    return (
      <Grid spacing={1} className={classes.grid} container>
        {obras.map((item) => (
          <Grid key={item.id} xs={12} md={4} item>
            <ObraItem artistaId={artistaId} obra={item} />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <Loading />
    </Grid>
  );
};

ObrasList.defaultProps = {
  obras: null,
};

ObrasList.propTypes = {
  obras: PropTypes.arrayOf(Fields.getPropTypes()),
  artistaId: PropTypes.string.isRequired,
};

export default ObrasList;
