import React from 'react';
import * as PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import { keyFor } from 'react-social-icons/dist/networks';
import SocialIcon from '../../../../components/social/icon';
import confirm from '../../../../components/confirm';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
  },
  icon: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    marginLeft: 5,
  },
}));

const SocialLink = ({ link, onDelete }) => {
  const classes = useStyles();
  const handleClick = () => {
    if (link.type && link.type === 'email') {
      // eslint-disable-next-line no-undef
      window.location.href = `mailto:${link.url}`;
    } else {
      // eslint-disable-next-line no-undef
      window.open(link.url);
    }
  };
  const handleDelete = () => {
    if (onDelete) {
      confirm(
        '¿Está seguro de quitar el enlace indicado?',
        'Confirmar Borrado',
      ).then(
        () => onDelete(),
        () => {},
      );
    }
  };
  return (
    <Chip
      icon={(<SocialIcon className={classes.icon} url={link.url} network={link.type} />)}
      label={link.label || keyFor(link.url)}
      onClick={handleClick}
      variant="outlined"
      onDelete={handleDelete}
      className={classes.chip}
    />
  );
};

SocialLink.defaultProps = {
  onDelete: null,
};

SocialLink.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
};

export default SocialLink;
