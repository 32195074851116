import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const FIELDS = {
  firstName: 'Nombres',
  lastName: 'Apellidos',
  docType: 'Tipo de documento',
  docNumber: 'Número de documento',
  nationality: 'País nacionalidad',
  gender: 'Género',
  vegetarian: 'Vegetariano',
  bloodType: 'Tipo de sangre',
  allergies: 'Alergias',
  countryResidence: 'País de residencia',
  cityResidence: 'Ciudad de residencia',
  addressResidence: 'Dirección Residencia',
  email: 'Correo electrónico',
  phone: 'Número telefónico',
  postalResidence: 'Código postal',
  participantNumber: 'Número de participante',
  contactName: 'Contacto de emergencia',
  contactEmail: 'Correo electrónico emergencia',
  contactPhone: 'Número telefónico emergencia',
  countryBirth: 'País de nacimiento',
  localAddress: 'Dirección en Colombia',
  localCity: 'Ciudad en Colombia',
  localPhone: 'Teléfono en Colombia',
  shirt: 'Talla camiseta',
  team: 'Equipo',
  sponsor: 'Patrocinadores',
  docDueDate: 'Fecha vencimiento documento',
  arriveDate: 'Fecha llegada',
  leaveDate: 'Fecha salida',
  civl: 'CIVL',
  club: 'Club',
  insuranceName: 'Compañía de seguros',
  insuranceNumber: 'Número de poliza',
  insurancePhone: 'Número de teléfono compañía de seguros',
  dateBirth: 'Fecha de nacimiento',
  faiLicense: 'Licencia FAI',
  gliderCertification: 'Certificación de vela',
  gliderColor: 'Color de vela',
  gliderManufacturer: 'Fabricante vela',
  gliderModel: 'Modelo de vela',
  ippi: 'Licencia IPPI',
  ippiActive: 'Licencia IPPI activa',
  ippiLicense: 'Número licencia IPPI',
  level: 'Nivel',
  nationalLicense: 'No Licencia de su país',
  status: 'Estado licencia temporal',
};

const reduceFunction = (prev, cur) => {
  return `${prev},"${cur}"`;
};

const DownloadButton = ({ data }) => {
  const clickAction = () => {
    const output = [];
    output.push(Object.values(FIELDS).reduce(reduceFunction));
    const fields = Object.keys(FIELDS);
    data.forEach((item) => {
      const line = [];
      fields.forEach((field) => {
        line.push(item[field]);
      });
      output.push(line.reduce(reduceFunction));
    });
    // console.warn(output.reduce((prev, cur) => `${prev}\n${cur}`));
  };
  return (
    <Button onClick={clickAction}>
      Descargar
    </Button>
  );
};

DownloadButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
};

export default DownloadButton;
