import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Fields from './fields';
import ItemVenue from './itemVenue';
import ExpansionPanels from '../../../../components/expansion/expansionPanel';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0, 1),
    clear: 'both',
  },
  venuesMessage: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
}));

const ListVenues = ({
  venues, deleteAction, editAction, formSuccess, loading,
}) => {
  const classes = useStyles();
  const onDelete = (index) => {
    if (deleteAction) {
      deleteAction(index);
    }
  };
  const onEdit = (index, venue) => {
    if (editAction) {
      editAction(index, venue);
    }
  };
  let component = (
    <Typography variant="subtitle2" component="p" className={classes.venuesMessage}>
      No se ha registrado ninguna sede
    </Typography>
  );
  if (venues.length === 1) {
    component = (
      <ItemVenue
        index={0}
        deleteAction={onDelete}
        venue={venues[0]}
        editAction={onEdit}
        loading={loading}
        formSuccess={formSuccess}
      />
    );
  } else if (venues.length > 1) {
    const elements = venues.map((item, index) => ({
      title: item.name,
      children: (
        <ItemVenue
          index={index}
          deleteAction={onDelete}
          venue={item}
          editAction={onEdit}
          loading={loading}
          formSuccess={formSuccess}
        />),
    }));
    component = <ExpansionPanels elements={elements} />;
  }
  return (
    <div className={classes.root}>
      {component}
    </div>
  );
};

ListVenues.defaultProps = {
  venues: [],
  deleteAction: null,
  loading: false,
  formSuccess: false,
  editAction: null,
};

ListVenues.propTypes = {
  venues: PropTypes.arrayOf(Fields.getPropTypes()),
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
  formSuccess: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ListVenues;
