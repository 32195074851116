import React from 'react';
import PropTypes from 'prop-types';
import each from 'async/each';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { generateInputs, MForm } from '../../components/form';
import { isCoordinator, isAuthor } from '../../components/access';
import {
  withCurrentUser, withAuthorization, AuthUserPropTypes,
} from '../../model/Session';
import { withFirebase, FirebasePropType } from '../../model/Firebase';
import Fields, { DATE_STATUS } from './fields';
import { CONTENT_MEMBERSHIPS } from '../Business/fields';
import { validateForm } from '../../components/validator';
import { withNotification, NotificationPropTypes } from '../../components/notification';
import * as ROUTES from '../../constants/routes';

const moment = extendMoment(Moment);

const INITIAL_STATE = (authUser) => {
  const fields = JSON.parse(JSON.stringify(Fields.fields));
  // fields.place.reference.validation = (data) => !isContentEditor(authUser)
  //   || CONTENT_MEMBERSHIPS.includes(data.membership);
  const initialState = {
    fields,
    notification: null,
    loading: false,
    content: false,
  };
  if (!isCoordinator(authUser)) {
    const { values } = initialState.fields.type.reference;
    const keys = Object.keys(values);
    keys.forEach((key) => {
      if (!values[key].content) {
        delete values[key];
      }
    });
    initialState.fields.type.reference.values = values;
  }
  return initialState;
};

const assignDefaultValues = (state, defaultValues) => {
  if (defaultValues) {
    Object.keys(defaultValues).forEach((item) => {
      if (defaultValues[item]) {
        // eslint-disable-next-line
        state.fields[item].value = defaultValues[item];
      }
    });
    // eslint-disable-next-line
    state.content = defaultValues.content || false;
  }
  return state;
};

const getInviteesValues = (invitees) => {
  const result = {};
  invitees.forEach((item) => {
    result[item] = true;
  });
  return result;
};

const DateForm = ({
  authUser, defaultValues, firebase, isNew, notification, history,
}) => {
  const [state, setState] = React.useState({ ...INITIAL_STATE(authUser) });
  React.useEffect(() => {
    setState((prev) => ({
      ...assignDefaultValues(prev, defaultValues),
    }));
  }, [defaultValues]);

  const validateAvailability = (dataSet) => new Promise((resolve, reject) => {
    each(dataSet.invitees, (item, cb) => {
      firebase.dates()
        .where('status', '==', DATE_STATUS.scheduled.value)
        .where(`invitees.${item.id}`, '==', true)
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            const range = moment.range(dataSet.initialDate, dataSet.endDate);
            let busy = false;
            snapShot.forEach((doc) => {
              const data = doc.data();
              const dataRange = moment.range(data.initialDate, data.endDate);
              if (range.overlaps(dataRange) && (isNew && doc.id !== dataSet.id)) {
                busy = true;
              }
            });
            if (busy) {
              cb(item);
              return;
            }
          }
          cb();
        });
    }, (item) => {
      if (item) {
        item
          .get()
          .then((doc) => {
            const data = doc.data();
            reject(new Error(`${data.username} ya tiene una cita dentro de el horario seleccionado`));
          });
      } else {
        resolve();
      }
    });
  });

  const saveData = (dateReference, dataSet, successMessage, redirect) => {
    dateReference.set(dataSet, { merge: false })
      .then(() => {
        notification.setMessage({ message: successMessage, type: 'success' });
        if (redirect) {
          history.goBack();
        } else {
          setState({ ...INITIAL_STATE(authUser) });
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
        setState((prev) => ({ ...prev, loading: false }));
      });
  };

  const onSubmit = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const { fields, content } = state;
    const dataSet = {
      subject: fields.subject.value,
      place: fields.place.value,
      initialDate: fields.initialDate.value,
      endDate: fields.endDate.value,
      type: fields.type.value,
      content,
      invitees: getInviteesValues(fields.invitees.value),
      status: fields.status.value,
      eventId: fields.eventId.value,
      observations: fields.observations.value,
      notif24: fields.notif24.value,
      notif1: fields.notif1.value,
    };
    validateAvailability(dataSet)
      .then(() => {
        const dateReference = isNew ? firebase.dates().doc()
          : firebase.date(fields.id.value);
        if (isNew) {
          dataSet.createdBy = authUser.uid;
          saveData(dateReference, dataSet, 'Se creó la nueva cita 😀');
        } else {
          saveData(dateReference, dataSet, 'Se modificó la cita 😀', ROUTES.DATES);
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'warning' });
        setState((prev) => ({ ...prev, loading: false }));
      });
  };

  const onChange = (result) => {
    const { fields } = state;
    let content = null;
    fields[result.id].value = result.value;
    fields[result.id].error = !result.isValid;
    if (result.id === Fields.fields.initialDate.id) {
      fields[Fields.fields.endDate.id].validationOptions = { previousDate: result.value };
      if (moment(fields[Fields.fields.endDate.id].value)
        .isBefore(fields[Fields.fields.initialDate.id].value)) {
        fields[Fields.fields.endDate.id].value = fields[Fields.fields.initialDate.id].value;
      }
      fields.notif24.value = false;
      fields.notif1.value = false;
    } else if (result.id === Fields.fields.place.id) {
      content = !!CONTENT_MEMBERSHIPS.includes(result.data.data.membership);
    }
    const newState = { fields };
    if (content !== null) {
      newState.content = content;
    }
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  const { loading, fields } = state;
  const isInvalid = !validateForm(fields);

  return (
    <MForm
      loading={loading}
      onSubmit={onSubmit}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Cita`,
        disabled: isInvalid,
      }}
    >
      {generateInputs(fields, onChange)}
    </MForm>
  );
};

DateForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

DateForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withCurrentUser,
  withFirebase,
  withRouter,
  withNotification,
)(DateForm);
