import React from 'react';
import * as PropTypes from 'prop-types';
import Fields from './fields';
import EditButton from '../../../../components/buttons/editButton';
import FormVenue from './formVenue';
import MDialog from '../../../../components/mdialog';

const ButtonEditVenue = ({
  onEdit, formSuccess, loading, venue,
}) => {
  const [open, setOpen] = React.useState(false);
  const closeDialog = () => {
    if (!loading) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    if (formSuccess) {
      setOpen(false);
    }
  }, [formSuccess]);
  return (
    <div style={{ display: 'inline-block' }}>
      <EditButton onClick={() => setOpen(true)} />
      <MDialog fullScreen keepMounted={false} open={open} handleClose={closeDialog} title="Modificar Sede">
        <FormVenue isNew={false} onSubmit={onEdit} venue={venue} loading={loading} />
      </MDialog>
    </div>
  );
};

ButtonEditVenue.propTypes = {
  onEdit: PropTypes.func.isRequired,
  formSuccess: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  venue: Fields.getPropTypes().isRequired,
};

export default ButtonEditVenue;
