import React from 'react';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import red from '@material-ui/core/colors/red';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { MForm, generateInputs } from '../../../components/form';
import { isAeroAdmin } from '../../../components/access';
import {
  withAuthorization,
} from '../../../model/Session';
import { withFirebase, FirebasePropType } from '../../../model/Firebase';
import Fields from './fields';
import { validateForm, validationFormInfo } from '../../../components/validator';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import {
  alreadyExist, useFields, saveDataPilot,
} from '../util/formHelper';

const cleanFields = (newFields, setFields, field) => {
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields[field].id].value = '';
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields[field].id].error = false;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields[field].id].file = null;
  setFields(newFields);
};

const useStyles = makeStyles((theme) => ({
  requiredDiv: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: red[700],
    backgroundColor: red[100],
    padding: theme.spacing(1),
  },
  title: {
    color: red[700],
  },
}));

const PilotoForm = ({
  firebase, notification, history, defaultValues, isNew,
}) => {
  const classes = useStyles();
  const [fields, setFields] = useFields(Fields, defaultValues);
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);

  const removeImage = React.useCallback((newFields, field) => {
    setLoading(true);
    if (defaultValues && newFields[Fields.fields[field].id].value.startsWith('/images/pilots')) {
      firebase.deleteFile(newFields[Fields.fields[field].id].value)
        .then(() => {
          const data = {};
          data[field] = '';
          firebase.pilot(defaultValues.id)
            .set(data, { merge: true })
            .then(() => {
              notification.setMessage({ type: 'success', message: 'Se quito correctamente la imagen.' });
              cleanFields(newFields, setFields, field);
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
              setLoading(false);
            });
        })
        .catch((e) => {
          notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
          setLoading(false);
          console.error(e);
        });
    } else {
      cleanFields(newFields, setFields, field);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removePhoto = React.useCallback(
    (newFields) => removeImage(newFields, 'photo'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const removePassportPhoto = React.useCallback(
    (newFields) => removeImage(newFields, 'passportPhoto'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(fields));
    newFields[Fields.fields.photo.id].onRemove = removePhoto;
    newFields[Fields.fields.passportPhoto.id].onRemove = removePassportPhoto;
    setFields(newFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const dataSet = {
      countryResidence: fields.countryResidence.value,
      arriveDate: fields.arriveDate.value,
      leaveDate: fields.leaveDate.value,
      docType: fields.docType.value,
      docNumber: fields.docNumber.value,
      docDueDate: fields.docDueDate.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      dateBirth: fields.dateBirth.value,
      countryBirth: fields.countryBirth.value,
      nationality: fields.nationality.value,
      email: fields.email.value,
      phone: fields.phone.value,
      cityResidence: fields.cityResidence.value,
      addressResidence: fields.addressResidence.value,
      postalResidence: fields.postalResidence.value,
      contactName: fields.contactName.value,
      contactEmail: fields.contactEmail.value,
      contactPhone: fields.contactPhone.value,
      insuranceName: fields.insuranceName.value,
      insuranceNumber: fields.insuranceNumber.value,
      insurancePhone: fields.insurancePhone.value,
      bloodType: fields.bloodType.value,
      allergies: fields.allergies.value,
      localCity: fields.localCity.value,
      localAddress: fields.localAddress.value,
      localPhone: fields.localPhone.value,
      participantNumber: fields.participantNumber.value,
      gender: fields.gender.value,
      vegetarian: fields.vegetarian.value,
      gliderManufacturer: fields.gliderManufacturer.value,
      gliderModel: fields.gliderModel.value,
      gliderColor: fields.gliderColor.value,
      gliderCertification: fields.gliderCertification.value,
      sponsor: fields.sponsor.value,
      faiLicense: fields.faiLicense.value,
      civl: fields.civl.value,
      nationalLicense: fields.nationalLicense.value,
      club: fields.club.value,
      team: fields.team.value,
      shirt: fields.shirt.value,
      discipline: fields.discipline.value,
      ippi: fields.ippi.value,
      ippiLicense: fields.ippiLicense.value,
      ippiActive: fields.ippiActive.value,
      level: fields.level.value,
      validate: fields.validate.value,
      validFrom: fields.validFrom.value,
      validTo: fields.validTo.value,
      localClub: fields.localClub.value,
      createdBy: firebase.auth.currentUser.uid,
    };
    dataSet.events = {};
    // eslint-disable-next-line no-unused-expressions
    fields.events.value && fields.events.value.forEach((event) => {
      dataSet.events[event] = true;
    });
    const photos = {
      passportPhoto: fields.passportPhoto,
      photo: fields.photo,
    };
    alreadyExist(firebase, 'pilots', isNew, fields, 'docNumber', dataSet.docNumber)
      .then((res) => {
        if (res) {
          notification.setMessage({
            message: 'Ya existe un piloto con ese número de identificación 🙄.',
            type: 'warning',
          });
        } else {
          const reference = isNew ? firebase.pilots().doc()
            : firebase.pilot(fields.id.value);
          if (isNew) {
            saveDataPilot(
              reference,
              dataSet,
              'Se creó el nuevo piloto 😀',
              null,
              setFields,
              notification,
              history,
              Fields,
              photos,
              setUploaded,
              firebase,
            );
          } else {
            saveDataPilot(
              reference,
              dataSet,
              'Se modificó la información del piloto 😀',
              ROUTES.PILOTOS,
              setFields,
              notification,
              history,
              Fields,
              photos,
              setUploaded,
              firebase,
            );
          }
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    if (result.file) {
      theFields[result.id].file = result.file;
    }
    setFields(theFields);
  };

  const isInvalid = !validateForm(fields);
  let invalidFields = validationFormInfo(fields);

  if (invalidFields.length > 0) {
    invalidFields = (
      <Box className={classes.requiredDiv}>
        <Typography
          variant="h5"
          component="h5"
          className={classes.title}
        >
          Revise los siguientes campos
        </Typography>
        <ul>
          {invalidFields.map((item) => (
            <li key={item}>
              {item}
            </li>
          ))}
        </ul>
      </Box>
    );
  } else {
    invalidFields = null;
  }

  return (
    <>
      <MForm
        onSubmit={onSubmit}
        loading={loading}
        uploaded={uploaded}
        button={{
          label: `${isNew ? 'Crear' : 'Editar'} Piloto`,
          disabled: isInvalid,
        }}
      >
        {generateInputs(fields, onChange)}
      </MForm>
      {invalidFields}
    </>
  );
};

PilotoForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

PilotoForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(PilotoForm);
