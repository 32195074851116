import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import LicenseLabelItem from './licenseLabelItem';
import LicenseTitleSection from './licenseTitleSection';
import {
  GENDERS, VEGETARIAN, CERTIFICATES, SHIRTS,
} from '../fields';

const CompetitionSection = ({ pilot }) => {
  if (!!pilot && !!pilot.participantNumber) {
    return (
      <div>
        <LicenseTitleSection marginTop title="Datos para competencia" />
        <Grid container spacing={3}>
          <LicenseLabelItem label="Número de participante" value={pilot.participantNumber} />
          {!!pilot.gender && (
            <LicenseLabelItem label="Género" value={GENDERS[pilot.gender].label} />
          )}
          {!!pilot.vegetarian && (
            <LicenseLabelItem label="Vegetariano" value={VEGETARIAN[pilot.vegetarian].label} />
          )}
          <LicenseLabelItem label="Fabricante Ala" value={pilot.gliderManufacturer} />
          <LicenseLabelItem label="Modelo Ala" value={pilot.gliderModel} />
          <LicenseLabelItem label="Color Ala" value={pilot.gliderColor} />
          {!!pilot.gliderCertification && (
            <LicenseLabelItem label="Certificación Ala" value={CERTIFICATES[pilot.gliderCertification].label} />
          )}
          <LicenseLabelItem label="Patrocinadores" value={pilot.sponsor} />
          <LicenseLabelItem label="ID licencia deportiva FAI" value={pilot.faiLicense} />
          <LicenseLabelItem label="ID CIVL" value={pilot.civl} />
          <LicenseLabelItem label="Licencia de la federación nacional" value={pilot.nationalLicense} />
          <LicenseLabelItem label="Club" value={pilot.club} />
          <LicenseLabelItem label="Equipo" value={pilot.team} />
          {!!pilot.shirt && (
            <LicenseLabelItem label="Talla camiseta" value={SHIRTS[pilot.shirt].label} />
          )}
        </Grid>
      </div>
    );
  }
  return null;
};

CompetitionSection.defaultProps = {
  pilot: null,
};

CompetitionSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
};

export default CompetitionSection;
