import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import MMultiSelect from './mMultiSelect';
// eslint-disable-next-line
import {getAddButton, getAddForm} from './AddForm';
import { TYPES, validate } from '../validator';
import LabelItem from './labelItem';
import { AuthUserPropTypes, withCurrentUser } from '../../model/Session';
import { isRestrictedUser } from '../../constants/restrictions';

const getLabel = (label, dataRow) => {
  let result = dataRow[label.shift()];
  label.forEach((i) => {
    result = `${result} ${dataRow[i]}`;
  });
  return result;
};

const ReferenceFormInput = ({
  firebase, reference, value, onValueChange, label, error,
  validationType, validationMessage, id, required, authUser,
  disabled,
}) => {
  const [data, setData] = useState({});
  const [noOptionsMessage, setNoOptionsMessage] = useState('Cargando opciones...');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase[reference.entity]().onSnapshot((querySnapshot) => {
      const tempData = {};
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (!isRestrictedUser('users', authUser, doc.id)) {
          tempData[doc.id] = {
            value: doc.id,
            label: getLabel([...reference.label], docData),
          };
        }
      });
      setData(tempData);
    }, () => setNoOptionsMessage('No se pudieron cargar las opciones.'));
    return () => {
      unsubscribe();
    };
  }, [authUser, firebase, reference.entity, reference.label]);

  function onChangeEvent(values) {
    const newValue = values ? values.map((item) => item.value) : [];
    const result = {
      id,
      value: newValue,
    };
    if (!validationType) {
      onValueChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      required,
    });
    onValueChange({
      ...result,
      ...validation,
    });
  }
  const values = value.map((item) => data[item]);
  const labelItemValue = Array.isArray(value) && value.length > 0 ? 'content' : '';

  return (
    <>
      <LabelItem required={required} value={labelItemValue} label={label} />
      <MMultiSelect
        setValue={onChangeEvent}
        value={values}
        options={Object.values(data)}
        label={label}
        noOptionsMessage={noOptionsMessage}
        disabled={disabled}
        addButton={getAddButton(reference.addNew, setOpen)}
      />
      {getAddForm(reference.addNew, reference.entity, setOpen, open)}
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </>
  );
};

ReferenceFormInput.defaultProps = {
  error: false,
  validationType: TYPES.REFERENCE,
  validationMessage: null,
  required: false,
  disabled: false,
};

ReferenceFormInput.propTypes = {
  firebase: FirebasePropType.isRequired,
  reference: PropTypes.shape({
    entity: PropTypes.string.isRequired,
    label: PropTypes.arrayOf(PropTypes.string).isRequired,
    addNew: PropTypes.string,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValueChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf(Object.values(TYPES)),
  validationMessage: PropTypes.string,
  authUser: AuthUserPropTypes.isRequired,
  disabled: PropTypes.bool,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(ReferenceFormInput);
