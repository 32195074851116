import React from 'react';
import { compose } from 'recompose';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import Container from '../../components/dashboard/container';
import { isAuthorExtrict, isAuthor, isEditorExtrict } from '../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import { MButton } from '../../components/form';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { withNotification, NotificationPropTypes } from '../../components/notification';
import Loading from '../../components/loading';
import Fields, { DATE_STATUS } from './fields';
import FilterDateStatus from './filterDateStatus';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventDetails from './eventDetails';
import EventAgenda from './EventAgenda';
import { dateRangeEvent, eventStyle } from './util';
import { isRestrictedDate } from '../../constants/restrictions';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    margin: theme.spacing(2),
    height: '500px',
    display: 'block',
  },
}));

const localizer = momentLocalizer(moment);

const CalendarView = ({ firebase, notification, authUser }) => {
  const classes = useStyles();

  const [date, setDate] = React.useState(new Date());
  const [view, setView] = React.useState(Views.MONTH);

  const [types, setTypes] = React.useState('all');
  const [status, setStatus] = React.useState(DATE_STATUS.scheduled.value);
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const [dateRange, setDateRange] = React.useState({
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  });

  const [selectedEvent, setSelectedEvent] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    let query = firebase.dates().where('initialDate', '<=', dateRange.end)
      .where('initialDate', '>=', dateRange.start);
    if (isAuthorExtrict(authUser) || isEditorExtrict(authUser)) {
      query = query.where('content', '==', true);
    }
    if (status && status !== 'all') {
      query = query.where('status', '==', status);
    }
    const unsubscribe = query.orderBy('initialDate', 'desc')
      .onSnapshot((querySnapshot) => {
        const newEvents = [];
        querySnapshot.forEach((doc) => {
          const event = Fields.getEvent(doc);
          if (types === 'mine') {
            if (Fields.isInvitee(authUser, event.resource.invitees)) {
              newEvents.push(event);
            }
          } else if (!isRestrictedDate(authUser, event)) {
            newEvents.push(event);
          }
        });
        setEvents(newEvents);
        setLoading(false);
      }, (e) => {
        setLoading(false);
        console.log(e);
        notification.setMessage({ message: 'Algo no salió bien cargando las citas 😰', type: 'error' });
      });
    return () => unsubscribe();
  }, [authUser, dateRange.end, dateRange.start, firebase, notification, status, types]);

  return (
    <Container
      title="Listado de Citas"
      header={(
        <MButton fullWidth={false} label="Nueva Cita" to={`${ROUTES.DATES}/new`} />
      )}
    >
      <FilterDateStatus
        changeStatus={setStatus}
        status={status}
        types={types}
        changeTypes={setTypes}
      />
      <div className={classes.calendarContainer}>
        {!loading && (
          <BigCalendar
            localizer={localizer}
            components={{
              agenda: {
                event: EventAgenda(setSelectedEvent),
              },
            }}
            events={events}
            date={date}
            view={view}
            onNavigate={(nDate) => setDate(nDate)}
            onView={(nView) => setView(nView)}
            onRangeChange={dateRangeEvent(setDateRange)}
            onSelectEvent={(event) => setSelectedEvent(event)}
            eventPropGetter={eventStyle}
          />
        )}
        {!!loading && (
          <Loading />
        )}
        {!!selectedEvent && (
          <EventDetails
            event={selectedEvent}
            closeEvent={() => setSelectedEvent(null)}
          />
        )}
      </div>
    </Container>
  );
};

CalendarView.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withNotification,
  withCurrentUser,
)(CalendarView);
