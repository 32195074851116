import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardMedia, CardContent, Typography, CardActions,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import Fields from './fields';
import Confirm from '../../../components/confirm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { isLolaAdmin } from '../../../components/access';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import { domain, defaultImage } from '../../../model/Images';
import { EditButton, DeleteButton, DownloadButton } from '../../../components/buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  media: {
    height: 340,
  },
}));

const getPhotosObras = async (artista, firebase) => {
  const photos = [];
  const obrasSnap = await firebase.obras(artista.id).get();
  obrasSnap.forEach((obraDoc) => {
    const obraPhotos = obraDoc.data().photos;
    if (obraPhotos) {
      photos.push(...obraPhotos);
    }
  });
  return photos;
};

const deleteArtista = async (artista, firebase, notification) => {
  let message;
  try {
    const photos = await getPhotosObras(artista, firebase);
    if (artista.photo) {
      photos.push(artista.photo);
    }
    await firebase.artista(artista.id).delete();
    const promises = photos.map((photo) => firebase.deleteFile(photo));
    await Promise.all(promises);
    message = { type: 'success', message: 'Artista borrado satisfactoriamente 😀' };
  } catch (e) {
    message = { type: 'success', message: 'Ups! Algo no salió bien borrando el artista 🥺' };
  }
  notification.setMessage(message);
};

const ArtistaItem = ({
  artista, firebase, notification, history,
}) => {
  const classes = useStyles();

  const deleteAction = () => {
    Confirm('¿Está seguro de borrar este artista?')
      .then(
        () => deleteArtista(artista, firebase, notification),
        () => {},
      );
  };

  const editAction = () => {
    history.push(`${ROUTES.ARTISTAS}/edit/${artista.id}`);
  };

  let image = defaultImage;
  if (artista.photoURL) {
    image = buildURL(`${domain}${artista.photo.replace('/images', '')}`, { w: 400 });
  }

  const downloadAction = () => {
    const url = buildURL(`https:${domain}${artista.photo.replace('/images', '')}`);
    // eslint-disable-next-line no-undef
    window.open(url);
  };

  return (
    <>
      <Card>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={image}
            title={artista.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {artista.name}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <EditButton onClick={editAction} />
          <DeleteButton onClick={deleteAction} />
          {!!artista.photoURL && <DownloadButton onClick={downloadAction} />}
        </CardActions>
      </Card>
    </>
  );
};

ArtistaItem.propTypes = {
  artista: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withFirebase,
  withNotification,
  withRouter,
)(ArtistaItem);
