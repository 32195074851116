import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import * as ROUTES from '../../constants/routes';
import { StatusIcon } from '../status';

function AccountMenu({ firebase, history }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function myAccount() {
    handleClose();
    history.push(ROUTES.ACCOUNT);
  }

  function logOut() {
    handleClose();
    firebase.doSignOut();
  }

  return (
    <div>
      <StatusIcon />
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={myAccount}>Mi Cuenta</MenuItem>
        <MenuItem onClick={logOut}>Cerrar Sesión</MenuItem>
      </Menu>
    </div>
  );
}

AccountMenu.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withRouter,
  withFirebase,
)(AccountMenu);
