import React from 'react';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { buildURL } from 'react-imgix';
import Image from 'material-ui-image';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from './fields';
import Loading from '../../../components/loading';
import BasicSection from './licenseComponents/basicSection';
import PersonalSection from './licenseComponents/personalSection';
import EmergencySection from './licenseComponents/emergencySection';
import AccommodationSection from './licenseComponents/accommodationSection';
import { domain } from '../../../model/Images';
import CompetitionSection from './licenseComponents/competitionSection';
import LicenseSection from './licenseComponents/licenseSection';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  imageContainer: {
    marginTop: theme.spacing(6),
  },
}));

const GenerateLicense = ({ firebase, match, history }) => {
  const [pilot, setPilot] = React.useState(Fields.defaultValues());
  const [image, setImage] = React.useState(null);
  const [passport, setPassport] = React.useState(null);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.pilot(id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const codPilot = Fields.getInstance(doc);
            setPilot(codPilot);
            setLoading(false);
          } else {
            history.push(ROUTES.PILOTOS)
          }
        }, () => history.push(ROUTES.PILOTOS));
    } else {
      history.push(ROUTES.PILOTOS);
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, history, match.params]);

  React.useEffect(() => {
    if (pilot && pilot.photo && pilot.photo !== '') {
      setImage(buildURL(
        `${domain}${pilot.photo.replace('/images', '')}`,
        {
          w: 400, ar: '5:6', crop: 'fit', fit: 'facearea', facepad: 2,
        },
      ));
    }
    if (pilot && pilot.passportPhoto && pilot.passportPhoto !== '') {
      const thumb = buildURL(
        `${domain}${pilot.passportPhoto.replace('/images', '')}`,
        {
          w: 400, ar: '4:3', crop: 'fit', fit: 'clamp', h: 300,
        },
      );
      const full = buildURL(
        `${domain}${pilot.passportPhoto.replace('/images', '')}`,
        {
          w: 1600,
        },
      );
      setPassport({ thumb, full });
    }
  }, [pilot]);

  return (
    <Container
      backPath={ROUTES.PILOTOS}
      title={`Piloto: ${pilot.docNumber} - ${pilot.firstName} ${pilot.lastName}`}
    >
      <Grid container justify="center">
        <Grid item xs={12} md={11}>
          {loading ? (
            <Loading />
          ) : pilot && (
            <Grid container className={classes.container} spacing={3}>
              <Grid item xs={12} md={3}>
                {!!image && (
                  <Paper elevation={2} className={classes.imageContainer}>
                    <Image src={image} className="MuiPaper-rounded" aspectRatio={5 / 6} />
                  </Paper>
                )}
                {!!passport && (
                  <Paper elevation={2} className={classes.imageContainer}>
                    <a href={passport.full} target="_blank" rel="noopener noreferrer">
                      <Image src={passport.thumb} className="MuiPaper-rounded" aspectRatio={4 / 3} />
                    </a>
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} md={9}>
                <BasicSection pilot={pilot} />
                <PersonalSection pilot={pilot} />
                <EmergencySection pilot={pilot} />
                <AccommodationSection pilot={pilot} />
                <CompetitionSection pilot={pilot} />
                <LicenseSection pilot={pilot} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

GenerateLicense.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
)(GenerateLicense);
