import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import NewClub from './newClub';
import EditClub from './editClub';
import TableClub from './table';

const ClubPage = () => (
  <Switch>
    <Route path={`${ROUTES.CLUBES}/new`} component={NewClub} />
    <Route path={`${ROUTES.CLUBES}/edit/:id`} component={EditClub} />
    <Route path={ROUTES.CLUBES} component={TableClub} />
  </Switch>
);

export default withAuthorization(isAeroAdmin)(ClubPage);
