import React from 'react';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { MForm, generateInputs } from '../../../components/form';
import { isAeroAdmin } from '../../../components/access';
import {
  withAuthorization,
} from '../../../model/Session';
import { withFirebase, FirebasePropType } from '../../../model/Firebase';
import Fields from './fields';
import { validateForm } from '../../../components/validator';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import {
  alreadyExist, useCities, useFields, saveData,
} from '../util/formHelper';

const CompetitionForm = ({
  firebase, notification, history, defaultValues, isNew,
}) => {
  const [fields, setFields] = useFields(Fields, defaultValues);
  const [loading, setLoading] = React.useState(false);

  useCities(firebase, notification, fields, setFields);

  const onSubmit = () => {
    setLoading(true);
    const dataSet = {
      name: fields.name.value,
      discipline: fields.discipline.value,
      city: fields.city.value,
      club: fields.club.value,
      faiEvent: fields.faiEvent.value,
      initialDate: fields.initialDate.value,
      endDate: fields.endDate.value,
    };
    alreadyExist(firebase, 'competitions', isNew, fields, 'name', dataSet.name)
      .then((res) => {
        if (res) {
          notification.setMessage({
            message: 'Ya existe una competencia con ese nombre 🙄.',
            type: 'warning',
          });
        } else {
          const reference = isNew ? firebase.competitions().doc()
            : firebase.competition(fields.id.value);
          if (isNew) {
            saveData(
              reference,
              dataSet,
              'Se creó la nueva competencia 😀',
              null,
              setFields,
              notification,
              history,
              Fields,
            );
          } else {
            saveData(
              reference,
              dataSet,
              'Se modificó la competencia 😀',
              ROUTES.COMPETENCIAS,
              setFields,
              notification,
              history,
              Fields,
            );
          }
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    setFields(theFields);
  };

  const isInvalid = !validateForm(fields);

  return (
    <MForm
      onSubmit={onSubmit}
      loading={loading}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Competencia`,
        disabled: isInvalid,
      }}
    >
      {generateInputs(fields, onChange)}
    </MForm>
  );
};

CompetitionForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

CompetitionForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(CompetitionForm);
