import DashboardIcon from '@material-ui/icons/Dashboard';
import DatesIcon from '@material-ui/icons/CalendarToday';
import BusinessIcon from '@material-ui/icons/Business';
import ClientsIcon from '@material-ui/icons/BusinessCenter';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import CloudIcon from '@material-ui/icons/Cloud';
import BrushIcon from '@material-ui/icons/Brush';
import History from '@material-ui/icons/History';
import * as ROUTES from '../../constants/routes';
import {
  isAdmin, isAuthor, isCoordinator, isAuthorOrTranslator, isEditor, isAeroAdmin, isLolaAdmin,
} from '../access';

const items = [
  {
    label: 'Dashboard',
    path: ROUTES.HOME,
    Icon: DashboardIcon,
  },
  {
    label: 'Citas',
    path: ROUTES.DATES,
    Icon: DatesIcon,
    authorize: isAuthor,
  },
  {
    label: 'Categorías',
    Icon: CategoryIcon,
    authorize: isEditor,
    path: ROUTES.CATEGORIES,
  },
  {
    label: 'Lugares',
    Icon: BusinessIcon,
    authorize: isAuthorOrTranslator,
    path: ROUTES.PLACES,
  },
  {
    label: 'Historias',
    Icon: History,
    authorize: isAuthorOrTranslator,
    path: ROUTES.STORIES,
  },
  {
    label: 'Clientes',
    Icon: ClientsIcon,
    authorize: isCoordinator,
    path: ROUTES.CLIENTES,
  },
  {
    label: 'Vuelo',
    Icon: CloudIcon,
    authorize: isAeroAdmin,
    subItems: [
      {
        label: 'Pilotos',
        path: ROUTES.PILOTOS,
      },
      {
        label: 'Competencias',
        path: ROUTES.COMPETENCIAS,
      },
      {
        label: 'Clubes',
        path: ROUTES.CLUBES,
      },
      {
        label: 'Ciudades',
        path: ROUTES.CIUDADES,
      },
      {
        label: 'Departamentos',
        path: ROUTES.DEPARTAMENTOS,
      },
    ],
  },
  {
    label: 'Lola Mora',
    Icon: BrushIcon,
    authorize: isLolaAdmin,
    subItems: [
      {
        label: 'Fotografía y Pintura',
        path: ROUTES.ARTISTAS,
      },
      {
        label: 'Expresiones',
        path: ROUTES.EXPRESIONES,
      },
    ],
  },
  {
    label: 'Admin',
    Icon: SettingsIcon,
    authorize: isAdmin,
    subItems: [
      {
        label: 'Usuarios',
        path: ROUTES.USERS,
      },
    ],
  },
];

export default items;
