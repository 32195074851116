import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography,
} from '@material-ui/core';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { buildURL } from 'react-imgix';
import Fields from './fields';
import ProductItem from './productItem';
import ProductForm from './productForm';
import MFullScrrenDialog from '../../../components/mfullscreendialog';
import PlaceFields from '../fields';
import { domain } from '../../../model/Images';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';

const updateProductsIndex = (firebase, products, place, notification) => {
  const batch = firebase.db.batch();
  products.forEach((item) => {
    const ref = firebase.product(place.id, item.id);
    const data = { index: item.index };
    batch.update(ref, data);
  });
  batch.commit()
    .then(() => {
      notification.setMessage({ type: 'success', message: 'Se actualizó el orden de los productos.' });
    })
    .catch((err) => {
      notification.setMessage({ type: 'warning', message: 'Ups... algo no salió bien 😟.' });
      console.error(err);
    });
};

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const ProductList = ({
  products, place, firebase, notification,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [theProducts, setTheProducts] = React.useState([]);
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    let productsTemp = [];
    products.forEach((item, theIndex) => {
      if (typeof item.index === 'number') {
        productsTemp.push(item);
      } else {
        productsTemp.push({
          ...item,
          index: theIndex,
        });
      }
    });
    productsTemp = productsTemp.sort((a, b) => (a.index < b.index ? -1 : 1));
    setTheProducts(productsTemp);
    const theImages = [];
    productsTemp.forEach((item) => {
      if (item.photoURL) {
        theImages.push({
          src: buildURL(`${domain}${item.photo.replace('/images', '')}`, { w: 1600 }),
        });
      }
    });
    setImages(theImages);
  }, [products]);

  const [product, setProduct] = React.useState(null);
  const editProduct = (tempProduct) => {
    setProduct(tempProduct);
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const [index, setIndex] = React.useState(0);
  const [openPhotos, setOpenPhotos] = React.useState(false);
  const toggleModal = (toOpen, toIndex) => {
    setOpenPhotos(toOpen);
    setIndex(toIndex >= images.length ? toIndex - 1 : toIndex);
  };

  const onUpdateProduct = (theProduct) => {
    const i = theProducts.findIndex((item) => item.id === theProduct.id);
    theProducts.splice(i, 1);
    theProducts.splice(theProduct.index, 0, theProduct);
    const newProducts = theProducts.map((item, j) => ({ ...item, index: j }));
    // setTheProducts(newProducts);
    updateProductsIndex(firebase, newProducts, place, notification);
    // updatePlace(place, null, firebase, notification, thePhotos);
  };

  if (!place || theProducts.length === 0) {
    return (
      <Typography variant="button" display="block" className={classes.emptyMessage}>
        No se han creado productos.
      </Typography>
    );
  }

  return (
    <>
      <Grid spacing={1} className={classes.grid} container>
        {theProducts.map((item, i) => (
          <Grid key={item.id} xs={12} sm={4} md={3} item>
            <ProductItem
              placeId={place.id}
              product={item}
              editProduct={editProduct}
              togglePhotosModal={toggleModal}
              onUpdateProduct={onUpdateProduct}
              index={i}
              totalProducts={theProducts.length}
            />
          </Grid>
        ))}
      </Grid>
      <MFullScrrenDialog handleClose={closeDialog} open={open} title="Nuevo Producto" buttonText="Guardar">
        <ProductForm productValues={product} place={place} />
      </MFullScrrenDialog>
      <ModalGateway>
        {openPhotos ? (
          <Modal
            onClose={() => toggleModal(false, 0)}
            styles={{
              positioner: (base) => ({
                ...base,
                zIndex: 2100,
              }),
              dialog: (base) => ({
                ...base,
                maxWidth: 800,
              }),
              blanket: (base) => ({
                ...base,
                zIndex: 2000,
              }),
            }}
          >
            <Carousel currentIndex={index} views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

ProductList.defaultProps = {
  products: [],
  place: null,
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(Fields.getPropTypes()),
  place: PlaceFields.getPropTypes(),
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(ProductList);
