import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import MSelect from './mSelect';
// eslint-disable-next-line
import { getAddButton, getAddForm } from './AddForm';
import { TYPES, validate } from '../validator';
import LabelItem from './labelItem';

const getLabel = (label, dataRow) => {
  let result = dataRow[label.shift()];
  label.forEach((i) => {
    result = `${result} ${dataRow[i]}`;
  });
  return result;
};

const ReferenceFormInput = ({
  firebase, reference, value, onValueChange, label, error,
  validationType, validationMessage, id, required,
}) => {
  const [data, setData] = useState({});
  const [noOptionsMessage, setNoOptionsMessage] = useState('Cargando opciones...');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase[reference.entity]().onSnapshot((querySnapshot) => {
      const tempData = required ? {} : { 0: { value: '', label } };
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        // eslint-disable-next-line react/prop-types
        if (!reference.validation || !!docData[reference.validation]) {
          tempData[doc.id] = {
            value: doc.id,
            data: docData,
            label: getLabel([...reference.label], docData),
          };
        }
      });
      setData(tempData);
    }, () => setNoOptionsMessage('No se pudieron cargar las opciones.'));
    return () => {
      unsubscribe();
    };
  }, [firebase, label, reference, reference.entity, reference.label, required]);

  function onChangeEvent(newValue) {
    const result = {
      id,
      value: newValue.value,
      data: data[newValue.value],
    };
    if (!validationType) {
      onValueChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      required,
    });
    onValueChange({
      ...result,
      ...validation,
    });
  }

  const currentValue = value === '' ? { value, label } : data[value];

  return (
    <>
      <LabelItem required={required} value={value} label={label} />
      <MSelect
        setValue={onChangeEvent}
        value={currentValue}
        options={Object.values(data)}
        noOptionsMessage={noOptionsMessage}
        addButton={getAddButton(reference.addNew, setOpen)}
      />
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
      {getAddForm(reference.addNew, reference.entity, setOpen, open)}
    </>
  );
};

ReferenceFormInput.defaultProps = {
  error: false,
  validationType: TYPES.REFERENCE,
  validationMessage: null,
  required: false,
};

ReferenceFormInput.propTypes = {
  firebase: FirebasePropType.isRequired,
  reference: PropTypes.shape({
    entity: PropTypes.string.isRequired,
    label: PropTypes.arrayOf(PropTypes.string).isRequired,
    addNew: PropTypes.string,
  }).isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]),
  validationMessage: PropTypes.string,
};

export default withFirebase(ReferenceFormInput);
