export default {
  paragliding: {
    label: 'Parapente',
    value: 'paragliding',
  },
  'hang-gliding': {
    label: 'Ala Delta',
    value: 'hang-gliding',
  },
  aeromodelling: {
    label: 'Aeromodelismo',
    value: 'aeromodelling',
  },
  'hot-air-balloons': {
    label: 'Globos Aerostáticos',
    value: 'hot-air-balloons',
  },
  skydiving: {
    label: 'Paracaidísmo',
    value: 'skydiving',
  },
  paramotor: {
    label: 'Paramotor',
    value: 'paramotor',
  },
  ultralivians: {
    label: 'Ultralivianos',
    value: 'ultralivians',
  },
};
