import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PasswordChangeForm from '../PasswordChange';
import {
  AuthUserPropTypes, withAuthorization, withCurrentUser,
} from '../../model/Session';
import Container from '../../components/dashboard/container';
import MChip from '../../components/chip';
import { getICSLink } from '../../constants';

const useStyles = makeStyles(theme => ({
  avatar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.primary.main,
    width: 70,
    height: 70,
  },
  avatarIcon: {
    width: 50,
    height: 50,
  },
  leftPanel: {
    textAlign: 'center',
  },
  label: {
    color: grey[500],
  },
  linkGoogle: {
    marginTop: theme.spacing(1),
  },
}));

const AccountPage = ({ authUser }) => {
  const classes = useStyles();
  return (
    <Container title="Perfil de usuario">
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6} className={classes.leftPanel}>
          <Avatar className={classes.avatar}>
            <PersonIcon className={classes.avatarIcon} />
          </Avatar>
          <Typography component="h3" variant="h5">
            {authUser.username}
          </Typography>
          <Typography component="h5" variant="caption" className={classes.label}>
            Nombre del usuario
          </Typography>
          <Typography component="h3" variant="h5">
            {authUser.email}
          </Typography>
          <Typography component="h5" variant="caption" className={classes.label}>
            Correo Electrónico
          </Typography>
          {!!authUser.phone && (
            <React.Fragment>
              <Typography component="h3" variant="h5">
                {authUser.phone}
              </Typography>
              <Typography component="h5" variant="caption" className={classes.label}>
                Número Telefónico
              </Typography>
            </React.Fragment>
          )}
          <React.Fragment>
            {Object.keys(authUser.roles).map(rol => (
              <MChip key={rol} label={rol} color="primary" />
            ))}
          </React.Fragment>
          <Typography component="h5" variant="caption" className={classes.label}>
            Roles
          </Typography>
          {!!authUser.calendarId && (
            <React.Fragment>
              <Typography component="p" variant="button" className={classes.linkGoogle}>
                <Link component="a" href={getICSLink(authUser.calendarId)} target="_blank" rel="noreferrer">
                  Añadir citas a Google Calendar
                </Link>
              </Typography>
              <Typography component="h5" variant="caption" className={classes.label}>
                Calendario
              </Typography>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordChangeForm />
        </Grid>
      </Grid>
    </Container>
  );
};

AccountPage.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withCurrentUser,
)(AccountPage);
