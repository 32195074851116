const DISCIPLINES = {
  paragliding: {
    value: 'paragliding',
    label: 'Parapente',
  },
  hangliding: {
    value: 'hangliding',
    label: 'Ala Delta',
  },
};
const LEVEL = {
  student1: {
    value: 'student1',
    label: 'Alumno 1',
  },
  student2: {
    value: 'student2',
    label: 'Alumno 2',
  },
  newie: {
    value: 'newie',
    label: 'Novato',
  },
  intermidiate: {
    value: 'intermidiate',
    label: 'Intermedio',
  },
  advanced: {
    value: 'advanced',
    label: 'Avanzado',
  },
  teacher: {
    value: 'teacher',
    label: 'Instructor',
  },
};

module.exports = {
  DISCIPLINES,
  LEVEL,
};
