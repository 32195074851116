import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isEditor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import ListCategories from './List';
import NewCategory from './new';
import EditCategory from './edit';

const CategoriesPage = () => (
  <Switch>
    <Route path={`${ROUTES.CATEGORIES}/new`} component={NewCategory} />
    <Route path={`${ROUTES.CATEGORIES}/edit/:id`} component={EditCategory} />
    <Route path={`${ROUTES.CATEGORIES}`} component={ListCategories} />
  </Switch>
);

export default withAuthorization(isEditor)(CategoriesPage);
