import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_ID,
};

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_ID,
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
// const config = prodConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.storage = app.storage();
    this.functions = app.functions();
    this.firestore = app.firestore;
    this.doCreateUserWithEmailAndPassword = this.doCreateUserWithEmailAndPassword.bind(this);
    this.doPasswordReset = this.doPasswordReset.bind(this);
    this.doPasswordUpdate = this.doPasswordUpdate.bind(this);
    this.doSignInWithEmailAndPassword = this.doSignInWithEmailAndPassword.bind(this);
    this.doSignOut = this.doSignOut.bind(this);
    this.enablePersistence = this.enablePersistence.bind(this);
  }

  enablePersistence() {
    return new Promise((resolve, reject) => {
      this.db = app.firestore();
      this.db.enablePersistence()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err.code === 'failed-precondition') {
            reject();
            return;
          }
          resolve();
        });
    });
  }

  // *** Authentication API *** //

  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut() {
    return this.auth.signOut();
  }

  doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email);
  }

  doPasswordUpdate(password) {
    return this.auth.currentUser.updatePassword(password);
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener(next, fallback) {
    const { auth } = this;
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((doc) => {
            const dbUser = doc.data();
            if (!dbUser) {
              fallback();
              return;
            }
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            const newAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };
            next(newAuthUser);
          });
      } else {
        fallback();
      }
    });
  }

  // *** User API *** //

  user(uid) {
    return this.db.collection('users').doc(uid);
  }

  users() {
    return this.db.collection('users');
  }

  // *** Summary *** //

  summaryPlaces() {
    return this.db.doc('/summary/places');
  }

  // *** Client API *** //

  client(uid) {
    return this.db.collection('clients').doc(uid);
  }

  clients() {
    return this.db.collection('clients');
  }

  // *** Places API *** //

  place(uid) {
    return this.db.collection('places').doc(uid);
  }

  places() {
    return this.db.collection('places');
  }

  placeFiles(uid) {
    return this.place(uid).collection('documents');
  }

  placeFile(placeId, fileId) {
    return this.placeFiles(placeId).doc(fileId);
  }

  products(placeId) {
    return this.place(placeId).collection('products');
  }

  product(placeId, productId) {
    return this.products(placeId).doc(productId);
  }

  // *** Cities and Departments *** //

  departments() {
    return this.db.collection('departments');
  }

  department(departmentId) {
    return this.departments().doc(departmentId);
  }

  cities() {
    return this.db.collection('cities');
  }

  city(cityId) {
    return this.cities().doc(cityId);
  }

  // *** Clubes *** //
  clubes() {
    return this.db.collection('clubes');
  }

  club(clubId) {
    return this.clubes().doc(clubId);
  }

  // *** Competitions *** //
  competitions() {
    return this.db.collection('competitions');
  }

  competition(compId) {
    return this.competitions().doc(compId);
  }

  // *** Pilotos *** //
  pilots() {
    return this.db.collection('pilots');
  }

  pilot(id) {
    return this.pilots().doc(id);
  }

  // *** Stories *** //

  stories() {
    return this.db.collection('stories');
  }

  story(storyId) {
    return this.stories()
      .doc(storyId);
  }

  // *** Dates API *** //

  date(uid) {
    return this.db.collection('dates').doc(uid);
  }

  dates() {
    return this.db.collection('dates');
  }

  // *** Categories *** //
  categories() {
    return this.db.collection('categories');
  }

  category(id) {
    return this.categories().doc(id);
  }

  // *** Artistas *** //
  artistas() {
    return this.db.collection('artistas');
  }

  artista(id) {
    return this.artistas().doc(id);
  }

  expresiones() {
    return this.db.collection('expresiones');
  }

  expresion(id) {
    return this.expresiones().doc(id);
  }

  obras(artistaId) {
    return this.artista(artistaId).collection('obras');
  }

  obra(artistaId, obraId) {
    return this.obras(artistaId).doc(obraId);
  }

  // *** Batch *** //
  batch() {
    return this.db.batch();
  }

  // *** Storage *** //
  files() {
    return this.storage.ref();
  }

  uploadFile(filePath, file, updateCallback, finalCallback) {
    const uploadTask = this.files().child(filePath).put(file);

    uploadTask.on(app.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = {
          value: ((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
        };
        switch (snapshot.state) {
          case app.storage.TaskState.PAUSED:
            progress.paused = true;
            break;
          case app.storage.TaskState.RUNNING:
            progress.paused = false;
            break;
          default:
            progress.paused = false;
        }
        updateCallback(progress);
      }, (error) => {
        let message;
        switch (error.code) {
          case 'storage/unauthorized':
            message = 'Ups! No tienes permisos para cargar esta imagen 🤭.';
            break;
          case 'storage/canceled':
            message = 'Se ha cancelado la carga de la imagen 🙃';
            break;
          // case 'storage/unknown':
          //   result.message = 'Ups! No tienes permisos para cargar esta imagen 🤭.';
          //   break;
          default:
            message = 'Ups! Algo ocurrió y no se pudo subir la imagen 😔.';
        }
        finalCallback(new Error(message));
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          finalCallback(null, downloadURL);
        });
      });
  }

  deleteFile(reference) {
    return this.files().child(reference).delete();
  }

  // generate license
  generateLicense(id) {
    const method = this.functions.httpsCallable('generateLicense');
    return method({ id });
  }

  downloadLicense(path) {
    return this.storage.ref(path).getDownloadURL();
  }
}

export default Firebase;
