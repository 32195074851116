import React from 'react';
import * as PropTypes from 'prop-types';
import MEditor from '../../../components/editor';
import Fields from '../fields';

const EditorBusinessDetails = ({
  place, property, changeEvent, readOnly, withPaper, title, uploadImage, deleteImage, keyStorage,
}) => {
  const changeProperty = (result) => {
    const newValue = {};
    newValue[property] = result.value;
    console.log('...');
    changeEvent(newValue);
  };

  const key = keyStorage || `place${property}${place.id}`;

  return (
    <MEditor
      id={property}
      keyStorage={key}
      value={place[property] || ''}
      onChange={changeProperty}
      readOnly={readOnly}
      withPaper={withPaper}
      uploadImage={uploadImage}
      deleteImage={deleteImage}
      title={title}
    />
  );
};

EditorBusinessDetails.defaultProps = {
  withPaper: false,
  title: null,
  uploadImage: null,
  deleteImage: null,
  keyStorage: null,
};

EditorBusinessDetails.propTypes = {
  place: Fields.getPropTypes().isRequired,
  property: PropTypes.string.isRequired,
  changeEvent: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  withPaper: PropTypes.bool,
  title: PropTypes.string,
  uploadImage: PropTypes.func,
  deleteImage: PropTypes.func,
  keyStorage: PropTypes.string,
};

export default EditorBusinessDetails;
