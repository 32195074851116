import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from '@material-ui/styles';
import * as PropTypes from 'prop-types';
import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#1D8BB0';
const secondary = '#92FFE9';
const grayMaclic = '#f0f0f0';
const darkGrayMaclic = '#707070';
const borderColor = '#c4c4c4';

const theme = createMuiTheme({
  palette: {
    primary: { main: primary }, // Purple and green play nicely together.
    secondary: { main: secondary }, // This is just green.A700 as hex.
  },
  background: `linear-gradient(175deg, ${primary} 20%, ${secondary} 160%)`,
  grayMaclic,
  darkGrayMaclic,
  borderColor,
});

const MaclicTheme = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

MaclicTheme.defaultProps = {
  children: null,
};

MaclicTheme.propTypes = {
  children: PropTypes.node,
};

export default MaclicTheme;
