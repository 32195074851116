import React from 'react';
import * as PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Fields from './fields';
import PanelDates from './panelDates';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const EventDetails = ({ event, closeEvent }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(!!event);
  }, [event]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => closeEvent(), 500);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      scroll="body"
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`Asunto Cita: ${event.title}`}
      </DialogTitle>
      <DialogContent>
        <PanelDates showTitle={false} dateId={event} />
      </DialogContent>
    </Dialog>
  );
};

EventDetails.defaultProps = {
  event: null,
};

EventDetails.propTypes = {
  event: Fields.eventPropTypes(),
  closeEvent: PropTypes.func.isRequired,
};

export default EventDetails;
