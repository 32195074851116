import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { MEMBERSHIPS } from '../fields';

const useStyle = makeStyles(theme => ({
  typography: {
    marginLeft: theme.spacing(2),
  },
}));

const ButtonAddProducts = ({ membership, productsCount, action }) => {
  const classes = useStyle();
  const [membershipProducts, setMembershipProducts] = React.useState(0);
  React.useEffect(() => {
    if (membership) {
      setMembershipProducts(MEMBERSHIPS[membership].products);
    }
  }, [membership]);
  if (productsCount === null) {
    return null;
  }
  const productsLeft = (membershipProducts - productsCount) <= 0;
  return (
    <div>
      <Button variant="outlined" disabled={productsLeft} color="primary" fullWidth={false} onClick={action}>
        Agregar Producto/Servicio
      </Button>
      <Typography className={classes.typography} variant="button">
        {`${productsCount} de ${membershipProducts} productos/servicios`}
      </Typography>
    </div>
  );
};

ButtonAddProducts.defaultProps = {
  membership: null,
  productsCount: null,
};

ButtonAddProducts.propTypes = {
  membership: PropTypes.string,
  productsCount: PropTypes.number,
  action: PropTypes.func.isRequired,
};

export default ButtonAddProducts;
