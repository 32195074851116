import React, { Component } from 'react';
import { MForm, MTextField, MButton } from '../../components/form';
import { withFirebase, FirebasePropType } from '../../model/Firebase';

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    const { firebase } = this.props;
    firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  onChange(event) {
    const { id, value } = event;
    this.setState({ [id]: value });
  }

  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <MForm onSubmit={this.onSubmit}>
        <MTextField
          id="email"
          type="email"
          label="Correo electrónico"
          value={email}
          onChange={this.onChange}
          autoComplete
          required
        />
        <MButton disabled={isInvalid} label="Restaurar mi contraseña" type="submit" />
        {error && <p>{error.message}</p>}
      </MForm>
    );
  }
}

PasswordForgetFormBase.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(PasswordForgetFormBase);
