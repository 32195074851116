import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { keyFor } from 'react-social-icons/dist/networks';
import MDialog from '../mdialog';
import { isEmail, validateForm, TYPES } from '../validator';
import FieldsTemplate from '../../lib/FieldsTemplate';
import { generateInputs, MForm } from '../form';

const getFields = () => JSON.parse(JSON.stringify({
  url: {
    id: 'url',
    label: 'Enlace a red social, correo electrónico o sitio Web',
    type: 'text',
    value: '',
    validationType: TYPES.LINK,
    validationMessage: 'El enlace ingresado no es válido',
    required: true,
  },
  label: {
    id: 'label',
    label: 'Etiqueta para el enlace (nombre de perfil o de usuario)',
    type: 'text',
    value: '',
    validationType: TYPES.NAME,
    validationMessage: 'La etiqueta ingresada no es válida',
    required: true,
  },
}));

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
  },
}));

const ButtonAddSocial = ({ disabled, onAddLink, success }) => {
  const classes = useStyles();
  const [fields, setFields] = React.useState(getFields());
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (success) {
      setOpen(false);
      setFields(getFields());
    }
  }, [success]);
  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    setFields(theFields);
  };
  const submit = () => {
    const type = isEmail(fields.url.value) ? 'email' : keyFor(fields.url.value);
    const link = FieldsTemplate.getInstanceLocal(fields);
    onAddLink({
      ...link,
      type,
    });
  };
  const isInvalid = !validateForm(fields);
  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        Agregar Enlace
      </Button>
      <MDialog
        maxWidth="sm"
        fullWidth
        open={open}
        handleClose={() => {
          if (!disabled) {
            setOpen(false);
          }
        }}
        title="Nuevo Enlace"
      >
        <MForm
          loading={disabled}
          onSubmit={submit}
          button={{
            disabled: isInvalid,
            label: 'Crear Enlace',
          }}
        >
          {generateInputs(fields, onChange)}
        </MForm>
      </MDialog>
    </>
  );
};

ButtonAddSocial.defaultProps = {
  disabled: false,
  success: false,
};

ButtonAddSocial.propTypes = {
  disabled: PropTypes.bool,
  onAddLink: PropTypes.func.isRequired,
  success: PropTypes.bool,
};

export default ButtonAddSocial;
