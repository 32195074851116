import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React from 'react';
import MDialog from '../mdialog';

// eslint-disable-next-line import/no-cycle
import ClientForm from '../../routes/Clients/clientForm';
// eslint-disable-next-line import/no-cycle
import CategoryForm from '../../routes/Categories/form';
// eslint-disable-next-line import/no-cycle
import DepartmentForm from '../../routes/Fedeaereos/Ciudades/departmentForm';

const getAddButton = (addNew, setOpen, disabled) => !!addNew && (
  <Tooltip title={addNew}>
    <IconButton
      onClick={() => setOpen(true)}
      aria-label={addNew}
      disabled={disabled || false}
    >
      <AddBoxIcon />
    </IconButton>
  </Tooltip>
);

const getAddForm = (addNew, entity, setOpen, open) => {
  if (addNew) {
    let Component = null;
    if (entity === 'clients') {
      Component = ClientForm;
    } else if (entity === 'categories') {
      Component = CategoryForm;
    } else if (entity === 'departments') {
      Component = DepartmentForm;
    }
    return (
      <MDialog
        maxWidth="sm"
        open={open}
        handleClose={() => setOpen(false)}
        title={addNew}
      >
        <Component />
      </MDialog>
    );
  }
  return null;
};

export { getAddForm, getAddButton };
