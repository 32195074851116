import React from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import LoadingMap from './loadingMap';

const GOOGLE_API = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_PROD_API_KEY : process.env.REACT_APP_DEV_API_KEY;

const DEFAULT_CENTER = {
  lat: 4.413354570011514,
  lng: -76.1511404877092,
};

const useStyles = (width, height) => makeStyles(theme => ({
  mapContainer: {
    position: 'relative',
    margin: theme.spacing(1, 0),
    width,
    height: (height < 300) ? 300 : height,
  },
  mapElement: {
    width: '100%',
    height: '100%',
  },
}));

const MMap = ({
  google, children, zoom, width, height, center, initialCenter, ...others
}) => {
  const classes = useStyles(width, height)();
  return (
    <div className={classes.mapContainer}>
      <Map
        {...others}
        className={classes.mapElement}
        initialCenter={initialCenter || DEFAULT_CENTER}
        center={center || DEFAULT_CENTER}
        google={google}
        zoom={zoom}
      >
        {children}
      </Map>
    </div>
  );
};

MMap.defaultProps = {
  children: null,
  zoom: 14,
  width: '100%',
  height: 300,
  center: null,
  initialCenter: null,
};

MMap.propTypes = {
  ...Map.propTypes,
  zoom: PropTypes.number,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.number,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
  apiKey: (GOOGLE_API),
  LoadingContainer: LoadingMap,
})(MMap);
