const DATE_TYPE = {
  payment: {
    value: 'payment',
    label: 'Pago',
    content: false,
  },
  initial: {
    value: 'initial',
    label: 'Cita Inicial',
    content: true,
  },
  capture: {
    value: 'capture',
    label: 'Recolección de Información',
    content: true,
  },
  preproduction: {
    value: 'preproduction',
    label: 'Concertación Producción',
    content: true,
  },
  photography: {
    value: 'photography',
    label: 'Producción Fotográfica',
    content: true,
  },
  presentation: {
    value: 'presentation',
    label: 'Presentación Contenido',
    content: true,
  },
  other: {
    value: 'other',
    label: 'Otro',
    content: false,
  },
};

module.exports = DATE_TYPE;
