import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Loading from '../../../../components/loading';
import Fields from '../../fields';
import ButtonAddVenue from './buttonAddVenue';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';
import ListVenues from './listVenues';
import confirm from '../../../../components/confirm';
import MPanel from '../../../../components/panel';
import { AuthUserPropTypes, withCurrentUser } from '../../../../model/Session';
import { isEditor } from '../../../../components/access';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PanelVenues = ({
  withPaper, withTitle, place, firebase, notification, authUser,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [formSuccess, setFormSuccess] = React.useState(false);

  let Component = (<Loading variant="h6" size={20} />);

  let venues = [];

  if (place) {
    venues = place.venues || [];
  }

  const updateVenues = (theVenues, successMessage, errorMessage) => {
    setLoading(true);
    setFormSuccess(false);
    let message;
    firebase.place(place.id)
      .set({ venues: theVenues }, { merge: true })
      .then(() => {
        message = { type: 'success', message: successMessage };
        setFormSuccess(true);
      })
      .catch(() => { message = { type: 'error', message: errorMessage }; })
      .finally(() => {
        notification.setMessage(message);
        setLoading(false);
      });
  };

  const addVenue = (venue) => {
    const theVenues = [...venues];
    theVenues.push(venue);
    updateVenues(
      theVenues,
      'Se agregó la nueva sede correctamente.',
      'Ups! Algo no salió bien agregando la nueva sede',
    );
  };

  const editVenue = (index, venue) => {
    const theVenues = [...venues];
    theVenues[index] = venue;
    updateVenues(
      theVenues,
      'Se modificó la sede correctamente.',
      'Ups! Algo no salió bien modificando la sede',
    );
  };

  const deleteVenue = (index) => {
    confirm(
      '¿Está seguro de que desea borrar la sede indicado?',
      'Confirmar Borrar Sede',
    ).then(
      () => {
        const theVenues = [...venues];
        theVenues.splice(index, 1);
        updateVenues(
          theVenues,
          'Se borró correctamente la sede.',
          'Ups! Algo no salió bien borrando la sede.',
        );
      },
      () => {},
    );
  };

  if (place) {
    Component = (
      <ListVenues
        deleteAction={deleteVenue}
        venues={venues}
        formSuccess={formSuccess}
        loading={loading}
        editAction={editVenue}
      />
    );
  }

  return (
    <MPanel
      className={classes.root}
      withPaper={withPaper}
      titleBlock={isEditor(authUser) && (
        <ButtonAddVenue
          disabled={!place}
          addVenue={addVenue}
          loading={loading}
          formSuccess={formSuccess}
        />
      )}
      title={withTitle ? 'Sedes y Horarios' : null}
    >
      {Component}
    </MPanel>
  );
};

PanelVenues.defaultProps = {
  withPaper: true,
  withTitle: true,
  place: null,
};

PanelVenues.propTypes = {
  withPaper: PropTypes.bool,
  withTitle: PropTypes.bool,
  place: Fields.getPropTypes(),
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withNotification,
  withFirebase,
  withCurrentUser,
)(PanelVenues);
