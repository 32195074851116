import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core';
import { isEditor } from '../../components/access';
import { withCurrentUser, AuthUserPropTypes } from '../../model/Session';
import Fields, { DATE_STATUS } from './fields';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import { FirebasePropType, withFirebase } from '../../model/Firebase';

const useStyles = makeStyles(() => ({
  root: {
    color: red[400],
  },
}));

const CancelButton = ({
  date, authUser, firebase, notification, processing, setProcessing,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    firebase.date(date.id)
      .set({
        status: DATE_STATUS.cancelled.value,
        observations: date.observations,
      }, { merge: true })
      .then(() => notification.setMessage({
        message: 'Se ha cancelado la cita satisfactoriamente 🙂', type: 'success',
      }))
      .catch(() => notification.setMessage({
        message: 'Algo no salió bien cancelando la cita 😥', type: 'warning',
      }))
      .finally(() => setProcessing(false));
  };
  if (date && (isEditor(authUser) || (date.status === DATE_STATUS.scheduled.value
    && (authUser.uid === date.createdBy || Fields.isInvitee(authUser, date.invitees))))) {
    return (
      <Button
        disabled={processing}
        component="button"
        onClick={handleClick}
        className={classes.root}
      >
        <CancelIcon />
        Cancelar Cita
      </Button>
    );
  }
  return null;
};

CancelButton.defaultProps = {
  date: null,
};

CancelButton.propTypes = {
  date: Fields.getPropTypes(),
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  processing: PropTypes.bool.isRequired,
  setProcessing: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withNotification,
  withFirebase,
)(CancelButton);
