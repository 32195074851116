import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import YouTube from '@u-wave/react-youtube';

const Youtube = ({ id, start }) => (
  <Box maxWidth={500} display="block" marginX="auto" textAlign="center" width="100%" height={300}>
    <YouTube
      allowFullscreen
      annotations={false}
      autoplay={false}
      className="youtube-player"
      startSeconds={start}
      lang="es"
      modestBranding
      video={id}
      showRelatedVideos={false}
    />
  </Box>
);

Youtube.defaultProps = {
  start: 0,
};

Youtube.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Youtube;
