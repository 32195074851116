import React from 'react';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import { MButton } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Table from '../../../components/table';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import Fields from './fields';

const TableCities = ({ firebase, history, notification }) => {
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState([]);
  React.useEffect(() => {
    const unsubscribe = firebase.cities().onSnapshot((querySnapshot) => {
      setLoading(true);
      const theValues = [];
      querySnapshot.forEach((doc) => {
        theValues.push(Fields.getInstance(doc));
      });
      setValues(theValues);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [firebase]);

  const actions = () => [
    () => ({
      icon: 'edit',
      tooltip: 'Editar Ciudad',
      onClick: (event, rowDataLocal) => history.push(`${ROUTES.CIUDADES}/edit/${rowDataLocal.id}`),
    }),
  ];

  const editable = () => {
    const onRowDelete = (oldData) => new Promise((resolve, reject) => {
      firebase.city(oldData.id)
        .delete()
        .then(() => {
          notification.setMessage({ message: 'Ciudad borrada satisfactoriamente 🙂', type: 'success' });
          resolve();
        })
        .catch((e) => {
          notification.setMessage({ message: 'Algo no salió bien borrando la ciudad 😥', type: 'warning' });
          reject(e);
        });
    });
    return {
      isDeletable: () => true,
      onRowDelete,
    };
  };

  const defaults = Fields.tableDefaults(firebase);
  return (
    <Container
      title="Listado de Ciudades"
      header={(
        <MButton fullWidth={false} label="Nueva Ciudad" to={`${ROUTES.CIUDADES}/new`} />
      )}
    >
      <Table
        data={values}
        columns={defaults.columns}
        title={defaults.title}
        isLoading={loading}
        editable={editable()}
        actions={actions()}
      />
    </Container>
  );
};


TableCities.propTypes = {
  firebase: FirebasePropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(TableCities);
