import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import LicenseLabelItem from './licenseLabelItem';
import LicenseTitleSection from './licenseTitleSection';
import {
  DISCIPLINES, IPPI, IPPI_ACTIVE, LEVEL,
} from '../fields';
import { RenderDate } from '../../../../components/renders';
import { AsyncColumnRender } from '../../../../components/table';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
}));

const LicenseSection = ({ pilot, firebase }) => {
  const classes = useStyles();
  if (!!pilot && !!pilot.discipline) {
    return (
      <div>
        <LicenseTitleSection marginTop title="Datos para licencia" />
        <Grid container spacing={3}>
          {!!pilot.discipline && (
            <LicenseLabelItem label="Disciplina" value={DISCIPLINES[pilot.discipline].label} />
          )}
          {!!pilot.ippi && (
            <LicenseLabelItem label="Licencia IPPI" value={IPPI[pilot.ippi].label} />
          )}
          <LicenseLabelItem label="Número de licencia IPPI" value={pilot.ippiLicense} />
          {!!pilot.ippiLicense && (
            <LicenseLabelItem label="Licencia IPPI activa" value={IPPI_ACTIVE[pilot.ippiActive].label} />
          )}
          {!!pilot.level && (
            <LicenseLabelItem label="Nivel validado" value={LEVEL[pilot.level].label} />
          )}
          <LicenseLabelItem label="Piloto para validación" value={pilot.validate} />
          {!!pilot.validFrom && (
            <LicenseLabelItem
              label="Licencia temporal válida desde"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.validFrom} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {!!pilot.validTo && (
            <LicenseLabelItem
              label="Licencia temporal válida hasta"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderDate date={pilot.validTo} format="DD/MM/YYYY" />
                </Typography>
              )}
            />
          )}
          {!!pilot.localClub && pilot.localClub !== '' && (
            <LicenseLabelItem
              label="Club Local"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <AsyncColumnRender
                    loadFunction={() => new Promise((resolve) => {
                      firebase.club(pilot.localClub)
                        .get()
                        .then((doc) => resolve(doc.exists ? doc.data().name : 'NO ENCONTRADO'));
                    })}
                  />
                </Typography>
              )}
            />
          )}
        </Grid>
      </div>
    );
  }
  return null;
};

LicenseSection.defaultProps = {
  pilot: null,
};

LicenseSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(LicenseSection);
