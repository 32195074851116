import React from 'react';
import FieldsTemplate from '../../../lib/FieldsTemplate';
import { AsyncColumnRender } from '../../../components/table';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  department: {
    id: 'department',
    label: 'Departamento',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'departments',
      label: ['name'],
      addNew: 'Agregar Nuevo Departamento?',
    },
    validationMessage: 'Débe indicar el departamento.',
    required: true,
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsCity extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults(firebase) {
    const columnsObject = this.columnsTable();
    columnsObject.id.hidden = true;
    columnsObject.department.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          firebase.department(rowData.department)
            .get()
            .then((doc) => resolve(doc.data().name));
        })}
      />
    );
    return {
      title: 'Listado de Ciudades',
      columns: Object.values(columnsObject),
    };
  }
}

export default new FieldsCity(FIELDS);
