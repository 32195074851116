import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Title from '../title';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const PaperSheet = ({
  title, children, header, headerAlign, backPath, backAction, withPaper,
}) => {
  const classes = useStyles();
  const Component = withPaper ? Paper : 'div';
  return (
    <Title title={title}>
      <Component className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" component="h3">
              {!!backPath && (
                <IconButton component={Link} to={backPath} aria-label="Atrás">
                  <ArrowBackIcon />
                </IconButton>
              )}
              {!!backAction && (
                <IconButton component={Button} onClick={() => backAction()} aria-label="Atrás">
                  <ArrowBackIcon />
                </IconButton>
              )}
              {title}
            </Typography>
          </Grid>
          {!!header && (
            <Grid item xs={12} style={{ textAlign: headerAlign }} md={4}>
              {header}
            </Grid>
          )}
        </Grid>
        {children}
      </Component>
    </Title>
  );
};

PaperSheet.defaultProps = {
  header: null,
  headerAlign: 'right',
  backPath: null,
  backAction: null,
  withPaper: true,
};

PaperSheet.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  headerAlign: PropTypes.string,
  backPath: PropTypes.string,
  backAction: PropTypes.func,
  withPaper: PropTypes.bool,
};

export default PaperSheet;
