import React from 'react';
import * as PropTypes from 'prop-types';
import { Marker } from 'google-maps-react';
import MMap from './map';

const ViewMap = ({ value, ...others }) => (
  <div>
    <MMap
      {...others}
      center={value}
      initialCenter={value}
    >
      {!!value && (
      <Marker
        title="Ubicación actual del lugar"
        name="Lugar/Negocio"
        position={value}
      />
      )}
    </MMap>
  </div>
);

ViewMap.propTypes = {
  ...MMap.propTypes,
  value: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
};

export default ViewMap;
