import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
}));

const StatusIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Online>
        <Tooltip title="Tu dispositivo se encuentra conectado a Internet">
          <IconButton color="inherit">
            <WifiIcon />
          </IconButton>
        </Tooltip>
      </Online>
      <Offline>
        <Tooltip title="Tu dispositivo NO se encuentra conectado a Internet, algunas opciones no se encuentran disponibles">
          <IconButton color="inherit">
            <WifiOffIcon />
          </IconButton>
        </Tooltip>
      </Offline>
    </div>
  );
};

export default StatusIcon;
