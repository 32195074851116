import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import CityForm from './cityForm';
import * as ROUTES from '../../../constants/routes';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from './fields';
import FieldsDepartment from './fieldsDepartamento';
import Loading from '../../../components/loading';

const EditCity = ({ firebase, match, history }) => {
  const [city, setCity] = React.useState(Fields.defaultValues());
  const [department, setDepartment] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.city(id)
        .onSnapshot((doc) => {
          const codCity = Fields.getInstance(doc);
          firebase.department(codCity.department).get()
            .then((docDep) => {
              if (docDep.exists) {
                setDepartment(FieldsDepartment.getInstance(docDep));
              }
              setCity(codCity);
              setLoading(false);
            });
        }, () => history.push(ROUTES.CIUDADES));
    } else {
      history.push(ROUTES.CIUDADES);
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, history, match.params]);

  return (
    <Container
      backPath={ROUTES.CIUDADES}
      title={`Ciudad ${city.name}${department ? ` - ${department.name}` : ''}`}
    >
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loading />
          ) : (
            <CityForm isNew={false} defaultValues={city} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

EditCity.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
)(EditCity);
