import React from 'react';
import Grid from '@material-ui/core/Grid';
import { PhotosPanel } from '../Photos';
import { PanelDescription } from '../Editors';
import Fields from '../fields';

const Description = ({ place }) => (
  <>
    <Grid item xs={12} md={4}>
      <PhotosPanel place={place} showTitle />
    </Grid>
    <Grid item xs={12} md={8}>
      <PanelDescription place={place} />
    </Grid>
  </>
);

Description.defaultProps = {
  place: null,
};

Description.propTypes = {
  place: Fields.getPropTypes(),
};

export default Description;
