import * as PropTypes from 'prop-types';
import { TYPES as VALIDATOR_TYPES } from '../components/validator';

class FieldsTemplate {
  static TYPES() {
    return { ...VALIDATOR_TYPES };
  }

  constructor(fields) {
    this.fields = fields || {};
    this.defaultValues = this.defaultValues.bind(this);
    this.columnsTable = this.columnsTable.bind(this);
    this.getInstance = this.getInstance.bind(this);
    this.getPropTypes = this.getPropTypes.bind(this);
  }

  defaultValues() {
    const obj = {};
    Object.values(this.fields).forEach((item) => { obj[item.id] = item.value; });
    return obj;
  }

  columnsTable() {
    const obj = {};
    Object.values(this.fields)
      .forEach((item) => { obj[item.id] = { title: item.label, field: item.id }; });
    return obj;
  }

  getPropTypes() {
    const obj = {};
    Object.values(this.fields)
      .forEach((item) => {
        let prop;
        switch (item.type) {
          case VALIDATOR_TYPES.MULTIREFERENCE:
          case VALIDATOR_TYPES.SCHEDULE:
            prop = PropTypes.oneOfType([PropTypes.array, PropTypes.object]);
            break;
          case VALIDATOR_TYPES.LATLNG:
            prop = PropTypes.shape({
              lat: PropTypes.number.isRequired,
              lng: PropTypes.number.isRequired,
            });
            break;
          case VALIDATOR_TYPES.BOOLEAN:
            prop = PropTypes.bool;
            break;
          case VALIDATOR_TYPES.MULTIFILE:
          case VALIDATOR_TYPES.LINKS:
          case VALIDATOR_TYPES.PHONES:
            prop = PropTypes.array;
            break;
          case VALIDATOR_TYPES.NUMBER:
            prop = PropTypes.number;
            break;
          default:
            prop = PropTypes.string;
        }
        obj[item.id] = prop;
      });
    return PropTypes.shape(obj);
  }

  getInstance(doc) {
    const instance = {};
    const data = doc.data();
    Object.keys(this.fields).forEach((key) => {
      if (key === this.fields.id.id) {
        instance[key] = doc.id;
      } else {
        instance[key] = data[key] === undefined || data[key] === undefined
          ? this.fields[key].value : data[key];
      }
    });
    return instance;
  }

  static getInstanceLocal(theFields) {
    const result = {};
    Object.keys(theFields).forEach((item) => {
      result[item] = theFields[item].value === undefined ? theFields[item] : theFields[item].value;
    });
    return result;
  }
}

export default FieldsTemplate;
