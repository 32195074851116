import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import Container from '../../../components/dashboard/container';
import { isAuthor } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import ProductForm from './productForm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import * as ROUTES from '../../../constants/routes';
import Fields, { MEMBERSHIPS } from '../fields';
import Loading from '../../../components/loading';

const redirect = (history, placeId) => {
  let path = ROUTES.PLACES;
  if (placeId) {
    path = `${path}/detail/${placeId}/products`;
  }
  history.push(path);
};

const NewProduct = ({ history, match, firebase }) => {
  const [place, setPlace] = React.useState(null);
  React.useEffect(() => {
    const { placeId } = match.params;
    if (placeId) {
      firebase.place(placeId)
        .get()
        .then((doc) => {
          firebase.products(placeId).get()
            .then((docs) => {
              const placeData = Fields.getInstance(doc);
              const mProducst = MEMBERSHIPS[placeData.membership].products;
              if (docs.size >= mProducst) {
                redirect(history, placeId);
              } else {
                setPlace(placeData);
              }
            })
            .catch(() => redirect(history));
        })
        .catch(() => redirect(history));
    } else {
      redirect(history);
    }
  }, [firebase, history, match.params]);

  let title = 'Cargando...';
  let component = <Loading />;
  if (place) {
    title = `Nuevo producto/servicio para ${place.name}`;
    component = <ProductForm place={place.id} />;
  }

  return (
    <Container backAction={() => redirect(history, place.id)} title={title}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {component}
        </Grid>
      </Grid>
    </Container>
  );
};

NewProduct.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withRouter,
)(NewProduct);
