import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import CompetitionForm from './competitionForm';
import * as ROUTES from '../../../constants/routes';

const NewCompetition = () => (
  <Container backPath={ROUTES.COMPETENCIAS} title="Nueva Competencia">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <CompetitionForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isAeroAdmin)(NewCompetition);
