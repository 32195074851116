import React from 'react';
import { Divider } from '@material-ui/core';
import { DetailsButton } from '../../../../components/buttons';
import MDialog from '../../../../components/mdialog';
import Fields, { DISCIPLINAS } from './fields';
import MEditor from '../../../../components/editor';
import LabelItem from '../../../../components/labelItem';

const ObraDetails = ({ obra }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <DetailsButton onClick={() => setOpen(true)} />
      <MDialog
        open={open}
        title={`Obra: ${obra.title}`}
        handleClose={() => setOpen(false)}
      >
        <LabelItem label="Categoría" value={DISCIPLINAS[obra.category].label} />
        <Divider />
        <MEditor id={obra.id} keyStorage={obra.id} readOnly value={obra.details} />
      </MDialog>
    </>
  );
};

ObraDetails.propTypes = {
  obra: Fields.getPropTypes().isRequired,
};

export default ObraDetails;
