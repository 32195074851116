import FieldsTemplate from '../../../../lib/FieldsTemplate';
import { isLocalPhone } from '../../../../components/validator';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre Sede',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de la sede no válida',
    required: true,
  },
  address: {
    id: 'address',
    label: 'Dirección',
    value: '',
    type: 'text',
    validationType: TYPES.ADDRESS,
    validationMessage: 'Dirección de la sede no válida',
    required: true,
  },
  phones: {
    id: 'phones',
    value: [],
    type: TYPES.PHONES,
  },
  location: {
    id: 'location',
    type: TYPES.LATLNG,
    validationType: TYPES.LATLNG,
    value: null,
  },
  schedule: {
    id: 'schedule',
    type: TYPES.SCHEDULE,
    value: {},
  },
};

export class FieldsVenues extends FieldsTemplate {
  // eslint-disable-next-line no-useless-constructor
  constructor(fields) {
    super(fields);
  }

  static getInstanceLocal(theFields) {
    const tempResult = FieldsTemplate.getInstanceLocal(theFields);
    const phones = [];
    if (isLocalPhone(tempResult.phone)) {
      phones.push(tempResult.phone);
    }
    delete tempResult.phone;
    for (let i = 1; i < tempResult.phoneCount; i += 1) {
      if (isLocalPhone(tempResult[`phone${i}`])) {
        phones.push(tempResult[`phone${i}`]);
      }
      delete tempResult[`phone${i}`];
    }
    delete tempResult.phoneCount;
    return {
      phones,
      ...tempResult,
    };
  }
}

export default new FieldsVenues(FIELDS);
