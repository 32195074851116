import React from 'react';
import * as PropTypes from 'prop-types';
import ColorPicker from 'material-ui-color-picker';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.button,
    borderColor: theme.borderColor,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '5px',
    padding: theme.spacing(1),
  },
  legend: {
    ...theme.typography.caption,
    textTransform: 'initial',
  },
}));

const MColor = ({
  required, id, label, value, onChange,
}) => {
  const classes = useStyles();
  const changeEvent = (color) => {
    const result = {
      id,
      value: color,
      isValid: !(required && color === ''),
    };
    onChange(result);
  };
  return (
    <fieldset className={classes.label}>
      <legend className={classes.legend}>
        {label}
      </legend>
      <ColorPicker
        name={label}
        defaultValue=""
        value={value}
        onChange={changeEvent}
      />
    </fieldset>
  );
};

MColor.defaultProps = {
  required: false,
  onChange: () => {},
};

MColor.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func,
};

export default MColor;
