import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  makeStyles, CardActions,
} from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import Fields from '../fields';
import { domain } from '../../../model/Images';
import { isEditor } from '../../../components/access';
import * as ROUTES from '../../../constants/routes';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import confirm from '../../../components/confirm';
import {
  DeleteButton, EditButton,
} from '../../../components/buttons';

const defaultImage = encodeURI(`${domain}/defaults/maclic-transparent.png`);

const useStyles = makeStyles((theme) => {
  const media = {
    width: '100%',
    // paddingTop: '25px',
    position: 'relative',
    paddingLeft: '5px',
    paddingRight: '5px',
  };
  return {
    card: {
      display: 'inline-table',
      float: 'right',
    },
    itemContent: {
      display: 'table',
      minWidth: 250,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    categoryItem: {
      width: '100%',
      marginLeft: 10,
      marginBottom: 15,
    },
    container: {
      // display: 'block',
      width: '100%',
      paddingLeft: 100,
      marginTop: 15,
      marginBottom: 15,
      borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
    titleContainer: {
      // position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    title: {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      padding: 0,
      margin: 0,
      color: 'black',
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      // textShadow: '1px 1px 1px #707070',
    },
    alpha: {
      ...media,
      backgroundColor: blue[800],
    },
  };
});

const getEditButton = (id, authUser, history) => {
  if (isEditor(authUser)) {
    return (
      <EditButton
        aria-label="Modificar"
        onClick={() => {
          history.push(`${ROUTES.CATEGORIES}/edit/${id}`);
        }}
      />
    );
  }
  return null;
};

const deleteAction = (id, firebase, notification) => {
  confirm(
    '¿Está seguro de borrar la categoria?',
    'Confirmar Borrar',
  )
    .then(
      () => {
        firebase.category(id)
          .delete()
          .then(() => {
            notification.setMessage({ message: 'Categoría borrada satisfactoriamente 🙂', type: 'success' });
          })
          .catch(() => {
            notification.setMessage({ message: 'Algo no salió bien borrando la categoría 😥', type: 'warning' });
          });
      },
      () => {},
    );
};

const getDeleteButton = (id, authUser, firebase, notification) => {
  if (isEditor(authUser)) {
    return (
      <DeleteButton
        aria-label="Borrar"
        onClick={() => deleteAction(id, firebase, notification)}
      />
    );
  }
  return null;
};

const CategoryItem = ({
  category, authUser, history, firebase, notification, categories, parent,
}) => {
  const classes = useStyles();
  const [display, setDisplay] = React.useState(null);

  React.useEffect(() => {
    const children = [];
    if (category.children) {
      Object.keys(category.children)
        .forEach((child) => {
          if (category.children[child] === true && categories[child]) {
            children.push(categories[child]);
          }
        });
    }
    setDisplay({
      name: `${category.index} - ${category.name}`,
      id: category.id,
      children,
      image: defaultImage,
    });
  }, [categories, category, parent]);

  if (!display) {
    return null;
  }

  const editButton = getEditButton(display.id, authUser, history);
  const deleteButton = getDeleteButton(display.id, authUser, firebase, notification);

  return (
    <div className={classes.categoryItem}>
      <div className={classes.itemContent}>
        <h5 className={classes.title}>
          {display.name}
        </h5>
        <CardActions className={classes.card} disableSpacing>
          {editButton}
          {deleteButton}
        </CardActions>
      </div>
      {!!display.children.length && (
        <div className={classes.container}>
          {display.children.map((child) => (
            <CategoryItem
              key={`${display.id}${child.id}`}
              category={child}
              categories={categories}
              parent={display.id}
              notification={notification}
              firebase={firebase}
              authUser={authUser}
              history={history}
            />
          ))}
        </div>
      )}
    </div>
  );
};

CategoryItem.defaultProps = {
  parent: null,
};

CategoryItem.propTypes = {
  category: Fields.getPropTypes().isRequired,
  categories: PropTypes.objectOf(PropTypes.any).isRequired,
  parent: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isEditor),
  withCurrentUser,
  withNotification,
  withFirebase,
  withRouter,
)(CategoryItem);
