import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Fields from './fields';
import PlaceFields from '../fields';
import DocumentItem from './DocumentItem';
import MPanel from '../../../components/panel';

const useStyles = makeStyles(theme => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const DocumentsList = ({ documents, place }) => {
  const classes = useStyles();
  const name = place ? place.name : '';
  if (documents.length === 0) {
    return (
      <MPanel withPaper>
        <Typography variant="button" display="block" className={classes.emptyMessage}>
          {`No se han cargado archivos para el lugar/negocio "${name}".`}
        </Typography>
      </MPanel>
    );
  }
  return (
    <Grid spacing={2} className={classes.grid} container>
      {documents.map(item => (
        <Grid key={item.id} xs={12} sm={6} item>
          <DocumentItem placeId={place.id} document={item} />
        </Grid>
      ))}
    </Grid>
  );
};

DocumentsList.defaultProps = {
  documents: [],
  place: null,
};

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(Fields.getPropTypes()),
  place: PlaceFields.getPropTypes(),
};

export default DocumentsList;
