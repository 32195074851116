import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
// import { MButton } from '../../../components/form';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import { withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import SignUpForm from '../../SignUp/signUpForm';

const NewUser = () => (
  <Container
    title="Nuevo Usuario"
    backPath={ROUTES.USERS}
  >
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <SignUpForm adminUsers />
      </Grid>
    </Grid>
  </Container>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(NewUser);
