import React from 'react';
import { Grid } from '@material-ui/core';
import PanelOthers from '../Others';
import { PanelVenues } from '../Others/Venues';
import Fields from '../fields';

const Contact = ({ place }) => (
  <>
    <Grid item xs={12} md={5}>
      <PanelOthers object={place} showTitle />
    </Grid>
    <Grid item xs={12} md={7}>
      <PanelVenues place={place} />
    </Grid>
  </>
);

Contact.defaultProps = {
  place: null,
};

Contact.propTypes = {
  place: Fields.getPropTypes(),
};

export default Contact;
