import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}));

const LabelItem = ({ label, value }) => {
  const classes = useStyles();
  const valueComponent = typeof value === 'string' ? (
    <Typography className={classes.itemValue} component="p" variant="subtitle2">
      {value}
    </Typography>
  ) : value;
  return (
    <>
      {valueComponent}
      <Typography className={classes.itemLabel} component="p" variant="overline">
        {label}
      </Typography>
    </>
  );
};

LabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default LabelItem;
