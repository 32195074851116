import defaultImage from '../../media/maclic-default-grey.jpg';

const devDomain = '//devmaclic.imgix.net';
// const devDomain = '//dmages.maclic.co';
const prodDomain = '//maclic.imgix.net';
// const prodDomain = '//images.maclic.co';

const domain = process.env.NODE_ENV === 'production' ? prodDomain : devDomain;
// const domain = prodDomain;
export {
  // eslint-disable-next-line import/prefer-default-export
  domain,
  defaultImage,
};
