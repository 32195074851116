import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/dashboard/container';
import { isAuthor, isEditor } from '../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import DateForm from './dateForm';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Fields from './fields';
import Loading from '../../components/loading';

const EditDate = ({
  firebase, match, history, authUser,
}) => {
  const [date, setDate] = React.useState({ ...Fields.defaultValues() });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.date(id).onSnapshot((doc) => {
        const d = Fields.getInstance(doc);
        d.invitees = [...Object.keys(d.invitees)];
        if (isEditor(authUser) || authUser.uid === d.createdBy) {
          setDate(d);
          setLoading(false);
        } else {
          history.goBack();
        }
      }, () => history.goBack());
    } else {
      history.goBack();
    }
    return () => !!unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container backAction={() => history.goBack()} title={`Cita: ${date.subject}`}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loading />
          ) : (
            <DateForm isNew={false} defaultValues={date} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

EditDate.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withRouter,
  withCurrentUser,
)(EditDate);
