import React from 'react';
import { Grid } from '@material-ui/core';
import { PanelNotes } from '../Editors';
import Fields from '../fields';

const Notes = ({ place }) => (
  <Grid item xs={12}>
    <PanelNotes place={place} />
  </Grid>
);

Notes.defaultProps = {
  place: null,
};

Notes.propTypes = {
  place: Fields.getPropTypes(),
};

export default Notes;
