import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../../model/Firebase';
import {
  MForm, generateInputs,
} from '../../components/form';
import Roles from '../Admin/Users/roles';
import * as ROUTES from '../../constants/routes';
import FIELDS from './fields';
import { validateForm } from '../../components/validator';

const INITIAL_STATE = {
  fields: FIELDS,
  roles: [],
  error: null,
  loading: false,
};

class SignUpFormBase extends Component {
  static isInvalid(fields) {
    const validForm = validateForm(fields);
    return !(validForm && fields.password.value === fields.passwordTwo.value);
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeRoles = this.onChangeRoles.bind(this);
  }

  onSubmit() {
    this.setState({
      loading: true,
    });
    const {
      fields, roles,
    } = this.state;
    // eslint-disable-next-line react/prop-types
    const { firebase, history, adminUsers } = this.props;
    const userRoles = {};
    roles.forEach((item) => {
      userRoles[item.label] = item.value;
    });
    firebase
      .doCreateUserWithEmailAndPassword(fields.email.value, fields.password.value)
      .then((authUser) => firebase
        .user(authUser.user.uid)
        .set({
          username: fields.username.value,
          email: fields.email.value,
          phone: fields.phone.value,
          roles: userRoles,
        }))
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(adminUsers ? ROUTES.USERS : ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  onChange(result) {
    const { fields } = this.state;
    fields[result.id].value = result.value;
    if (result.id === 'passwordTwo') {
      fields[result.id].error = fields.password.value !== fields.passwordTwo.value;
    } else {
      fields[result.id].error = !result.isValid;
    }
    this.setState({
      fields,
    });
  }

  onChangeRoles(values) {
    this.setState({ roles: values });
  }

  render() {
    const { adminUsers } = this.props;
    const {
      fields,
      roles,
      error,
      loading,
    } = this.state;
    const isInvalid = SignUpFormBase.isInvalid(fields);
    return (
      <MForm
        loading={loading}
        onSubmit={this.onSubmit}
        button={{
          label: 'Registrar Usuario',
          disabled: isInvalid,
        }}
      >
        {generateInputs(fields, this.onChange)}
        {!!adminUsers && (<Roles adminUsers value={roles} setValue={this.onChangeRoles} />)}
        {error && (
          <Typography
            variant="caption"
            style={{ color: 'red' }}
          >
            {error.message}
          </Typography>
        )}
      </MForm>
    );
  }
}

SignUpFormBase.defaultProps = {
  adminUsers: false,
};

SignUpFormBase.propTypes = {
  adminUsers: PropTypes.bool,
};

export default compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);
