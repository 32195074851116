import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Fields, { DATE_TYPE, DATE_STATUS } from './fields';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Loading from '../../components/loading';
import { SectionTitle } from '../../components/title';
import MChip from '../../components/chip';
import { RenderDate } from '../../components/renders';
import { AsyncColumnRender } from '../../components/table';
import { withAuthorization } from '../../model/Session';
import { isAuthor } from '../../components/access';
import EditButton from './editButton';
import DoneButton from './doneButton';
import CancelButton from './cancelButton';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
  placeValue: {
    fontSize: theme.typography.pxToRem(26),
    textTransform: 'uppercase',
    color: 'black',
    marginBottom: theme.spacing(0),
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}));

const PanelDates = ({
  dateId, firebase, showTitle,
}) => {
  const classes = useStyles();

  const [processing, setProcessing] = React.useState(false);
  const [dateState, setDateState] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (typeof dateId === 'string') {
      unsubscribe = firebase.date(dateId).onSnapshot((doc) => {
        setDateState(Fields.getInstance(doc));
      });
    } else if (dateId) {
      setDateState(dateId);
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, dateId]);

  if (dateState) {
    return (
      <div style={{ textAlign: 'center' }}>
        {!!showTitle && (
          <>
            <SectionTitle marginBottom title="Detalles de la cita" />
            <Typography className={classes.itemValue} component="p" variant="subtitle2">
              {dateState.title}
            </Typography>
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Asunto
            </Typography>
          </>
        )}
        <AsyncColumnRender
          loadFunction={() => new Promise((resolve) => {
            firebase.place(dateState.resource.place).get()
              .then((doc) => {
                const data = doc.data();
                resolve((
                  <Typography className={classes.placeValue} component="p" variant="subtitle2">
                    {data ? data.name : 'No se encontró'}
                  </Typography>
                ));
              });
          })}
        />
        <Typography className={classes.itemLabel} component="p" variant="overline">
          Lugar
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <MChip label={DATE_TYPE[dateState.resource.type].label} />
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Tipo de Cita
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MChip
              label={DATE_STATUS[dateState.resource.status].label}
              color={DATE_STATUS[dateState.resource.status].color}
            />
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Estado
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.itemValue} component="p" variant="subtitle2">
              <RenderDate date={dateState.start} />
            </Typography>
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Inicia
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.itemValue} component="p" variant="subtitle2">
              <RenderDate date={dateState.end} />
            </Typography>
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Termina
            </Typography>
          </Grid>
        </Grid>
        <>
          {Object.keys(dateState.resource.invitees).map((invitee) => (
            <AsyncColumnRender
              key={invitee}
              loadFunction={() => new Promise((resolve) => {
                firebase.user(invitee).get()
                  .then((doc) => {
                    const data = doc.data();
                    resolve((
                      <MChip label={data.username} color="default" />
                    ));
                  })
                  .catch(() => resolve('No cargó el usuario'));
              })}
            />
          ))}
        </>
        <Typography className={classes.itemLabel} component="p" variant="overline">
          Invitados
        </Typography>
        <DialogActions>
          <DoneButton
            processing={processing}
            setProcessing={setProcessing}
            date={dateState.resource}
          />
          <CancelButton
            processing={processing}
            setProcessing={setProcessing}
            date={dateState.resource}
          />
          <EditButton date={dateState.resource} processing={processing} />
        </DialogActions>
      </div>
    );
  }
  return (
    <Loading variant="h6" size={20} />
  );
};

PanelDates.defaultProps = {
  dateId: null,
  showTitle: true,
};

PanelDates.propTypes = {
  showTitle: PropTypes.bool,
  firebase: FirebasePropType.isRequired,
  dateId: PropTypes.oneOfType([
    PropTypes.string,
    Fields.getPropTypes(),
  ]),
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withRouter,
)(PanelDates);
