import FieldsTemplate from '../../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const Fields = {
  name: {
    id: 'name',
    label: 'Nombre Artista',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de artísta no válido',
    required: true,
  },
  photo: {
    id: 'photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía Artista',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  details: {
    id: 'details',
    value: '',
    type: 'editor',
    validationType: TYPES.EDITOR,
    label: 'Detalle',
    required: false,
  },
  phone: {
    id: 'phone',
    label: 'Teléfono',
    value: '',
    type: 'text',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: false,
  },
  email: {
    id: 'email',
    label: 'Correo electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: false,
  },
  links: {
    id: 'links',
    value: [],
    type: TYPES.LINKS,
  },
  published: {
    id: 'published',
    label: 'Publicado',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  photoURL: {
    id: 'photoURL',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsArtista extends FieldsTemplate {}

export default new FieldsArtista(Fields);
