import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import Container from '../../../components/dashboard/container';
import { isAuthor } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import ProductForm from './productForm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import * as ROUTES from '../../../constants/routes';
import Fields from './fields';
import Loading from '../../../components/loading';

const redirect = (history, placeId) => {
  let path = ROUTES.PLACES;
  if (placeId) {
    path = `${path}/detail/${placeId}/products`;
  }
  history.push(path);
};

const EditProduct = ({ history, match, firebase }) => {
  const [product, setProduct] = React.useState(null);
  const [place, setPlace] = React.useState(null);
  React.useEffect(() => {
    const { productId, placeId } = match.params;
    if (productId && placeId) {
      firebase.product(placeId, productId)
        .get()
        .then((doc) => {
          const productData = Fields.getInstance(doc);
          setProduct(productData);
          setPlace(placeId);
        })
        .catch(() => redirect(history, placeId));
    } else {
      redirect(history, placeId);
    }
  }, [firebase, history, match.params]);

  let title = 'Cargando...';
  let component = <Loading />;
  if (product && place) {
    title = `Editando ${product.name}`;
    component = <ProductForm place={place} productValues={product} />;
  }

  return (
    <Container backAction={() => redirect(history, place)} title={title}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {component}
        </Grid>
      </Grid>
    </Container>
  );
};

EditProduct.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withRouter,
)(EditProduct);
