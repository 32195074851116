import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { LinearProgress, Typography } from '@material-ui/core';
import MButton from './mButton';
import Loading from '../loading';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    backgroundColor: fade('#fff', 0.8),
    display: 'block',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: 100,
  },
}));

const MForm = ({
  children, noValidate, onSubmit, button, loading, loadingMessage, uploaded,
}) => {
  const classes = useStyles();
  const submitEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return (
    <form key="MFORM" onSubmit={submitEvent} className={classes.form} noValidate={noValidate}>
      {children}
      {!!button && (
        <>
          <br />
          <MButton
            mt={2}
            fullWidth={false}
            disabled={button.disabled}
            type="submit"
            label={button.label}
          />
        </>
      )}
      {!!loading && (
        <div className={classes.loading}>
          <Loading message={loadingMessage} size={20} variant="h5" />
          {uploaded > -1 && (
            <>
              <Typography variant="overline" display="block" style={{ marginTop: '16px', textAlign: 'center' }}>
                {`Cargando archivo ${Math.round(uploaded)}%`}
              </Typography>
              <LinearProgress variant="determinate" value={uploaded} />
            </>
          )}
        </div>
      )}
    </form>
  );
};

MForm.defaultProps = {
  children: null,
  noValidate: true,
  onSubmit: null,
  button: null,
  loading: false,
  uploaded: -1,
  loadingMessage: 'Guardando... ⏳',
};

MForm.propTypes = {
  children: PropTypes.node,
  noValidate: PropTypes.bool,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  uploaded: PropTypes.number,
  loadingMessage: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};

export default MForm;
