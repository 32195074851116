import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase, FirebasePropType } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = ({ firebase, history, ...props }) => {
    const listener = React.useRef(null);
    React.useEffect(() => {
      listener.current = firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            history.push(ROUTES.HOME);
          }
        },
        () => history.push(ROUTES.SIGN_IN),
      );
      return () => {
        listener.current = () => {};
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <AuthUserContext.Consumer>
        {
          (authUser) => (condition(authUser) ? <Component {...props} /> : null)
        }
      </AuthUserContext.Consumer>
    );
  };

  WithAuthorization.propTypes = {
    firebase: FirebasePropType.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
