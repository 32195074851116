import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardMedia, CardActions, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import Confirm from '../../../components/confirm';
import { withAuthorization } from '../../../model/Session';
import { isAuthor } from '../../../components/access';
import { domain } from '../../../model/Images';
import { DeleteButton, DownloadButton } from '../../../components/buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  media: {
    height: 140,
  },
  select: {
    minWidth: 90,
    marginLeft: theme.spacing(1),
  },
}));

const PhotoItem = ({
  photo, onDeletedImage, toggleModal, totalPhotos, onUpdateImage,
}) => {
  const classes = useStyles();

  const deleteAction = () => {
    Confirm('¿Está seguro de borrar esta imagen?')
      .then(
        () => { onDeletedImage(photo); },
        () => {},
      );
  };

  const changeIndex = (event) => {
    if (onUpdateImage) {
      const { value } = event.target;
      const thePhoto = {
        ...photo,
        index: value,
      };
      onUpdateImage(thePhoto);
    }
  };

  const downloadImage = () => {
    const url = buildURL(`https:${domain}${photo.reference.replace('/images', '')}`);
    // eslint-disable-next-line no-undef
    window.open(url);
  };

  const source = buildURL(`${domain}${photo.reference.replace('/images', '')}`, { w: 400 });

  const options = [];
  for (let i = 0; i < totalPhotos; i += 1) {
    options.push((
      <MenuItem key={`option${i}`} value={i}>{i}</MenuItem>
    ));
  }

  return (
    <React.Fragment>
      <Card>
        <CardActionArea onClick={() => toggleModal(true, photo.index)}>
          <CardMedia image={source} className={classes.media} />
        </CardActionArea>
        <CardActions>
          <DeleteButton onClick={deleteAction} />
          <DownloadButton onClick={downloadImage} />
          {totalPhotos > 1 && (
            <FormControl className={classes.select}>
              <InputLabel htmlFor="index">Posición</InputLabel>
              <Select
                value={photo.index}
                onChange={changeIndex}
                inputProps={{
                  name: 'index',
                  id: 'index',
                }}
              >
                {options}
              </Select>
            </FormControl>
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

PhotoItem.defaultProps = {
  totalPhotos: 1,
  onUpdateImage: null,
};

PhotoItem.propTypes = {
  photo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
  onDeletedImage: PropTypes.func.isRequired,
  onUpdateImage: PropTypes.func,
  toggleModal: PropTypes.func.isRequired,
  totalPhotos: PropTypes.number,
};

export default withAuthorization(isAuthor)(PhotoItem);
