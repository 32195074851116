import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Loading from '../loading';
import ButtonAddSocial from './buttonAddSocial';
import { NotificationPropTypes, withNotification } from '../notification';
import MPanel from '../panel';
import SocialLink from '../../routes/Business/Others/Social/SocialLink';
import { AuthUserPropTypes, withCurrentUser } from '../../model/Session';
import { isEditor } from '../access';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 1),
    clear: 'both',
  },
  links: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
}));

const PanelSocial = ({
  object, entity, showTitle, withPaper, firebase, notification, authUser,
  message, editCondition, title,
}) => {
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  React.useEffect(() => {
    if (object) {
      setDisabled(false);
    }
  }, [object]);

  const updateLinks = (theLinks) => {
    setDisabled(true);
    setSuccess(false);
    let msg;
    firebase[entity](object.id).set({ links: theLinks }, { merge: true })
      .then(() => {
        msg = { type: 'success', message: `Se actualizaron los enlaces ${message}.` };
        setSuccess(true);
      })
      .catch(() => {
        msg = { type: 'error', message: `Ups! Algo no salió bien actualizando los enlaces ${message}.` };
      })
      .finally(() => {
        notification.setMessage(msg);
        setDisabled(false);
      });
  };

  let links = null;
  if (object) {
    links = object.links || [];
  }

  const onAddLink = (link) => {
    const newLinks = [...links, link];
    updateLinks(newLinks);
  };

  const onDeleteLink = (index) => {
    const theLinks = [...links];
    theLinks.splice(index, 1);
    updateLinks(theLinks);
  };

  let linksComponent = <Loading variant="h6" size={20} />;

  const canEdit = editCondition(authUser);

  if (object) {
    linksComponent = links.length === 0 ? (
      <Typography variant="subtitle2" component="p" className={classes.links}>
        No se han registrado redes sociales o enlaces
      </Typography>
    ) : (
      <div className={classes.links}>
        {links.map((item, index) => {
          const onDelete = canEdit ? () => onDeleteLink(index) : null;
          return (
            <SocialLink key={item.url} link={item} onDelete={onDelete} />
          );
        })}
      </div>
    );
  }

  return (
    <MPanel
      withPaper={withPaper}
      title={showTitle ? title : null}
      titleBlock={canEdit && (
        <ButtonAddSocial success={success} disabled={disabled} onAddLink={onAddLink} />
      )}
    >
      <div className={classes.root}>
        {linksComponent}
      </div>
    </MPanel>
  );
};

PanelSocial.defaultProps = {
  object: null,
  entity: 'place',
  showTitle: true,
  withPaper: true,
  message: 'del Lugar/Negocio',
  editCondition: isEditor,
  title: 'Redes sociales / Web / Correo',
};

PanelSocial.propTypes = {
  showTitle: PropTypes.bool,
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object,
  entity: PropTypes.string,
  withPaper: PropTypes.bool,
  notification: NotificationPropTypes.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  message: PropTypes.string,
  editCondition: PropTypes.func,
  title: PropTypes.string,
};

export default compose(
  withFirebase,
  withNotification,
  withCurrentUser,
)(PanelSocial);
