import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { RenderEmail, RenderPhone } from '../../../../components/renders';
import LicenseLabelItem from './licenseLabelItem';
import { BLOOD_TYPES } from '../fields';
import LicenseTitleSection from './licenseTitleSection';

const useStyles = makeStyles((theme) => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
    wordWrap: 'break-word',
  },
}));

const EmergencySection = ({ pilot }) => {
  const classes = useStyles();
  if (pilot) {
    return (
      <div>
        <LicenseTitleSection marginTop title="Información de Emergencia" />
        <Grid container spacing={3}>
          <LicenseLabelItem label="Nombre de contacto" value={pilot.contactName} />
          {!!pilot.contactEmail && (
            <LicenseLabelItem
              label="Correo electrónico de contacto"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderEmail value={pilot.contactEmail} />
                </Typography>
              )}
            />
          )}
          {!!pilot.contactPhone && (
            <LicenseLabelItem
              label="Número telefónico de contacto"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderPhone value={pilot.contactPhone} />
                </Typography>
              )}
            />
          )}
          <LicenseLabelItem label="Compañía de seguros" value={pilot.insuranceName} />
          <LicenseLabelItem label="Número de poliza" value={pilot.insuranceNumber} />
          {!!pilot.insurancePhone && (
            <LicenseLabelItem
              label="Número telefónico compañía de seguros"
              value={(
                <Typography className={classes.itemValue} component="p" variant="subtitle2">
                  <RenderPhone value={pilot.insurancePhone} />
                </Typography>
              )}
            />
          )}
          {!!pilot.bloodType && (
            <LicenseLabelItem label="Tipo de sangre / RH" value={BLOOD_TYPES[pilot.bloodType].label} />
          )}
          <LicenseLabelItem label="Alergias" value={pilot.allergies} />
        </Grid>
      </div>
    );
  }
  return null;
};

EmergencySection.defaultProps = {
  pilot: null,
};

EmergencySection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object,
};

export default EmergencySection;
