import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardMedia, CardContent, Typography,
  CardActions, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import Fields from './fields';
import Confirm from '../../../components/confirm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { isAuthor } from '../../../components/access';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import { domain, defaultImage } from '../../../model/Images';
import { EditButton, DeleteButton, DownloadButton } from '../../../components/buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  media: {
    height: 140,
  },
  select: {
    minWidth: 90,
    marginLeft: theme.spacing(1),
  },
}));

const deleteProduct = (product, placeId, firebase, notification) => {
  let message;
  firebase.product(placeId, product.id)
    .delete()
    .then(() => {
      if (product.photo) {
        firebase.deleteFile(product.photo)
          .then(() => {})
          .catch(() => {});
      }
      message = { type: 'success', message: 'Producto/Servicio borrado satisfactoriamente 😀' };
    })
    .catch(() => {
      message = { type: 'success', message: 'Ups! Algo no salió bien borrando el producto/servicio 🥺' };
    })
    .finally(() => notification.setMessage(message));
};

const ProductItem = ({
  product, placeId, firebase, notification, history, index,
  togglePhotosModal, onUpdateProduct, totalProducts,
}) => {
  const classes = useStyles();

  const deleteAction = () => {
    Confirm('¿Está seguro de borrar este producto?')
      .then(
        () => deleteProduct(product, placeId, firebase, notification),
        () => {},
      );
  };

  const editAction = () => {
    history.push(`${ROUTES.PLACES}/${placeId}/product/edit/${product.id}`);
  };

  let image = defaultImage;
  if (product.photoURL) {
    image = buildURL(`${domain}${product.photo.replace('/images', '')}`, { w: 400 });
  }

  const toggleModal = () => {
    if (togglePhotosModal && product.photoURL) {
      togglePhotosModal(true, index);
    }
  };

  const changeIndex = (event) => {
    if (onUpdateProduct) {
      const { value } = event.target;
      onUpdateProduct({ ...product, index: value });
    }
  };

  const downloadAction = () => {
    const url = buildURL(`https:${domain}${product.photo.replace('/images', '')}`);
    // eslint-disable-next-line no-undef
    window.open(url);
  };

  const options = [];
  for (let i = 0; i < totalProducts; i += 1) {
    options.push((
      <MenuItem key={`option${i}`} value={i}>{i}</MenuItem>
    ));
  }

  return (
    <>
      <Card>
        <CardActionArea onClick={toggleModal}>
          <CardMedia
            className={classes.media}
            image={image}
            title={product.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {product.name}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p"> */}
            {/*  {product.details} */}
            {/* </Typography> */}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <EditButton onClick={editAction} />
          <DeleteButton onClick={deleteAction} />
          {!!product.photoURL && <DownloadButton onClick={downloadAction} />}
          {totalProducts > 1 && (
            <FormControl className={classes.select}>
              <InputLabel htmlFor="index">Posición</InputLabel>
              <Select
                value={index}
                onChange={changeIndex}
                inputProps={{
                  name: 'index',
                  id: 'index',
                }}
              >
                {options}
              </Select>
            </FormControl>
          )}
        </CardActions>
      </Card>
    </>
  );
};

ProductItem.defaultProps = {
  togglePhotosModal: null,
  index: 0,
  totalProducts: 1,
  onUpdateProduct: null,
};

ProductItem.propTypes = {
  product: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  placeId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  togglePhotosModal: PropTypes.func,
  index: PropTypes.number,
  totalProducts: PropTypes.number,
  onUpdateProduct: PropTypes.func,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withNotification,
  withRouter,
)(ProductItem);
