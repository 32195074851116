import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { MEMBERSHIPS, CONTENT_MEMBERSHIPS } from '../fields';
import { AuthUserPropTypes } from '../../../model/Session';
import { isCoordinator, isTranslator } from '../../../components/access';
import { MSimpleSelect, MTextField } from '../../../components/form';
import { TRANSLATE_STATUS } from '../Sections/translate';

const getMemberships = (authUser) => (
  isCoordinator(authUser) || isTranslator(authUser) ? MEMBERSHIPS : CONTENT_MEMBERSHIPS
);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-3),
  },
  labelContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    textAlign: 'right',
  },
  buttonContainer: {
    paddingTop: `${theme.spacing(4)}px !important`,
  },
}));

const Filter = ({
  authUser, filter, values, disabled,
}) => {
  const classes = useStyles();
  const [theValues, setValues] = React.useState(values);
  const memberships = React.useRef(getMemberships(authUser));
  React.useEffect(() => {
    setValues(values);
  }, [values]);
  const onValueChange = (result) => {
    const newValues = { ...theValues };
    newValues[result.id] = result.value;
    setValues(newValues);
  };
  const onFilter = () => {
    filter(theValues);
  };
  const clearFilter = () => {
    const data = { name: '', membership: '', translated: '' };
    setValues(data);
    filter(data);
  };
  return (
    <Grid item xs={12} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1} className={classes.labelContainer}>
          <Typography variant="subtitle2" component="p" color="primary" className={classes.label}>
            Filtrar por
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <MSimpleSelect
            disabled={disabled}
            label="Membresía"
            onChange={onValueChange}
            id="membership"
            options={memberships.current}
            value={theValues.membership}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <MSimpleSelect
            disabled={disabled}
            label="Estado traducción"
            onChange={onValueChange}
            id="translated"
            options={TRANSLATE_STATUS}
            value={theValues.translated}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MTextField
            value={theValues.name}
            id="name"
            label="Nombre"
            onChange={onValueChange}
            onEnter={onFilter}
            type="text"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={3} className={classes.buttonContainer}>
          <Button disabled={disabled} size="small" variant="text" color="primary" onClick={onFilter}>
            Filtrar
          </Button>
          {' '}
          <Button disabled={disabled} size="small" variant="text" color="default" onClick={clearFilter}>
            Limpiar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Filter.defaultProps = {
  values: {
    name: '',
    membership: '',
  },
  disabled: true,
};

Filter.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  filter: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    membership: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
};

export default Filter;
