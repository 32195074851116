import React from 'react';
// eslint-disable-next-line
import MTextField from './mTextField';
// eslint-disable-next-line
import ReferenceFormInput from './referenceFormInput';
// eslint-disable-next-line
import MultiReferenceFormInput from './referenceMultiFormInput';
import SelectFormInput from './selectFormInput';
import MultiSelectFormInput from './multiSelectFormInput';
import MDateInput from './mDateInput';
import { TYPES } from '../validator';
import MEditor from '../editor';
import MUpload from './mUpload';
import MSwitch from './mSwitch';
import MColor from './MColor';

const inputText = (field, onChange, multiLine = false) => (
  <MTextField
    key={`Textfield${field.id}`}
    id={field.id}
    label={field.label}
    onChange={onChange}
    type={field.type}
    error={field.error}
    validationMessage={field.validationMessage}
    validationType={field.validationType}
    value={field.value}
    required={field.required}
    multiline={multiLine}
    addNew={field.addNew}
  />
);

const switchInput = (field, onChange) => (
  <MSwitch
    key={`MSwitch${field.id}`}
    label={field.label}
    id={field.id}
    value={field.value}
    onChange={onChange}
    required={field.required}
  />
);

const dateInput = (field, onChange) => (
  <MDateInput
    label={field.label}
    value={field.value}
    id={field.id}
    key={`referenceinput${field.id}`}
    onChange={onChange}
    format={field.format}
    validationType={field.validationType}
    validationMessage={field.validationMessage}
    error={field.error}
    type={field.type}
    required={field.required}
    validationOptions={field.validationOptions}
  />
);

const multiReferenceInput = (field, onChange) => (
  <MultiReferenceFormInput
    key={`multireferenceinput${field.id}`}
    onValueChange={onChange}
    value={field.value}
    reference={field.reference}
    label={field.label}
    required={field.required}
    validationType={field.validationType}
    validationMessage={field.validationMessage}
    error={field.error}
    id={field.id}
  />
);

const referenceInput = (field, onChange) => (
  <ReferenceFormInput
    key={`referenceinput${field.id}`}
    onValueChange={onChange}
    value={field.value}
    reference={field.reference}
    label={field.label}
    required={field.required}
    validationType={field.validationType}
    validationMessage={field.validationMessage}
    error={field.error}
    id={field.id}
  />
);

const editor = (field, onChange, editorKey) => (
  <MEditor
    key={`editorInput${field.id}`}
    id={field.id}
    keyStorage={`${field.id}${editorKey}`}
    value={field.value}
    onChange={onChange}
    title={field.label}
    error={field.error}
    required={field.required}
    validationMessage={field.validationMessage}
    validationType={field.validationType}
  />
);

const multiSelectInput = (field, onChange) => (
  <MultiSelectFormInput
    key={`multiselectform${field.id}`}
    onValueChange={onChange}
    value={field.value}
    reference={field.reference}
    label={field.label}
    required={field.required}
    validationType={field.validationType}
    validationMessage={field.validationMessage}
    error={field.error}
    id={field.id}
  />
);

const selectInput = (field, onChange) => (
  <SelectFormInput
    key={`referenceinput${field.id}`}
    onValueChange={onChange}
    value={field.value}
    reference={field.reference}
    label={field.label}
    required={field.required}
    validationType={field.validationType}
    validationMessage={field.validationMessage}
    error={field.error}
    id={field.id}
  />
);

const uploadInput = (field, onChange, fields) => (
  <MUpload
    key={`upload${field.id}`}
    id={field.id}
    error={field.error}
    validationMessage={field.validationMessage}
    fileTypes={field.fileTypes}
    value={field.value}
    label={field.label}
    onChange={onChange}
    required={field.required}
    validationType={field.validationType}
    multiple={field.multiple}
    onRemove={field.onRemove}
    fields={fields}
  />
);

const colorInput = (field, onChange) => (
  <MColor
    key={`color${field.id}`}
    label={field.label}
    id={field.id}
    value={field.value}
    onChange={onChange}
    required={field.required}
  />
);

const generateInputs = (fields, onChange, editorKey) => Object.values(fields).map((field) => {
  if (field.label) {
    switch (field.validationType) {
      case TYPES.SELECT:
        return selectInput(field, onChange);
      case TYPES.MULTISELECT:
        return multiSelectInput(field, onChange);
      case TYPES.DATE:
      case TYPES.FUTURE_DATE:
        return dateInput(field, onChange);
      case TYPES.REFERENCE:
        return referenceInput(field, onChange);
      case TYPES.MULTIREFERENCE:
        return multiReferenceInput(field, onChange);
      case TYPES.TEXTAREA:
        return inputText(field, onChange, true);
      case TYPES.EDITOR:
        return editor(field, onChange, editorKey);
      case TYPES.FILE:
      case TYPES.MULTIFILE:
        return uploadInput(field, onChange, fields);
      case TYPES.COLOR:
        return colorInput(field, onChange);
      case TYPES.BOOLEAN:
        return switchInput(field, onChange);
      default:
        return inputText(field, onChange);
    }
  }
  return null;
});

export default generateInputs;
