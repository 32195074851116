import React from 'react';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import MChip from '../../../components/chip';
import FieldsTemplate from '../../../lib/FieldsTemplate';
import Countries from '../../../constants/countries';
import { LEVEL, DISCIPLINES } from '../../../constants/licenseConstants';
import { AsyncColumnRender } from '../../../components/table';
import LicensesButton from './licenseComponents/licensesButton';
import { LICENSE_STATUS } from './licenseComponents/filters';

const moment = extendMoment(Moment);
moment.tz.setDefault('America/Bogota');

const TYPES = FieldsTemplate.TYPES();

export const DOC_TYPES = {
  passport: {
    value: 'passport',
    label: 'Pasaporte',
  },
  id: {
    value: 'id',
    label: 'DNI',
  },
  cc: {
    value: 'cc',
    label: 'Cédula de ciudadanía',
  },
  ce: {
    value: 'ce',
    label: 'Cédula de extranjería',
  },
};
export const VEGETARIAN = {
  vegetarian: {
    label: 'Vegetariano',
    value: 'vegetarian',
  },
  no: {
    label: 'No',
    value: 'no',
  },
  vegan: {
    label: 'Vegano',
    value: 'vegan',
  },
};
export const BLOOD_TYPES = {
  'O+': {
    value: 'O+',
    label: 'O+',
  },
  'O-': {
    value: 'O-',
    label: 'O-',
  },
  'A+': {
    value: 'A+',
    label: 'A+',
  },
  'A-': {
    value: 'A-',
    label: 'A-',
  },
  'AB+': {
    value: 'AB+',
    label: 'AB+',
  },
  'AB-': {
    value: 'AB-',
    label: 'AB-',
  },
  'B+': {
    value: 'AB-',
    label: 'AB-',
  },
};
export const GENDERS = {
  female: {
    label: 'Mujer',
    value: 'female',
  },
  male: {
    label: 'Hombre',
    value: 'male',
  },
};
export const CERTIFICATES = {
  'EN-A': {
    label: 'EN-A',
    value: 'EN-A',
  },
  'EN-B': {
    label: 'EN-B',
    value: 'EN-B',
  },
  'EN-C': {
    label: 'EN-C',
    value: 'EN-C',
  },
  'EN-D': {
    label: 'EN-D',
    value: 'EN-D',
  },
  CCC: {
    label: 'CCC',
    value: 'CCC',
  },
  no: {
    label: 'Sin certificación',
    value: 'no',
  },
};
export const COUNTRIES = () => {
  const results = {};
  Countries.countries.forEach((country) => {
    results[country.code] = {
      label: country.name,
      value: country.code,
    };
  });
  return results;
};
export const SHIRTS = {
  XXS: {
    label: 'XXS',
    value: 'XXS',
  },
  XS: {
    label: 'XS',
    value: 'XS',
  },
  S: {
    label: 'S',
    value: 'S',
  },
  M: {
    label: 'M',
    value: 'M',
  },
  L: {
    label: 'L',
    value: 'L',
  },
  XL: {
    label: 'XL',
    value: 'XL',
  },
  XXL: {
    label: 'XXL',
    value: 'XXL',
  },
};
export const IPPI = {
  IPP5: {
    label: 'IPP5',
    value: 'IPP5',
  },
  IPP4: {
    label: 'IPP4',
    value: 'IPP4',
  },
  IPP3: {
    label: 'IPP3',
    value: 'IPP3',
  },
  IPP2: {
    label: 'IPP2',
    value: 'IPP2',
  },
  IPP1: {
    label: 'IPP1',
    value: 'IPP1',
  },
};
export const IPPI_ACTIVE = {
  yes: {
    value: 'yes',
    label: 'Si',
  },
  no: {
    value: 'no',
    label: 'No',
  },
};

const FIELDS = {
  events: {
    id: 'events',
    label: 'Eventos',
    value: [],
    type: 'reference',
    validationType: TYPES.MULTIREFERENCE,
    reference: {
      entity: 'competitions',
      label: ['name'],
    },
  },
  licenseURL: {
    id: 'licenseURL',
    value: '',
  },
  countryResidence: {
    id: 'countryResidence',
    label: 'País de residencia',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: COUNTRIES(),
    },
    validationMessage: 'Débe indicar el país de residencia.',
    required: true,
  },
  arriveDate: {
    id: 'arriveDate',
    label: 'Fecha de llegada (si aplica)',
    value: null,
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: false,
  },
  leaveDate: {
    id: 'leaveDate',
    label: 'Fecha de salida (si aplica)',
    value: null,
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: false,
  },
  photo: {
    id: 'photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía Piloto',
    multiple: false,
    required: true,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  docType: {
    id: 'docType',
    value: '',
    type: 'select',
    label: 'Tipo de Documento',
    validationType: TYPES.SELECT,
    reference: {
      values: DOC_TYPES,
    },
    validationMessage: 'Débe indicar el tipo de documento.',
    required: true,
  },
  passportPhoto: {
    id: 'passportPhoto',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía Pasaporte (si aplica)',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  docNumber: {
    id: 'docNumber',
    label: 'Número de documento',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Número de documento no válido',
    required: true,
  },
  docDueDate: {
    id: 'docDueDate',
    label: 'Fecha vencimiento de documento',
    value: null,
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.FUTURE_DATE,
    validationMessage: 'Débe indicar una fecha válida para el vencimiento del documento',
    required: false,
  },
  firstName: {
    id: 'firstName',
    label: 'Nombres',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombres no válidos',
    required: true,
  },
  lastName: {
    id: 'lastName',
    label: 'Apellidos',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Apellidos no válidos',
    required: true,
  },
  dateBirth: {
    id: 'dateBirth',
    label: 'Fecha de Nacimiento',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  countryBirth: {
    id: 'countryBirth',
    label: 'País de nacimiento',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: COUNTRIES(),
    },
    validationMessage: 'Débe indicar el país de nacimiento.',
    required: true,
  },
  nationality: {
    id: 'nationality',
    label: 'País de nacionalidad',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: COUNTRIES(),
    },
    validationMessage: 'Débe indicar el país de nacionalidad.',
    required: true,
  },
  email: {
    id: 'email',
    label: 'Correo electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: true,
  },
  phone: {
    id: 'phone',
    label: 'Número telefónico',
    value: '',
    type: 'phone',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: true,
  },
  cityResidence: {
    id: 'cityResidence',
    label: 'Ciudad de residencia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Ciudad de residencia no válida',
    required: true,
  },
  addressResidence: {
    id: 'addressResidence',
    label: 'Dirección de residencia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Dirección de residencia no válida',
    required: true,
  },
  postalResidence: {
    id: 'postalResidence',
    label: 'Código postal residencia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Código postal no válido',
  },
  contactName: {
    id: 'contactName',
    label: 'Nombre de contacto de emergencia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  contactEmail: {
    id: 'contactEmail',
    label: 'Correo electrónico de contacto',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Correo electrónico no válido',
    required: true,
  },
  contactPhone: {
    id: 'contactPhone',
    label: 'Número telefónico de contacto',
    value: '',
    type: 'phone',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido',
    required: true,
  },
  insuranceName: {
    id: 'insuranceName',
    label: 'Compañía aseguradora',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido no válido',
    required: true,
  },
  insuranceNumber: {
    id: 'insuranceNumber',
    label: 'Número de póliza',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Número de póliza no válido',
    required: true,
  },
  insurancePhone: {
    id: 'insurancePhone',
    label: 'Número telefónico compañía aseguradora',
    value: '',
    type: 'phone',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: true,
  },
  bloodType: {
    id: 'bloodType',
    value: '',
    type: 'select',
    label: 'Tipo de sangre',
    validationType: TYPES.SELECT,
    reference: {
      values: BLOOD_TYPES,
    },
    validationMessage: 'Débe indicar el tipo de sangre.',
  },
  allergies: {
    id: 'allergies',
    label: 'Alergias',
    value: '',
    type: 'textarea',
    validationType: TYPES.TEXTAREA,
    required: false,
  },
  localCity: {
    id: 'localCity',
    label: 'Ciudad en Colombia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Ciudad no válida',
  },
  localAddress: {
    id: 'localAddress',
    label: 'Dirección en Colombia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Dirección no válida',
  },
  localPhone: {
    id: 'localPhone',
    label: 'Número telefónico en Colombia',
    value: '',
    type: 'phone',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido',
    required: false,
  },
  participantNumber: {
    id: 'participantNumber',
    label: 'Número de participante',
    value: '',
    type: 'number',
    validationType: TYPES.NUMBER,
    validationMessage: 'Número de participante no válido',
  },
  gender: {
    id: 'gender',
    value: '',
    type: 'select',
    label: 'Género',
    validationType: TYPES.SELECT,
    reference: {
      values: GENDERS,
    },
    validationMessage: 'Débe indicar el género.',
  },
  vegetarian: {
    id: 'vegetarian',
    value: '',
    type: 'select',
    label: 'Vegetariano',
    validationType: TYPES.SELECT,
    reference: {
      values: VEGETARIAN,
    },
    validationMessage: 'Débe indicar si es vegetariano.',
  },
  gliderManufacturer: {
    id: 'gliderManufacturer',
    label: 'Fabricante Vela Parapente',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Fabricante no válido',
  },
  gliderModel: {
    id: 'gliderModel',
    label: 'Modelo de Vela',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Modelo no válido',
  },
  gliderColor: {
    id: 'gliderColor',
    label: 'Color de Vela',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Color no válido',
  },
  gliderCertification: {
    id: 'gliderCertification',
    value: '',
    type: 'select',
    label: 'Certificación de vela',
    validationType: TYPES.SELECT,
    reference: {
      values: CERTIFICATES,
    },
  },
  sponsor: {
    id: 'sponsor',
    label: 'Patrocinadores',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Patrocinadores no válidos',
  },
  faiLicense: {
    id: 'faiLicense',
    label: 'ID Licencia deportiva FAI',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'ID Licencia deportiva FAI no válida',
  },
  civl: {
    id: 'civl',
    label: 'ID CIVL',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'ID CIVL no válido',
  },
  nationalLicense: {
    id: 'nationalLicense',
    label: 'Licencia de la federación nacional',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Licencia de la federación nacional no válida',
  },
  club: {
    id: 'club',
    label: 'Club',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Club no válido',
  },
  team: {
    id: 'team',
    label: 'Equipo',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Equipo no válido',
  },
  shirt: {
    id: 'shirt',
    value: '',
    type: 'select',
    label: 'Talla camiseta',
    validationType: TYPES.SELECT,
    reference: {
      values: SHIRTS,
    },
  },
  discipline: {
    id: 'discipline',
    value: '',
    type: 'select',
    label: 'Disciplina',
    validationType: TYPES.SELECT,
    reference: {
      values: DISCIPLINES,
    },
  },
  ippi: {
    id: 'ippi',
    value: '',
    type: 'select',
    label: 'Licencia IPPI',
    validationType: TYPES.SELECT,
    reference: {
      values: IPPI,
    },
  },
  ippiLicense: {
    id: 'ippiLicense',
    label: 'Licencia IPPI',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Licencia IPPI no válida',
  },
  ippiActive: {
    id: 'ippiActive',
    value: '',
    type: 'select',
    label: 'Licencia IPPI activa',
    validationType: TYPES.SELECT,
    reference: {
      values: IPPI_ACTIVE,
    },
  },
  level: {
    id: 'level',
    value: '',
    type: 'select',
    label: 'Nivel validado',
    validationType: TYPES.SELECT,
    reference: {
      values: LEVEL,
    },
  },
  validate: {
    id: 'validate',
    label: 'Piloto para validación',
    value: '',
    type: 'text',
    validationType: TYPES.TEXTAREA,
    validationMessage: 'Valor no válido',
  },
  validFrom: {
    id: 'validFrom',
    label: 'Licencia temp válida desde',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  validTo: {
    id: 'validTo',
    label: 'Licencia temp válida hasta',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  localClub: {
    id: 'localClub',
    label: 'Club Local',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'clubes',
      label: ['name'],
    },
    validationMessage: 'Débe indicar el club.',
    required: false,
  },
  id: {
    id: 'id',
    value: '',
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
};

const getMoment = (val, end, event = false) => {
  const d = moment(val);
  if (end) {
    d.endOf('day');
    if (event) { d.add('day', 2); }
  } else {
    d.startOf('day');
    if (event) { d.add('day', -2); }
  }
  return d;
};

const getRange = (start, end, event = false) => moment
  .range(getMoment(start, false, event), getMoment(end, true, event));

const getEvents = (events, competitions) => {
  const ranges = [];
  Object.keys(events).forEach((event) => {
    if (events[event]) {
      const comp = competitions[event];
      if (!!comp && comp.faiEvent) {
        const tempRange = getRange(comp.initialDate, comp.endDate, true);
        const overlaps = ranges.some((range, index) => {
          if (range.overlaps(tempRange)) {
            ranges[index] = range.add(tempRange);
            return true;
          }
          return false;
        });
        if (!overlaps) { ranges.push(tempRange); }
      }
    }
  });
  return ranges;
};

export const requiresTempLicence = (values, competitions) => {
  if (!values.arriveDate || !values.leaveDate
    || values.arriveDate === '' || values.leaveDate === '') {
    return false;
  }
  const events = getEvents(values.eventsId, competitions);
  if (events.length === 0) {
    return true;
  }
  const pilot = getRange(values.arriveDate, values.leaveDate);
  return events.some((event) => pilot
    .start.isBefore(event.start) || pilot.end.isAfter(event.end));
};

class FieldsPilots extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults(competitions) {
    const columnsObject = this.columnsTable();
    columnsObject.id.hidden = true;
    // columnsObject.countryResidence.hidden = true;
    columnsObject.countryResidence.render = (rowData) => (
      <>
        {rowData.countryResidence ? COUNTRIES()[rowData.countryResidence].label : ''}
      </>
    );
    columnsObject.arriveDate.hidden = true;
    columnsObject.leaveDate.hidden = true;
    // columnsObject.docType.hidden = true;
    // columnsObject.docNumber.hidden = true;
    columnsObject.docDueDate.hidden = true;
    // columnsObject.firstName.hidden = true;
    // columnsObject.lastName.hidden = true;
    columnsObject.dateBirth.hidden = true;
    columnsObject.countryBirth.hidden = true;
    columnsObject.nationality.hidden = true;
    columnsObject.email.hidden = true;
    columnsObject.phone.hidden = true;
    columnsObject.cityResidence.hidden = true;
    columnsObject.addressResidence.hidden = true;
    columnsObject.postalResidence.hidden = true;
    columnsObject.contactName.hidden = true;
    columnsObject.contactEmail.hidden = true;
    columnsObject.contactPhone.hidden = true;
    columnsObject.insuranceName.hidden = true;
    columnsObject.insuranceNumber.hidden = true;
    columnsObject.insurancePhone.hidden = true;
    columnsObject.bloodType.hidden = true;
    columnsObject.allergies.hidden = true;
    columnsObject.localCity.hidden = true;
    columnsObject.localAddress.hidden = true;
    columnsObject.localPhone.hidden = true;
    columnsObject.participantNumber.hidden = true;
    columnsObject.gender.hidden = true;
    columnsObject.vegetarian.hidden = true;
    columnsObject.gliderManufacturer.hidden = true;
    columnsObject.gliderModel.hidden = true;
    columnsObject.gliderColor.hidden = true;
    columnsObject.gliderCertification.hidden = true;
    columnsObject.sponsor.hidden = true;
    columnsObject.faiLicense.hidden = true;
    columnsObject.civl.hidden = true;
    columnsObject.nationalLicense.hidden = true;
    columnsObject.club.hidden = true;
    columnsObject.team.hidden = true;
    columnsObject.shirt.hidden = true;
    columnsObject.discipline.hidden = true;
    columnsObject.ippi.hidden = true;
    columnsObject.ippiLicense.hidden = true;
    columnsObject.ippiActive.hidden = true;
    columnsObject.createdBy.hidden = true;
    columnsObject.photo.hidden = true;
    columnsObject.passportPhoto.hidden = true;
    columnsObject.level.hidden = true;
    columnsObject.validate.hidden = true;
    columnsObject.validFrom.hidden = true;
    columnsObject.validTo.hidden = true;
    columnsObject.localClub.hidden = true;
    columnsObject.events.render = (rowData) => !!rowData.events
      && Object.values(rowData.events).map((item) => (
        <MChip
          key={`${item}${rowData.id}`}
          id={`${item}${rowData.id}`}
          label={item}
          color="primary"
        />
      ));
    columnsObject.docType.render = (rowData) => (
      <>
        {DOC_TYPES[rowData.docType] ? DOC_TYPES[rowData.docType].label : rowData.docType}
      </>
    );
    columnsObject.licenseURL.title = 'Licencia Temporal';
    columnsObject.licenseURL.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          if (rowData.status !== LICENSE_STATUS.unrequired.value) {
            resolve((
              <LicensesButton pilot={rowData} />
            ));
          } else {
            resolve('No requerida');
          }
        })}
      />
    );
    // columnsObject.events.render = (rowData) => (
    //   <AsyncColumnRender
    //     loadFunction={() => new Promise((resolve) => {
    //       firebase.events().get.then((snapshot) => {
    //       })
    //       firebase.event(rowData.event).get().then((doc) => {
    //         const city = doc.data();
    //         if (city.department) {
    //           firebase.department(city.department).get().then((docDep) => {
    //             if (docDep.exists) {
    //               resolve(`${city.name} - ${docDep.data().name}`);
    //             } else {
    //               resolve(city.name);
    //             }
    //           });
    //         } else {
    //           resolve(city.name);
    //         }
    //       });
    //     })}
    //   />
    // );
    return {
      title: 'Listado de Pilotos',
      columns: Object.values(columnsObject),
    };
  }
}

export {
  DISCIPLINES,
  LEVEL,
};

export default new FieldsPilots(FIELDS);
