import React from 'react';
import { compose } from 'recompose';
import MPanel from '../../../../components/panel';
import ButtonAddObra from './buttonAdd';
import Fields from '../fields';
import ObraFields from './fields';
import ObrasList from './list';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';

const MAX_OBRAS = 3;

const PanelObras = ({
  artista, firebase, notification,
}) => {
  const [obras, setObras] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    const unsubscribe = firebase.obras(artista.id).onSnapshot((snap) => {
      const items = [];
      snap.forEach((doc) => items.push(ObraFields.getInstance(doc)));
      setDisabled(items.length >= MAX_OBRAS);
      setObras(items);
    }, () => notification.setMessage({
      type: 'warning', message: 'Ups! Algo no salió bien cargando las obras de este artista 😢.',
    }));
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MPanel
      withPaper
      title="Obras"
      titleBlock={(
        <ButtonAddObra artistaId={artista.id} disabled={disabled} />
      )}
    >
      <ObrasList artistaId={artista.id} obras={obras} />
    </MPanel>
  );
};

PanelObras.propTypes = {
  artista: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(PanelObras);
