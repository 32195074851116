import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import Fields from '../../fields';
import ProductFields from '../../Products/fields';
import { withAuthorization } from '../../../../model/Session';
import { isTranslator } from '../../../../components/access';
import { withFirebase, FirebasePropType } from '../../../../model/Firebase';
import SectionTranslate from './sectionTranslate';
import { MSelect } from '../../../../components/form';
import LabelItem from '../../../../components/form/labelItem';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';

export const TRANSLATE_STATUS = {
  pending: {
    label: 'Pendiente',
    value: 'pending',
  },
  partial: {
    label: 'Parcial',
    value: 'partial',
  },
  completed: {
    label: 'Completed',
    value: 'completed',
  },
};

const editions = {
  place: [
    {
      field: 'name',
      type: 'text',
      label: 'Nombre',
      englishLabel: 'Name',
      required: true,
    },
    {
      field: 'details',
      type: 'textarea',
      label: 'Descripción',
      englishLabel: 'Description',
      required: false,
    },
  ],
  product: [
    {
      field: 'section',
      type: 'text',
      label: 'Sección',
      englishLabel: 'Section',
      required: false,
    },
    {
      field: 'name',
      type: 'text',
      label: 'Nombre',
      englishLabel: 'Name',
      required: true,
    },
    {
      field: 'details',
      type: 'textarea',
      label: 'Descripción',
      englishLabel: 'Description',
      required: false,
    },
  ],
  story: [
    {
      field: 'story_title',
      type: 'text',
      label: 'Título de la historia',
      englishLabel: 'Story title',
      required: true,
    },
    {
      field: 'story',
      type: 'textarea',
      label: 'Historia Destacada',
      englishLabel: 'Highlighted Story',
      required: false,
    },
  ],
};

const Translate = ({
  place, firebase, products, notification,
}) => {
  const [references, setReferences] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const [editors, setEditors] = React.useState(null);
  React.useEffect(() => {
    const theReferences = [];
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!place) {
      theReferences.push({
        reference: firebase.place(place.id),
        object: place,
        objectName: 'place',
        editions: editions.place,
        label: 'Lugar/Negocio',
        value: place.name,
        index: 0,
      });

      if (place.story) {
        theReferences.push({
          reference: firebase.place(place.id),
          object: place,
          objectName: 'placeStory',
          editions: editions.story,
          label: 'Historia destacada',
          value: `historia${place.name}`,
          index: 1,
        });
      }

      const j = theReferences ? theReferences.length : 0;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!products) {
        const theProducts = products.sort((a, b) => a.index < b.index);
        theProducts.forEach((product, i) => {
          theReferences.push({
            reference: firebase.product(place.id, product.id),
            object: product,
            objectName: 'product',
            editions: editions.product,
            label: `Producto ${i + 1}`,
            value: product.name,
            index: i + j,
          });
        });
      }
      setReferences(theReferences);
      if (typeof index !== 'number' && theReferences && theReferences.length > 0) {
        setIndex(0);
      }
    }
  }, [place, products, firebase, index]);

  React.useEffect(() => {
    const theEditors = editors ? [...editors] : [];
    if (typeof index === 'number' && references && references[index] && !theEditors[index]) {
      theEditors[index] = (
        <Grid key={`translator${index}`} item xs={12}>
          <SectionTranslate
            object={references[index].object}
            title={references[index].label}
            editions={references[index].editions}
            reference={references[index].reference}
            objectName={references[index].objectName}
          />
        </Grid>
      );
    }
    setEditors(theEditors);
    // eslint-disable-next-line
  }, [index, references]);

  const selectReference = (res) => {
    setIndex(res.index);
  };

  const translateStatus = (res) => {
    if (res.value) {
      firebase.place(place.id).set({ translated: res.value }, { merge: true })
        .then(() => {
          notification.setMessage({
            message: `Se actualizó el estado de la traducción a: "${res.label}"`,
            type: 'success',
          });
        })
        .catch((err) => {
          notification.setMessage({
            message: 'Ups! Algo no salió bien cambiando el estado de la traducción 😅',
            type: 'warning',
          });
          console.warn(err.message);
        });
    }
  };

  const selector = !!references && typeof index === 'number' && (
    <>
      <Grid item xs={12} md={4}>
        <LabelItem label={references[index].label} value={references[index].value} />
        <MSelect
          setValue={selectReference}
          value={references[index]}
          noOptionsMessage="No hay datos para traducir"
          options={references}
          placeHolder="Seleccione un elemento para traducir"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LabelItem
          label="Estado traducción"
          value={place.translated ? TRANSLATE_STATUS[place.translated].label : 'N/D'}
        />
        <MSelect
          setValue={translateStatus}
          value={TRANSLATE_STATUS[place.translated]}
          noOptionsMessage="No se cargaron los estados disponibles"
          options={Object.values(TRANSLATE_STATUS)}
          placeHolder="Seleccione una opción"
        />
      </Grid>
    </>
  );

  let editor = null;
  if (typeof index === 'number' && !!editors) {
    editor = editors[index];
  }

  return (
    <>
      {selector}
      {editor}
    </>
  );
};

Translate.defaultProps = {
  place: null,
  products: null,
};

Translate.propTypes = {
  place: Fields.getPropTypes(),
  products: PropTypes.arrayOf(ProductFields.getPropTypes()),
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isTranslator),
  withNotification,
  withFirebase,
)(Translate);
