import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { MSelect } from '../form';
import {
  validateForm, getMinutes, getHours, getDays,
} from './util';

const PeriodField = ({
  // eslint-disable-next-line react/prop-types
  setValue, value, options,
}) => {
  const onChange = (newVal) => {
    setValue(newVal);
  };
  return (
    <MSelect
      withMargins={false}
      setValue={onChange}
      value={value}
      options={options}
    />
  );
};

const Period = ({ addPeriod }) => {
  const [startHour, setStartHour] = React.useState({ value: '', label: 'HH' });
  const [startMinute, setStartMinute] = React.useState({ value: '', label: 'mm' });
  const [endHour, setEndHour] = React.useState({ value: '', label: 'HH' });
  const [endMinute, setEndMinute] = React.useState({ value: '', label: 'mm' });
  const [day, setDay] = React.useState({ value: '', label: 'DD' });
  const [data, setData] = React.useState({ days: [], minutes: [], hours: [] });
  React.useEffect(() => {
    setData({
      days: getDays(),
      hours: getHours(),
      minutes: getMinutes(),
    });
  }, []);

  const validation = validateForm({
    day, startMinute, startHour, endMinute, endHour,
  });

  const submit = () => {
    const result = {
      start: `${startHour.label}:${startMinute.label}`,
      end: `${endHour.label}:${endMinute.label}`,
      day: day.value,
    };
    if (addPeriod) {
      addPeriod(result);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" component="p">
          Agregar periodo de tiempo de apertura
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <PeriodField options={data.days} value={day} setValue={setDay} />
      </Grid>
      <Grid item xs={3}>
        <PeriodField options={data.hours} value={startHour} setValue={setStartHour} />
      </Grid>
      <Grid item xs={3}>
        <PeriodField options={data.minutes} value={startMinute} setValue={setStartMinute} />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <PeriodField options={data.hours} value={endHour} setValue={setEndHour} />
      </Grid>
      <Grid item xs={3}>
        <PeriodField options={data.minutes} value={endMinute} setValue={setEndMinute} />
      </Grid>
      <Grid item xs={5}>
        <Button variant="outlined" color="primary" disabled={validation.invalid} onClick={submit}>
          Agregar
        </Button>
      </Grid>
      {!!validation.message && (
        <Grid item xs={7}>
          <Typography variant="body2" component="p" color="error">
            {validation.message}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

Period.defaultProps = {
  addPeriod: null,
};

Period.propTypes = {
  addPeriod: PropTypes.func,
};

export default Period;
