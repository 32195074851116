const RESTRICTED_UIDS = [
  '3kjh8VXe4qU4pUUlYnRHih67h592',
  'MzbKkJ8CdgRSxlzJd3eDgshvU3L2',
];

const ALLOWED_PLACES = [
  'ZzrONfZh6Fdo3AXtxYqV',
  'oUASI78pcTdV3OILclZU',
  'ddiMl9n7P0EM5vsiNXlD',
  'tntdxf2tw3M91uoDdWPv',
  'ns8Tjk5ZcOxrYCeu0hQ2',
];

const RESTRICTED_USERS = [
  'lvUYnvbINQZpwzDZdVY99QOyOBf2',
];

const isRestrictedUser = (reference, authUser, userUid) => reference === 'users'
  && RESTRICTED_UIDS.includes(authUser.uid)
  && RESTRICTED_USERS.includes(userUid);

const isRestrictedPlace = (authUser, placeId) => RESTRICTED_UIDS.includes(authUser.uid)
  && !ALLOWED_PLACES.includes(placeId);

const isInvitee = (authUser, inviteesObject) => {
  const invitees = inviteesObject ? Object.keys(inviteesObject) : [];
  return invitees.some((item) => item === authUser.uid);
};

const isRestrictedDate = (authUser, date) => RESTRICTED_UIDS.includes(authUser.uid)
    && (!ALLOWED_PLACES.includes(date.resource.place)
    || RESTRICTED_USERS.some((ru) => isInvitee({ uid: ru }, date.resource.invitees)));

export {
  isRestrictedPlace,
  isRestrictedUser,
  isRestrictedDate,
};
