import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { MForm, generateInputs } from '../../components/form';
import {
  withCurrentUser, AuthUserPropTypes,
} from '../../model/Session';
import { withFirebase, FirebasePropType } from '../../model/Firebase';
import Fields from './fields';
import { validateForm } from '../../components/validator';
import { withNotification, NotificationPropTypes } from '../../components/notification';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = () => ({
  fields: JSON.parse(JSON.stringify(Fields.fields)),
  notification: null,
  loading: false,
});

const assignDefaultValues = (state, defaultValues) => {
  if (defaultValues) {
    Object.keys(defaultValues).forEach((item) => {
      // eslint-disable-next-line
      state.fields[item].value = defaultValues[item];
    });
  }
  return state;
};

const ClientForm = ({
  defaultValues, firebase, authUser, isNew, notification, history,
}) => {
  const [state, setState] = React.useState({ ...INITIAL_STATE() });
  React.useEffect(() => {
    setState((prev) => ({
      ...assignDefaultValues(prev, defaultValues),
    }));
  }, [defaultValues]);

  const saveData = (clientReference, dataSet, successMessage, redirect) => {
    clientReference.set(dataSet, { merge: true })
      .then(() => {
        notification.setMessage({ message: successMessage, type: 'success' });
        setState({ ...INITIAL_STATE() });
        if (redirect) {
          history.push(redirect);
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
        setState((prev) => ({ ...prev, loading: false }));
      });
  };

  const onSubmit = () => {
    setState((prev) => ({ ...prev, loading: true }));
    const { fields } = state;
    const dataSet = {
      document: fields.document.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      phone: fields.phone.value,
      email: fields.email.value,
    };
    firebase.clients().where('document', '==', dataSet.document)
      .get()
      .then((snapShot) => {
        if ((isNew && !snapShot.empty)
          || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
          notification.setMessage({
            message: 'El documento indicado ya existe para otro cliente.',
            type: 'warning',
          });
        } else {
          const clientReference = isNew ? firebase.clients().doc()
            : firebase.client(fields.id.value);
          if (isNew) {
            dataSet.createdBy = authUser.uid;
            saveData(clientReference, dataSet, 'Se creó el nuevo cliente 😀', ROUTES.CLIENTES);
          } else {
            saveData(clientReference, dataSet, 'Se modificó el cliente 😀', ROUTES.CLIENTES);
          }
        }
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
        notification.setMessage({ message: e.message, type: 'error' });
      });
  };

  const onChange = (result) => {
    const { fields } = state;
    fields[result.id].value = result.value;
    fields[result.id].error = !result.isValid;
    setState((prev) => ({
      ...prev,
      fields,
    }));
  };

  const { fields, loading } = state;
  const isInvalid = !validateForm(fields);

  return (
    <MForm
      onSubmit={onSubmit}
      loading={loading}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Cliente`,
        disabled: isInvalid,
      }}
    >
      {generateInputs(fields, onChange)}
    </MForm>
  );
};

ClientForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

ClientForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withCurrentUser,
  // withAuthorization(isCoordinator),
  withFirebase,
  withRouter,
  withNotification,
)(ClientForm);
