import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

const Badge = () => {
  const classes = useStyle();
  return (
    <Box className={classes.box}>
      <img
        src="https://github.com/gurzaf/Maclic-Web/workflows/Build%20and%20Deploy/badge.svg"
        alt="Maclic Web Status"
      />
    </Box>
  );
};

export default Badge;
