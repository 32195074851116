import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Offline } from 'react-detect-offline';

const useStyles = makeStyles((theme) => {
  const root = {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
  };
  return {
    overlay: {
      ...root,
      zIndex: 10000,
      backgroundColor: 'black',
      opacity: 0.8,
    },
    root: {
      ...root,
      zIndex: 10001,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
    },
    container: {
      display: 'table',
      margin: '0 auto',
      textAlign: 'center',
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      color: 'white',
      textShadow: '1px 1px 3px #000',
      textTransform: 'uppercase',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(12),
      color: 'white',
    },
  };
});

const WarningTab = () => {
  const classes = useStyles();
  return (
    <Offline>
      <div className={classes.overlay} />
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography variant="h6" className={classes.title} component="h2">
            {'Ups! Ya abriste Maclic en otra pestaña '}
            <span role="img" aria-label="Carita sonriendo">😅</span>
          </Typography>
          <Typography variant="body2" className={classes.subtitle} component="p">
            Mientras no estés conectado a Internet, la consola de administración de Maclic
            <br />
            sólo funciona en una pestaña de tu navegador mientras no estés conectado a Internet.
          </Typography>
        </div>
      </div>
    </Offline>
  );
};

export default WarningTab;
