import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { TYPES, validate } from '../validator';
// eslint-disable-next-line import/no-cycle
import { getAddButton } from './AddForm';

const styles = {
  root: {
    display: 'flex',
  },
};

const MTextField = ({
  value: theValue,
  error: theError,
  onEnter,
  validationType,
  required,
  id,
  onChange,
  autoComplete,
  classes,
  addNew,
  validationMessage,
  ...props
}) => {
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState('');
  React.useEffect(() => {
    setError(!!theError);
  }, [theError]);
  React.useEffect(() => {
    setValue(theValue);
  }, [theValue]);

  const onKeyUp = (event) => {
    if (onEnter && event.key === 'Enter') {
      onEnter();
    }
  };

  const onChangeEvent = (event) => {
    const { value: val } = event.target;
    const result = {
      id,
      value: val,
    };
    if (!validationType) {
      onChange(result);
      return;
    }
    const validation = validate({
      id,
      value: theValue,
      validationType,
      required,
    });
    onChange({
      ...result,
      ...validation,
    });
  };
  const complete = autoComplete ? id : 'off';
  return (
    <>
      <div className={classes.root}>
        <TextField
          required={required}
          onChange={onChangeEvent}
          onBlur={onChangeEvent}
          onKeyUp={onKeyUp}
          error={error}
          id={id}
          name={id}
          value={value}
          autoComplete={complete}
          {...props}
        />
        {!!addNew && getAddButton(addNew.label, addNew.action, addNew.disabled)}
      </div>
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </>
  );
};

MTextField.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  required: false,
  fullWidth: true,
  autoFocus: false,
  autoComplete: false,
  type: 'text',
  onChange: null,
  onEnter: null,
  error: false,
  validationType: null,
  validationMessage: null,
  multiline: false,
  disabled: false,
  addNew: null,
};

MTextField.propTypes = {
  variant: PropTypes.string,
  margin: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf(Object.values(TYPES)),
  validationMessage: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  addNew: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MTextField);
