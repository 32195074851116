import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isCoordinator } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import NewClient from './newClient';
import EditClient from './editClient';
import TableClients from './table';

const ClientPage = () => (
  <Switch>
    <Route path={`${ROUTES.CLIENTES}/new`} component={NewClient} />
    <Route path={`${ROUTES.CLIENTES}/edit/:document`} component={EditClient} />
    <Route path={ROUTES.CLIENTES} component={TableClients} />
  </Switch>
);

export default withAuthorization(isCoordinator)(ClientPage);
