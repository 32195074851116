// import React from 'react';
import FieldsTemplate from '../../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsDepartamento extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults() {
    const columnsObject = this.columnsTable();
    columnsObject.id.hidden = true;
    return {
      title: 'Listado de Departamentos',
      columns: Object.values(columnsObject),
    };
  }
}

export default new FieldsDepartamento(FIELDS);
