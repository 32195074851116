import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import Fields from './fields';
import { validateForm } from '../../../components/validator';
import { generateInputs, MForm } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { isAuthor } from '../../../components/access';
import { changeAction, getRandomString } from '../../../lib/misc';
import * as ROUTES from '../../../constants/routes';

const saveData = (notification, productReference, dataSet, successMessage, endSubmit) => {
  productReference.set(dataSet, { merge: true })
    .then(() => {
      notification.setMessage({ message: successMessage, type: 'success' });
      endSubmit(false, true);
    })
    .catch((e) => {
      notification.setMessage({ message: e.message, type: 'error' });
      endSubmit(false);
    });
};

const uploadFile = (place, id, file, firebase, setUploaded) => new Promise((resolve, reject) => {
  const productPath = id === '' ? 'new' : id;
  const timeStamp = new Date().getTime();
  const filePath = `/images/places/${place}/products/${productPath}/${timeStamp}-${file.name}`;
  firebase.uploadFile(filePath, file, (progress) => {
    setUploaded(progress.value);
  }, (err, url) => {
    if (err) {
      setUploaded(-1);
      reject(err);
    }
    setUploaded(100);
    resolve({ url, reference: filePath });
  });
});

const persistProduct = (
  firebase, isNew, fields, place, authUser, dataSet, notification, endSubmit,
) => {
  const productReference = isNew ? firebase.products(place).doc()
    : firebase.product(place, fields.id.value);
  let successMessage = 'Se modificó el producto/servicio 😀';
  if (isNew) {
    // eslint-disable-next-line no-param-reassign
    dataSet.createdBy = authUser.uid;
    successMessage = 'Se creó el nuevo producto/servicio 😀';
  }
  saveData(notification, productReference, dataSet, successMessage, endSubmit);
};

const cleanFields = (newFields, setFields) => {
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].value = '';
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].error = false;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].file = null;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photoURL.id].value = '';
  setFields(newFields);
};

const ProductForm = ({
  notification, productValues, authUser, firebase, place, history,
}) => {
  const [fields, setFields] = React.useState({});
  const [key, setKey] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);

  const removeImage = React.useCallback((newFields) => {
    setLoading(true);
    if (productValues && newFields[Fields.fields.photoURL.id].value !== '') {
      firebase.deleteFile(newFields[Fields.fields.photo.id].value)
        .then(() => {
          firebase.product(place, productValues.id)
            .set({ photo: '', photoURL: '' }, { merge: true })
            .then(() => {
              notification.setMessage({ type: 'success', message: 'Se quito correctamente la imagen.' });
              cleanFields(newFields, setFields);
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
              setLoading(false);
            });
        })
        .catch((e) => {
          notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
          setLoading(false);
          console.error(e);
        });
    } else {
      cleanFields(newFields, setFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(Fields.fields));
    if (productValues) {
      setKey(productValues.id);
      Object.keys(productValues).forEach((item) => {
        newFields[item].value = productValues[item];
      });
      if (typeof newFields[Fields.fields.photo.id].value !== 'string') {
        newFields[Fields.fields.photo.id].value = '';
        newFields[Fields.fields.photoURL.id].value = '';
      }
      newFields[Fields.fields.photo.id].onRemove = removeImage;
    } else {
      setKey(getRandomString());
    }
    setFields(newFields);
  }, []); // eslint-disable-line

  const isInvalid = !validateForm(fields);

  const endSubmit = React.useCallback((load, success) => {
    if (success) {
      history.push(`${ROUTES.PLACES}/detail/${place}/products`);
    } else {
      setLoading(load);
    }
  }, [history, place]);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      endSubmit(false);
    } else {
      const dataSet = {
        name: fields.name.value,
        details: fields.details.value,
        section: fields.section.value,
        price: parseInt(fields.price.value, 10),
        priceTo: parseInt(fields.priceTo.value, 10),
        priceFixed: fields.priceFixed.value,
        category: fields.category.value,
        published: fields.published.value,
      };
      const isNew = !fields.id.value;
      firebase.products(place)
        .where('name', '==', dataSet.name)
        .get()
        .then((snapShot) => {
          if ((isNew && !snapShot.empty)
            || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
            notification.setMessage({
              message: 'Ya existe otro producto/servicio para este lugar/negocio con este nombre.',
              type: 'warning',
            });
            endSubmit(false, false);
          } else {
            if (fields.photo.value === '' || fields.photoURL.value !== '') {
              persistProduct(firebase, isNew, fields, place,
                authUser, dataSet, notification, endSubmit);
              return;
            }
            uploadFile(place, fields.id.value, fields.photo.file, firebase, setUploaded)
              .then((image) => {
                dataSet[Fields.fields.photo.id] = image.reference;
                dataSet[Fields.fields.photoURL.id] = image.url;
                persistProduct(firebase, isNew, fields, place,
                  authUser, dataSet, notification, endSubmit);
              })
              .catch((err) => {
                endSubmit(false, false);
                notification.setMessage({ message: err.message, type: 'error' });
              });
          }
        })
        .catch((e) => {
          endSubmit(false, false);
          notification.setMessage({ message: e.message, type: 'error' });
        });
    }
  }, [authUser, endSubmit, fields, firebase, isInvalid, notification, place]);

  const onChange = (result) => changeAction(result, fields, setFields);

  return (
    <Grid item xs={12}>
      <MForm
        loading={loading}
        button={{ label: 'Guardar Producto/Servicio', disabled: isInvalid }}
        uploaded={uploaded}
        onSubmit={submitAction}
      >
        {generateInputs(fields, onChange, key)}
      </MForm>
    </Grid>
  );
};

ProductForm.defaultProps = {
  productValues: null,
};

ProductForm.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  productValues: Fields.getPropTypes(),
  place: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withNotification,
  withCurrentUser,
  withRouter,
)(ProductForm);
