import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/dashboard/container';
import { isEditor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import BusinessForm from './businessForm';
import * as ROUTES from '../../constants/routes';

const NewClient = () => (
  <Container backPath={ROUTES.PLACES} title="Nuevo Negocio/Lugar">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <BusinessForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isEditor)(NewClient);
