import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import ClubForm from './clubForm';
import * as ROUTES from '../../../constants/routes';

const NewClub = () => (
  <Container backPath={ROUTES.CLUBES} title="Nuevo Club">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <ClubForm />
      </Grid>
    </Grid>
  </Container>
);

export default withAuthorization(isAeroAdmin)(NewClub);
