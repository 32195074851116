import React, { Component } from 'react';
import { compose } from 'recompose';
import FIELDS from '../Account/fields';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import withAuthorization from '../../model/Session/withAuthorization';
import { validateForm } from '../../components/validator';
import { generateInputs, MForm } from '../../components/form';

const INITIAL_STATE = {
  fields: FIELDS.fields,
  loading: false,
};

const passwordMatch = fields => fields.password.value === fields.passwordTwo.value;

const validatePasswordForm = fields => validateForm(fields) && passwordMatch(fields);

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit() {
    const { fields } = this.state;
    const { firebase, notification } = this.props;
    this.setState({
      loading: true,
    });
    firebase
      .doPasswordUpdate(fields.password.value)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        notification.setMessage({ message: 'Se cambió la contraseña exitosamente 🙂', type: 'success' });
      })
      .catch(() => {
        notification.setMessage({ message: 'Algo no salió bien cambiando la contraseña 😰', type: 'error' });
      })
      .finally(() => this.setState({ loading: false }));
  }

  onChange(result) {
    const { fields } = this.state;
    fields[result.id].value = result.value;
    fields[result.id].error = !result.isValid;
    if (result.id === 'passwordTwo') {
      fields[result.id].error = !passwordMatch(fields);
    }
    this.setState({
      fields,
    });
  }

  render() {
    const { fields, loading } = this.state;
    const isInvalid = !validatePasswordForm(fields);
    return (
      <MForm
        onSubmit={this.onSubmit}
        button={{
          label: 'Cambiar Contraseña',
          disabled: isInvalid,
        }}
        loading={loading}
      >
        {generateInputs(fields, this.onChange)}
      </MForm>
    );
  }
}

PasswordChangeForm.propTypes = {
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
};

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  withNotification,
)(PasswordChangeForm);
