import React from 'react';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import PanelBusiness from '../panelBusiness';
import { PanelClient } from '../../Clients';
import Fields from '../fields';
import MPanel from '../../../components/panel';

const General = ({ place }) => (
  <React.Fragment>
    <Grid item xs={12} md={6}>
      <MPanel title="Detalles del Lugar/Negocio" withPaper>
        <PanelBusiness placeId={place} showTitle={false} />
      </MPanel>
    </Grid>
    <Grid item xs={12} md={6}>
      <MPanel title="Cliente/Propietario" withPaper>
        <PanelClient clientId={place ? place.client : null} showTitle={false} />
      </MPanel>
    </Grid>
  </React.Fragment>
);

General.defaultProps = {
  place: null,
};

General.propTypes = {
  place: Fields.getPropTypes(),
};

General.section = '';
General.label = 'General';
General.icon = InfoIcon;

export default General;
