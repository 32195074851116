import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';
import { SectionTitle } from '../../../../components/title';
import MTextField from '../../../../components/form/mTextField';
import EditorBusinessDetailes from '../../Editors/editor';
import MButton from '../../../../components/form/mButton';
import { MForm } from '../../../../components/form';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  column: {
    padding: theme.spacing(1),
  },
  editor: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: '5px',
  },
  labelEditor: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
  },
}));

const SectionField = ({
  field, id, handleChange, keyStorage,
}) => {
  const classes = useStyles();
  if (!field || !id) {
    return null;
  }
  if (field.type === 'text') {
    return (
      <MTextField
        id={field.id}
        label={field.label}
        value={field.value || ''}
        onChange={handleChange}
        required={field.required}
      />
    );
  }
  const place = { id };
  place[field.id] = field.value;
  const changeEvent = (res) => {
    const result = { id: field.id };
    result.value = res[field.id];
    handleChange(result);
  };
  return (
    <div key={keyStorage}>
      <span className={classes.labelEditor}>{field.label}</span>
      <div className={classes.editor}>
        <EditorBusinessDetailes
          readOnly={false}
          place={place}
          keyStorage={keyStorage}
          changeEvent={changeEvent}
          property={field.id}
        />
      </div>
    </div>
  );
};

SectionField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  keyStorage: PropTypes.string,
};

SectionField.defaultProps = {
  handleChange: (res) => {},
  field: null,
  id: null,
  keyStorage: null,
};

const SectionTranslate = ({
  reference, editions, object, notification, title, objectName,
}) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const theData = [];
    editions.forEach((item) => {
      const enField = `${item.field}_en`;
      let enValue = object[enField];
      if (objectName === 'placeStory' && (!enValue || enValue.trim() === '')) {
        enValue = object[item.field];
      }
      theData.push({
        id: item.field,
        type: item.type,
        value: object[item.field],
        label: item.label,
        required: item.required,
      });
      theData.push({
        id: enField,
        type: item.type,
        value: enValue,
        label: item.englishLabel,
        required: false,
      });
    });
    setData(theData);
  }, [editions, object, objectName]);

  const submitEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    const finalData = {};
    data.forEach((item) => {
      if (!!item.value && item.value !== '') {
        finalData[item.id] = item.value;
      }
    });
    reference.set(finalData, { merge: true })
      .then(() => {
        notification.setMessage({ type: 'success', message: 'Se guardó correctamente la traducción 😁' });
      })
      .catch((e) => {
        notification.setMessage({ type: 'error', message: 'Ups... algo no salió bien guardando la traducción 😣' });
        // eslint-disable-next-line no-console
        console.warn(e.message);
      })
      .finally(() => setLoading(false));
  };

  const changeDataEvent = (res) => {
    const theData = [...data];
    let invalid = false;
    theData.forEach((item, index) => {
      if (item.id === res.id) {
        theData[index].value = res.value;
      }
      if (item.required && (!item.value || item.value === '')) {
        invalid = true;
      }
    });
    setDisabled(invalid);
    setData(theData);
  };

  return !!data && (
    <Paper className={classes.paper}>
      <SectionTitle title={title} marginBottom />
      <MForm loading={loading} onSubmit={submitEvent}>
        <Grid container>
          {data.map((element) => !!element && (
            <Grid key={element.id} item xs={12} md={6} className={classes.column}>
              <SectionField
                id={object.id}
                field={element}
                handleChange={changeDataEvent}
                keyStorage={`translate-${objectName}-${object.id}`}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <MButton
              mt={2}
              fullWidth={false}
              disabled={disabled}
              type="submit"
              label="Guardar/Save"
            />
          </Grid>
        </Grid>
      </MForm>
    </Paper>
  );
};

SectionTranslate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  reference: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object.isRequired,
  objectName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editions: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    type: PropTypes.oneOf(['text', 'textarea']),
    label: PropTypes.string,
    englishLabel: PropTypes.string,
    required: PropTypes.bool,
  })).isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default withNotification(SectionTranslate);
