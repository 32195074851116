/* eslint-disable react/prop-types */
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { withTheme } from '@material-ui/core';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import Loading from '../loading';

const Image = ({
  attributes, node, isSelected, readOnly, theme, editor,
}) => {
  const [hasError, setHasError] = React.useState(false);

  const handleClick = (e) => e.preventDefault();

  const handleKeyDown = (ev) => {
    if (ev.key === 'Enter' || ev.key === 'ArrowDown') {
      ev.preventDefault();
      return editor
        .moveToRangeOfNode(node)
        .moveToStartOfNextBlock()
        .focus();
    }
    return null;
  };

  const handleChange = (ev) => {
    ev.stopPropagation();
    const alt = ev.target.value;
    const data = node.data.toObject();
    editor.setNodeByKey(node.key, { data: { ...data, alt } });
  };

  const handleError = () => {
    setHasError(true);
  };

  const isLoading = node.data.get('loading');
  const caption = node.data.get('alt') || '';
  const src = node.data.get('src');
  const showCaption = !readOnly || caption;

  let imgComponent = null;

  if (isLoading) {
    imgComponent = <Loading message="Cargando imagen..." size={12} variant="body1" />;
  } else {
    imgComponent = !readOnly ? (
      <StyledImg
        {...attributes}
        src={src}
        alt={caption}
        isSelected={isSelected}
        isLoading={isLoading}
        loading="lazy"
        theme={theme}
      />
    ) : (
      <Zoom>
        <img
          src={src}
          alt={caption}
          style={{ maxWidth: '100%' }}
          {...attributes}
        />
      </Zoom>
    );
  }

  return (
    <CenteredImage contentEditable={false}>
      {hasError ? (
        <>
          <ErrorImg as="div" isSelected={isSelected} />
          <ErrorMessage>Could not load image</ErrorMessage>
        </>
      ) : (
        <>
          <HiddenImg src={src} onError={handleError} />
          {imgComponent}
          {showCaption && (
            <Caption
              type="text"
              placeholder="Write a caption"
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onClick={handleClick}
              defaultValue={caption}
              contentEditable={false}
              disabled={readOnly}
              tabIndex={-1}
              async
            />
          )}
        </>
      )}
    </CenteredImage>
  );
};

const ErrorMessage = styled.div`
  position: absolute;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;

  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.imageErrorBackground};
  display: block;
  margin: 0 auto;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
`;

const Img = (props) => <Imgix {...props} sizes="80vw" />;

const HiddenImg = styled(Img)`
  display: none;
`;

const StyledImg = styled(Img)`
  max-width: 600px;
  max-height: 300px;
  width: 100%;
  box-shadow: ${(props) => (props.isSelected ? `0 0 0 2px ${props.theme.palette.primary.main}` : 'none')};
  border-radius: ${(props) => (props.isSelected ? '2px' : '0')};
  opacity: ${(props) => (props.isLoading ? 0.5 : 1)};
`;

const ErrorImg = styled(StyledImg)`
  width: 200px;
  height: 100px;
  margin: 0 auto;
`;

const CenteredImage = styled.span`
  display: block;
  text-align: center;
  position: relative;
`;

const Caption = styled(TextareaAutosize)`
  border: 0;
  display: block;
  font-size: 13px;
  font-style: italic;
  color: ${(props) => props.theme.textSecondary};
  padding: 2px 0;
  line-height: 16px;
  text-align: center;
  width: 100%;
  outline: none;
  background: none;
  resize: none;
  &::placeholder {
    color: ${(props) => props.theme.placeholder};
  }
`;

export default withTheme(Image);
