import { TYPES } from '../../components/validator';

const FIELDS = {
  email: {
    id: 'email',
    label: 'Correo Electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: true,
  },
  password: {
    id: 'password',
    label: 'Contraseña',
    value: '',
    type: 'password',
    validationType: TYPES.PASSWORD,
    validationMessage: 'Débe indicar su contraseña.',
    required: true,
  },

};

export default FIELDS;
