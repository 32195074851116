// import React from 'react';
import FieldsTemplate from '../../lib/FieldsTemplate';
import { RenderPhone, TableRenderEmail } from '../../components/table';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  document: {
    id: 'document',
    label: 'Número de documento',
    value: '',
    type: 'number',
    validationType: TYPES.DOCUMENT,
    validationMessage: 'Número de documento no válido.',
    required: true,
  },
  firstName: {
    id: 'firstName',
    label: 'Nombres',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  lastName: {
    id: 'lastName',
    label: 'Apellidos',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Apellido no válido',
    required: true,
  },
  phone: {
    id: 'phone',
    label: 'Teléfono',
    value: '',
    type: 'text',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: false,
  },
  email: {
    id: 'email',
    label: 'Correo Electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: false,
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  createdByUser: {
    id: 'createdByUser',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsClient extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults() {
    const columnsObject = this.columnsTable();

    columnsObject.id.hidden = true;
    columnsObject.phone.render = RenderPhone('phone');
    columnsObject.email.render = TableRenderEmail('email');
    columnsObject.createdBy.hidden = true;
    // columnsObject.createdBy.render = rowData => (
    //   <AsyncColumnRender
    //     loadFunction={() => new Promise((resolve) => {
    //       firebase.user(rowData.createdBy)
    //         .get()
    //         .then(doc => resolve(doc.data().username));
    //     })}
    //   />
    // );
    return {
      title: 'Listado de Clientes',
      columns: Object.values(columnsObject),
    };
  }
}

export default new FieldsClient(FIELDS);
