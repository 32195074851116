import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAuthorOrTranslator } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import New from './new';
import Edit from './edit';
import ListStories from './List';
import Translate from './Translate';

const StoriesPage = () => (
  <Switch>
    <Route path={`${ROUTES.STORIES}/new`} component={New} />
    <Route path={`${ROUTES.STORIES}/detail/:id/edit`} component={Edit} />
    <Route path={`${ROUTES.STORIES}/detail/:id/translate`} component={Translate} />
    <Route path={`${ROUTES.STORIES}`} component={ListStories} />
  </Switch>
);

export default withAuthorization(isAuthorOrTranslator)(StoriesPage);
