/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';

const useStyles = (withMargins) => makeStyles((theme) => ({
  root: {
    margin: withMargins ? theme.spacing(3, 0, 2) : theme.spacing(0),
    display: 'flex',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    margin: theme.spacing(2, 0, 1),
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
}));

function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

function Control(props) {
  const {
    selectProps, innerRef, children, innerProps,
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const {
    innerRef, isFocused, isSelected, innerProps, children,
  } = props;
  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

Option.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/require-default-props
  isFocused: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer({ selectProps, children }) {
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

ValueContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function MultiValue({
  children, selectProps, isFocused, removeProps,
}) {
  return (
    <Chip
      tabIndex={-1}
      label={children}
      color="primary"
      className={clsx(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  isFocused: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  removeProps: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function Menu({ selectProps, innerProps, children }) {
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

Menu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

function MMultiSelect({
  setValue, value, options, noOptionsMessage, addButton, label, withMargins, disabled,
}) {
  const classes = useStyles(withMargins)();
  const theme = useTheme();

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <div style={{ width: '100%' }}>
          <Select
            classes={classes}
            styles={selectStyles}
            options={options}
            components={components}
            value={value}
            onChange={setValue}
            noOptionsMessage={() => noOptionsMessage}
            placeholder={label}
            isMulti
            isDisabled={disabled}
          />
        </div>
      </NoSsr>
      {addButton}
    </div>
  );
}

MMultiSelect.defaultProps = {
  value: [],
  options: [],
  addButton: null,
  noOptionsMessage: 'Sin opciones.',
  withMargins: true,
  disabled: false,
};

MMultiSelect.propTypes = {
  setValue: PropTypes.func.isRequired,
  withMargins: PropTypes.bool,
  label: PropTypes.string.isRequired,
  addButton: PropTypes.node,
  value: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  noOptionsMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  disabled: PropTypes.bool,
};

export default MMultiSelect;
