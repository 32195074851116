import React from 'react';
import { compose } from 'recompose';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { AuthUserPropTypes, withCurrentUser } from '../../../model/Session';
import Fields from '../fields';
import EditorBusinessDetailes from './editor';
import { isEditorExtrict } from '../../../components/access';
import Loading from '../../../components/loading';
import { SectionTitle } from '../../../components/title';

const isReadOnly = (place, authUser) => !(isEditorExtrict(authUser)
  || (place && place.editor === authUser.uid));

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DescriptionPanel = ({ place, firebase, authUser }) => {
  const readOnly = isReadOnly(place, authUser);
  const classes = useStyles();

  const handleUpdate = (newValue) => {
    if (place) {
      firebase.place(place.id)
        .set(newValue, { merge: true });
    }
  };

  const editorDetails = place ? (
    <EditorBusinessDetailes
      readOnly={readOnly}
      place={place}
      changeEvent={handleUpdate}
      property={Fields.fields.details.id}
    />
  ) : (
    <Loading size={20} variant="h6" />
  );

  return (
    <Paper className={classes.paper}>
      <SectionTitle title="Descripción del Lugar/Negocio" marginBottom />
      {editorDetails}
    </Paper>
  );
};

DescriptionPanel.defaultProps = {
  place: null,
};

DescriptionPanel.propTypes = {
  place: Fields.getPropTypes(),
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(DescriptionPanel);
