import React from 'react';
import { debounce } from 'lodash';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Editor, { theme } from 'rich-markdown-editor';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SectionTitle } from '../title';
import { TYPES, validate } from '../validator';
import Image from './image';
import { domain } from '../../model/Images';

const getReferenceFromURL = (url) => url.replace(domain, '/images');

const useStyles = makeStyles((thm) => ({
  paper: {
    padding: thm.spacing(3, 3, 3, 6),
  },
  withoutPaper: {
    padding: thm.spacing(2),
    border: '1px solid #c4c4c4',
    borderRadius: 5,
    '&:hover': {
      borderColor: 'black',
    },
    '&:focus, &:active': {
      borderColor: thm.palette.primary.main,
    },
  },
  readOnly: {
    padding: thm.spacing(2),
  },
  label: {
    marginTop: '-34px',
    fontSize: '12.5px',
    display: 'table',
    background: 'white',
    padding: thm.spacing(0, 0.5),
    marginLeft: thm.spacing(-0.5),
    marginBottom: thm.spacing(1),
  },
}));

const renderNode = (props, editor, next) => {
  if (props.node.type === 'image') {
    return <Image {...props} />;
  }
  return next();
};

const MEditor = ({
  value, readOnly, onChange, withPaper, title, keyStorage, deleteImage,
  id, required, validationType, error, validationMessage, uploadImage,
}) => {
  const classes = useStyles();

  const [theError, setTheError] = React.useState(error);
  React.useEffect(() => {
    setTheError(error);
  }, [error]);

  const handleKeyDown = (event, editor, next) => {
    if (deleteImage && (event.key === 'Backspace' || event.key === 'Delete')) {
      const { startBlock } = editor.value;
      if (!startBlock) {
        next();
        return;
      }
      if (startBlock.type === 'image') {
        const src = startBlock.data.get('src');
        if (src) {
          deleteImage(getReferenceFromURL(src))
            // .then(() => console.log('DELETED: ', src))
            .catch((e) => console.error(e));
        }
      }
    }
    next();
  };

  const handleChange = debounce((getValue) => {
    const tempValue = getValue();
    // eslint-disable-next-line
    localStorage.setItem(keyStorage, tempValue);
    const result = {
      id,
      value: tempValue,
    };
    if (!validationType) {
      onChange(result);
      return;
    }
    const validation = validate({
      id,
      value: tempValue,
      validationType,
      required,
    });
    onChange({
      ...result,
      ...validation,
    });
  }, 1000);

  // eslint-disable-next-line
  const defaultValue = value || (localStorage.getItem(keyStorage) || '');

  let titleComponent = null;

  if (title) {
    if (withPaper) {
      titleComponent = <SectionTitle marginBottom title={title} />;
    } else {
      titleComponent = (
        <Typography
          component="span"
          variant="body1"
          className={classes.label}
        >
          {title}
        </Typography>
      );
    }
  }

  const editor = (
    <>
      {titleComponent}
      <Editor
        key={keyStorage}
        readOnly={readOnly}
        defaultValue={defaultValue}
        theme={{ ...theme, zIndex: 2000 }}
        placeholder="Escribe algo lindo..."
        onSave={() => {}}
        onCancel={() => {}}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        uploadImage={uploadImage}
        renderNode={renderNode}
      />
      {!!theError && !!validationMessage && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </>
  );

  if (withPaper) {
    return (
      <Paper className={classes.paper}>
        {editor}
      </Paper>
    );
  }

  return (
    <div className={readOnly ? classes.readOnly : classes.withoutPaper}>
      {editor}
    </div>
  );
};

MEditor.defaultProps = {
  value: '',
  readOnly: false,
  onChange: null,
  withPaper: false,
  title: null,
  error: false,
  validationType: null,
  validationMessage: null,
  required: false,
  uploadImage: null,
  deleteImage: null,
};

MEditor.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]),
  validationMessage: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  withPaper: PropTypes.bool,
  title: PropTypes.string,
  keyStorage: PropTypes.string.isRequired,
  uploadImage: PropTypes.func,
  deleteImage: PropTypes.func,
};

export default MEditor;
