import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/AddCircle';
// import { MSelect } from '../../components/form';
import Select from '@material-ui/core/Select';
import { DATE_STATUS } from './fields';
import DialogNewEvent from './dialogNewEvent';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  container: {
    display: 'block',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  button: {
    float: 'right',
    display: 'table',
    marginTop: theme.spacing(1),
  },
}));

const options = [
  { value: 'all', label: 'Todas' },
  ...Object.values(DATE_STATUS),
];

const optionsTypes = [
  { value: 'all', label: 'Todas' },
  { value: 'mine', label: 'Mis Citas' },
];

const FilterDateStatus = ({
  changeStatus, status, types, changeTypes, showLabel, showButton, place,
}) => {
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => changeStatus(event.target.value);
  const handleChangeTypes = event => changeTypes(event.target.value);

  const [newEvent, setNewEvent] = React.useState(false);
  const preloadedValue = !!place && {
    key: 'place',
    value: place,
  };

  const selector = (
    <FormControl required={false} variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor="outlined-date-status-simple">
        Estado de Cita
      </InputLabel>
      <Select
        value={status}
        onChange={handleChange}
        input={<OutlinedInput labelWidth={labelWidth} name="date-status" id="outlined-date-status-simple" />}
      >
        {options.map(option => (
          <MenuItem key={`option${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const selectorTypes = !!types && !!changeTypes && (
    <FormControl required={false} variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor="outlined-date-type-simple">
        Tipo de cita
      </InputLabel>
      <Select
        value={types}
        onChange={handleChangeTypes}
        input={<OutlinedInput labelWidth={labelWidth} name="date-status" id="outlined-date-type-simple" />}
      >
        {optionsTypes.map(option => (
          <MenuItem key={`types${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <div className={classes.container}>
      {!!showLabel && (
        <Typography display="inline" component="span" variant="overline">
          Filtrar citas por:
        </Typography>
      )}
      <div className={classes.root}>
        {selector}
      </div>
      {!!selectorTypes && (
        <div className={classes.root}>
          {selectorTypes}
        </div>
      )}
      {!!showButton && (
        <div className={classes.button}>
          <Button
            component="button"
            color="primary"
            variant="outlined"
            size="large"
            onClick={() => setNewEvent(true)}
          >
            <AddIcon />
          </Button>
        </div>
      )}
      {!!newEvent && (
        <DialogNewEvent
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          preloadedValue={preloadedValue}
        />
      )}
    </div>
  );
};

FilterDateStatus.defaultProps = {
  changeTypes: null,
  types: null,
  showButton: false,
  showLabel: true,
  place: null,
};

FilterDateStatus.propTypes = {
  changeStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  changeTypes: PropTypes.func,
  types: PropTypes.string,
  showLabel: PropTypes.bool,
  showButton: PropTypes.bool,
  place: PropTypes.string,
};

export default FilterDateStatus;
