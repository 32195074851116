import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import NewDepartment from './newDepartment';
import EditDepartment from './editDepartment';
import NewCity from './newCity';
import EditCity from './editCity';
import TableDepartments from './tableDepartments';
import TableCities from './table';

const CiudadPage = () => (
  <Switch>
    <Route path={`${ROUTES.DEPARTAMENTOS}/new`} component={NewDepartment} />
    <Route path={`${ROUTES.DEPARTAMENTOS}/edit/:id`} component={EditDepartment} />
    <Route path={ROUTES.DEPARTAMENTOS} component={TableDepartments} />
    <Route path={`${ROUTES.CIUDADES}/new`} component={NewCity} />
    <Route path={`${ROUTES.CIUDADES}/edit/:id`} component={EditCity} />
    <Route path={ROUTES.CIUDADES} component={TableCities} />
  </Switch>
);

export default withAuthorization(isAeroAdmin)(CiudadPage);
