import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import LabelItem from '../../../components/labelItem';
import MDialog from '../../../components/mdialog';
import Fields, { DISCIPLINAS } from './fields';
import Youtube from '../../../components/video/youtube';

const ExpresionDetails = ({ expresion, isOpen, handleClose }) => (
  <MDialog
    open={isOpen}
    keepMounted={false}
    title={expresion.title}
    handleClose={handleClose}
  >
    <Youtube id={expresion.show} start={expresion.start} />
    <Divider style={{ marginTop: '16px' }} />
    {!!expresion.category && (
      <LabelItem label="Categoría" value={DISCIPLINAS[expresion.category].label} />
    )}
  </MDialog>
);

ExpresionDetails.propTypes = {
  expresion: Fields.getPropTypes().isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ExpresionDetails;
