import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Loading from '../../../components/loading';
import { PanelProducts, Fields as ProductFields } from '../Products';
import Fields from '../fields';

const Products = ({ products, loadingProducts, place }) => {
  if (products) {
    return (
      <Grid item xs={12}>
        {loadingProducts ? (
          <Loading />
        ) : (
          <PanelProducts place={place} products={products} showTitle />
        )}
      </Grid>
    );
  }
  return null;
};

Products.defaultProps = {
  loadingProducts: true,
  products: null,
  place: null,
};

Products.propTypes = {
  loadingProducts: PropTypes.bool,
  products: PropTypes.arrayOf(ProductFields.getPropTypes),
  place: Fields.getPropTypes(),
};

export default Products;
