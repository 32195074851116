import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/dashboard/container';
import { isAuthor } from '../../components/access';
import { withAuthorization } from '../../model/Session';
import Form from './form';

const NewCategory = ({ history }) => (
  <Container backAction={() => history.goBack()} title="Nueva Categoría">
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={6}>
        <Form />
      </Grid>
    </Grid>
  </Container>
);

NewCategory.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withRouter,
)(NewCategory);
