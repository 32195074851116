import React from 'react';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { makeStyles, SvgIcon } from '@material-ui/core';
import { AuthUserPropTypes } from '../../model/Session';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuItem = ({
  authorize, authUser, nested, path, Icon, label, subItems, navigate,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  if (authorize && !authorize(authUser)) {
    return null;
  }
  let props = {
    onClick: () => navigate(path),
  };
  let submenuItems = null;
  let expand = null;
  if (subItems) {
    props = {
      onClick: () => setOpen(!open),
    };
    submenuItems = (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map(item => (
            <MenuItem
              key={item.label}
              {...item}
              authUser={authUser}
              navigate={navigate}
              nested
            />
          ))}
        </List>
      </Collapse>
    );
    expand = open ? <ExpandLess /> : <ExpandMore />;
  }

  props.className = nested ? classes.nested : null;

  return (
    <React.Fragment>
      <ListItem {...props} dense button>
        {!!Icon && (
          <ListItemIcon>
            <Icon fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
        {expand}
      </ListItem>
      {submenuItems}
    </React.Fragment>
  );
};

MenuItem.defaultProps = {
  authorize: null,
  authUser: null,
  nested: false,
  subItems: null,
  path: null,
  Icon: null,
};

MenuItem.propTypes = {
  authorize: PropTypes.func,
  authUser: AuthUserPropTypes,
  nested: PropTypes.bool,
  subItems: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string,
  Icon: PropTypes.shape(SvgIcon.propTypes),
  label: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default MenuItem;
