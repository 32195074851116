// const APP_BASE = '/app';
const LANDING = '/';
const HOME = '/app';
const ACCOUNT = `${HOME}/account`;
const CLIENTES = `${HOME}/clients`;
const PLACES = `${HOME}/places`;
const STORIES = `${HOME}/stories`;
const CATEGORIES = `${HOME}/categories`;
const DATES = `${HOME}/dates`;
const ADMIN = `${HOME}/admin`;
const USERS = `${ADMIN}/users`;
const SIGN_IN = '/login';
const SIGN_UP = `${SIGN_IN}/new-user`;
const PASSWORD_FORGET = `${SIGN_IN}/pw-forget`;
const FEDEAEREOS = `${HOME}/vuelo`;
const COMPETENCIAS = `${FEDEAEREOS}/competencias`;
const PILOTOS = `${FEDEAEREOS}/pilotos`;
const CIUDADES = `${FEDEAEREOS}/ciudades`;
const DEPARTAMENTOS = `${CIUDADES}/departamentos`;
const CLUBES = `${FEDEAEREOS}/clubes`;

const LOLA = `${HOME}/lola`;
const ARTISTAS = `${LOLA}/artistas`;
const EXPRESIONES = `${LOLA}/expresiones`;

module.exports = {
  LANDING,
  HOME,
  ACCOUNT,
  CLIENTES,
  PLACES,
  STORIES,
  CATEGORIES,
  DATES,
  ADMIN,
  USERS,
  SIGN_IN,
  SIGN_UP,
  PASSWORD_FORGET,
  FEDEAEREOS,
  COMPETENCIAS,
  CIUDADES,
  DEPARTAMENTOS,
  CLUBES,
  PILOTOS,
  LOLA,
  ARTISTAS,
  EXPRESIONES,
};
