import React from 'react';
import * as PropTypes from 'prop-types';
import ButtonGenerateLicense from './buttonGenerateLicense';
import ButtonDownloadLicense from './buttonDownloadLicense';

const LicensesButton = ({ pilot }) => {
  const [licenseURL, setLicenseURL] = React.useState(pilot.licenseURL);
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <ButtonGenerateLicense
        pilot={pilot}
        setLicenseURL={setLicenseURL}
        loading={loading}
        setLoading={setLoading}
      />
      {licenseURL && (
        <ButtonDownloadLicense
          licenseURL={licenseURL}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

LicensesButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pilot: PropTypes.object.isRequired,
};

export default LicensesButton;
