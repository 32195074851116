import React from 'react';
import * as PropTypes from 'prop-types';
import MDialog from '../../components/mdialog';
import DateForm from './dateForm';
import Fields from './fields';

const DialogNewEvent = ({ newEvent, setNewEvent, preloadedValue }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(!!newEvent);
  }, [newEvent]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setNewEvent(false), 300);
  };

  const defaultValues = Fields.defaultValues();

  if (preloadedValue) {
    defaultValues[preloadedValue.key] = preloadedValue.value;
  }

  return (
    <MDialog open={open} handleClose={handleClose} title="Nueva Cita">
      <DateForm isNew defaultValues={defaultValues} />
    </MDialog>
  );
};

DialogNewEvent.defaultProps = {
  preloadedValue: null,
};

DialogNewEvent.propTypes = {
  newEvent: PropTypes.bool.isRequired,
  setNewEvent: PropTypes.func.isRequired,
  preloadedValue: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default DialogNewEvent;
