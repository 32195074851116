import React from 'react';
import { compose } from 'recompose';
import RRDPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { isLolaAdmin } from '../../../components/access';
import * as ROUTES from '../../../constants/routes';
import Loading from '../../../components/loading';
import Fields from './fields';
import ExpresionForm from './form';
import MPanel from '../../../components/panel';
import PanelSocial from '../../../components/social/panelSocial';
import FullWidthContainer from '../../../components/dashboard/fullWidthContainer';

const ExpresionEdit = ({
  firebase, match, history,
}) => {
  const [expresion, setExpresion] = React
    .useState(JSON.parse(JSON.stringify(Fields.defaultValues())));
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      setLoading(true);
      unsubscribe = firebase.expresion(id).onSnapshot((snap) => {
        if (snap.exists) {
          const data = Fields.getInstance(snap);
          data.datetime = data.datetime.toDate().toISOString();
          setExpresion(data);
          setLoading(false);
        } else {
          history.push(ROUTES.EXPRESIONES);
        }
      }, () => history.push(ROUTES.EXPRESIONES));
    } else {
      history.push(ROUTES.EXPRESIONES);
    }
    return () => !!unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullWidthContainer
      backPath={ROUTES.EXPRESIONES}
      title={loading ? 'Cargando expresión artística...' : `Expresión artística de: ${expresion.name}`}
    >
      <Grid container spacing={3} justify="center">
        {loading ? (
          <Grid item xs={12} md={6}>
            <Loading />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <MPanel withPaper>
                <ExpresionForm isNew={false} values={expresion} />
              </MPanel>
            </Grid>
            <Grid item xs={12} md={6}>
              <PanelSocial entity="expresion" editCondition={isLolaAdmin} message="Redes sociales / Enlaces" object={expresion} />
            </Grid>
          </>
        )}
      </Grid>
    </FullWidthContainer>
  );
};

ExpresionEdit.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: RRDPropTypes.match.isRequired,
  history: RRDPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withRouter,
  withFirebase,
)(ExpresionEdit);
