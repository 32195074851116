import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import * as ROUTES from '../../constants/routes';

const PasswordForgetLink = () => (
  <Grid item xs>
    <Link to={ROUTES.PASSWORD_FORGET} component={RouterLink} variant="body2">
      ¿Olvidaste tu contraseña?
    </Link>
  </Grid>
);

export default PasswordForgetLink;
