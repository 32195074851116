import React from 'react';
import * as PropTypes from 'prop-types';
import FieldsTemplate from '../../lib/FieldsTemplate';
import { AsyncColumnRender, TableRenderDate } from '../../components/table';
import MChip from '../../components/chip';
import * as ROUTES from '../../constants/routes';
import { isEditor } from '../../components/access';
import DATE_TYPE from '../../constants/dateTypes';
import DATE_STATUS from '../../constants/dateStatus';

const TYPES = FieldsTemplate.TYPES();


export {
  DATE_TYPE, DATE_STATUS,
};

const FIELDS = {
  subject: {
    id: 'subject',
    label: 'Asunto',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Débe indicar un asunto válido para la cita',
    required: true,
  },
  place: {
    id: 'place',
    label: 'Lugar/Negocio',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'places',
      label: ['name'],
    },
    validationMessage: 'Débe indicar el negocio/lugar con quien será la cita.',
    required: true,
  },
  initialDate: {
    id: 'initialDate',
    label: 'Inicio',
    value: (new Date()).toISOString(),
    type: 'datetime',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY hh:mm a',
    validationType: TYPES.FUTURE_DATE,
    validationMessage: 'Débe indicar una fecha válida para una futura reunión',
    required: true,
  },
  endDate: {
    id: 'endDate',
    label: 'Fin',
    value: (new Date()).toISOString(),
    type: 'datetime',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY hh:mm a',
    validationType: TYPES.FUTURE_DATE,
    validationMessage: 'Débe indicar una fecha y hora de finalización de la reunión posterior al inicio',
    required: true,
  },
  type: {
    id: 'type',
    value: '',
    type: 'select',
    label: 'Tipo de cita',
    validationType: TYPES.SELECT,
    reference: {
      values: DATE_TYPE,
    },
    validationMessage: 'Débe indicar el tipo de cita.',
    required: true,
  },
  invitees: {
    id: 'invitees',
    label: 'Invitados',
    value: [],
    type: 'multireference',
    validationType: TYPES.MULTIREFERENCE,
    reference: {
      entity: 'users',
      label: ['username'],
    },
    validationMessage: 'Débe indicar al menos un invitado.',
    required: true,
  },
  status: {
    id: 'status',
    value: DATE_STATUS.scheduled.value,
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: DATE_STATUS,
    },
    validationMessage: 'Débe indicar el estado de la cita.',
    required: true,
  },
  observations: {
    id: 'observations',
    label: 'Observaciones',
    value: '',
    type: 'textarea',
    validationType: TYPES.TEXTAREA,
    validationMessage: 'Débe indicar observaciones para la cita',
    required: false,
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  content: {
    id: 'content',
    value: false,
    type: 'boolean',
    validationType: TYPES.BOOLEAN,
  },
  eventId: {
    id: 'eventId',
    value: '',
  },
  notif24: {
    id: 'notif24',
    value: false,
  },
  notif1: {
    id: 'notif1',
    value: false,
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsDate extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
    this.getEvent = this.getEvent.bind(this);
    this.eventPropTypes = this.eventPropTypes.bind(this);
  }

  tableDefaults(firebase) {
    const columnsObject = this.columnsTable();

    columnsObject.place.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          firebase.place(rowData.place)
            .get()
            .then((doc) => resolve(doc.data().name));
        })}
      />
    );

    columnsObject.invitees.render = (rowData) => (
      <>
        {Object.keys(rowData.invitees).map((invitee) => (
          <AsyncColumnRender
            key={invitee}
            loadFunction={() => new Promise((resolve) => {
              firebase.user(invitee).get()
                .then((doc) => {
                  const data = doc.data();
                  resolve((
                    <MChip label={data.username} color="default" />
                  ));
                });
            })}
          />
        ))}
      </>
    );

    columnsObject.type.render = (rowData) => DATE_TYPE[rowData.type].label;

    columnsObject.status.title = 'Estado';
    columnsObject.status.render = (rowData) => (
      <MChip
        label={DATE_STATUS[rowData.status].label}
        color={DATE_STATUS[rowData.status].color}
      />
    );

    columnsObject.initialDate.render = TableRenderDate('initialDate');
    columnsObject.endDate.render = TableRenderDate('endDate');

    columnsObject.id.hidden = true;
    columnsObject.createdBy.hidden = true;
    return {
      columns: Object.values(columnsObject),
      title: 'Listado de citas',
    };
  }

  // eslint-disable-next-line
  actions(firebase, history, authUser, notification, setState) {
    return [
      (rowData) => ({
        icon: 'edit',
        tooltip: 'Editar Cita',
        onClick: (event, rowDataLocal) => {
          history.push(`${ROUTES.DATES}/edit/${rowDataLocal.id}`);
        },
        disabled: !(isEditor(authUser)
            || authUser.uid === rowData.createdBy),
      }),
      (rowData) => ({
        icon: 'check',
        tooltip: 'Marcar cita como atendida',
        onClick: (event, rowDataLocal) => {
          setState({
            loading: true,
          });
          firebase.date(rowDataLocal.id)
            .set({ status: DATE_STATUS.done.value }, { merge: true })
            .then(() => notification.setMessage({
              message: 'Cita marcada como atendida satisfactoriamente 🙂', type: 'success',
            }))
            .catch(() => notification.setMessage({
              message: 'Algo no salió bien marcando como atentida la cita 😥', type: 'warning',
            }))
            .finally(() => setState({ loading: false }));
        },
        disabled: !(isEditor(authUser) || (rowData.status === DATE_STATUS.scheduled.value
          && (authUser.uid === rowData.createdBy
            || FieldsDate.isInvitee(authUser, rowData.invitees)))),
      }),
      (rowData) => ({
        icon: 'delete_outline',
        tooltip: 'Cancelar cita',
        onClick: (event, rowDataLocal) => {
          setState({
            loading: true,
          });
          firebase.date(rowDataLocal.id)
            .set({ status: DATE_STATUS.cancelled.value }, { merge: true })
            .then(() => notification.setMessage({
              message: 'Se ha cancelado la cita satisfactoriamente 🙂', type: 'success',
            }))
            .catch(() => notification.setMessage({
              message: 'Algo no salió bien cancelando la cita 😥', type: 'warning',
            }))
            .finally(() => setState({ loading: false }));
        },
        disabled: !(isEditor(authUser) || (rowData.status === DATE_STATUS.scheduled.value
            && (authUser.uid === rowData.createdBy
            || FieldsDate.isInvitee(authUser, rowData.invitees)))),
      }),
    ];
  }

  // eslint-disable-next-line
  isInvitee(authUser, inviteesObject) {
    const invitees = inviteesObject ? Object.keys(inviteesObject) : [];
    return invitees.some((item) => item === authUser.uid);
  }

  getEvent(doc) {
    const obj = this.getInstance(doc);
    return {
      title: obj.subject,
      start: new Date(obj.initialDate),
      end: new Date(obj.endDate),
      resource: obj,
    };
  }

  eventPropTypes() {
    const resource = this.getPropTypes();
    return PropTypes.shape({
      title: PropTypes.string.isRequired,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      resource: resource.isRequired,
    });
  }
}

export default new FieldsDate(FIELDS);
