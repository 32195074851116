import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PlaceFields from '../fields';
import Loading from '../../../components/loading';
import { SectionTitle } from '../../../components/title';
import PhotosList from './photosList';
import ButtonAddPhoto from './buttonAddPhoto';

const PhotosPanel = ({
  place, showTitle,
}) => {
  if (place) {
    return (
      <>
        {!!showTitle && (
          <SectionTitle title="Fotografía(s) del Lugar/Negocio" />
        )}
        <Grid container>
          <Grid item xs={12}>
            <ButtonAddPhoto place={place} />
          </Grid>
          <Grid item xs={12}>
            <PhotosList place={place} />
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Loading variant="h6" size={20} />
  );
};

PhotosPanel.defaultProps = {
  place: null,
  showTitle: true,
};

PhotosPanel.propTypes = {
  showTitle: PropTypes.bool,
  place: PlaceFields.getPropTypes(),
};

export default PhotosPanel;
