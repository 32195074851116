import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import Fields from './fields';
import { validateForm } from '../../components/validator';
import { generateInputs, MForm } from '../../components/form';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import { isAuthor } from '../../components/access';
import { changeAction, getRandomString } from '../../lib/misc';
import * as ROUTES from '../../constants/routes';

const saveData = (notification, storyReference, dataSet, successMessage, endSubmit) => {
  storyReference.set(dataSet, { merge: true })
    .then(() => {
      notification.setMessage({ message: successMessage, type: 'success' });
      endSubmit(false, true);
    })
    .catch((e) => {
      notification.setMessage({ message: e.message, type: 'error' });
      endSubmit(false);
    });
};

const uploadFile = (id, file, firebase, setUploaded) => new Promise((resolve, reject) => {
  const productPath = id === '' ? 'new' : id;
  const timeStamp = new Date().getTime();
  const filePath = `/images/stories/${productPath}/${timeStamp}-${file.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}`;
  firebase.uploadFile(filePath, file, (progress) => {
    setUploaded(progress.value);
  }, (err, url) => {
    if (err) {
      setUploaded(-1);
      reject(err);
    }
    setUploaded(100);
    resolve({ url, reference: filePath });
  });
});

const persistStory = (
  firebase, isNew, fields, authUser, dataSet, notification, endSubmit,
) => {
  const storyReference = isNew ? firebase.stories().doc()
    : firebase.story(fields.id.value);
  let successMessage = 'Se modificó la historia 😀';
  if (isNew) {
    // eslint-disable-next-line no-param-reassign
    dataSet.createdBy = authUser.uid;
    successMessage = 'Se creó la nueva historia 😀';
  }
  saveData(notification, storyReference, dataSet, successMessage, endSubmit);
};

const cleanFields = (newFields, setFields) => {
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].value = '';
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].error = false;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].file = null;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photoURL.id].value = '';
  setFields(newFields);
};

const StoryForm = ({
  notification, storyValues, authUser, firebase, history,
}) => {
  const [fields, setFields] = React.useState({});
  const [key, setKey] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);

  const removeImage = React.useCallback((newFields) => {
    setLoading(true);
    if (storyValues && newFields[Fields.fields.photoURL.id].value !== '') {
      firebase.deleteFile(newFields[Fields.fields.photo.id].value)
        .then(() => {
          firebase.story(storyValues.id)
            .set({ photo: '', photoURL: '' }, { merge: true })
            .then(() => {
              notification.setMessage({ type: 'success', message: 'Se quito correctamente la imagen.' });
              cleanFields(newFields, setFields);
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
              setLoading(false);
            });
        })
        .catch((e) => {
          notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
          setLoading(false);
          console.error(e);
        });
    } else {
      cleanFields(newFields, setFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(Fields.fields));
    if (storyValues) {
      setKey(storyValues.id);
      Object.keys(storyValues).forEach((item) => {
        newFields[item].value = storyValues[item];
      });
      if (typeof newFields[Fields.fields.photo.id].value !== 'string') {
        newFields[Fields.fields.photo.id].value = '';
        newFields[Fields.fields.photoURL.id].value = '';
      }
      newFields[Fields.fields.photo.id].onRemove = removeImage;
    } else {
      setKey(getRandomString());
    }
    setFields(newFields);
  }, []); // eslint-disable-line

  const isInvalid = !validateForm(fields);

  const endSubmit = React.useCallback((load, success) => {
    if (success) {
      history.push(ROUTES.STORIES);
    } else {
      setLoading(load);
    }
  }, [history]);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      endSubmit(false);
    } else {
      const dataSet = {
        title: fields.title.value,
        category: fields.category.value,
        details: fields.details.value,
        initialDate: fields.initialDate.value,
        endDate: fields.endDate.value,
        color: fields.color.value,
        published: fields.published.value,
      };
      const isNew = !fields.id.value;
      if (fields.photo.value === '' || fields.photoURL.value !== '') {
        persistStory(firebase, isNew, fields,
          authUser, dataSet, notification, endSubmit);
        return;
      }
      uploadFile(fields.id.value, fields.photo.file, firebase, setUploaded)
        .then((image) => {
          dataSet[Fields.fields.photo.id] = image.reference;
          dataSet[Fields.fields.photoURL.id] = image.url;
          persistStory(firebase, isNew, fields,
            authUser, dataSet, notification, endSubmit);
        })
        .catch((err) => {
          endSubmit(false, false);
          notification.setMessage({ message: err.message, type: 'error' });
        });
    }
  }, [authUser, endSubmit, fields, firebase, isInvalid, notification]);

  const onChange = (result) => changeAction(result, fields, setFields);

  return (
    <Grid item xs={12}>
      <MForm
        loading={loading}
        button={{ label: 'Guardar Historia', disabled: isInvalid }}
        uploaded={uploaded}
        onSubmit={submitAction}
      >
        {generateInputs(fields, onChange, key)}
      </MForm>
    </Grid>
  );
};

StoryForm.defaultProps = {
  storyValues: null,
};

StoryForm.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  storyValues: Fields.getPropTypes(),
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAuthor),
  withFirebase,
  withNotification,
  withCurrentUser,
  withRouter,
)(StoryForm);
