import React from 'react';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import Fields, { MEMBERSHIPS } from '../fields';
import MAutoUpload from '../../../components/form/mAutoUpload';
import { TYPES } from '../../../components/validator';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';

const ButtonAddPhoto = ({ place, firebase, notification }) => {
  const [membershipPhotos, setMembershipPhotos] = React.useState(0);
  React.useEffect(() => {
    if (place.membership) {
      setMembershipPhotos(MEMBERSHIPS[place.membership].photos);
    }
  }, [place.membership]);

  const [photos, setPhotos] = React.useState([]);
  React.useEffect(() => {
    if (place.photos) {
      setPhotos(place.photos);
    }
  }, [place.photos]);

  const onUpload = (result) => {
    const newPhotos = [...photos];
    newPhotos.push(...result);
    let message = {};
    firebase.place(place.id)
      .set({ photos: newPhotos }, { merge: true })
      .then(() => {
        message = { type: 'success', message: 'Se agregaron imagenes al lugar/negocio 😃' };
      })
      .catch(() => {
        message = { type: 'error', message: 'Ups! Algo no salió bien agregando las imagenes al lugar/negocio 🙁' };
      })
      .finally(() => notification.setMessage(message));
  };

  const photosLeft = membershipPhotos - photos.length;
  return (
    <div>
      {photosLeft > 0 && (
        <MAutoUpload
          validationMessage="El archivo indicado no parece ser una imagen válida"
          validationType={TYPES.FILE}
          label="Sube una imagen"
          fileTypes={['.jpg', '.jpeg', '.png']}
          onUpload={onUpload}
          firebaseRef={`/images/places/${place.id}`}
          maxFiles={photosLeft}
          dndText=" Arrastra imagenes aquí o "
        />
      )}
      <Typography variant="body2" color="textSecondary">
        {`${photos.length} de ${membershipPhotos} fotos`}
      </Typography>
    </div>
  );
};

ButtonAddPhoto.propTypes = {
  place: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
)(ButtonAddPhoto);
