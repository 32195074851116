import React from 'react';
import * as PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/PhoneInTalkRounded';
import SocialIcon from '../social/icon';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
  },
  whatsapp: {
    width: `${theme.spacing(3)}px !important`,
    height: `${theme.spacing(3)}px !important`,
    marginLeft: -5,
    marginRight: 10,
  },
  phone: {
    borderRadius: '50%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    marginLeft: -5,
    marginRight: 10,
  },
  phoneIcon: {
    color: 'white',
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  label: {
    marginLeft: -5,
  },
}));

const RenderPhoneWhatsapp = ({ phone, deleteAction }) => {
  const [thePhone, setPhone] = React.useState({ number: '' });
  React.useEffect(() => {
    setPhone({
      number: phone.number || phone,
      whatsapp: phone.whatsapp || false,
      phone: phone.phone === undefined || phone.phone === null ? true : phone.phone,
    });
  }, [phone]);
  const classes = useStyles();
  return (
    <Chip
      className={classes.root}
      component="div"
      variant="outlined"
      onDelete={deleteAction}
      label={(
        <React.Fragment>
          {!!thePhone.phone && (
            <div className={classes.phone}><PhoneIcon className={classes.phoneIcon} /></div>
          )}
          {!!thePhone.whatsapp && <SocialIcon className={classes.whatsapp} network="whatsapp" />}
          <span className={classes.label}>{thePhone.number}</span>
        </React.Fragment>
      )}
      color="primary"
    />
  );
};

RenderPhoneWhatsapp.defaultProps = {
  deleteAction: null,
};

RenderPhoneWhatsapp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  phone: PropTypes.any.isRequired,
  deleteAction: PropTypes.func,
};

export default RenderPhoneWhatsapp;
