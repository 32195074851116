import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PlaceFields from '../fields';
import Fields from './fields';
import { SectionTitle } from '../../../components/title';
import ProductDialog from './productDialog';
import ProductList from './productList';

const PanelProducts = ({
  place, products, showTitle,
}) => (
  <React.Fragment>
    {!!showTitle && (
    <SectionTitle marginBottom title="Información de productos" />
    )}
    <Grid container>
      <Grid item xs={12}>
        <ProductDialog productsCount={products.length} place={place} />
      </Grid>
      <Grid item xs={12}>
        <ProductList place={place} products={products} />
      </Grid>
    </Grid>
  </React.Fragment>
);

PanelProducts.defaultProps = {
  showTitle: true,
};

PanelProducts.propTypes = {
  showTitle: PropTypes.bool,
  place: PlaceFields.getPropTypes().isRequired,
  products: PropTypes.arrayOf(Fields.getPropTypes()).isRequired,
};

export default PanelProducts;
