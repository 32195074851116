const ADMIN = 'admin';
const AUTHOR = 'autor';
const EDITOR = 'editor';
const COORDINATOR = 'coordinador';
const TRANSLATOR = 'traductor';
const AEROADMIN = 'aeroadmin';
const LOLAADMIN = 'lolaadmin';

module.exports = {
  ADMIN, AUTHOR, EDITOR, COORDINATOR, TRANSLATOR, AEROADMIN, LOLAADMIN,
};
