import React from 'react';
import * as PropTypes from 'prop-types';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { TYPES, validate } from '../validator';

moment.locale('es');

const dateComponent = {
  date: DatePicker,
  time: TimePicker,
  datetime: DateTimePicker,
};


const MDateInput = ({
  id, value, type, label, variant, error, validationMessage, validationType,
  onChange, required, margin, fullWidth, format, validationOptions,
}) => {
  const Component = dateComponent[type];

  const onChangeEvent = (newDate) => {
    const result = {
      id,
      value: newDate.isValid() ? newDate.toISOString() : '',
    };
    if (!validationType) {
      onChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      required,
      validationOptions,
    });
    onChange({
      ...result,
      ...validation,
    });
  };

  const renderLabel = (date) => (date && date.isValid() ? date.format(format) : '');

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <>
        <Component
          label={label}
          inputVariant={variant}
          onChange={onChangeEvent}
          id={id}
          key={`inputeDate${id}`}
          fullWidth={fullWidth}
          margin={margin}
          name={id}
          format={format}
          value={value}
          cancelLabel="Cancelar"
          okLabel="Aceptar"
          labelFunc={renderLabel}
        />
        {!!error && (
          <Typography color="error" variant="caption" component="span">
            {validationMessage}
          </Typography>
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};

MDateInput.defaultProps = {
  type: 'datetime',
  variant: 'outlined',
  required: false,
  onChange: null,
  error: false,
  validationType: TYPES.FUTURE_DATE,
  validationMessage: null,
  fullWidth: true,
  margin: 'normal',
  format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY hh:mm a',
  validationOptions: null,
  value: null,
};

MDateInput.propTypes = {
  type: PropTypes.oneOf(['time', 'date', 'datetime']),
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf([TYPES.FUTURE_DATE, TYPES.DATE]),
  validationMessage: PropTypes.string,
  validationOptions: PropTypes.shape({
    previousDate: PropTypes.string,
  }),
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  format: PropTypes.string,
};

export default MDateInput;
