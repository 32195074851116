import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const useStyles = (marginTop) => (makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: theme.spacing(marginTop),
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    color: grey[400],
  },
})));

const LicenseTitleSection = ({ title, marginTop, block }) => {
  const classes = useStyles(marginTop ? 4 : 0)();
  return (
    <Typography
      component="h3"
      variant="h5"
      className={classes.heading}
      display={block ? 'block' : 'inline'}
    >
      {title}
    </Typography>
  );
};

LicenseTitleSection.defaultProps = {
  marginTop: false,
  block: true,
};

LicenseTitleSection.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.bool,
  block: PropTypes.bool,
};

export default LicenseTitleSection;
