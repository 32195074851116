import checkEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isLenght from 'validator/lib/isLength';
import isNumeric from 'validator/lib/isNumeric';
import isAfter from 'validator/lib/isAfter';
import isISO8601 from 'validator/lib/isISO8601';
import isURL from 'validator/lib/isURL';
import isLatlong from 'validator/lib/isLatLong';
// import checkURL from 'validator/lib/isURL';

// eslint-disable-next-line
const isNumericLenght = (value, min, max, no_symbols) => isNumeric(`${value}`, { no_symbols })
  && isLenght(`${value}`, { min, max });
const isDocument = (value) => isNumericLenght(value, 4, 15, false);
// TODO Buscar expresion regular para números de teléfono en Colombia
export const isLocalPhone = (value) => isNumericLenght(value, 7, 20, false);
const isName = (value) => isLenght(value, 3, 100);
const isAddress = (value) => isLenght(value, 10, 100);
export const containsExtension = (value, options) => new RegExp(`(${options.join('|')})$`, 'i').test(value);
export const isEmail = (value) => checkEmail(value);
export const isNotEmpty = (value) => !isEmpty(value.trim());
const isDate = (value) => {
  try {
    return isISO8601(value);
  } catch (e) {
    return false;
  }
};

// export const isURL = test => checkURL(test);

export const TYPES = {
  PHONE: 'phone',
  PHONES: 'phones',
  DOCUMENT: 'document',
  NAME: 'name',
  EMAIL: 'email',
  NEW_PASSWORD: 'newpassword',
  PASSWORD: 'password',
  REFERENCE: 'reference',
  MULTIREFERENCE: 'multireference',
  FUTURE_DATE: 'future_date',
  DATE: 'date',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  TEXTAREA: 'textarea',
  EDITOR: 'editor',
  BOOLEAN: 'boolean',
  FILE: 'file',
  MULTIFILE: 'multifile',
  URL: 'url',
  LINK: 'link',
  LINKS: 'links',
  ADDRESS: 'address',
  LATLNG: 'latlng',
  SCHEDULE: 'schedule',
  NUMBER: 'number',
  COLOR: 'color',
};

const applyValidation = (validateItem) => {
  const { value } = validateItem;

  switch (validateItem.validationType) {
    case TYPES.PHONE:
      return isLocalPhone(value);
    case TYPES.DOCUMENT:
      return isDocument(value);
    case TYPES.NAME:
      return value && isName(value);
    case TYPES.ADDRESS:
      return value && isAddress(value);
    case TYPES.LATLNG:
      return value && isLatlong(value);
    case TYPES.EMAIL:
      return isEmail(`${value}`);
    case TYPES.URL:
      return isURL(`${value}`);
    case TYPES.LINK:
      return isURL(`${value}`) || isEmail(`${value}`);
    case TYPES.PASSWORD:
    case TYPES.REFERENCE:
    case TYPES.SELECT:
    case TYPES.TEXTAREA:
    case TYPES.EDITOR:
      return !isEmpty(value);
    case TYPES.NEW_PASSWORD:
      return isLenght(value, 5, 15);
    case TYPES.NUMBER:
      return isNumeric(`${value}`);
    case TYPES.FILE:
      return containsExtension(value, validateItem.fileTypes);
    case TYPES.MULTIFILE:
      return Array.isArray(value)
        && value.every((item) => containsExtension(item, validateItem.fileTypes));
    case TYPES.DATE:
      return isDate(value);
    case TYPES.FUTURE_DATE: {
      const previous = validateItem.validateOptions && validateItem.validateOptions.previousDate
        ? validateItem.validateOptions.previousDate : (new Date()).toISOString();
      return isAfter(value, previous);
    }
    case TYPES.MULTIREFERENCE:
    case TYPES.MULTISELECT:
      return Array.isArray(value) && value.length > 0;
    default:
      return true;
  }
};

export const validate = (item) => {
  const validationResult = {};
  if (item.required) {
    validationResult.isValid = item && applyValidation(item);
  } else {
    validationResult.isValid = (item || isEmpty(`${item.value}`.trim())) ? true : applyValidation(item);
  }
  return validationResult;
};

export const validateForm = (FIELDS) => !Object.values(FIELDS)
  .some((item) => {
    if (!item.label) {
      return false;
    }
    const result = !validate({ ...item }).isValid;
    return result;
  });

export const validationFormInfo = (FIELDS) => {
  const pendingFields = [];
  Object.values(FIELDS).forEach((field) => {
    if (!!field.label && !validate({ ...field }).isValid) {
      pendingFields.push(field.label);
    }
  });
  return pendingFields;
};
