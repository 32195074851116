import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Grid, makeStyles, Typography, Chip,
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => {
  const chip = {
    color: 'white',
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 'bold',
    height: theme.spacing(2),
    display: 'table',
    margin: '0 auto',
  };
  const value = {
    fontWeight: 100,
    display: 'block',
    fontSize: '3rem',
    textAlign: 'center',
  };
  return {
    root: {
      padding: theme.spacing(2, 3),
    },
    total: {
      ...chip,
      backgroundColor: theme.palette.primary.main,
    },
    blue: {
      ...chip,
      backgroundColor: blue[800],
    },
    green: {
      ...chip,
      backgroundColor: green[800],
    },
    red: {
      ...chip,
      backgroundColor: red[800],
    },
    alpha: {
      ...chip,
      backgroundColor: grey[800],
    },
    value,
    valueTotal: {
      ...value,
      fontSize: '5.5rem',
    },
  };
});

const SummaryField = ({
  label, value, color, total, ...props
}) => {
  const classes = useStyles();
  return (
    <Grid item {...props} className={classes.root}>
      <Typography className={total ? classes.valueTotal : classes.value} variant="h4" component="span">
        {value}
      </Typography>
      <Chip className={classes[color]} label={label} />
    </Grid>
  );
};

SummaryField.defaultProps = {
  value: 0,
  label: '',
  color: 'total',
  total: false,
};

SummaryField.propTypes = {
  ...Grid.propTypes,
  value: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'green', 'red', 'alpha', 'total']),
  total: PropTypes.bool,
};

export default SummaryField;
