import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const MButton = ({
  type, fullWidth, variant, color, label, disabled, to, size,
}) => {
  const classes = useStyles();
  const component = to ? Link : undefined;
  const className = type === 'submit' ? classes.submit : null;
  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      className={className}
      component={component}
      to={to}
    >
      {label}
    </Button>
  );
};

MButton.defaultProps = {
  type: 'button',
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  disabled: false,
  to: null,
  size: 'medium',
};

MButton.propTypes = {
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large',
  ]),
};

export default MButton;
