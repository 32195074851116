import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import moment from 'moment';
import { DATE_STATUS } from './fields';

export const eventStyle = (event) => {
  let color = grey;
  if (DATE_STATUS.done.value === event.resource.status) {
    color = green;
  } else if (DATE_STATUS.scheduled.value === event.resource.status) {
    color = blue;
  }
  return {
    style: {
      backgroundColor: color[300],
    },
  };
};

export const dateRangeEvent = setDateRange => (res) => {
  if (res) {
    let start = moment().startOf('month').toISOString();
    let end = moment().endOf('month').toISOString();
    if (Array.isArray(res)) {
      ([start] = res);
      end = moment(res[res.length - 1]).endOf('day').toISOString();
    } else {
      ({ start, end } = res);
    }
    const range = {
      start: moment(start).toISOString(),
      end: moment(end).toISOString(),
    };
    setDateRange(range);
  }
};
