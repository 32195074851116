import React from 'react';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {
  Container, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

const Transition = React
  .forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MFullScreenDialog = ({
  open, handleClose, title, children, buttonText,
}) => {
  const [doProcess, setDoProcess] = React.useState(false);
  const processAction = React.useCallback((processed) => {
    setDoProcess(false);
    if (processed) {
      handleClose();
    }
  }, [handleClose]);
  const newChildren = React.Children.map(children, child => React.cloneElement(child, {
    processAction,
    doProcess,
  }));

  const submit = () => {
    setDoProcess(true);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth scroll="body" onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Container>
          {newChildren}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={submit}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MFullScreenDialog.defaultProps = {
  title: '',
  buttonText: 'Cerrar',
  children: null,
};

MFullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
};

export default MFullScreenDialog;
