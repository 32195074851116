import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import Fields from './fields';
import { validateForm } from '../../components/validator';
// eslint-disable-next-line import/no-cycle
import { generateInputs, MForm } from '../../components/form';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import { AuthUserPropTypes, withCurrentUser } from '../../model/Session';
import { getRandomString } from '../../lib/misc';
import * as ROUTES from '../../constants/routes';

const saveData = (notification, storyReference, dataSet, successMessage, endSubmit) => {
  storyReference.set(dataSet, { merge: true })
    .then(() => {
      notification.setMessage({ message: successMessage, type: 'success' });
      endSubmit(false, true);
    })
    .catch((e) => {
      notification.setMessage({ message: e.message, type: 'error' });
      endSubmit(false);
    });
};

const getParentValues = (parents) => {
  const result = {};
  parents.forEach((item) => {
    result[item] = true;
  });
  return result;
};

const persistCategory = (
  firebase, isNew, fields, authUser, dataSet, notification, endSubmit,
) => {
  const storyReference = isNew ? firebase.categories().doc()
    : firebase.category(fields.id.value);
  let successMessage = 'Se modificó la categoría 😀';
  if (isNew) {
    // eslint-disable-next-line no-param-reassign
    dataSet.createdBy = authUser.uid;
    successMessage = 'Se creó la nueva categoría 😀';
  }
  saveData(notification, storyReference, dataSet, successMessage, endSubmit);
};

const CategoryForm = ({
  notification, categoryValues, authUser, firebase, history,
}) => {
  const [fields, setFields] = React.useState({});
  const [key, setKey] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(Fields.fields));
    if (categoryValues) {
      setKey(categoryValues.id);
      Object.keys(categoryValues).forEach((item) => {
        newFields[item].value = categoryValues[item];
      });
    } else {
      setKey(getRandomString());
    }
    setFields(newFields);
  }, []); // eslint-disable-line

  const isInvalid = !validateForm(fields);

  const endSubmit = React.useCallback((load, success) => {
    if (success && categoryValues) {
      history.push(ROUTES.CATEGORIES);
    } else {
      if (!categoryValues) {
        setFields(JSON.parse(JSON.stringify(Fields.fields)));
      }
      setLoading(load);
    }
  }, [categoryValues, history]);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      endSubmit(false);
    } else {
      const dataSet = {
        name: fields.name.value,
        name_en: fields.name_en.value,
        parents: getParentValues(fields.parents.value),
      };
      const isNew = !fields.id.value;
      firebase.categories().where('name', '==', dataSet.name)
        .get()
        .then((snapShot) => {
          if ((isNew && !snapShot.empty)
            || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
            notification.setMessage({
              message: 'Ya existe otra categoría con ese nombre.',
              type: 'warning',
            });
            endSubmit(false);
          } else {
            persistCategory(firebase, isNew, fields, authUser, dataSet, notification, endSubmit);
          }
        })
        .catch((e) => {
          notification.setMessage({ message: e.message, type: 'error' });
          endSubmit(false);
        });
    }
  }, [authUser, endSubmit, fields, firebase, isInvalid, notification]);

  const onChange = (result) => {
    const newFields = { ...fields };
    newFields[result.id].value = result.value;
    newFields[result.id].error = !result.isValid;
    setFields(newFields);
  };

  return (
    <Grid item xs={12}>
      <MForm
        loading={loading}
        button={{ label: 'Guardar Categoría', disabled: isInvalid }}
        onSubmit={submitAction}
      >
        {generateInputs(fields, onChange, key)}
      </MForm>
    </Grid>
  );
};

CategoryForm.defaultProps = {
  categoryValues: null,
};

CategoryForm.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  categoryValues: Fields.getPropTypes(),
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withFirebase,
  withNotification,
  withCurrentUser,
  withRouter,
)(CategoryForm);
