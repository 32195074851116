import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import MDialog from '../../../../components/mdialog';
import FormVenue from './formVenue';

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
  },
}));

const ButtonAddVenue = ({
  disabled, addVenue, loading, formSuccess,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const closeDialog = () => {
    if (!loading) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    if (formSuccess) {
      setOpen(false);
    }
  }, [formSuccess]);
  return (
    <React.Fragment>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        Agregar Sede
      </Button>
      <MDialog fullScreen keepMounted={false} open={open} handleClose={closeDialog} title="Agregar Nueva Sede">
        <FormVenue isNew onSubmit={addVenue} loading={loading} />
      </MDialog>
    </React.Fragment>
  );
};

ButtonAddVenue.defaultProps = {
  disabled: false,
  loading: false,
  formSuccess: false,
};

ButtonAddVenue.propTypes = {
  disabled: PropTypes.bool,
  addVenue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  formSuccess: PropTypes.bool,
};

export default ButtonAddVenue;
