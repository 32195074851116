import React from 'react';
import { observable, action, decorate } from 'mobx';

class NotificationStore {
  constructor() {
    this.message = null;
    this.open = false;
  }

  setMessage(message) {
    this.message = message;
    this.open = !!message;
  }

  close() {
    this.open = false;
  }
}

decorate(NotificationStore, {
  message: observable,
  open: observable,
  setMessage: action,
  close: action,
});

export default React.createContext(new NotificationStore());
