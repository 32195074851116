import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Container from '../../components/dashboard/container';
import { isAdmin, isCoordinator } from '../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import * as ROUTES from '../../constants/routes';
import { MButton } from '../../components/form';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Table from '../../components/table';
import { withNotification, NotificationPropTypes } from '../../components/notification';
import Fields from './fields';

const TableClients = ({
  firebase, notification, history, authUser,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = firebase.clients().onSnapshot((snap) => {
      const values = [];
      snap.forEach((doc) => {
        values.push(Fields.getInstance(doc));
      });
      setData(values);
      setLoading(false);
    }, () => notification.setMessage({ type: 'warning', message: 'Ups! Algo no salió bien cargando los clientes 😢, inténtalo de nuevo.' }));
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = () => [
    (rowData) => ({
      icon: 'edit',
      tooltip: 'Editar Cliente',
      onClick: (event, rowDataLocal) => history.push(`${ROUTES.CLIENTES}/edit/${rowDataLocal.id}`),
      disabled: !(isAdmin(authUser) || authUser.uid === rowData.createdBy),
    }),
  ];

  const editable = () => {
    const isDeletable = (rowData) => isAdmin(authUser) || authUser.uid === rowData.createdBy;
    const onRowDelete = (oldData) => new Promise((resolve, reject) => {
      firebase.client(oldData.id)
        .delete()
        .then(() => {
          notification.setMessage({ message: 'Cliente borrado satisfactoriamente 🙂', type: 'success' });
          resolve();
        })
        .catch((e) => {
          notification.setMessage({ message: 'Algo no salió ien borrando el cliente 😥', type: 'warning' });
          reject(e);
        });
    });
    return {
      isDeletable,
      onRowDelete,
    };
  };

  const defaults = Fields.tableDefaults(firebase);

  return (
    <Container
      title="Listado de Clientes"
      header={(
        <MButton fullWidth={false} label="Nuevo Cliente" to={`${ROUTES.CLIENTES}/new`} />
      )}
    >
      <Table
        data={data}
        columns={defaults.columns}
        title={defaults.title}
        isLoading={loading}
        editable={editable()}
        actions={actions()}
      />
    </Container>
  );
};

TableClients.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isCoordinator),
  withCurrentUser,
  withFirebase,
  withRouter,
  withNotification,
)(TableClients);
