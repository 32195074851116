import React from 'react';
import * as PropTypes from 'prop-types';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles, Tooltip } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  labelIcon: {
    minHeight: 40,
  },
  icon: {
    fontSize: '1.25rem',
    marginBottom: '0 !important',
  },
  root: {
    minWidth: theme.spacing(8),
  },
}));

const MTab = ({
  icon, value, label, ...props
}) => {
  const { icon: iconClass, ...classes } = useStyles();
  const IconComponent = icon;
  return (
    <Tab
      {...props}
      value={value}
      classes={classes}
      icon={(
        <Tooltip title={label}>
          <IconComponent className={iconClass} />
        </Tooltip>
      )}
    />
  );
};

MTab.defaultProps = {
  icon: StarIcon,
  value: null,
  label: 'Item',
};

MTab.propTypes = {
  ...Tab.propTypes,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  label: PropTypes.string,
};

export default MTab;
