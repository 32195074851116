import React from 'react';
import * as PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import {
  makeStyles, Typography,
} from '@material-ui/core';

import './dnd.css';

const useStyles = makeStyles((theme) => ({
  dndContainer: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 2,
  },
}));

const DndContainer = ({
  handleDrop, areaText, children,
}) => {
  const classes = useStyles();

  const onDrop = React.useCallback((source, event) => {
    event.preventDefault();
    event.stopPropagation();
    handleDrop(source);
  }, [handleDrop]);

  return (
    <div id="dnd-files" className={classes.dndContainer}>
      <FileDrop onDrop={onDrop}>
        <Typography variant="overline" component="span">
          {areaText}
        </Typography>
        {children}
      </FileDrop>
    </div>
  );
};

DndContainer.defaultProps = {
  areaText: ' Arrastra archivos aquí o ',
  children: null,
};

DndContainer.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  areaText: PropTypes.string,
  children: PropTypes.node,
};

export default DndContainer;
