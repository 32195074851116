import React from 'react';
import FieldsCities from '../Ciudades/fields';
import Disciplines from '../Disciplines';

const getInitialFields = (Fields) => JSON.parse(JSON.stringify(Fields.fields));

const assignDefaultValues = (fields, defaultValues) => {
  if (defaultValues) {
    const theFields = { ...fields };
    Object.keys(defaultValues).forEach((item) => {
      if (item === 'disciplines') {
        theFields[item].value = Object.keys(defaultValues[item])
          .map((value) => Disciplines[value].value);
      } else if (item === 'events') {
        if (typeof defaultValues[item] === 'string') {
          theFields[item].value = [];
        } else {
          const events = [];
          Object.keys(defaultValues[item]).forEach((key) => {
            if (defaultValues[item][key]) {
              events.push(key);
            }
          });
          theFields[item].value = events;
        }
      } else {
        theFields[item].value = defaultValues[item];
      }
    });
    return theFields;
  }
  return fields;
};

export const useFields = (Fields, defaultValues) => {
  const [fields, setFields] = React.useState(getInitialFields(Fields));
  React.useEffect(() => {
    setFields(assignDefaultValues(fields, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  return [fields, setFields];
};

export const useCities = (firebase, notification, fields, setFields) => {
  const [cities, setCities] = React.useState({});
  React.useEffect(() => {
    firebase.cities().onSnapshot((snapshot) => {
      const theCities = {};
      const departments = {};
      firebase.departments().get().then((depSnapshot) => {
        depSnapshot.forEach((doc) => {
          departments[doc.id] = doc.data().name;
        });
        snapshot.forEach((doc) => {
          const city = FieldsCities.getInstance(doc);
          let label = city.name;
          if (departments[city.department]) {
            label = `${label} - ${departments[city.department]}`;
          }
          theCities[city.id] = { value: city.id, label };
        });
        setCities(theCities);
      });
    }, () => {
      notification
        .setMessage({ type: 'warning', message: 'Ups! algo pasó actualizando las ciudades.' });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (Object.keys(cities).length > 0) {
      const theFields = { ...fields };
      theFields.city.reference.values = cities;
      // console.warn(theFields);
      setFields(theFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);
};

const getFileName = (basePath, fileName) => `/images/pilots/${basePath}/${fileName.replace(/[^a-zA-Z0-9-_/.]/g, '')}`;

const uploadFile = (
  data, name, firebase, setUploaded, base, parts, docNumber,
) => new Promise((resolve, reject) => {
  if (data[name].value === '' || data[name].value.startsWith('/images/pilots')) {
    resolve(data[name].value);
    return;
  }
  const timeStamp = new Date().getTime();
  const filePath = getFileName(name, `${timeStamp}-${docNumber}-${data[name].file.name}`);
  firebase.uploadFile(filePath, data[name].file, (progress) => {
    setUploaded(Math.ceil(progress.value / parts) + base);
  }, (err) => {
    if (err) {
      setUploaded(-1);
      reject(err);
    }
    setUploaded(100);
    resolve(filePath);
  });
});

export const saveData = (
  reference, dataSet, successMessage, redirect, setFields, notification, history, Fields,
) => {
  reference.set(dataSet, { merge: true })
    .then(() => {
      setFields(getInitialFields(Fields));
      notification.setMessage({ message: successMessage, type: 'success' });
      if (redirect) {
        history.push(redirect);
      }
    })
    .catch((e) => notification.setMessage({ message: e.message, type: 'error' }));
};

export const saveDataPilot = async (
  reference, dataSet, successMessage, redirect, setFields,
  notification, history, Fields, photos, setUploaded, firebase,
) => {
  const newDataSet = { ...dataSet };
  newDataSet.photo = await uploadFile(
    photos, 'photo', firebase, setUploaded, 0, 2, dataSet.docNumber,
  );
  newDataSet.passportPhoto = await uploadFile(
    photos, 'passportPhoto', firebase, setUploaded, 0, 2, dataSet.docNumber,
  );
  saveData(
    reference, newDataSet, successMessage, redirect, setFields, notification, history, Fields,
  );
};

export const onChange = (result, fields, setFields) => {
  const theFields = { ...fields };
  theFields[result.id].value = result.value;
  theFields[result.id].error = !result.isValid;
  setFields(theFields);
};

export const alreadyExist = async (firebase, entity, isNew, fields, fieldName, fieldValue) => {
  const snapShot = await firebase[entity]().where(fieldName, '==', fieldValue).get();
  return ((isNew && !snapShot.empty)
    || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value));
};
