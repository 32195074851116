import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Title from '../../components/title';
// import SignUpLink from '../SignUp/signUpLink';
import PasswordForgetLink from '../PasswordForget/passwordForgetLink';
import SignInForm from './signInFormBase';

const SignInPage = () => (
  <Title title="Ingresar">
    <Fragment>
      <Typography component="h1" variant="h5">
        Ingresar
      </Typography>
      <SignInForm />
      <Grid container>
        <PasswordForgetLink />
        {/* <SignUpLink /> */}
      </Grid>
    </Fragment>
  </Title>
);

export default SignInPage;
