import React from 'react';
import { Button } from '@material-ui/core';
import Cloud from '@material-ui/icons/CloudUpload';

const TOKEN = '2b2f6115662e21fcc91928d48e679389298d830b';
const URL = 'https://api.github.com/repos/gurzaf/Maclic-Web/dispatches';

const WebAppButton = () => {
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    let timer;
    if (disabled) {
      timer = setTimeout(() => {
        setDisabled(false);
      }, 30000);
    }
    return () => !!timer && clearTimeout(timer);
  }, [disabled]);
  const webAppFunction = () => {
    const init = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.github.everest-preview+json',
        Authorization: `token ${TOKEN}`,
      },
      body: JSON.stringify({ event_type: 'update' }),
    };
    // eslint-disable-next-line no-undef
    fetch(URL, init)
      .catch((e) => console.warn(e))
      .finally(() => setDisabled(true));
  };
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={webAppFunction}
      startIcon={<Cloud />}
    >
      Actualizar Sitio Web
    </Button>
  );
};

export default WebAppButton;
