const DATE_TYPE = require('./dateTypes');
const ROLES = require('./roles');
const ROUTES = require('./routes');

const getICSLink = (calendarId) => {
  const base = encodeURIComponent(calendarId); // eslint-disable-line
  return `https://calendar.google.com/calendar/b/2?cid=${base}`;
};

const VERSION = 'Ártico';
const VERSION_CODE = '2.0.5';

module.exports = {
  DATE_TYPE, ROLES, ROUTES, getICSLink, VERSION, VERSION_CODE,
};
