import React from 'react';
import * as PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  green: {
    backgroundColor: green[500],
    color: 'white',
    fontWeight: 'bold',
  },
  blue: {
    backgroundColor: blue[500],
    color: 'white',
    fontWeight: 'bold',
  },
  red: {
    backgroundColor: red[500],
    color: 'white',
    fontWeight: 'bold',
  },
  alpha: {
    backgroundColor: grey[500],
    color: 'white',
    fontWeight: 'bold',
  },
}));

const MembershipChip = (props) => {
  const classes = useStyles();
  const { value } = props;
  return (
    <Chip
      {...props}
      component="span"
      className={classes[value]}
    />
  );
};

MembershipChip.defaultProps = {
  value: '',
  label: '',
};

MembershipChip.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default MembershipChip;
