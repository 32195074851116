import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import BusinessForm from '../businessForm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from '../fields';
import Loading from '../../../components/loading';
import MDialog from '../../../components/mdialog';
import {
  Form as DocumentForm,
  Fields as DocumentFields,
  DocumentsList,
  uploadMultipleDocuments,
} from '../Documents';
import DragNDrop from '../../../components/dragNdrop';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import MPanel from '../../../components/panel';

const Edit = ({
  firebase, notification, place,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [loaded, setLoaded] = React.useState(-1);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    let unsubscribeFiles;
    if (place) {
      unsubscribeFiles = firebase.placeFiles(place.id).onSnapshot((snap) => {
        const docs = [];
        snap.forEach((docFile) => docs.push(DocumentFields.getInstance(docFile)));
        setDocuments(docs);
        setLoading(false);
      }, () => {
        setError('Ups! Algo no salió bien cargando el formulario de edición y los archivos 😔');
        setLoading(false);
      });
    }
    return () => {
      if (unsubscribeFiles) {
        unsubscribeFiles();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  const handleDrop = (source) => {
    uploadMultipleDocuments(source, place, firebase, setLoaded)
      .then((res) => notification.setMessage({ type: 'success', message: res }))
      .catch((err) => notification.setMessage({ type: 'error', message: err.message }));
  };

  return (
    <>
      {loading ? (
        <Grid item xs={12}>
          <MPanel withPaper title="Editar Lugar/Negocio">
            <Loading />
          </MPanel>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <MPanel withPaper title="Editar Lugar/Negocio">
              <BusinessForm isNew={false} defaultValues={place} />
            </MPanel>
          </Grid>
          <Grid item xs={12} md={6}>
            <MPanel withPaper>
              <DragNDrop
                onClick={() => setOpen(true)}
                disabled={!place}
                handleDrop={handleDrop}
                loaded={loaded}
              />
            </MPanel>
            <DocumentsList place={place} documents={documents} />
          </Grid>
        </>
      )}
      {!!place && (
        <MDialog open={open} handleClose={() => setOpen(false)} title="Cargar nuevo documento">
          <DocumentForm place={place} onSuccessAction={() => setOpen(false)} />
        </MDialog>
      )}
      {!!error && (
        <MPanel title="Editar Lugar/Negocio" withPaper>
          <Typography display="inline" variant="h5" component="p">
            {error}
          </Typography>
        </MPanel>
      )}
    </>
  );
};

Edit.defaultProps = {
  place: null,
};

Edit.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  place: Fields.getPropTypes(),
};

export default compose(
  withFirebase,
  withNotification,
)(Edit);
