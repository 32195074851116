import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography,
} from '@material-ui/core';
import { compose } from 'recompose';
import ArtistaItem from './item';
import { isLolaAdmin } from '../../../components/access';
import { MButton } from '../../../components/form';
import * as ROUTES from '../../../constants/routes';
import Container from '../../../components/dashboard/container';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import Loading from '../../../components/loading';

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const ArtistasList = ({ firebase, notification }) => {
  const classes = useStyles();
  const [artistas, setArtistas] = React.useState(null);
  React.useEffect(() => {
    const unsubscribe = firebase.artistas().onSnapshot((snap) => {
      const items = [];
      snap.forEach((doc) => {
        items.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setArtistas(items);
    }, (err) => {
      console.warn(err);
      notification.setMessage({ type: 'warning', message: 'Ups! Algo pasó mientras se cargaban los artistas 😥, intentalo de nuevo.' });
    });
    return () => unsubscribe();
  }, [firebase, notification]);

  let component;

  if (artistas) {
    if (artistas.length === 0) {
      component = (
        <Typography variant="button" display="block" className={classes.emptyMessage}>
          No se han creado artistas.
        </Typography>
      );
    } else {
      component = (
        <Grid spacing={1} className={classes.grid} container>
          {artistas.map((item) => (
            <Grid key={item.id} xs={12} sm={4} md={3} item>
              <ArtistaItem artista={item} />
            </Grid>
          ))}
        </Grid>
      );
    }
  } else {
    component = (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }

  return (
    <Container
      title="Listado de artistas de Fotografía y Pintura"
      header={(
        <MButton fullWidth={false} label="Nuevo Artista" to={`${ROUTES.ARTISTAS}/new`} />
      )}
    >
      {component}
    </Container>
  );
};

ArtistasList.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withNotification,
  withFirebase,
)(ArtistasList);
