import React from 'react';
import * as PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MIconButton from './iconButton';

const styles = () => ({
  buttonDelete: {
    // backgroundColor: grey[50],
    // color: grey[500],
    '&:hover': {
      backgroundColor: grey[100],
      color: red[900],
    },
  },
});

const DeleteButton = ({ classes, onClick, ...others }) => (
  <MIconButton {...others} onClick={onClick} className={classes.buttonDelete}>
    <DeleteIcon fontSize="small" />
  </MIconButton>
);

DeleteButton.defaultProps = {
  onClick: null,
};

DeleteButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(DeleteButton);
