import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import LocatedComponent from './locatedComponent';

const CurrentLocation = ({ onGetCurrentLocation }) => {
  const ref = React.useRef(null);
  const [disabled, setDisabled] = React.useState(false);
  const [locating, setLocating] = React.useState(false);
  const getLocation = () => {
    if (ref.current) {
      setLocating(true);
      ref.current.getLocation();
    }
  };
  const locationCallBack = (result) => {
    if (result) {
      if (result.status === 'deactivated' || result.status === 'unsupported') {
        setLocating(false);
        setDisabled(true);
        return;
      }
      const { latitude: lat, longitude: lng } = result.coords;
      onGetCurrentLocation({ lat, lng });
      setDisabled(false);
      setLocating(false);
      return;
    }
    setDisabled(true);
    setLocating(false);
  };
  return (
    <div>
      <Button disabled={disabled || locating} size="small" color="primary" onClick={getLocation}>
        Obtener ubicación
      </Button>
      <LocatedComponent ref={ref} locating={locating} locationCallBack={locationCallBack} />
    </div>
  );
};

CurrentLocation.propTypes = {
  onGetCurrentLocation: PropTypes.func.isRequired,
};

export default CurrentLocation;
