import React from 'react';
import NotificationStore from './store';

const withNotification = (Component) => (props) => (
  <NotificationStore.Consumer>
    {
      (notification) => (<Component {...props} notification={notification} />)
    }
  </NotificationStore.Consumer>
);

export default withNotification;
