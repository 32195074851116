import React from 'react';
import * as PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import MIconButton from './iconButton';

const styles = () => ({
  button: {
    '&:hover': {
      backgroundColor: grey[100],
      color: blue[900],
    },
  },
});

const DetailsButton = ({ classes, onClick, ...others }) => (
  <MIconButton {...others} onClick={onClick} className={classes.button}>
    <ListIcon fontSize="small" />
  </MIconButton>
);

DetailsButton.defaultProps = {
  onClick: null,
};

DetailsButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(DetailsButton);
