import React from 'react';
import * as PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, makeStyles, OutlinedInput,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(2, 1, 1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const defaultOption = { label: '', value: '' };

const MSimpleSelect = ({
  id, value, label, required, options, onChange, disabled,
}) => {
  const classes = useStyles();
  const theOptions = React.useRef([defaultOption]);
  React.useEffect(() => {
    theOptions.current = required
      ? Object.values(options) : [defaultOption, ...Object.values(options)];
  }, [options, required]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (event) => {
    onChange({
      id,
      value: event.target.value,
    });
  };
  return (
    <div className={classes.root}>
      <FormControl variant="outlined" disabled={disabled} margin="normal" required={required} className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor={`${label}select`}>
          {label}
        </InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          input={<OutlinedInput labelWidth={labelWidth} name={`${label}select`} id={`${label}select`} />}
        >
          {theOptions.current.map(item => (
            <MenuItem key={`${id}${item.value}`} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

MSimpleSelect.defaultProps = {
  required: false,
  disabled: false,
};

MSimpleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MSimpleSelect;
