import React from 'react';
import * as PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const RenderPhone = ({ value }) => (
  <Link component="a" href={`tel:${value}`}>
    {value}
  </Link>
);

RenderPhone.defaultProps = {
  value: '',
};

RenderPhone.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default RenderPhone;
