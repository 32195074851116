import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit';
import Calendar from '@material-ui/icons/CalendarToday';
import Store from '@material-ui/icons/Store';
import Place from '@material-ui/icons/Place';
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore';
import Star from '@material-ui/icons/Star';
import Notes from '@material-ui/icons/Notes';
import Translate from '@material-ui/icons/Translate';
import { isCoordinator, isTranslator, isAuthor } from '../../../components/access';

const sections = {
  general: {
    label: 'Información General',
    icon: Store,
    value: 'general',
    isRestricted: (membership, authUser) => !isAuthor(authUser),
  },
  edit: {
    label: 'Editar',
    icon: Edit,
    isRestricted: (membership, authUser) => !isCoordinator(authUser),
    value: 'edit',
  },
  description: {
    label: 'Descripción y Fotografías',
    icon: Info,
    value: 'description',
    isRestricted: (membership, authUser) => !isAuthor(authUser),
  },
  products: {
    label: 'Productos',
    icon: LocalGroceryStore,
    value: 'products',
    isRestricted: (membership, authUser) => !isAuthor(authUser) || membership === 'alpha',
  },
  story: {
    label: 'Historias destacadas',
    icon: Star,
    value: 'story',
    isRestricted: (membership, authUser) => !isAuthor(authUser),
  },
  calendar: {
    label: 'Calendario',
    icon: Calendar,
    value: 'calendar',
    isRestricted: (membership, authUser) => !isAuthor(authUser),
  },
  contact: {
    label: 'Sedes y Datos de contacto',
    icon: Place,
    value: 'contact',
    isRestricted: (membership, authUser) => !isAuthor(authUser),
  },
  translation: {
    label: 'Traducir',
    icon: Translate,
    value: 'translation',
    isRestricted: (membership, authUser) => !isTranslator(authUser),
  },
  notes: {
    label: 'Notas',
    icon: Notes,
    value: 'notes',
    isRestricted: () => false,
  },
};

export default sections;
