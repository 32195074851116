import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  makeStyles, Card, CardActions,
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { Background } from 'react-imgix';
// import defaultImage from '../../media/maclic-transparent.png';
import MembershipChip from '../../components/renders/renderMembershipChip';
import Fields, { MEMBERSHIPS } from './fields';
import { domain } from '../../model/Images';
import {
  isAuthor, isAuthorOrTranslator, isCoordinator, isTranslator,
} from '../../components/access';
import * as ROUTES from '../../constants/routes';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import confirm from '../../components/confirm';
import {
  DetailsButton, DeleteButton, EditButton, TranslateButton,
} from '../../components/buttons';

const defaultImage = encodeURI(`${domain}/defaults/maclic-transparent.png`);

const useStyles = makeStyles((theme) => {
  const media = {
    width: '100%',
    paddingTop: '60%',
    position: 'relative',
  };
  return {
    // card: {
    //   maxWidth: 345,
    // },
    titleContainer: {
      position: 'absolute',
      left: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    title: {
      ...theme.typography.subtitle1,
      fontWeight: 500,
      padding: 0,
      margin: 0,
      color: 'white',
      textShadow: '1px 1px 1px #707070',
    },
    alpha: {
      ...media,
      backgroundColor: grey[800],
    },
    blue: {
      ...media,
      backgroundColor: blue[800],
    },
    green: {
      ...media,
      backgroundColor: green[800],
    },
    red: {
      ...media,
      backgroundColor: red[800],
    },
  };
});

const getEditButton = (id, authUser, history) => {
  if (isCoordinator(authUser)) {
    return (
      <EditButton
        aria-label="Modificar"
        onClick={() => {
          history.push(`${ROUTES.PLACES}/detail/${id}/edit`);
        }}
      />
    );
  }
  return null;
};

const getTranslateButton = (id, authUser, history) => {
  if (isTranslator(authUser)) {
    return (
      <TranslateButton
        aria-label="Traducir"
        onClick={() => {
          history.push(`${ROUTES.PLACES}/detail/${id}/translation`);
        }}
      />
    );
  }
  return null;
};

const getDetailsButton = (id, authUser, history) => {
  if (isAuthor(authUser)) {
    return (
      <DetailsButton
        aria-label="Ver Detalles"
        onClick={() => {
          history.push(`${ROUTES.PLACES}/detail/${id}`);
        }}
      />
    );
  }
  return null;
};

const deleteAction = (id, firebase, notification) => {
  confirm(
    '¿Está seguro de borrar el lugar/negocio?',
    'Confirmar Borrar',
  )
    .then(
      () => {
        firebase.place(id)
          .delete()
          .then(() => {
            notification.setMessage({ message: 'Lugar/Negocio borrado satisfactoriamente 🙂', type: 'success' });
          })
          .catch(() => {
            notification.setMessage({ message: 'Algo no salió bien borrando el lugar/negocio 😥', type: 'warning' });
          });
      },
      () => {},
    );
};

const getDeleteButton = (id, authUser, firebase, notification) => {
  if (isCoordinator(authUser)) {
    return (
      <DeleteButton
        aria-label="Borrar"
        onClick={() => deleteAction(id, firebase, notification)}
      />
    );
  }
  return null;
};

const PlaceItem = ({
  place, authUser, history, firebase, notification,
}) => {
  const classes = useStyles();
  const [display, setDisplay] = React.useState({
    id: '',
    name: 'Maclic Place',
    membership: 'blue',
    membershipLabel: 'Blue',
    image: defaultImage,
  });

  React.useEffect(() => {
    let image = defaultImage;
    if (place.photos && place.photos.length > 0) {
      // image = buildURL(`${domain}${place.photos[0].reference.replace('/images', '')}`,
      // { w: 400 });
      image = encodeURI(`${domain}${place.photos[0].reference.replace('/images', '')}`);
    }

    setDisplay({
      name: place.name,
      id: place.id,
      membership: place.membership,
      membershipLabel: MEMBERSHIPS[place.membership].label,
      image,
    });
  }, [place]);

  const editButton = getEditButton(display.id, authUser, history);
  const detailsButton = getDetailsButton(display.id, authUser, history);
  const deleteButton = getDeleteButton(display.id, authUser, firebase, notification);
  const translateButton = getTranslateButton(display.id, authUser, history);

  return (
    <Card>
      <Background src={display.image} className={classes[display.membership]}>
        <div className={classes.titleContainer}>
          <h5 className={classes.title}>
            {display.name}
          </h5>
          <MembershipChip
            style={{ fontSize: 10, height: 20 }}
            value={display.membership}
            label={display.membershipLabel}
          />
        </div>
      </Background>
      <CardActions disableSpacing>
        {editButton}
        {deleteButton}
        {detailsButton}
        {translateButton}
      </CardActions>
    </Card>
  );
};

PlaceItem.propTypes = {
  place: Fields.getPropTypes().isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withCurrentUser,
  withNotification,
  withFirebase,
  withRouter,
)(PlaceItem);
