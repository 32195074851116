import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import SectionTitle from './SectionTitle';

const DEFAULT_TITLE = 'Maclic Consola de Administración';

export { DEFAULT_TITLE, SectionTitle };

const Title = ({ title, children }) => {
  const resultTitle = !title ? DEFAULT_TITLE : `${title} | ${DEFAULT_TITLE}`;
  return (
    <DocumentTitle title={resultTitle}>
      <>
        {children}
      </>
    </DocumentTitle>
  );
};

Title.defaultProps = {
  title: null,
};

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
