import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { TYPES, validate } from '../validator';
import LabelItem from './labelItem';
import MMultiSelect from './mMultiSelect';

const MultiSelectFormInput = ({
  reference, value, onValueChange, label, error,
  validationType, validationMessage, id, required,
  disabled,
}) => {
  const onChangeEvent = (values) => {
    const newValue = values ? values.map((item) => item.value) : [];
    const result = {
      id,
      value: newValue,
    };
    if (!validationType) {
      onValueChange(result);
      return;
    }
    const validation = validate({
      id,
      value: result.value,
      validationType,
      required,
    });
    onValueChange({
      ...result,
      ...validation,
    });
  };

  const values = value.map((item) => reference.values[item]);
  const labelItemValue = Array.isArray(value) && value.length > 0 ? 'content' : '';

  return (
    <>
      <LabelItem required={required} value={labelItemValue} label={label} />
      <MMultiSelect
        setValue={onChangeEvent}
        value={values}
        options={Object.values(reference.values)}
        label={label}
        noOptionsMessage="No se cargaron las opciones."
        disabled={disabled}
      />
      {!!error && (
        <Typography color="error" variant="caption" component="span">
          {validationMessage}
        </Typography>
      )}
    </>
  );
};

MultiSelectFormInput.defaultProps = {
  error: false,
  validationType: TYPES.SELECT,
  validationMessage: null,
  required: false,
  disabled: false,
};

MultiSelectFormInput.propTypes = {
  reference: PropTypes.shape({
    // eslint-disable-next-line
    values: PropTypes.object.isRequired,
  }).isRequired,
  // eslint-disable-next-line
  value: PropTypes.any.isRequired,
  onValueChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  validationType: PropTypes.oneOf([...Object.values(TYPES)]),
  validationMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MultiSelectFormInput;
