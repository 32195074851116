import React from 'react';
import FieldsTemplate from '../../lib/FieldsTemplate';
import { AsyncColumnRender } from '../../components/table';
import TableRenderMembershipChip from './membershipChip';

export const MEMBERSHIPS = {
  blue: {
    value: 'blue',
    label: 'Blue',
    content: true,
    products: 30,
    photos: 4,
  },
  green: {
    value: 'green',
    label: 'Green',
    content: true,
    products: 30,
    photos: 4,
  },
  red: {
    value: 'red',
    label: 'Red',
    content: false,
    products: 20,
    photos: 4,
  },
  alpha: {
    value: 'alpha',
    label: 'Alpha',
    content: false,
    products: 0,
    photos: 1,
  },
};

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre Lugar',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de negocio no válido',
    required: true,
  },
  client: {
    id: 'client',
    label: 'Cliente',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'clients',
      label: ['firstName', 'lastName'],
      addNew: 'Agregar Nuevo Cliente?',
      validation: 'firstName',
    },
    validationMessage: 'Débe indicar el cliente al que pertenece el negocio/lugar.',
    required: true,
  },
  address: {
    id: 'address',
    label: 'Dirección',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Dirección no válida',
    required: false,
  },
  contactName: {
    id: 'contactName',
    label: 'Persona Contacto',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  contactPhone: {
    id: 'contactPhone',
    label: 'Teléfono Contacto',
    value: '',
    type: 'text',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: false,
  },
  contactEmail: {
    id: 'contactEmail',
    label: 'Correo electrónico Contacto',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: false,
  },
  membership: {
    id: 'membership',
    value: '',
    type: 'select',
    label: 'Membresía',
    validationType: TYPES.SELECT,
    reference: {
      values: MEMBERSHIPS,
    },
    validationMessage: 'Débe indicar una membresía.',
    required: true,
  },
  categories: {
    id: 'categories',
    label: 'Categorías',
    value: [],
    type: 'multireference',
    validationType: TYPES.MULTIREFERENCE,
    reference: {
      entity: 'categories',
      label: ['name'],
      addNew: 'Agregar Nueva Categoría?',
      validation: 'name',
    },
    validationMessage: 'Débe indicar al menos una categoría',
    required: false,
  },
  advisor: {
    id: 'advisor',
    label: 'Asesor Venta',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'users',
      label: ['username'],
      validation: 'username',
    },
    validationMessage: 'Débe indicar un asesor de venta.',
    required: true,
  },
  editor: {
    id: 'editor',
    label: 'Redactor',
    value: '',
    type: 'reference',
    validationType: TYPES.REFERENCE,
    reference: {
      entity: 'users',
      label: ['username'],
      validation: 'username',
    },
    validationMessage: 'Débe indicar un editor/redactor.',
    required: true,
  },
  delivery: {
    id: 'delivery',
    value: false,
    type: TYPES.BOOLEAN,
    label: 'Domicilios',
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  published: {
    id: 'published',
    label: 'Publicada',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  photos: {
    id: 'photos',
    type: TYPES.MULTIFILE,
    value: [],
  },
  links: {
    id: 'links',
    value: [],
    type: TYPES.LINKS,
  },
  venues: {
    id: 'venues',
    value: [],
    type: TYPES.LINKS,
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
  details: {
    id: 'details',
    value: null,
    validationType: TYPES.EDITOR,
  },
  details_en: {
    id: 'details_en',
    value: '',
  },
  name_en: {
    id: 'name_en',
    value: '',
  },
  story_en: {
    id: 'story_en',
    value: '',
  },
  story_color: {
    id: 'story_color',
    value: '',
  },
  story_published: {
    id: 'story_published',
    value: false,
    type: TYPES.BOOLEAN,
  },
  completed: {
    id: 'completed',
    value: '',
  },
  translated: {
    id: 'translated',
    value: '',
  },
  story_title: {
    id: 'story_title',
    value: '',
  },
  story_title_en: {
    id: 'story_title_en',
    value: '',
  },
  story: {
    id: 'story',
    value: null,
    validationType: TYPES.EDITOR,
  },
  story_photo: {
    id: 'story_photo',
    value: '',
  },
  story_photo_URL: {
    id: 'story_photo_URL',
    value: '',
  },
  notes: {
    id: 'notes',
    value: null,
    validationType: TYPES.EDITOR,
  },
};

class FieldsBusiness extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }

  tableDefaults(firebase) {
    const columnsObject = this.columnsTable();

    columnsObject.client.hidden = true;
    // columnsObject.client.render = rowData => (
    //   <AsyncColumnRender
    //     loadFunction={() => new Promise((resolve) => {
    //       firebase.client(rowData.client)
    //         .get()
    //         .then((doc) => {
    //           const docData = doc.data();
    //           resolve(`${docData.firstName} ${docData.lastName}`);
    //         });
    //     })}
    //   />
    // );

    columnsObject.contactPhone.hidden = true;
    columnsObject.contactEmail.hidden = true;
    // columnsObject.contactPhone.render = RenderPhone('contactPhone');
    // columnsObject.contactEmail.render = TableRenderEmail('contactEmail');

    columnsObject.advisor.hidden = true;
    // columnsObject.advisor.render = rowData => (
    //   <AsyncColumnRender
    //     loadFunction={() => new Promise((resolve) => {
    //       firebase.user(rowData.advisor)
    //         .get()
    //         .then(doc => resolve(doc.data().username));
    //     })}
    //   />
    // );

    columnsObject.editor.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          firebase.user(rowData.editor)
            .get()
            .then((doc) => {
              if (doc.exists) {
                resolve(doc.data().username);
              } else {
                resolve('No se encontró');
              }
            });
        })}
      />
    );

    columnsObject.membership.render = TableRenderMembershipChip('membership', MEMBERSHIPS);

    columnsObject.createdBy.hidden = true;
    columnsObject.id.hidden = true;
    columnsObject.details.hidden = true;
    columnsObject.notes.hidden = true;
    columnsObject.photos.hidden = true;
    columnsObject.links.hidden = true;
    columnsObject.venues.hidden = true;

    return {
      columns: Object.values(columnsObject),
      title: 'Listado de Lugares/Negocios',
    };
  }
}

const getContentMemberships = () => {
  const res = [];
  Object.keys(MEMBERSHIPS).forEach((item) => {
    if (MEMBERSHIPS[item].content) {
      res.push(item);
    }
  });
  return res;
};

export const CONTENT_MEMBERSHIPS = getContentMemberships();

export default new FieldsBusiness(FIELDS);
