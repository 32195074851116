import React from 'react';
import { compose } from 'recompose';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import Title from '../../components/title';
import Summary from './Summary';
import WebPanel from '../../components/webapp';

const title = 'Inicio';

const greeting = () => {
  const hour = moment().format('HH');
  if (hour <= 5) {
    return 'Linda madrugada';
  }
  if (hour < 12) {
    return 'Buenos días';
  }
  if (hour < 19) {
    return 'Buenas tardes';
  }
  return 'Buenas noches';
};

const useStyles = makeStyles((theme) => ({
  greeting: {
    fontSize: '1.8rem',
    color: 'black',
    fontWeight: 300,
    marginBottom: 10,
    marginTop: 20,
  },
  name: {
    fontWeight: 400,
    fontSize: '2rem',
  },
  welcome: {
    fontSize: '1.4rem',
    color: 'black',
    fontWeight: 300,
  },
  welcomePanel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
  },
}));

const HomePage = ({ authUser }) => {
  const classes = useStyles();
  return (
    <Title title={title}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={8} className={classes.welcomePanel}>
          <Typography component="p" variant="h4" className={classes.greeting}>
            {greeting()}
            <span className={classes.name}>
              {` ${authUser.username}`}
            </span>
            ,
          </Typography>
          <Typography component="p" variant="h4" className={classes.welcome}>
            Bienvenido al panel de administración y gestión de recursos de Maclic.
          </Typography>
          <Summary />
          <WebPanel />
        </Grid>
      </Grid>
    </Title>
  );
};

const condition = (authUser) => !!authUser;

HomePage.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withAuthorization(condition),
  withCurrentUser,
)(HomePage);
