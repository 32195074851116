import React from 'react';
import { compose } from 'recompose';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Fields from '../fields';
import Loading from '../../../components/loading';
import * as ROUTES from '../../../constants/routes';
import Container from '../../../components/dashboard/container';
import SectionTranslate from '../../Business/Sections/translate/sectionTranslate';
import { withAuthorization } from '../../../model/Session';
import { isAuthorOrTranslator } from '../../../components/access';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';

const EDITIONS = [
  {
    field: 'title',
    type: 'text',
    label: 'Título de la historia',
    englishLabel: 'Story title',
    required: true,
  },
  {
    field: 'category',
    type: 'text',
    label: 'Categoría',
    englishLabel: 'Category',
    required: true,
  },
  {
    field: 'details',
    type: 'textarea',
    label: 'Historia Destacada',
    englishLabel: 'Highlighted Story',
    required: false,
  },
];

const redirect = (history) => {
  history.push(ROUTES.STORIES);
};

const StoryTranslate = ({ firebase, match, history }) => {
  const [story, setStory] = React.useState(null);
  React.useEffect(() => {
    const { id } = match.params;
    if (id) {
      firebase.story(id)
        .get()
        .then((doc) => {
          const storyData = Fields.getInstance(doc);
          const object = { id: doc.id, ...storyData };
          setStory({
            reference: firebase.story(object.id),
            object,
            objectName: 'story',
            editions: EDITIONS,
            label: 'Historia Destacada',
            value: object.title,
            index: 0,
          });
        })
        .catch(() => redirect(history));
    } else {
      redirect(history);
    }
  }, [firebase, history, match.params]);

  let title = 'Cargando...';
  let component = <Loading />;
  if (story) {
    title = `Editando ${story.label}`;
    component = (
      <SectionTranslate
        object={story.object}
        title={story.label}
        editions={story.editions}
        reference={story.reference}
        objectName={story.objectName}
      />
    );
  }

  return (
    <Container backAction={() => redirect(history)} title={title}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          {component}
        </Grid>
      </Grid>
    </Container>
  );
};

StoryTranslate.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAuthorOrTranslator),
  withFirebase,
  withRouter,
)(StoryTranslate);
