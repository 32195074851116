import React from 'react';
import PropTypes from 'prop-types';

const AsyncColumnRender = ({
  loadingValue, loadFunction,
}) => {
  const [value, setValue] = React.useState(loadingValue);
  React.useEffect(() => {
    loadFunction()
      .then((val) => setValue(val))
      .catch(() => setValue('No se pudo cargar'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {value}
    </>
  );
};

AsyncColumnRender.defaultProps = {
  loadingValue: 'Cargando...',
};

AsyncColumnRender.propTypes = {
  loadingValue: PropTypes.string,
  loadFunction: PropTypes.func.isRequired,
};

export default AsyncColumnRender;
