const save = async (notification, reference, data) => {
  await reference.set(data, { merge: true });
  return reference;
};

const uploadFile = (id, file, firebase, setUploaded, basePath) => new Promise((resolve, reject) => {
  const entityPath = id === '' ? 'new' : id;
  const timeStamp = new Date().getTime();
  const filePath = `/images/${basePath}/${entityPath}/${timeStamp}-${file.name}`;
  firebase.uploadFile(filePath, file, (progress) => {
    setUploaded(progress.value);
  }, (err, url) => {
    if (err) {
      setUploaded(-1);
      reject(err);
    }
    setUploaded(100);
    resolve({ url, reference: filePath });
  });
});

const persist = (
  firebase, isNew, fields, authUser, data, notification, callback, entity, message,
) => {
  const reference = isNew ? firebase[entity]().doc()
    : firebase[entity]().doc(fields.id.value);
  let successMessage = `Se modificó ${message} 😀`;
  if (isNew) {
    // eslint-disable-next-line no-param-reassign
    data.createdBy = authUser.uid;
    successMessage = `Se creó ${message} 😀`;
  }
  save(notification, reference, data)
    .then((ref) => {
      notification.setMessage({ message: successMessage, type: 'success' });
      callback(false, true, ref.id);
    })
    .catch((e) => {
      notification.setMessage({ message: e.message, type: 'error' });
      callback(false);
    });
};

export {
  uploadFile,
  persist,
};
