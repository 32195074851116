import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const MCheckBox = ({
  value, label, color, onChange,
}) => (
  <FormControlLabel
    control={<Checkbox onChange={onChange} checked={value} color={color} />}
    label={label}
  />
);

MCheckBox.defaultProps = {
  color: 'primary',
  onChange: null,
};

MCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func,
};

export default MCheckBox;
