import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActionArea, CardMedia, CardContent, Typography, CardActions,
} from '@material-ui/core';
import { buildURL } from 'react-imgix';
import moment from 'moment';
import Fields from './fields';
import Confirm from '../../../components/confirm';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { isLolaAdmin } from '../../../components/access';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import { domain, defaultImage } from '../../../model/Images';
import { EditButton, DeleteButton, DownloadButton } from '../../../components/buttons';
import ExpresionDetails from './details';
import 'moment/locale/es';

moment.locale('es');

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  media: {
    height: 340,
  },
}));

const deleteExpresion = (expresion, firebase, notification) => {
  let message;
  firebase.expresion(expresion.id)
    .delete()
    .then(() => {
      if (expresion.photo) {
        firebase.deleteFile(expresion.photo)
          .then(() => {})
          .catch(() => {});
      }
      message = { type: 'success', message: 'Expresión artística borrada satisfactoriamente 😀' };
    })
    .catch(() => {
      message = { type: 'success', message: 'Ups! Algo no salió bien borrando la expresión artística 🥺' };
    })
    .finally(() => notification.setMessage(message));
};

const ExpresionItem = ({
  expresion, firebase, notification, history,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const deleteAction = () => {
    Confirm('¿Está seguro de borrar esta expresión artística?')
      .then(
        () => deleteExpresion(expresion, firebase, notification),
        () => {},
      );
  };

  const editAction = () => {
    history.push(`${ROUTES.EXPRESIONES}/edit/${expresion.id}`);
  };

  let image = defaultImage;
  if (expresion.photoURL) {
    image = buildURL(`${domain}${expresion.photo.replace('/images', '')}`, { w: 400 });
  }

  const downloadAction = () => {
    const url = buildURL(`https:${domain}${expresion.photo.replace('/images', '')}`);
    // eslint-disable-next-line no-undef
    window.open(url);
  };

  return (
    <>
      <Card>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia
            className={classes.media}
            image={image}
            title={expresion.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h3">
              {expresion.title}
            </Typography>
            <Typography variant="caption" component="h6">
              {moment(expresion.datetime).format('dddd, DD \\d\\e MMMM \\d\\e YYYY hh:mm a')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <EditButton onClick={editAction} />
          <DeleteButton onClick={deleteAction} />
          {!!expresion.photoURL && <DownloadButton onClick={downloadAction} />}
        </CardActions>
      </Card>
      <ExpresionDetails handleClose={() => setOpen(false)} isOpen={open} expresion={expresion} />
    </>
  );
};

ExpresionItem.propTypes = {
  expresion: Fields.getPropTypes().isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withFirebase,
  withNotification,
  withRouter,
)(ExpresionItem);
