import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import * as ROLES from '../../../constants/roles';

const rolesKeys = Object.keys(ROLES);
const roles = rolesKeys.map(key => ({
  value: ROLES[key],
  label: ROLES[key],
}));

const useStyles = adminUsers => (makeStyles(theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    margin: adminUsers ? theme.spacing(2, 0, 1) : theme.spacing(),
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
})));

function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

function Control(props) {
  const {
    selectProps, innerRef, children, innerProps,
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const {
    innerRef, isFocused, isSelected, innerProps, children,
  } = props;
  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

Option.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/require-default-props
  isFocused: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer({ selectProps, children }) {
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

ValueContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function MultiValue({
  children, selectProps, isFocused, removeProps,
}) {
  return (
    <Chip
      tabIndex={-1}
      label={children}
      color="primary"
      className={clsx(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  isFocused: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  removeProps: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object.isRequired,
};

function Menu({ selectProps, innerProps, children }) {
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

Menu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line
  innerProps: PropTypes.object,
  // eslint-disable-next-line
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

function RolesInput({ value, setValue, adminUsers }) {
  const classes = useStyles(adminUsers)();
  const theme = useTheme();

  function handleChangeMulti(val) {
    setValue(val);
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          TextFieldProps={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          options={roles}
          components={components}
          value={value}
          onChange={handleChangeMulti}
          placeholder="Seleccione Roles"
          isMulti
        />
      </NoSsr>
    </div>
  );
}

RolesInput.defaultProps = {
  adminUsers: false,
};

RolesInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  adminUsers: PropTypes.bool,
};

export default RolesInput;
