import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../../components/dashboard/container';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import DepartmentForm from './departmentForm';
import * as ROUTES from '../../../constants/routes';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import Fields from './fieldsDepartamento';
import Loading from '../../../components/loading';

const EditDepartment = ({ firebase, match, history }) => {
  const [department, setDepartment] = React.useState(Fields.defaultValues());
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const { id } = match.params;
    let unsubscribe;
    if (id) {
      unsubscribe = firebase.department(id)
        .onSnapshot((doc) => {
          const docDepartment = Fields.getInstance(doc);
          setDepartment(docDepartment);
          setLoading(false);
        }, () => history.push(ROUTES.DEPARTAMENTOS));
    } else {
      history.push(ROUTES.DEPARTAMENTOS);
    }
    return () => !!unsubscribe && unsubscribe();
  }, [firebase, history, match.params]);

  return (
    <Container backPath={ROUTES.DEPARTAMENTOS} title={`Departamento ${department.name}`}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loading />
          ) : (
            <DepartmentForm isNew={false} defaultValues={department} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

EditDepartment.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
)(EditDepartment);
