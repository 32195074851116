import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MenuItem from './MenuItem';
import { AuthUserPropTypes, withCurrentUser } from '../../model/Session';
import menuItems from './menuItems';
import { StatusChip } from '../status';

const Navigation = ({ authUser, history, setOpenNavigation }) => {
  const navigate = (path) => {
    history.push(path);
    setOpenNavigation(false);
  };
  return (
    <React.Fragment>
      <Divider />
      <StatusChip />
      <List>
        {menuItems.map(item => (
          <MenuItem
            key={item.label}
            {...item}
            authUser={authUser}
            SubMenuItem={MenuItem}
            navigate={navigate}
          />
        ))}
      </List>
    </React.Fragment>
  );
};

Navigation.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  setOpenNavigation: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withRouter,
)(Navigation);
