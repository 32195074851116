import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Grid, IconButton, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Title from '../title';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4, 0),
    margin: theme.spacing(-3, 0, 3),
    boxShadow: theme.shadows[0],
    marginLeft: 'calc(-50vw + 50%)',
    width: '100vw',
  },
  backButton: {
    marginTop: theme.spacing(-1),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const FullWidthContainer = ({
  title, backPath, tabs, children,
}) => {
  const classes = useStyles();
  return (
    <Title title={title}>
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <IconButton
              size="small"
              className={classes.backButton}
              component={Link}
              to={backPath}
              aria-label="Atrás"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              className={classes.title}
              display="inline"
              variant="h5"
              component="h2"
            >
              {title}
            </Typography>
          </Grid>
          {!!tabs && (
            <Grid item xs={12} lg={7}>
              {tabs}
            </Grid>
          )}
        </Grid>
      </Paper>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Title>
  );
};

FullWidthContainer.defaultProps = {
  backPath: '/',
  tabs: null,
  children: null,
  title: null,
};

FullWidthContainer.propTypes = {
  title: PropTypes.string,
  backPath: PropTypes.string,
  tabs: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FullWidthContainer;
