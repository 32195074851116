import React, { Component } from 'react';
import { compose } from 'recompose';
import Container from '../../../components/dashboard/container';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
// import * as ROLES from '../../../constants/roles';
// import UserList from './list';
import Table from '../../../components/table';
import { MButton } from '../../../components/form';
import * as ROUTES from '../../../constants/routes';
import Roles from './roles';
import { isAdmin } from '../../../components/access';
import MChip from '../../../components/chip';

const tableDefaults = () => ({
  columns: [
    { title: 'Nombre Completo', field: 'username' },
    { title: 'Número telefónico', field: 'phone', editable: 'always' },
    { title: 'Correo Electrónico', field: 'email', editable: 'never' },
    {
      title: 'Roles',
      field: 'roles',
      render: (rowData) => !!rowData.roles
        && rowData.roles.map((item) => (
          <MChip key={`${item}${rowData.uid}`} id={`${item}${rowData.uid}`} label={item} />
        )),
      // eslint-disable-next-line
      editComponent: ({ value, onChange }) => {
        let values;
        if (!Array.isArray(value) || value.length === 0) {
          values = [];
        } else if (!value[0].label) {
          values = value.map((item) => ({ label: item, value: item }));
        } else {
          values = [...value];
        }
        return (
          <Roles
            value={values}
            setValue={(e) => {
              const val = e || [];
              onChange(val);
            }}
          />
        );
      },
    },
  ],
  title: 'Listado de Usuarios',
});

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        values: [],
      },
    };
    this.editAction = this.editAction.bind(this);
    // this.deleteAction = this.deleteAction.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { firebase } = this.props;
    const { data } = this.state;
    firebase.users().onSnapshot((querySnapshot) => {
      data.values = [];
      querySnapshot.forEach((doc) => {
        const newData = doc.data();
        const roles = !!newData.roles && Object.keys(newData.roles);
        if (roles) {
          data.values.push({
            uid: doc.id,
            email: newData.email,
            username: newData.username,
            phone: newData.phone,
            roles,
          });
        }
      });
      this.setState({
        data,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    const { firebase } = this.props;
    firebase.users().onSnapshot(() => {});
  }

  editAction(newData) {
    return new Promise((resolve, reject) => {
      const { firebase } = this.props;
      const roles = {};
      newData.roles.forEach((item) => {
        roles[item.label] = item.value;
      });
      firebase
        .user(newData.uid)
        .set({
          username: newData.username,
          email: newData.email,
          phone: newData.phone,
          roles,
        }, { merge: false })
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  // deleteAction(oldData) {
  //   const { firebase } = this.props;
  //   return firebase
  //     .user(oldData.uid)
  //     .delete();
  // }

  render() {
    const { loading, data } = this.state;
    const defaults = tableDefaults();
    return (
      <Container
        title="Gestión de usuarios"
        header={(
          <MButton
            to={`${ROUTES.USERS}/new`}
            fullWidth={false}
            label="Agregar Usuario"
          />
        )}
      >
        <Table
          data={data.values}
          columns={defaults.columns}
          title={defaults.title}
          isLoading={loading}
          editable={{
            isEditable: () => true,
            onRowUpdate: this.editAction,
          }}
        />
      </Container>
    );
  }
}

UsersPage.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAdmin),
  withFirebase,
)(UsersPage);
