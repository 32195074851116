import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Grid, Divider, makeStyles,
} from '@material-ui/core';
import Fields from './fields';
import LabelItem from '../../../../components/labelItem';
import DeleteButton from '../../../../components/buttons/deleteButton';
import { RenderPhoneWhatsapp } from '../../../../components/renders';
import { MapView } from '../../../../components/map';
import ButtonEditVenue from './buttonEditVenue';
import PeriodList from '../../../../components/schedule/periodList';
import { AuthUserPropTypes, withCurrentUser } from '../../../../model/Session';
import { isEditor } from '../../../../components/access';

const useStyles = makeStyles(theme => ({
  phoneValue: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
  mapContainer: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

const ItemVenue = ({
  venue, deleteAction, index, formSuccess, loading, editAction, authUser,
}) => {
  const classes = useStyles();
  const onDelete = () => {
    deleteAction(index);
  };
  const onEdit = (theVenue) => {
    editAction(index, theVenue);
  };
  const canEdit = isEditor(authUser);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <LabelItem label="Dirección" value={venue.address} />
      </Grid>
      {!!venue.phones && (
        <Grid item xs={12} sm={6}>
          <LabelItem
            label="Teléfono(s)"
            value={venue.phones.map(item => (
              <RenderPhoneWhatsapp key={item.number ? item.number : item} phone={item} />
            ))}
          />
        </Grid>
      )}
      {!!venue.location && (
        <Grid item xs={12} sm={6} className={classes.mapContainer}>
          <MapView value={venue.location} zoom={18} />
        </Grid>
      )}
      {!!venue.schedule && (
        <Grid item xs={12} sm={6}>
          <PeriodList periods={venue.schedule} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider component="hr" className={classes.divider} />
        {canEdit && (
          <ButtonEditVenue
            formSuccess={formSuccess}
            loading={loading}
            onEdit={onEdit}
            venue={venue}
          />
        )}
        {canEdit && <DeleteButton onClick={onDelete} />}
      </Grid>
    </Grid>
  );
};

ItemVenue.defaultProps = {
  index: 0,
  loading: false,
  formSuccess: false,
};

ItemVenue.propTypes = {
  venue: Fields.getPropTypes().isRequired,
  index: PropTypes.number,
  deleteAction: PropTypes.func.isRequired,
  editAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  formSuccess: PropTypes.bool,
  authUser: AuthUserPropTypes.isRequired,
};

export default withCurrentUser(ItemVenue);
