import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Button, Grid, makeStyles, Typography,
} from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../../components/notification';
import { MSimpleSelect } from '../../../../components/form';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-3),
  },
  labelContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    textAlign: 'right',
  },
  buttonContainer: {
    paddingTop: `${theme.spacing(4)}px !important`,
  },
}));

export const LICENSE_STATUS = {
  required: {
    value: 'required',
    label: 'Requieren Licencia Temporal',
  },
  generated: {
    value: 'generated',
    label: 'Licencias generadas',
  },
  pending: {
    value: 'pending',
    label: 'Pendientes',
  },
  unrequired: {
    value: 'unrequired',
    label: 'No Requieren Licencia Temporal',
  },
};

const Filters = ({
  firebase, notification, filter, values: theValues, disabled,
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState(theValues);
  React.useEffect(() => {
    setValues(theValues);
  }, [theValues]);
  const [competitions, setCompetitions] = React.useState({});
  React.useEffect(() => {
    firebase.competitions()
      .get()
      .then((snapshot) => {
        const comps = {};
        snapshot.forEach((doc) => {
          comps[doc.id] = {
            value: doc.id,
            label: doc.data().name,
          };
        });
        setCompetitions(comps);
      })
      .catch((err) => {
        console.warn(err.message);
        notification.setMessage({
          type: 'warning',
          message: 'Ups! No se pudieron cargar las competencias para el filtro.',
        });
      });
  }, [firebase, notification]);
  const onValueChange = (result) => {
    const newValues = { ...values };
    newValues[result.id] = result.value;
    setValues(newValues);
  };
  const onFilter = () => {
    filter(values);
  };
  const clearFilter = () => {
    const data = { event: '', status: '' };
    setValues(data);
    filter(data);
  };
  return (
    <Grid item xs={12} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={1} className={classes.labelContainer}>
          <Typography variant="subtitle2" component="p" color="primary" className={classes.label}>
            Filtrar por
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <MSimpleSelect
            disabled={disabled}
            label="Estado licencia"
            onChange={onValueChange}
            id="status"
            options={LICENSE_STATUS}
            value={values.status}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MSimpleSelect
            disabled={disabled}
            label="Competencias"
            onChange={onValueChange}
            id="event"
            value={values.event}
            options={competitions}
          />
        </Grid>
        <Grid item xs={12} md={3} className={classes.buttonContainer}>
          <Button disabled={disabled} size="small" variant="text" color="primary" onClick={onFilter}>
            Filtrar
          </Button>
          {' '}
          <Button disabled={disabled} size="small" variant="text" color="default" onClick={clearFilter}>
            Limpiar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

Filters.defaultProps = {
  values: {
    status: '',
    event: '',
  },
  disabled: false,
};

Filters.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  filter: PropTypes.func.isRequired,
  values: PropTypes.shape({
    status: PropTypes.string.isRequired,
    event: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
};

export default compose(
  withFirebase,
  withNotification,
)(Filters);
