import React from 'react';
import * as PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const RenderEmail = ({ value }) => (
  <Link component="a" href={`mailto:${value}`}>
    {value}
  </Link>
);

RenderEmail.defaultProps = {
  value: '',
};

RenderEmail.propTypes = {
  value: PropTypes.string,
};

export default RenderEmail;
