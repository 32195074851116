import FieldsTemplate from '../../lib/FieldsTemplate';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  title: {
    id: 'title',
    label: 'Título Historia',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Título de historia no válido',
    required: true,
  },
  category: {
    id: 'category',
    label: 'Categoría',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Categoría de historia no válido',
    required: false,
  },
  details: {
    id: 'details',
    value: '',
    type: 'editor',
    validationType: TYPES.EDITOR,
    label: 'Detalle',
    required: false,
  },
  photo: {
    id: 'photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  color: {
    id: 'color',
    label: 'Color Fondo Historia',
    value: '',
    type: TYPES.COLOR,
    validationType: TYPES.COLOR,
    validationMessage: 'Débe indicar un color válido',
    required: false,
  },
  initialDate: {
    id: 'initialDate',
    label: 'Inicio',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  endDate: {
    id: 'endDate',
    label: 'Fin',
    value: (new Date()).toISOString(),
    type: 'date',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY',
    validationType: TYPES.FUTURE_DATE,
    validationMessage: 'Débe indicar una fecha válida',
    required: true,
  },
  published: {
    id: 'published',
    label: 'Publicada',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  photoURL: {
    id: 'photoURL',
    value: '',
  },
  index: {
    id: 'index',
    value: 0,
    type: TYPES.NUMBER,
  },
  createdBy: {
    id: 'createdBy',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
  title_en: {
    id: 'title_en',
    value: '',
  },
  category_en: {
    id: 'category_en',
    value: '',
  },
  details_en: {
    id: 'details_en',
    value: '',
  },
};

class FieldsProducts extends FieldsTemplate {}

export default new FieldsProducts(FIELDS);
