import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Fields from './fields';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Loading from '../../components/loading';
import { RenderEmail, RenderPhone } from '../../components/renders';
import { SectionTitle } from '../../components/title';

const useStyles = makeStyles(theme => ({
  itemValue: {
    color: 'black',
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(0),
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}));

const PanelClient = ({ clientId, firebase, showTitle }) => {
  const classes = useStyles();

  const [client, setClient] = React.useState(null);
  React.useEffect(() => {
    if (typeof clientId === 'string') {
      firebase.client(clientId).onSnapshot((doc) => {
        setClient(Fields.getInstance(doc));
      });
    } else if (clientId) {
      setClient(clientId);
    }
    return function cleanup() {
      if (typeof clientId === 'string') {
        firebase.client(clientId).onSnapshot(() => {});
      }
    };
  }, [firebase, clientId]);

  if (client) {
    return (
      <div>
        {!!showTitle && (
          <SectionTitle marginBottom title="Cliente/Propietario" />
        )}
        <Typography className={classes.itemValue} component="p" variant="subtitle2">
          {`${client.firstName} ${client.lastName}`}
        </Typography>
        <Typography className={classes.itemLabel} component="p" variant="overline">
          Nombres
        </Typography>
        {!!client.email && (
          <React.Fragment>
            <Typography className={classes.itemValue} component="p" variant="subtitle2">
              <RenderPhone value={client.phone} />
            </Typography>
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Teléfono
            </Typography>
          </React.Fragment>
        )}
        {!!client.email && (
          <React.Fragment>
            <Typography className={classes.itemValue} component="p" variant="subtitle2">
              <RenderEmail value={client.email} />
            </Typography>
            <Typography className={classes.itemLabel} component="p" variant="overline">
              Correo electrónico
            </Typography>
          </React.Fragment>
        )}
      </div>
    );
  }
  return (
    <Loading variant="h6" size={20} />
  );
};

PanelClient.defaultProps = {
  clientId: null,
  showTitle: true,
};

PanelClient.propTypes = {
  showTitle: PropTypes.bool,
  firebase: FirebasePropType.isRequired,
  clientId: PropTypes.oneOfType([
    PropTypes.string,
    Fields.getPropTypes(),
  ]),
};

export default withFirebase(PanelClient);
