import FieldsTemplate from '../../../lib/FieldsTemplate';

export const DISCIPLINAS = {
  musica: {
    label: 'Música',
    value: 'musica',
  },
  teatro: {
    label: 'Teatro',
    value: 'teatro',
  },
  danza: {
    label: 'Danzas',
    value: 'danza',
  },
  circo: {
    label: 'Circo',
    value: 'circo',
  },
  corto: {
    label: 'Cortometraje',
    value: 'corto',
  },
};

const TYPES = FieldsTemplate.TYPES();

const Fields = {
  name: {
    id: 'name',
    label: 'Nombre Artista',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de artísta no válido',
    required: true,
  },
  photo: {
    id: 'photo',
    value: '',
    type: TYPES.FILE,
    fileTypes: ['.jpg', '.png', '.jpeg'],
    validationType: TYPES.FILE,
    label: 'Fotografía Artista',
    multiple: false,
    required: false,
    validationMessage: 'Débe indicar una fotografía con un formato válido.',
  },
  details: {
    id: 'details',
    value: '',
    type: 'editor',
    validationType: TYPES.EDITOR,
    label: 'Detalle',
    required: false,
  },
  phone: {
    id: 'phone',
    label: 'Teléfono',
    value: '',
    type: 'text',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: false,
  },
  email: {
    id: 'email',
    label: 'Correo electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: false,
  },
  title: {
    id: 'title',
    label: 'Título Presentación',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Título de presentación no válido',
    required: true,
  },
  category: {
    id: 'category',
    value: '',
    type: 'select',
    label: 'Categoría',
    validationType: TYPES.SELECT,
    reference: {
      values: DISCIPLINAS,
    },
    validationMessage: 'Débe indicar una categoría.',
    required: true,
  },
  show: {
    id: 'show',
    label: 'ID video Youtube',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'ID de Youtube no válido.',
    required: true,
  },
  start: {
    id: 'start',
    label: 'Tiempo Inicio (segundos)',
    value: '',
    type: 'number',
    validationType: TYPES.NUMBER,
    validationMessage: 'Tiempo de inicio no válido.',
    required: true,
  },
  datetime: {
    id: 'datetime',
    label: 'Inicio',
    value: (new Date()).toISOString(),
    type: 'datetime',
    format: 'dddd, DD \\d\\e MMMM \\d\\e YYYY hh:mm a',
    validationType: TYPES.DATE,
    validationMessage: 'Débe indicar una fecha y hora válidas',
    required: true,
  },
  links: {
    id: 'links',
    value: [],
    type: TYPES.LINKS,
  },
  published: {
    id: 'published',
    label: 'Publicado',
    value: false,
    type: TYPES.BOOLEAN,
    validationType: TYPES.BOOLEAN,
    required: false,
  },
  photoURL: {
    id: 'photoURL',
    value: '',
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsExpresion extends FieldsTemplate {}

export default new FieldsExpresion(Fields);
