import { TYPES } from '../../components/validator';

const FIELDS = {
  username: {
    id: 'username',
    label: 'Nombre de Usuario',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre de usuario no válido.',
    required: true,
  },
  email: {
    id: 'email',
    label: 'Correo Electrónico',
    value: '',
    type: 'email',
    validationType: TYPES.EMAIL,
    validationMessage: 'Correo electrónico no válido.',
    required: true,
  },
  phone: {
    id: 'phone',
    label: 'Número Telefónico',
    value: '',
    type: 'phone',
    validationType: TYPES.PHONE,
    validationMessage: 'Número telefónico no válido.',
    required: true,
  },
  password: {
    id: 'password',
    label: 'Contraseña',
    value: '',
    type: 'password',
    validationType: TYPES.NEW_PASSWORD,
    validationMessage: 'Débe indicar una contraseña válida.',
    required: true,
  },
  passwordTwo: {
    id: 'passwordTwo',
    label: 'Confirmar Contraseña',
    value: '',
    type: 'password',
    validationMessage: 'Las contraseñas no coinciden.',
    required: false,
  },
};

export default FIELDS;
