import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography,
} from '@material-ui/core';
import { compose } from 'recompose';
import { isLolaAdmin } from '../../../components/access';
import { MButton } from '../../../components/form';
import * as ROUTES from '../../../constants/routes';
import Container from '../../../components/dashboard/container';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { withAuthorization } from '../../../model/Session';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import Loading from '../../../components/loading';
import Fields from './fields';
import ExpresionItem from './item';

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

const ExpresionList = ({ firebase, notification }) => {
  const classes = useStyles();
  const [expresiones, setExpresiones] = React.useState(null);
  React.useEffect(() => {
    const unsubscribe = firebase.expresiones().onSnapshot((snap) => {
      const items = [];
      snap.forEach((doc) => {
        const expresion = Fields.getInstance(doc);
        expresion.datetime = expresion.datetime.toDate();
        items.push(expresion);
      });
      setExpresiones(items);
    }, (err) => {
      console.warn(err);
      notification.setMessage({ type: 'warning', message: 'Ups! Algo pasó mientras se cargaban las expresiones artísticas 😥, intentalo de nuevo.' });
    });
    return () => unsubscribe();
  }, [firebase, notification]);

  let component;

  if (expresiones) {
    if (expresiones.length === 0) {
      component = (
        <Typography variant="button" display="block" className={classes.emptyMessage}>
          No se han creado expresiones artísticas.
        </Typography>
      );
    } else {
      component = (
        <Grid spacing={1} className={classes.grid} container>
          {expresiones.map((item) => (
            <Grid key={item.id} xs={12} sm={4} md={3} item>
              <ExpresionItem expresion={item} />
            </Grid>
          ))}
        </Grid>
      );
    }
  } else {
    component = (
      <Grid item xs={12}>
        <Loading />
      </Grid>
    );
  }

  return (
    <Container
      title="Listado de Expresiones Artísticas"
      header={(
        <MButton fullWidth={false} label="Nueva Expresión" to={`${ROUTES.EXPRESIONES}/new`} />
      )}
    >
      {component}
    </Container>
  );
};

ExpresionList.propTypes = {
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withNotification,
  withFirebase,
)(ExpresionList);
