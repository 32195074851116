import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Fields, { FieldsVenues } from './fields';
import { generateInputs, MForm } from '../../../../components/form';
import { validateForm } from '../../../../components/validator';
import { MapInput } from '../../../../components/map';
import Period from '../../../../components/schedule/period';
import {
  NotificationPropTypes, withNotification,
} from '../../../../components/notification';
import { isValidPeriod } from '../../../../components/schedule/util';
import PeriodList from '../../../../components/schedule/periodList';
import PhonesField from './phonesField';

const FormVenue = ({
  venue, isNew, onSubmit, loading, notification,
}) => {
  const [fields, setFields] = React.useState({});
  React.useEffect(() => {
    const theFields = JSON.parse(JSON.stringify(Fields.fields));
    Object.keys(venue).forEach((item) => {
      if (theFields[item]) {
        theFields[item].value = venue[item];
      }
    });
    setFields(theFields);
  }, [venue]);

  const submit = () => {
    onSubmit(FieldsVenues.getInstanceLocal(fields));
  };

  const onChange = (result) => {
    const theFields = { ...fields };
    theFields[result.id].value = result.value;
    theFields[result.id].error = !result.isValid;
    setFields(theFields);
  };

  const onChangeLocation = (location) => {
    onChange({
      id: Fields.fields.location.id,
      value: location,
    });
  };

  const removePeriod = (day, indexPeriod) => {
    const { value, id } = fields.schedule;
    value[day].splice(indexPeriod, 1);
    onChange({
      id,
      value,
    });
  };

  const addPeriod = (period) => {
    const { value } = fields.schedule;
    if (isValidPeriod(value, period)) {
      let day = [];
      if (value[period.day]) {
        day = value[period.day];
      }
      day.push({
        start: period.start,
        end: period.end,
      });
      value[period.day] = day;
      onChange({
        id: fields.schedule.id,
        value,
      });
    } else {
      notification.setMessage({
        type: 'warning',
        message: 'El periodo a agregar no es válido. Revisa si el tiempo no se superpone a otro periodo.',
      });
    }
  };

  const disabled = !validateForm(fields);

  return (
    <MForm
      button={{ label: isNew ? 'Crear Sede' : 'Editar Sede', disabled }}
      loading={loading}
      onSubmit={submit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {!!fields.phones && (
            <PhonesField
              editPhones={value => onChange({ id: 'phones', value, isValid: true })}
              phones={fields.phones.value}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {generateInputs(fields, onChange)}
          {!!fields.location && (
            <MapInput value={fields.location.value} onChange={onChangeLocation} />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Period addPeriod={addPeriod} />
          {!!fields.schedule && (
            <PeriodList periods={fields.schedule.value} removePeriod={removePeriod} />
          )}
        </Grid>
      </Grid>
    </MForm>
  );
};

FormVenue.defaultProps = {
  venue: Fields.defaultValues(),
  isNew: true,
  loading: false,
};

FormVenue.propTypes = {
  venue: Fields.getPropTypes(),
  isNew: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  notification: NotificationPropTypes.isRequired,
};

export default withNotification(FormVenue);
