import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Grid } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import Fields from './fields';
import { validateForm } from '../../../components/validator';
import { generateInputs, MForm } from '../../../components/form';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { NotificationPropTypes, withNotification } from '../../../components/notification';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../../model/Session';
import { isLolaAdmin } from '../../../components/access';
import { changeAction, getRandomString } from '../../../lib/misc';
import * as ROUTES from '../../../constants/routes';
import { persist, uploadFile } from '../functions';

const cleanFields = (newFields, setFields) => {
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].value = '';
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].error = false;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photo.id].file = null;
  // eslint-disable-next-line no-param-reassign
  newFields[Fields.fields.photoURL.id].value = '';
  setFields(newFields);
};

const ExpresionForm = ({
  notification, values, authUser, firebase, history, toEdit,
}) => {
  const [fields, setFields] = React.useState({});
  const [key, setKey] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(-1);

  const removeImage = React.useCallback((newFields) => {
    setLoading(true);
    if (values && newFields[Fields.fields.photoURL.id].value !== '') {
      firebase.deleteFile(newFields[Fields.fields.photo.id].value)
        .then(() => {
          firebase.expresion(values.id)
            .set({ photo: '', photoURL: '' }, { merge: true })
            .then(() => {
              notification.setMessage({ type: 'success', message: 'Se quito correctamente la imagen.' });
              cleanFields(newFields, setFields);
              setLoading(false);
            })
            .catch((e) => {
              console.error(e);
              notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
              setLoading(false);
            });
        })
        .catch((e) => {
          notification.setMessage({ type: 'error', message: 'Ups! No se pudo quitar la imagen.' });
          setLoading(false);
          console.error(e);
        });
    } else {
      cleanFields(newFields, setFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const newFields = JSON.parse(JSON.stringify(Fields.fields));
    if (values) {
      setKey(values.id);
      Object.keys(values).forEach((item) => {
        newFields[item].value = values[item];
      });
      if (typeof newFields[Fields.fields.photo.id].value !== 'string') {
        newFields[Fields.fields.photo.id].value = '';
        newFields[Fields.fields.photoURL.id].value = '';
      }
      newFields[Fields.fields.photo.id].onRemove = removeImage;
    } else {
      setKey(getRandomString());
    }
    setFields(newFields);
  }, []); // eslint-disable-line

  const isInvalid = !validateForm(fields);

  const endSubmit = React.useCallback((load, success, id) => {
    if (success) {
      let push = `${ROUTES.EXPRESIONES}`;
      if (toEdit && id) {
        push = `${ROUTES.EXPRESIONES}/edit/${id}`;
      }
      history.push(push);
    } else {
      setLoading(load);
    }
  }, [history, toEdit]);

  const submitAction = React.useCallback(() => {
    setLoading(true);
    if (isInvalid) {
      notification.setMessage({ type: 'warning', message: 'Ups! Algo sucede con el formulario.' });
      endSubmit(false);
    } else {
      const dataSet = {
        name: fields.name.value,
        phone: fields.phone.value,
        email: fields.email.value,
        details: fields.details.value,
        title: fields.title.value,
        show: fields.show.value,
        category: fields.category.value,
        start: fields.start.value,
        datetime: firebase.firestore.Timestamp.fromDate(new Date(fields.datetime.value)),
        published: fields.published.value,
      };
      // console.warn(dataSet);
      const isNew = !fields.id.value;
      firebase.expresiones()
        .where('name', '==', dataSet.name)
        .get()
        .then((snapShot) => {
          if ((isNew && !snapShot.empty)
            || (!isNew && !snapShot.empty && snapShot.docs[0].id !== fields.id.value)) {
            notification.setMessage({
              message: 'Ya existe otra expresión artística para un artísta con este nombre.',
              type: 'warning',
            });
            endSubmit(false, false);
          } else {
            if (fields.photo.value === '' || fields.photoURL.value !== '') {
              persist(
                firebase, isNew, fields, authUser, dataSet, notification,
                endSubmit, 'expresiones', 'la expresión artística',
              );
              return;
            }
            uploadFile(
              fields.id.value, fields.photo.file, firebase, setUploaded, 'expresiones',
            ).then((image) => {
              dataSet[Fields.fields.photo.id] = image.reference;
              dataSet[Fields.fields.photoURL.id] = image.url;
              persist(
                firebase, isNew, fields, authUser, dataSet, notification,
                endSubmit, 'expresiones', 'la expresión artística',
              );
            })
              .catch((err) => {
                endSubmit(false, false);
                notification.setMessage({ message: err.message, type: 'error' });
              });
          }
        })
        .catch((e) => {
          endSubmit(false, false);
          notification.setMessage({ message: e.message, type: 'error' });
        });
    }
  }, [authUser, endSubmit, fields, firebase, isInvalid, notification]);

  const onChange = (result) => changeAction(result, fields, setFields);

  return (
    <Grid item xs={12}>
      <MForm
        loading={loading}
        button={{ label: 'Guardar Expresión', disabled: isInvalid }}
        uploaded={uploaded}
        onSubmit={submitAction}
      >
        {generateInputs(fields, onChange, key)}
      </MForm>
    </Grid>
  );
};

ExpresionForm.defaultProps = {
  values: null,
  toEdit: false,
};

ExpresionForm.propTypes = {
  authUser: AuthUserPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  notification: NotificationPropTypes.isRequired,
  values: Fields.getPropTypes(),
  history: ReactRouterPropTypes.history.isRequired,
  toEdit: PropTypes.bool,
};

export default compose(
  withAuthorization(isLolaAdmin),
  withFirebase,
  withNotification,
  withCurrentUser,
  withRouter,
)(ExpresionForm);
