import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/dashboard/container';
import { isAdmin, isCoordinator } from '../../components/access';
import { AuthUserPropTypes, withAuthorization, withCurrentUser } from '../../model/Session';
import ClientForm from './clientForm';
import * as ROUTES from '../../constants/routes';
import { FirebasePropType, withFirebase } from '../../model/Firebase';
import Fields from './fields';
import Loading from '../../components/loading';

const EditClient = ({
  firebase, match, history, authUser,
}) => {
  const [client, setClient] = React.useState({ ...Fields.defaultValues() });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { document } = match.params;
    let unsubscribe;
    if (document) {
      unsubscribe = firebase.client(document).onSnapshot((doc) => {
        const cl = Fields.getInstance(doc);
        if (isAdmin(authUser) || authUser.uid === cl.createdBy) {
          setClient(cl);
          setLoading(false);
        } else {
          history.push(ROUTES.CLIENTES);
        }
      }, () => history.push(ROUTES.CLIENTES));
    } else {
      history.push(ROUTES.CLIENTES);
    }
    return () => !!unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container backPath={ROUTES.CLIENTES} title={`Cliente ${client.firstName} ${client.lastName}`}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          {loading ? (
            <Loading />
          ) : (
            <ClientForm isNew={false} defaultValues={client} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

EditClient.propTypes = {
  firebase: FirebasePropType.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withAuthorization(isCoordinator),
  withFirebase,
  withRouter,
  withCurrentUser,
)(EditClient);
