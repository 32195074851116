import React from 'react';
import * as PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MIconButton from './iconButton';

const styles = () => ({
  buttonEdit: {
    // backgroundColor: grey[50],
    // color: grey[500],
    '&:hover': {
      backgroundColor: grey[100],
      color: green[900],
    },
  },
});

const EditButton = ({ classes, onClick, ...others }) => (
  <MIconButton {...others} onClick={onClick} className={classes.buttonEdit}>
    <EditIcon fontSize="small" />
  </MIconButton>
);

EditButton.defaultProps = {
  onClick: null,
};

EditButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(EditButton);
