import React from 'react';
import FieldsTemplate from '../../../lib/FieldsTemplate';
import { AsyncColumnRender } from '../../../components/table';
import Disciplines from '../Disciplines';
import MChip from '../../../components/chip';

const TYPES = FieldsTemplate.TYPES();

const FIELDS = {
  name: {
    id: 'name',
    label: 'Nombre',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Nombre no válido',
    required: true,
  },
  disciplines: {
    id: 'disciplines',
    label: 'Disciplinas',
    value: [],
    type: 'multiselect',
    validationType: TYPES.MULTISELECT,
    reference: {
      values: Disciplines,
    },
    validationMessage: 'Débe indicar la disciplina de la competencia.',
    required: true,
  },
  acronym: {
    id: 'acronym',
    label: 'Siglas',
    value: '',
    type: 'text',
    validationType: TYPES.NAME,
    validationMessage: 'Siglas no válidas',
    required: true,
  },
  city: {
    id: 'city',
    label: 'Ciudad',
    value: '',
    type: 'select',
    validationType: TYPES.SELECT,
    reference: {
      values: {},
    },
    validationMessage: 'Débe indicar la ciudad.',
    required: true,
  },
  id: {
    id: 'id',
    value: '',
  },
};

class FieldsClub extends FieldsTemplate {
  constructor(fields) {
    super(fields);
    this.tableDefaults = this.tableDefaults.bind(this);
  }
  // eslint-disable-next-line
  tableDefaults(firebase) {
    const columnsObject = this.columnsTable();
    columnsObject.id.hidden = true;
    columnsObject.disciplines.render = (rowData) => !!rowData.disciplines
      && Object.keys(rowData.disciplines).map((item) => (
        <MChip
          key={`${item}${rowData.id}`}
          id={`${item}${rowData.id}`}
          label={Disciplines[item].label}
          color="primary"
        />
      ));
    columnsObject.city.render = (rowData) => (
      <AsyncColumnRender
        loadFunction={() => new Promise((resolve) => {
          firebase.city(rowData.city).get().then((doc) => {
            const city = doc.data();
            if (city.department) {
              firebase.department(city.department).get().then((docDep) => {
                if (docDep.exists) {
                  resolve(`${city.name} - ${docDep.data().name}`);
                } else {
                  resolve(city.name);
                }
              });
            } else {
              resolve(city.name);
            }
          });
        })}
      />
    );
    return {
      title: 'Listado de Clubes',
      columns: Object.values(columnsObject),
    };
  }
}

export default new FieldsClub(FIELDS);
