import React from 'react';
import * as PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import { MForm, generateInputs } from '../../../components/form';
import { isAeroAdmin } from '../../../components/access';
import {
  withAuthorization,
} from '../../../model/Session';
import { withFirebase, FirebasePropType } from '../../../model/Firebase';
import Fields from './fields';
import { validateForm } from '../../../components/validator';
import { withNotification, NotificationPropTypes } from '../../../components/notification';
import * as ROUTES from '../../../constants/routes';
import {
  useCities, saveData, onChange, useFields, alreadyExist,
} from '../util/formHelper';

const ClubForm = ({
  firebase, notification, history, defaultValues, isNew,
}) => {
  const [fields, setFields] = useFields(Fields, defaultValues);
  const [loading, setLoading] = React.useState(false);

  useCities(firebase, notification, fields, setFields);

  const existNameOrAcronym = async (name, acronym) => {
    if (await alreadyExist(firebase, 'clubes', isNew, fields, 'name', name)) {
      return 'Ya existe un club con ese nombre.';
    }
    if (await alreadyExist(firebase, 'clubes', isNew, fields, 'acronym', acronym)) {
      return 'Las siglas indicadas ya se encuentran en uso para otro club.';
    }
    return false;
  };

  const onSubmit = () => {
    setLoading(true);
    const dataSet = {
      name: fields.name.value,
      acronym: fields.acronym.value,
      city: fields.city.value,
      disciplines: {},
    };
    if (fields.disciplines.value.length > 0) {
      fields.disciplines.value.forEach((item) => {
        dataSet.disciplines[item] = true;
      });
      if (defaultValues && defaultValues.disciplines) {
        Object.keys(defaultValues.disciplines).forEach((disciplineName) => {
          if (!dataSet.disciplines[disciplineName]) {
            dataSet.disciplines[disciplineName] = firebase.firestore.FieldValue.delete();
          }
        });
      }
    }
    existNameOrAcronym(dataSet.name, dataSet.acronym)
      .then((res) => {
        if (res) {
          notification.setMessage({
            message: res,
            type: 'warning',
          });
        } else {
          const reference = isNew ? firebase.clubes().doc()
            : firebase.club(fields.id.value);
          if (isNew) {
            saveData(
              reference,
              dataSet,
              'Se creó el nuevo club 😀',
              null,
              setFields,
              notification,
              history,
              Fields,
            );
          } else {
            saveData(
              reference,
              dataSet,
              'Se modificó el club 😀',
              ROUTES.CLUBES,
              setFields,
              notification,
              history,
              Fields,
            );
          }
        }
      })
      .catch((e) => {
        notification.setMessage({ message: e.message, type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onChangeInputs = (result) => onChange(result, fields, setFields);

  const isInvalid = !validateForm(fields);

  return (
    <MForm
      onSubmit={onSubmit}
      loading={loading}
      button={{
        label: `${isNew ? 'Crear' : 'Editar'} Club`,
        disabled: isInvalid,
      }}
    >
      {generateInputs(fields, onChangeInputs)}
    </MForm>
  );
};

ClubForm.defaultProps = {
  defaultValues: null,
  isNew: true,
};

ClubForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  // eslint-disable-next-line
  defaultValues: PropTypes.any,
  isNew: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  notification: NotificationPropTypes.isRequired,
};

export default compose(
  withAuthorization(isAeroAdmin),
  withFirebase,
  withRouter,
  withNotification,
)(ClubForm);
