import React from 'react';
import { compose } from 'recompose';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { FirebasePropType, withFirebase } from '../../../model/Firebase';
import { AuthUserPropTypes, withCurrentUser } from '../../../model/Session';
import Fields from '../fields';
import EditorBusinessDetailes from './editor';
import { isEditorExtrict } from '../../../components/access';
import Loading from '../../../components/loading';
import { SectionTitle } from '../../../components/title';
import { getRandomString } from '../../../lib/misc';
import { domain } from '../../../model/Images';

const isReadOnly = (place, authUser) => !(isEditorExtrict(authUser)
  || (place && place.editor === authUser.uid));

const getFilePath = (file, place) => encodeURI(
  `/images/places/${place.id}/${getRandomString()}${file.name.replace(/[^a-zA-Z0-9-._]/g, '')}`,
);

const getFinalUrl = url => `${domain}${url.replace('/images', '')}`;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StoryPanel = ({ place, firebase, authUser }) => {
  const readOnly = isReadOnly(place, authUser);
  const classes = useStyles();

  const uploadImage = file => new Promise((resolve, reject) => {
    const filePath = getFilePath(file, place);
    firebase.uploadFile(filePath, file, () => {
    }, (err) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(getFinalUrl(filePath));
    });
    return file;
  });

  const deleteImage = reference => new Promise((resolve, reject) => {
    firebase.deleteFile(reference)
      .then(() => resolve())
      .catch(e => reject(e));
  });

  const handleUpdate = (newValue) => {
    if (place) {
      firebase.place(place.id)
        .set(newValue, { merge: true });
    }
  };

  const editorDetails = place ? (
    <EditorBusinessDetailes
      readOnly={readOnly}
      place={place}
      changeEvent={handleUpdate}
      uploadImage={uploadImage}
      deleteImage={deleteImage}
      property={Fields.fields.story.id}
    />
  ) : (
    <Loading size={20} variant="h6" />
  );

  return (
    <Paper className={classes.paper}>
      <SectionTitle title="History destacada del Lugar/Negocio" marginBottom />
      {editorDetails}
    </Paper>
  );
};

StoryPanel.defaultProps = {
  place: null,
};

StoryPanel.propTypes = {
  place: Fields.getPropTypes(),
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropTypes.isRequired,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(StoryPanel);
