import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core';
import { isEditor } from '../../components/access';
import { withCurrentUser, AuthUserPropTypes } from '../../model/Session';
import Fields, { DATE_STATUS } from './fields';
import { NotificationPropTypes, withNotification } from '../../components/notification';
import { FirebasePropType, withFirebase } from '../../model/Firebase';

const useStyles = makeStyles(() => ({
  root: {
    color: green[400],
  },
}));

const DoneButton = ({
  date, authUser, firebase, notification, processing, setProcessing,
}) => {
  const classes = useStyles();
  if (date && (isEditor(authUser) || (date.status === DATE_STATUS.scheduled.value
    && (authUser.uid === date.createdBy || Fields.isInvitee(authUser, date.invitees))))) {
    const handleClick = () => {
      firebase.date(date.id)
        .set({
          status: DATE_STATUS.done.value,
          observations: date.observations,
        }, { merge: true })
        .then(() => notification.setMessage({
          message: 'Cita marcada como atendida satisfactoriamente 🙂', type: 'success',
        }))
        .catch(() => notification.setMessage({
          message: 'Algo no salió bien marcando como atentida la cita 😥', type: 'warning',
        }))
        .finally(() => setProcessing(false));
    };

    return (
      <Button
        disabled={processing}
        component="button"
        onClick={handleClick}
        className={classes.root}
      >
        <CheckIcon />
        Cita Atendida
      </Button>
    );
  }
  return null;
};

DoneButton.defaultProps = {
  date: null,
};

DoneButton.propTypes = {
  date: Fields.getPropTypes(),
  authUser: AuthUserPropTypes.isRequired,
  notification: NotificationPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  processing: PropTypes.bool.isRequired,
  setProcessing: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withNotification,
  withFirebase,
)(DoneButton);
