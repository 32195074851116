import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

const MSwitch = ({
  required, id, label, value, onChange,
}) => {
  const changeEvent = () => {
    const newValue = !(value === true);
    const result = {
      id,
      value: newValue,
      isValid: !(required && !newValue),
    };
    onChange(result);
  };

  return (
    <FormControlLabel
      value={label}
      control={<Switch checked={value === true} required={required} onChange={changeEvent} color="primary" />}
      label={label}
      labelPlacement="top"
    />
  );
};

MSwitch.defaultProps = {
  required: false,
  onChange: () => {},
};

MSwitch.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func,
};

export default MSwitch;
