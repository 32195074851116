import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { observer } from 'mobx-react-lite';
import NotificationContent from './NotificationContent';
import NotificationStore from './store';

const NotificationReceiver = observer(() => {
  const store = React.useContext(NotificationStore);
  const handleClose = () => {
    store.close();
  };
  return (
    !!store.message && (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={store.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <NotificationContent
          onClose={handleClose}
          variant={store.message.type}
          message={store.message.message}
        />
      </Snackbar>
    )
  );
});

export default NotificationReceiver;
