import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { isAeroAdmin } from '../../../components/access';
import { withAuthorization } from '../../../model/Session';
import * as ROUTES from '../../../constants/routes';
import TablePiloto from './table';
import NewPiloto from './newPiloto';
import EditPiloto from './editPiloto';
import GenerateLicense from './generateLicense';

const PilotosPage = () => (
  <Switch>
    <Route path={`${ROUTES.PILOTOS}/new`} component={NewPiloto} />
    <Route path={`${ROUTES.PILOTOS}/edit/:id`} component={EditPiloto} />
    <Route path={`${ROUTES.PILOTOS}/license/:id`} component={GenerateLicense} />
    <Route path={ROUTES.PILOTOS} component={TablePiloto} />
  </Switch>
);

export default withAuthorization(isAeroAdmin)(PilotosPage);
